// 所有埋点事件名称枚举
export enum EventName {
  PAGE_VIEW = 'PAGE_VIEW',
  PAGE_CLICK = 'PAGE_CLICK',
  PAGE_TIME = 'PAGE_TIME',
  POPUP_VIEW = 'POPUP_VIEW',
  SEARCH_CLICK = 'search_click',
  WRITE_ERROR = 'write_error',
  SUBMIT_ERROR = 'submit_error',
  RD_API_RESULT = 'rd_api_result',

  // 达人广场
  FIND_CREATORS_CLICK = 'find_creators_click',
  FIND_CREATORS_PAGE_SHOW = 'find_creators_page_show',
  ECOMMERCE_AFFILIATE_HOME_PAGE_SHOW = 'ecommerce_affiliate_home_page_show',
  ECOMMERCE_AFFILIATE_HOME_PAGE_TIME = 'ecommerce_affiliate_home_page_time',
  ECOMMERCE_AFFILIATE_ERROR_PAGE_SHOW = 'ecommerce_affiliate_error_page_show',
  ECOMMERCE_AFFILIATE_ERROR_PAGE_CLICK = 'ecommerce_affiliate_error_page_click',
  ECOMMERCE_AFFILIATE_ERROR_TOAST_SHOW = 'ecommerce_affiliate_error_toast_show',
  ECOMMERCE_AFFILIATE_ANNOUNCEMENT_SHOW = 'ecommerce_affiliate_announcement_show',
  ECOMMERCE_AFFILIATE_ANNOUNCEMENT_CLICK = 'ecommerce_affiliate_announcement_click',
  ECOMMERCE_AFFILIATE_SELLER_CENTER_CLICK = 'ecommerce_affiliate_seller_center_click',
  ECOMMERCE_AFFILIATE_TCM_CREATOR_LIST_CNT = 'ecommerce_affiliate_find_creator_screen_cnt',
  ECOMMERCE_AFFILIATE_TCM_CREATOR_LIST_PAGE_TIME = 'ecommerce_affiliate_find_creator_page_time',
  ECOMMERCE_AFFILIATE_TCM_CREATOR_LIST_PAGE_SHOW = 'ecommerce_affiliate_find_creator_page_show',
  ECOMMERCE_AFFILIATE_TCM_CREATOR_LIST_PAGE_CLICK = 'ecommerce_affiliate_find_creator_page_click',
  ECOMMERCE_AUTHOR_HOMEPAGE_SHOW = 'ecommerce_author_homepage_show',
  ECOMMERCE_AUTHOR_HOMEPAGE_CLICK = 'ecommerce_author_homepage_click',
  ECOMMERCE_AUTHOR_HOMEPAGE_TAB_TIME = 'ecommerce_author_homepage_tab_time',
  ECOMMERCE_AUTHOR_HOMEPAGE_HOVER_SHOW = 'ecommerce_author_homepage_hover_show',
  ECOMMERCE_AUTHOR_HOMEPAGE_POPUP_CLICK = 'ecommerce_author_homepage_popup_click',
  ECOMMERCE_AUTHOR_INFO_POPUP_SHOW = 'ecommerce_author_info_popup_show',

  // 商家邀请达人弹窗相关
  TIKTOK_EC_WEB_AFFILIATE_AUTHOR_INVITE_CLICK = 'tiktok_ec_web_affiliate_author_invite_click',
  TIKTOK_EC_WEB_AFFILIATE_AUTHOR_CONTACT_CLICK = 'tiktok_ec_web_affiliate_author_contact_click',
  TIKTOK_EC_WEB_AFFILIATE_AUTHOR_INVITE_POPUP_SHOW = 'tiktok_ec_web_affiliate_author_invite_popup_show',
  TIKTOK_EC_WEB_AFFILIATE_AUTHOR_INVITECREATOR_CLICK = 'tiktok_ec_web_affiliate_author_invitecreator_click',
  TIKTOK_EC_WEB_AFFILIATE_AUTHOR_INVITECREATOR_SEND_CLICK = 'tiktok_ec_web_affiliate_author_invitecreator_send_click',
  TIKTOK_EC_WEB_AFFILIATE_AUTHOR_INVITECREATOR_SUCCESS_SHOW = 'tiktok_ec_web_affiliate_author_invitecreator_success_show',
  TIKTOK_EC_WEB_AFFILIATE_AUTHOR_INVITECREATOR_CANCEL_CLICK = 'tiktok_ec_web_affiliate_author_invitecreator_cancel_click',

  // 邀约历史页
  ECOMMERCE_AFFILIATE_INVITATION_HISTORY_PAGE_SHOW = 'ecommerce_affiliate_invitation_history_page_show',
  ECOMMERCE_AFFILIATE_INVITATION_HISTORY_PAGE_TIME = 'ecommerce_affiliate_invitation_history_page_time',
  ECOMMERCE_AFFILIATE_INVITATION_HISTORY_PAGE_CLICK = 'ecommerce_affiliate_invitation_history_page_click',

  // 数据首页
  ECOMMERCE_DATA_HOMEPAGE_SHOW = 'ecommerce_data_homepage_show',
  ECOMMERCE_DATA_HOMEPAGE_CLICK = 'ecommerce_data_homepage_click',
  ECOMMERCE_DATA_HOMEPAGE_HOVER_SHOW = 'ecommerce_data_homepage_hover_show',
  ECOMMERCE_DATA_HOMEPAGE_TIME = 'ecommerce_data_homepage_time',
  ECOMMERCE_DATA_HOMEPAGE_METRIC_PAGE = 'ecommerce_date_homepage_index_turn_click',

  // rd埋点，错误相关埋点
  RD_LOAD_MODULE_ERROR = 'rd_load_module_error',
  RD_RENDER_ERROR = 'rd_render_error',

  // rd埋点，其他
  RD_CREATE_PLAN = 'rd_create_plan',
}

// 常用埋点参数Keys，
// Keys尽量都做成枚举，省得拼错或者统一修改的时候被遗漏了；
export enum EventParamKeys {
  PAGE_NAME = 'page_name',
  BUTTON_FOR = 'button_for',
  BUTTON_AREA = 'button_area',
  POPUP_TITLE = 'popup_title',
  POPUP_FOR = 'popup_for',
  ENTER_FROM = 'enter_from',
  PAIR_SOURCE = 'pair_source',
  QUERY = 'query',
  SEARCH_TYPE = 'search_type',
  DURATION = 'duration',
  ERROR_AREA = 'error_area',
  ERROR_DETAIL = 'error_detail',
  ERROR_INFO = 'error_info',
  ERROR_NUM = 'error_num',
  CLICK_FOR = 'click_for',
  ERROR_TYPE = 'error_type',
  PREVIOUS_PATH = 'previous_path',
  PREVIOUS_PAGE = 'previous_page',
  PATH = 'path',
  PLAN_CNT = 'plan_cnt',
  SEARCH_DIMENSION = 'search_dimension',
  PLAN_STATUS = 'plan_status',
  PLAN_ID = 'plan_id',
  OPERATION_FOR = 'operation_for',
  PRODUCT_CNT = 'product_cnt',
  AUTHOR_ID = 'author_id',
  SELLER_ID = 'seller_id',

  // rd埋点，错误相关参数
  ERROR_MESSAGE = 'error_message',
  MODULE_NAME = 'module_name',
  PAGE_QUERY = 'page_query',
  PAGE_PATH = 'page_path',
  PAGE_HOST = 'page_host',

  // rd埋点，其他参数
  PLAN_TYPE = 'plan_type',
  RESULT = 'result',
  PAGE_TYPE = 'page_type',
  SEARCH_TERMS = 'search_terms',
  SEARCH_KEY = 'search_key',
  RESULT_CNT = 'result_cnt',
  SEARCH_PARAM = 'search_param',
  AUTHOR_SORT = 'author_sort',
  SCREEN_CNT = 'screen_cnt',
  REGISTER_REGION_ID = 'register_region_id',
  HOVER_FOR = 'hover_for',
  API_PATH = 'api_path',
  CREATOR_SCORE = 'creator_score',
  IS_MCN_CREATOR = 'is_mcn_creator',
}

export enum EventParamPageNameValues {
  PUBLIC_PLAN_LIST = 'open_plan',
  TARGET_PLAN_LIST = 'targeted_plan_list',
  ADD_PUBLIC_PLAN = 'create_open_plan',
  STEP_1 = 'step1',
  STEP_2 = 'step2',
  ADD_SHOP_PLAN = 'create_shop_plan',
  SHOP_PLAN_HOME = 'affiliate_shopplan_home',
  SHOP_PLAN_STEP2 = 'affiliate_shopplan_step2',
  SHOP_PLAN_RESULT = 'affiliate_shopplan_result',
  ADD_TARGET_PLAN = 'add_targeted_plan',
  MANAGE_CREATOR = 'manage_creator',
  EDIT_PUBLIC_PLAN = 'edit_open_plan',
  EDIT_TARGET_PLAN = 'edit_targeted_plan',
  CHECK_TARGET_PLAN = 'check_plan',
  HOME_PAGE = 'home_page',
  FIND_CREATORS = 'find_creators',
  AFFILIATE_ORDER = 'affiliate_order',
  ANNOUNCEMENT = 'announcement',
  AUTHOR_HOMEPAGE = 'author_homepage',
  ERROR_PAGE = 'error_page',
  ACCOUNT_INFO = 'ttec_web_affiliate_account_contact',
  CONTACT_INFO = 'contact_info',
  DATA_HOMEPAGE = 'data_homepage',
  SHOP_PLAN = 'affiliate_shopplan_home',
  LINK_CREATOR = 'affiliate_partner_enter_homepage',
  INVITATION_HISTORY = 'invitation_history',
  SAMPLE_REQUEST = 'sample_request',
}
