export enum ApiCode {
  TIMEOUT = 'timeout',
  FAIL = 'fetch_fail',
  SUCCESS = 0,
  SERVER_ERROR = 98001001,
  NOT_LOGGED_IN = 98001002,
  INVALID_PARAMS = 98001004,
  USER_NOT_EXIST = 98001006,
  ACCOUNT_NO_PERMISSION = 98001008,
  PARTNER_NOT_LOGGED_IN = 16201010,
  NO_ALLIANCE_PERMISSION = 16201001,
  SELLER_ACCOUNT_UNDER_REVIEW = 16201018,
  SELLER_ACCOUNT_DEACTIVATED = 16201019,
  SELLER_ACCOUNT_NOT_REGISTER_FINISHED = 16201020,
  NOT_SELLER = 16201021,
  EXIST_PLAN = 16003001,
  NOT_ACTIVE = 16003022,
}
type ErrorNameType = {
  [key in string]: string;
};
export const ERROR_NAME: ErrorNameType = {
  timeout: 'TIMEOUT',
  [ApiCode.SERVER_ERROR]: 'SERVER_ERROR',
  [ApiCode.INVALID_PARAMS]: 'INVALID_PARAMS',
};

// slardar log level: 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace';
export enum SlardarLogLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  FATAL = 'fatal',
}

type ApiCodeType = (string | number)[];
const apiErrorLevelMap: { [key: string]: SlardarLogLevel } = {};
export const FATAL_CODE_LIST: ApiCodeType = [
  ApiCode.SERVER_ERROR,
  ApiCode.INVALID_PARAMS,
];
export const ERROR_CODE_LIST: ApiCodeType = [ApiCode.TIMEOUT];
const WARN_CODE_LIST: ApiCodeType = [ApiCode.FAIL];
const INFO_CODE_LIST: ApiCodeType = [
  ApiCode.NOT_LOGGED_IN,
  ApiCode.PARTNER_NOT_LOGGED_IN,
  ApiCode.USER_NOT_EXIST,
  ApiCode.ACCOUNT_NO_PERMISSION,
  ApiCode.NO_ALLIANCE_PERMISSION,
  ApiCode.SELLER_ACCOUNT_UNDER_REVIEW,
  ApiCode.SELLER_ACCOUNT_DEACTIVATED,
  ApiCode.SELLER_ACCOUNT_NOT_REGISTER_FINISHED,
  ApiCode.NOT_SELLER,
  ApiCode.EXIST_PLAN,
  ApiCode.NOT_ACTIVE,
];

function initApiErrorLevelMap() {
  FATAL_CODE_LIST.forEach((item: string | number) => {
    apiErrorLevelMap[String(item)] = SlardarLogLevel.FATAL;
  });
  ERROR_CODE_LIST.forEach((item: string | number) => {
    apiErrorLevelMap[String(item)] = SlardarLogLevel.ERROR;
  });
  WARN_CODE_LIST.forEach((item: string | number) => {
    apiErrorLevelMap[String(item)] = SlardarLogLevel.WARN;
  });
  INFO_CODE_LIST.forEach((item: string | number) => {
    apiErrorLevelMap[String(item)] = SlardarLogLevel.INFO;
  });
}
initApiErrorLevelMap();

export const API_ERROR_LEVEL_MAP = apiErrorLevelMap;

export const API_ERROR_CONTENT_MAP = {
  [String(ApiCode.TIMEOUT)]: 'Server timeout',
  [String(ApiCode.FAIL)]: 'Server fetch fail',
  [String(ApiCode.SERVER_ERROR)]: 'Server has an unknow error occurred',
  [String(ApiCode.NOT_LOGGED_IN)]:
    'Current user not login or login info expired',
  [String(ApiCode.PARTNER_NOT_LOGGED_IN)]:
    'Current partner user not login or login info expired',
  [String(ApiCode.INVALID_PARAMS)]: 'Current params invalid',
  [String(ApiCode.USER_NOT_EXIST)]: 'Current creator not exist',
  [String(ApiCode.ACCOUNT_NO_PERMISSION)]: 'Account not permission',
  [String(ApiCode.NO_ALLIANCE_PERMISSION)]: 'Account not alliance permission',
  [String(ApiCode.SELLER_ACCOUNT_UNDER_REVIEW)]: 'Account under review',
  [String(ApiCode.SELLER_ACCOUNT_DEACTIVATED)]: 'Account deactivated',
  [String(ApiCode.SELLER_ACCOUNT_NOT_REGISTER_FINISHED)]:
    'Account not register finished',
  [String(ApiCode.NOT_SELLER)]: 'Account type is not seller',
  [String(ApiCode.EXIST_PLAN)]: 'Has actived shop plan',
  [String(ApiCode.NOT_ACTIVE)]: 'Current shop plan not active',
  default: 'api error',
};
