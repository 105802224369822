export const LOGID_HEADER = 'x-tt-logid';

export const REQUEST_TIMESTAMP_HEADER = 'request-start-time';

export const FIELD_NOT_AVAILABLE = 'not available';

export const NO_RESPONSE_MESSAGE = 'no response received ';

export const NO_REQUEST_MESSAGE = 'no request sent ';

export const NO_INTERCEPTOR_ADDED_MESSAGE = 'no interceptor added ';

export const NO_INTERCEPTOR_REMOVED_MESSAGE = 'no interceptor removed ';

export enum RDAPIEventType {
  // Request made and server responded
  RESPONSE_ERROR = 'RDAPI_response_error',
  // The request was made but no response was received
  NO_RESPONSE = 'RDAPI_no_response',
  // Report missing requests as 'RDAPI_no_response' events on Slardar too
  NO_REQUEST = 'RDAPI_no_response',
  // Error inserting or deleting interceptor
  INTERCEPTOR_ERROR = 'RDAPI_interceptor_error',
}

export enum SlardarPageName {
  plan_analytics = 'plan_analytics',
}

export enum SlardarSectionName {
  plan_list = 'plan_list',
  plan_stats = 'plan_stats',
}

export type SomeType = string | number | boolean | any;

export type TagsType = Record<string, SomeType>;

export type StringTags = Record<string, string>;

export type ResponseType = {
  headers?: any;
  data?: any;
  config?: any;
  status?: any;
};

export type ErrorType = {
  api_code?: any;
  api_url?: string;
  http_code?: any;
  isFetchError?: boolean;
  log_id?: string;
  message?: string;
};
