import { i18n } from '@ecom-alliance/libs';
import { NumberFormat, AbbreviationKey } from '@i18n-ecom/universal-sdk';
var lastRegion = '';
var lastLocale = '';
var shortNumberFormat = new NumberFormat({
  // To change in the future for all the locales
  validAbbreviations: [AbbreviationKey.THOUSAND, AbbreviationKey.MILLION, AbbreviationKey.BILLION, AbbreviationKey.TRILLION],
  roundingDp: {
    normal: 2,
    abbreviated: 1
  }
}); /**
    * Universal SDK
    */
export function formatShortNumber(num) {
  return shortNumberFormat.formatNumber(num);
}
var longNumberFormat = new NumberFormat({
  validAbbreviations: []
});
export function formatLongNumber(num) {
  return longNumberFormat.formatNumber(num);
}
var currencyFormat = new NumberFormat({
  validAbbreviations: []
});
export function formatCurrency(num, currency) {
  var _i18n$origin;
  var locale = (i18n === null || i18n === void 0 ? void 0 : (_i18n$origin = i18n.origin) === null || _i18n$origin === void 0 ? void 0 : _i18n$origin.language) || 'en';
  return currencyFormat.formatCurrency(num, locale, currency);
}
export function reInitUniversalSDK(region) {
  var _i18n$origin2;
  var locale = (i18n === null || i18n === void 0 ? void 0 : (_i18n$origin2 = i18n.origin) === null || _i18n$origin2 === void 0 ? void 0 : _i18n$origin2.language) || 'en';
  if (region === lastRegion && locale === lastLocale) {
    return;
  }
  lastLocale = locale;
  lastRegion = region;
  shortNumberFormat.init({
    locale: locale,
    region: region
  });
  longNumberFormat.init({
    locale: locale,
    region: region
  });
  currencyFormat.init({
    locale: locale,
    region: region
  });
}