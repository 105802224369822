import _slicedToArray from "/opt/tiger/compile_path/src/code.byted.org/i18n_ecom_fe/ecom_affiliate_seller_data/node_modules/.pnpm/@babel+runtime@7.21.0/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { createModel } from '@byted/hooks';
import { useState } from 'react';
import { SafeLocalStorage } from '@/utils/safe-local-storage';
var SeenFeatureKeys;
(function (SeenFeatureKeys) {
  SeenFeatureKeys["EU_INTERCONNECT_BANNER_PRODUCT"] = "eu-interconnect-banner-product";
  SeenFeatureKeys["EU_INTERCONNECT_BANNER_COLLABORATION"] = "eu-interconnect-banner-collaboration";
  SeenFeatureKeys["EU_INTERCONNECT_BANNER_CREATOR"] = "eu-interconnect-banner-creator";
})(SeenFeatureKeys || (SeenFeatureKeys = {}));
var useSeenFeatureState = function useSeenFeatureState(key) {
  var _useState = useState(SafeLocalStorage.getItemSeen(key)),
    _useState2 = _slicedToArray(_useState, 2),
    hasSeen = _useState2[0],
    setSeen = _useState2[1];
  return {
    hasSeen: hasSeen,
    setToSeen: function setToSeen() {
      var noErrors = SafeLocalStorage.setSeenItem(key);
      if (noErrors) {
        setSeen(true);
      }
    }
  };
};
var useSeenFeature = function useSeenFeature() {
  var _useSeenFeatureState = useSeenFeatureState(SeenFeatureKeys.EU_INTERCONNECT_BANNER_PRODUCT),
    hasSeenEuInterconnectBannerProduct = _useSeenFeatureState.hasSeen,
    setSeenEuInterconnectBannerProduct = _useSeenFeatureState.setToSeen;
  var _useSeenFeatureState2 = useSeenFeatureState(SeenFeatureKeys.EU_INTERCONNECT_BANNER_COLLABORATION),
    hasSeenEuInterconnectBannerCollaboration = _useSeenFeatureState2.hasSeen,
    setSeenEuInterconnectBannerCollaboration = _useSeenFeatureState2.setToSeen;
  var _useSeenFeatureState3 = useSeenFeatureState(SeenFeatureKeys.EU_INTERCONNECT_BANNER_CREATOR),
    hasSeenEuInterconnectBannerCreator = _useSeenFeatureState3.hasSeen,
    setSeenEuInterconnectBannerCreator = _useSeenFeatureState3.setToSeen;
  return {
    hasSeenEuInterconnectBannerProduct: hasSeenEuInterconnectBannerProduct,
    setSeenEuInterconnectBannerProduct: setSeenEuInterconnectBannerProduct,
    hasSeenEuInterconnectBannerCollaboration: hasSeenEuInterconnectBannerCollaboration,
    setSeenEuInterconnectBannerCollaboration: setSeenEuInterconnectBannerCollaboration,
    hasSeenEuInterconnectBannerCreator: hasSeenEuInterconnectBannerCreator,
    setSeenEuInterconnectBannerCreator: setSeenEuInterconnectBannerCreator
  };
};
export default createModel(useSeenFeature);