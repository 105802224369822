import { ApiError } from '@i18n-ecom/logcatcher';
import {
  ApiReportOptions,
  ReportOptions,
} from '@i18n-ecom/logcatcher/lib/logger/types';

export function getNameToMakeItCompatibleWithAffiliateHostLibrary(
  name: string,
) {
  // Changing this format may cause slardar log to be ignored. Check properly before changing
  return `[error][${name}]`;
}

export class AffiliateAPIError extends ApiError {
  constructor(
    message: string,
    apiOptions: ApiReportOptions,
    options?: ReportOptions,
  ) {
    super(message, apiOptions, options);
    this.name = getNameToMakeItCompatibleWithAffiliateHostLibrary(this.name);
  }
}
