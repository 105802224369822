import React, { useEffect, useRef } from 'react';
import {
  Redirect as ReactRedirect,
  RedirectProps as ReactRedirectProps,
} from '@jupiter/plugin-runtime/router';

const NOOP = () => {};

export interface RedirectProps extends ReactRedirectProps {
  onEnter?: () => void;
  onLeave?: () => void;
}
export function Redirect(props: RedirectProps) {
  const { onEnter = NOOP, onLeave = NOOP, to, ...rest } = props;
  const onEnterRef = useRef(onEnter);
  onEnterRef.current = onEnter;
  const onLeaveRef = useRef(onLeave);
  onLeaveRef.current = onLeave;

  const ObjectToParam =
    typeof to === 'string'
      ? {
          pathname: to,
          search: location.search,
        }
      : to;

  useEffect(() => {
    onEnterRef.current();
    return () => {
      onLeaveRef.current();
    };
  }, []);

  return <ReactRedirect {...rest} to={ObjectToParam} />;
}
