export enum ContactInfoMap {
  WhatsApp = 'WhatsApp',
  Email = 'Email',
  Fb = 'Fb',
}

export const FormRegExp = {
  [ContactInfoMap.Fb]: /^\S*$/,
  [ContactInfoMap.WhatsApp]: /^[0-9]{1,30}$/,
  [ContactInfoMap.Email]:
    /[a-zA-Z0-9.\-_+]{2,32}@[a-zA-Z0-9.\-_]{2,32}\.[A-Za-z]{2,4}/,
};
