// global multi country shop selector's order similar to SELLER_REGION
const SELLER_REGION = {
  GB: 'GB',
  ID: 'ID',
  MY: 'MY',
  PH: 'PH',
  TH: 'TH',
  VN: 'VN',
  SG: 'SG',
};

module.exports = {
  SELLER_REGION,
};
