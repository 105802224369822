var generateKey = function generateKey(key) {
  return "seen-feature-" + key;
};
export var SafeLocalStorage = {
  /**
  * Sets key-value in local storage.
  * @returns whether error was thrown or not.
  */
  setSeenItem: function setSeenItem(itemKey) {
    try {
      localStorage.setItem(generateKey(itemKey), 'true');
      return true;
    } catch (error) {
      return false;
    }
  },
  getItemSeen: function getItemSeen(itemKey) {
    return localStorage.getItem(generateKey(itemKey)) === 'true';
  }
};