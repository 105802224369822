export enum RoutePath {
  DataOverview = '/data/overview',
  DataOverviewRelativePath = '/overview',

  DataPlan = '/data/plan',
  DataPlanRelativePath = '/plan',

  CreatorAnalysis = '/data/creator-analysis',
  CreatorAnalysisRelativePath = '/creator-analysis',

  CreateTargetedPlan = '/connection/target-invitation',

  Video = '/data/video',
  VideoRelativePath = '/video',
}
