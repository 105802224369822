import { ByteDate } from '@i18n-ecom/universal-sdk'; /**
                                                     * Universal SDK
                                                     */
var supportedLocales = new Set(['en-US', 'en-GB', 'en-SG', 'en-ES', 'id-ID', 'ms-MY', 'pt-BR', 'th-TH', 'vi-VN', 'zh-CN']);
export var getDateFormatterLocale = function getDateFormatterLocale(locale) {
  if (supportedLocales.has(locale)) {
    return locale;
  }
  if (locale.startsWith('zh')) {
    return 'zh-CN';
  }
  return 'en-GB';
};
export var dateFormatter = new ByteDate();