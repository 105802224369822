export enum SellerAccountStatus {
  NEW_CREATE = 0,
  PENDING_TO_REVIEW = 1,
  ACTIVE = 2,
  DEACTIVE = 3,
  REVIEW_REJECTED = 4,
  IN_ACTIVE = 5,
  WITHDRAW = 6,
  PLATFORM_DISMISS = 7,
}
