import {
  FIELD_NOT_AVAILABLE,
  LOGID_HEADER,
  NO_INTERCEPTOR_ADDED_MESSAGE,
  NO_INTERCEPTOR_REMOVED_MESSAGE,
  RDAPIEventType,
  REQUEST_TIMESTAMP_HEADER,
  ResponseType,
  SlardarPageName,
  SlardarSectionName,
} from '@features/constants/slardar';

import { Logger, fetchWrapper } from '@ecom-alliance/libs';
import { logError, logInfo } from '@i18n-ecom/logcatcher';
import { get } from 'lodash';
import { ErrorInfo } from 'react';
import { AffiliateAPIError } from './apiError';
import { AffiliateBaseError } from './baseError';

const urlParser = document.createElement('a');

const defaultFields = {
  api_url: FIELD_NOT_AVAILABLE,
  api_domain: FIELD_NOT_AVAILABLE,
  api_code: FIELD_NOT_AVAILABLE,
  api_cost: FIELD_NOT_AVAILABLE,
  time: FIELD_NOT_AVAILABLE,
  log_id: FIELD_NOT_AVAILABLE,
  http_status: FIELD_NOT_AVAILABLE,
};

const formatRDAPI = (config: any, error?: any, _url?: string) => {
  urlParser.href = config?.url || _url || '';
  const requestTime = Number(get(config?.headers, REQUEST_TIMESTAMP_HEADER));

  return {
    ...defaultFields,
    api_url: urlParser.pathname ?? FIELD_NOT_AVAILABLE,
    api_domain: urlParser.hostname ?? FIELD_NOT_AVAILABLE,
    api_cost: requestTime ? `${Date.now() - requestTime}` : FIELD_NOT_AVAILABLE,
    time: `${requestTime ?? FIELD_NOT_AVAILABLE}`,
    error_log: error?.stack ?? error ?? FIELD_NOT_AVAILABLE,
  };
};

function getApiInfo(url: string) {
  urlParser.href = url;

  return {
    api_url: urlParser.pathname ?? FIELD_NOT_AVAILABLE,
    api_domain: urlParser.hostname ?? FIELD_NOT_AVAILABLE,
  };
}

const logAxiosParseErrorToRDAPI = (event: RDAPIEventType, error: any) => {
  logError(
    new AffiliateBaseError(
      `Axios parse JS error: ${error}`,
      'Axios parse JS error',
      {
        extra: {
          message: error.message,
          error_log: error?.stack ?? error ?? FIELD_NOT_AVAILABLE,
        },
      },
    ),
  );
  Logger.info(event, {
    ...defaultFields,
    message: `axios parse error ${error?.message}`,
    error_log: error?.stack ?? error ?? FIELD_NOT_AVAILABLE,
  });
};

const onResponseError = (response: ResponseType, error?: any) => {
  try {
    const { headers, data, config, status } = response || {};
    logError(
      new AffiliateAPIError(
        `Response error - ${data.message}`,
        {
          httpCode: status ? String(status) : FIELD_NOT_AVAILABLE,
          businessCode: data.code ?? FIELD_NOT_AVAILABLE,
          logId: get(headers, LOGID_HEADER) ?? FIELD_NOT_AVAILABLE,
          url: config.url ?? FIELD_NOT_AVAILABLE,
        },
        {
          extra: {
            ...getApiInfo(config.url),
            apiMessage: data.message ?? FIELD_NOT_AVAILABLE,
          },
        },
      ),
    );

    Logger.info(RDAPIEventType.RESPONSE_ERROR, {
      ...formatRDAPI(config, error),
      api_code: `${data.code ?? FIELD_NOT_AVAILABLE}`,
      log_id: get(headers, LOGID_HEADER) ?? FIELD_NOT_AVAILABLE,
      http_status: `${status}` ?? FIELD_NOT_AVAILABLE,
      message: `${data.message ?? FIELD_NOT_AVAILABLE}`,
    });
  } catch (e: any) {
    logAxiosParseErrorToRDAPI(RDAPIEventType.RESPONSE_ERROR, e);
    logError(
      new AffiliateBaseError(
        `Parse error response failed ${e?.message}`,
        'AxiosParseError',
        {
          extra: {
            message: e.message,
          },
        },
      ),
    );
  }
};

const onNoInterceptorAdded = (error: any) => {
  const { config } = error ?? {};
  Logger.info(RDAPIEventType.INTERCEPTOR_ERROR, {
    ...formatRDAPI(config, error),
    message:
      NO_INTERCEPTOR_ADDED_MESSAGE +
      (error?.message ? `: ${error?.message}` : ''),
  });
};

const onNoInterceptorRemoved = (error: any) => {
  const { config } = error ?? {};
  Logger.info(RDAPIEventType.INTERCEPTOR_ERROR, {
    ...formatRDAPI(config, error),
    message:
      NO_INTERCEPTOR_REMOVED_MESSAGE +
      (error?.message ? `: ${error?.message}` : ''),
  });
};

export const reportJsError = ({
  error,
  section,
}: {
  error: Error;
  react_info: ErrorInfo;
  section: { page_name: SlardarPageName; section_name: SlardarSectionName };
}) => {
  logError(
    new AffiliateBaseError(`JS Error: ${error.message}`, 'Exception error', {
      extra: {
        message: error.message,
        pageName: section.page_name,
        sectionName: section.section_name,
      },
    }),
  );
  Logger.error('handled_exception', 'error_boundary', error, {
    ...section,
  });
};

export const logRetryEvent = (componentName: string) => {
  logInfo({
    name: 'RETRY_EVENT',
    message: `Retry ${componentName}`,
    extra: {
      page: window?.location?.pathname ?? '--',
      component: componentName ?? '--',
    },
  });
};

const RDAPIResponseInterceptor = {
  fulfilled: (response: ResponseType) => {
    // HTTP 200 && code == 0
    if (response?.data?.status_code && response?.data?.status_code !== 0) {
      onResponseError(response);
    } else if (response?.data?.code !== 0) {
      onResponseError(response);
    }
    return response;
  },
  rejected: (error: any) => {
    logError(
      new AffiliateAPIError(
        `API error - ${error?.message ?? FIELD_NOT_AVAILABLE}`,
        {
          httpCode: error?.http_code ?? FIELD_NOT_AVAILABLE,
          businessCode: error?.api_code ?? FIELD_NOT_AVAILABLE,
          logId: error?.log_id ?? FIELD_NOT_AVAILABLE,
          url: error?.api_url ?? FIELD_NOT_AVAILABLE,
        },
        {
          extra: {
            apiMessage: error?.message,
          },
        },
      ),
    );
    return Promise.reject(error);
  },
};

export const addSlardarRDAPIInterceptor = () => {
  fetchWrapper((fetchInstance: any) => {
    try {
      // request interceptor to add start time to request
      fetchInstance.interceptors.request.use((config: any) => {
        config.headers[REQUEST_TIMESTAMP_HEADER] = Date.now();
        return config;
      });
      // use splice to put our response interceptor as the first interceptor
      fetchInstance?.interceptors?.response?.handlers?.splice(
        0,
        0,
        RDAPIResponseInterceptor,
      );
    } catch (e: any) {
      onNoInterceptorAdded(e);
    }
    return fetchInstance;
  });
};

export const removeSlardarRDAPIInterceptor = () => {
  // callback to remove our interceptors
  fetchWrapper((fetchInstance: any) => {
    try {
      fetchInstance.interceptors.request.handlers?.splice(
        fetchInstance.interceptors.request.handlers.length - 1,
        1,
      );
      fetchInstance.interceptors.response.handlers?.splice(0, 1);
    } catch (e: any) {
      onNoInterceptorRemoved(e);
    }
    return fetchInstance;
  });
};
