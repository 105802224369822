// for tree sharking, this repeat define SELLER_REGION
// if import SELLER_REGION from env.js, some inner domain will not be tree sharking.
export { SELLER_REGION } from './seller-region';

export enum REGION {
  US = 'en-US',
  UK = 'en-GB',
  ID = 'id-ID',
}

export enum CurrencyCode {
  US = 'USD',
  UK = 'GBP',
  ID = 'IDR',
}

export const CURRENCY_MAP = {
  [REGION.US]: CurrencyCode.US,
  [REGION.UK]: CurrencyCode.UK,
  [REGION.ID]: CurrencyCode.ID,
};
