export enum TabKey {
  ALL = 0,
  PENDING = 10,
  READY_TO_SHIP = 20,
  AWAIT_COLLECTION = 21,
  SHIPPED = 30,
  CONTENT_PENDING = 40,
  COMPLETED = 100,
  CANCELLED = 50,
}

export interface SampleItemValueType {
  id: TabKey;
  has_unread: boolean;
  total_count: number;
}

export const sampleTabsInitValue = [
  {
    id: TabKey.ALL,
    has_unread: false,
    total_count: 0,
  },
  {
    id: TabKey.PENDING,
    has_unread: false,
    total_count: 0,
  },
  {
    id: TabKey.READY_TO_SHIP,
    has_unread: false,
    total_count: 0,
  },
  {
    id: TabKey.AWAIT_COLLECTION,
    has_unread: false,
    total_count: 0,
  },
  {
    id: TabKey.SHIPPED,
    has_unread: false,
    total_count: 0,
  },
  {
    id: TabKey.CONTENT_PENDING,
    has_unread: false,
    total_count: 0,
  },
  {
    id: TabKey.COMPLETED,
    has_unread: false,
    total_count: 0,
  },
  {
    id: TabKey.CANCELLED,
    has_unread: false,
    total_count: 0,
  },
];
