import { SELLER_REGION } from './seller-region';

export enum LangMap {
  DE = 'de-DE',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  ID = 'id-ID',
  IT = 'it-IT',
  CN = 'zh-CN',
  MY = 'ms-MY',
  TH = 'th-TH',
  VN = 'vi-VN',
}

export const languageQuery = 'oec_lng';

export const AllowLanguagesMap = ((): { [x: string]: LangMap[] } =>
  isGlobal
    ? {
        [SELLER_REGION.GB]: [LangMap.EN, LangMap.CN],
        [SELLER_REGION.ID]: [LangMap.EN, LangMap.CN],
        [SELLER_REGION.MY]: [LangMap.EN, LangMap.CN],
        [SELLER_REGION.TH]: [LangMap.EN, LangMap.CN],
        [SELLER_REGION.VN]: [LangMap.EN, LangMap.CN],
        [SELLER_REGION.PH]: [LangMap.EN, LangMap.CN],
        [SELLER_REGION.SG]: [LangMap.EN, LangMap.CN],
        default: [LangMap.CN],
      }
    : {
        [SELLER_REGION.GB]: [LangMap.EN, LangMap.ID],
        [SELLER_REGION.ID]: [LangMap.EN, LangMap.ID],
        [SELLER_REGION.MY]: [LangMap.EN, LangMap.MY],
        [SELLER_REGION.TH]: [LangMap.EN, LangMap.TH],
        [SELLER_REGION.VN]: [LangMap.EN, LangMap.VN],
        [SELLER_REGION.PH]: [LangMap.EN],
        [SELLER_REGION.SG]: [LangMap.EN],
        default: [LangMap.EN],
      })();

export type LangOptionsItemType = {
  label: string;
  value: LangMap;
  show: boolean;
};
type LangOptionsType = {
  [key in
    | (
        | LangMap.EN
        | LangMap.ID
        | LangMap.MY
        | LangMap.TH
        | LangMap.VN
        | LangMap.CN
      )
    | string]: LangOptionsItemType;
};
const langOptions: LangOptionsType = {
  [LangMap.EN]: {
    label: 'English',
    value: LangMap.EN,
    show: true,
  },
  [LangMap.ID]: {
    label: 'Bahasa',
    value: LangMap.ID,
    show: true,
  },
  [LangMap.MY]: {
    label: 'Bahasa Melayu',
    value: LangMap.MY,
    show: true,
  },
  [LangMap.TH]: {
    label: 'ไทย',
    value: LangMap.TH,
    show: true,
  },
  [LangMap.VN]: {
    label: 'Tiếng Việt',
    value: LangMap.VN,
    show: true,
  },
  [LangMap.CN]: {
    label: '简体中文',
    value: LangMap.CN,
    show: true,
  },
};

type LanguagesMapType = {
  [key in string | 'global' | 'default']: LangOptionsItemType[];
};

/*
 * {
 *  [SELLER_REGION.GB]: [langOptions[LangMap.EN], langOptions[LangMap.ID]],
 *  'default': [langOptions[LangMap.CN]],
 * }
 */
export const LanguagesMap: LanguagesMapType = ((): {
  [x: string]: LangOptionsItemType[];
} => {
  //  map AllowLanguagesMap to LanguagesMap
  // if need to modify website language, change AllowLanguagesMap
  const languagesMap = {} as LanguagesMapType;
  Object.keys(AllowLanguagesMap).forEach(key => {
    languagesMap[key] = (
      AllowLanguagesMap[key] as unknown as Array<LangMap>
    ).map(ele => langOptions[ele as string]);
  });
  return languagesMap;
})();
