// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.75.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__container--0to3R{width:100%;height:100%;display:-webkit-flex;display:-moz-box;display:flex;-webkit-justify-content:center;-moz-box-pack:center;justify-content:center;-webkit-align-items:center;-moz-box-align:center;align-items:center}.index__container--0to3R>div{width:auto}.index__container--0to3R .arco-spin-icon{color:#009995}.index__spinner--nEiuo .arco-icon-loading{height:28px;width:28px}.index__spinnerImg--JP7uE{width:28px;height:28px}", "",{"version":3,"sources":["webpack://./src/components/loading/index.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CACA,WAAA,CACA,oBAAA,CAAA,gBAAA,CAAA,YAAA,CACA,8BAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,0BAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,6BACE,UAAA,CAGA,yCACE,aAAA,CAOF,0CACE,WAAA,CACA,UAAA,CAIJ,0BACE,UAAA,CACA,WAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  > div {\n    width: auto;\n  }\n  :global {\n    .arco-spin-icon {\n      color: #009995;\n    }\n  }\n}\n\n.spinner {\n  :global {\n    .arco-icon-loading {\n      height: 28px;\n      width: 28px;\n    }\n  }\n\n  &Img {\n    width: 28px;\n    height: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "index__container--0to3R",
	"spinner": "index__spinner--nEiuo",
	"spinnerImg": "index__spinnerImg--JP7uE"
};
export default ___CSS_LOADER_EXPORT___;
