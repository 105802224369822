/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-lines */
/* eslint-disable max-len */
// import { i18n } from '@jupiter/plugin-runtime/i18n';

export const LANGS = {
  AFFILIATE_ACCESS_RESTRICT_DIALOG_TEXT_NOT_MEET_STANDARD:
    'affiliate_access_restrict_dialog_text_not_meet_standard',
  AFFILIATE_ACCESS_RESTRICT_DIALOG_TEXT_VIOLATE_RULES_RESTRICT_CHECK_SHOP_HEALTH:
    'affiliate_access_restrict_dialog_text_violate_rules_restrict_check_shop_health',
  AFFILIATE_AGENCY_ACTION_ERROR_TOAST_ACCOUNT_BANNED:
    'affiliate_agency_action_error_toast_account_banned',
  AFFILIATE_AGENCY_LINK_CREATOR_SIDEBAR_CREATOR_MANAGEMENT:
    'affiliate_agency_link_creator_sidebar_creator_management',
  AFFILIATE_AGENCY_LINK_CREATOR_SIDEBAR_LINK_MANAGEMENT:
    'affiliate_agency_link_creator_sidebar_link_management',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ACTION_ADD_AGAIN:
    'affiliate_agency_link_management_action_add_again',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ACTION_APPROVE:
    'affiliate_agency_link_management_action_approve',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ACTION_DECLINE:
    'affiliate_agency_link_management_action_decline',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ACTION_REMOVE:
    'affiliate_agency_link_management_action_remove',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ACTION_SUCCESS_TOAST:
    'affiliate_agency_link_management_action_success_toast',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_BTN:
    'affiliate_agency_link_management_add_btn',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_CONTRACT:
    'affiliate_agency_link_management_add_contract',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_DATE_PLACEHOLDER:
    'affiliate_agency_link_management_add_date_placeholder',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_DATE_TIPS:
    'affiliate_agency_link_management_add_date_tips',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_EXPIRY_DATE:
    'affiliate_agency_link_management_add_expiry_date',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_EXPIRY_TIPS:
    'affiliate_agency_link_management_add_expiry_tips',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_MAX_NUM_ERROR_TOAST:
    'affiliate_agency_link_management_add_max_num_error_toast',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_NAME:
    'affiliate_agency_link_management_add_name',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_NAME_ERROR:
    'affiliate_agency_link_management_add_name_error',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_NAME_FORMAT_ERROR:
    'affiliate_agency_link_management_add_name_format_error',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_NAME_NO_PERMISSION_ERROR:
    'affiliate_agency_link_management_add_name_no_permission_error',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_NAME_PENDING_ERROR:
    'affiliate_agency_link_management_add_name_pending_error',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_NAME_PLACEHOLDER:
    'affiliate_agency_link_management_add_name_placeholder',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_NAME_REPEAT_ERROR:
    'affiliate_agency_link_management_add_name_repeat_error',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_NEW_BTN:
    'affiliate_agency_link_management_add_new_btn',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_NO_AGREEMENT_TOAST:
    'affiliate_agency_link_management_add_no_agreement_toast',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_SUBMIT_SUCCESS_TOAST:
    'affiliate_agency_link_management_add_submit_success_toast',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ADD_TITLE:
    'affiliate_agency_link_management_add_title',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_DIALOG_REMOVE_CANCEL:
    'affiliate_agency_link_management_dialog_remove_cancel',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_DIALOG_REMOVE_SURE:
    'affiliate_agency_link_management_dialog_remove_sure',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_DIALOG_REMOVE_TEXT1:
    'affiliate_agency_link_management_dialog_remove_text1',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_DIALOG_REMOVE_TEXT2:
    'affiliate_agency_link_management_dialog_remove_text2',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_ERROR_BTN_RETRY:
    'affiliate_agency_link_management_error_btn_retry',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_LIST_REQUEST_DATE:
    'affiliate_agency_link_management_list_request_date',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_LIST_TITLE_ACTION:
    'affiliate_agency_link_management_list_title_action',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_LIST_TITLE_CREATOR:
    'affiliate_agency_link_management_list_title_creator',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_LIST_TITLE_EXPIRES:
    'affiliate_agency_link_management_list_title_expires',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NETWORK_ERROR:
    'affiliate_agency_link_management_network_error',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NETWORK_ERROR_TEXT:
    'affiliate_agency_link_management_network_error_text',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION:
    'affiliate_agency_link_management_notification',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_ADD_FAIL_TEXT:
    'affiliate_agency_link_management_notification_add_fail_text',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_ADD_FAIL_TITLE:
    'affiliate_agency_link_management_notification_add_fail_title',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_ADD_SUCCESS_TEXT:
    'affiliate_agency_link_management_notification_add_success_text',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_ADD_SUCCESS_TITLE:
    'affiliate_agency_link_management_notification_add_success_title',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_BTN_REQUEST_AGAIN:
    'affiliate_agency_link_management_notification_btn_request_again',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_END_TEXT:
    'affiliate_agency_link_management_notification_end_text',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_END_TITLE:
    'affiliate_agency_link_management_notification_end_title',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_LINK_EXPIRY_TEXT:
    'affiliate_agency_link_management_notification_link_expiry_text',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_LINK_EXPIRY_TITLE:
    'affiliate_agency_link_management_notification_link_expiry_title',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_REQUEST_EXPIRY_TEXT:
    'affiliate_agency_link_management_notification_request_expiry_text',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_TIME_DAY_PLURAL:
    'affiliate_agency_link_management_notification_time_day_plural',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_TIME_DAY_SINGULAR:
    'affiliate_agency_link_management_notification_time_day_singular',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_TIME_HOUR_PLURAL:
    'affiliate_agency_link_management_notification_time_hour_plural',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_TIME_HOUR_SINGULAR:
    'affiliate_agency_link_management_notification_time_hour_singular',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_TIME_JUST_NOW:
    'affiliate_agency_link_management_notification_time_just_now',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_TIME_MINUTE_PLURAL:
    'affiliate_agency_link_management_notification_time_minute_plural',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_TIME_MINUTE_SINGULAR:
    'affiliate_agency_link_management_notification_time_minute_singular',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_TIME_WEEK_SINGULAR:
    'affiliate_agency_link_management_notification_time_week_singular',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_UNLINK_BTN_APPROVE:
    'affiliate_agency_link_management_notification_unlink_btn_approve',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_UNLINK_BTN_DECLINE:
    'affiliate_agency_link_management_notification_unlink_btn_decline',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_UNLINK_REPLY_APPROVED:
    'affiliate_agency_link_management_notification_unlink_reply_approved',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_UNLINK_REPLY_DECLINED:
    'affiliate_agency_link_management_notification_unlink_reply_declined',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NOTIFICATION_WEEK_DAY_PLURAL:
    'affiliate_agency_link_management_notification_week_day_plural',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NO_CREATOR_TEXT:
    'affiliate_agency_link_management_no_creator_text',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_NO_CREATOR_TITLE:
    'affiliate_agency_link_management_no_creator_title',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_PAGE_NUM:
    'affiliate_agency_link_management_page_num',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_SERVER_ERROR:
    'affiliate_agency_link_management_server_error',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_SERVER_ERROR_TEXT:
    'affiliate_agency_link_management_server_error_text',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_STATE_ADDED:
    'affiliate_agency_link_management_state_added',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_STATE_FAILED:
    'affiliate_agency_link_management_state_failed',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_STATE_PENDING:
    'affiliate_agency_link_management_state_pending',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_STATE_REMOVED:
    'affiliate_agency_link_management_state_removed',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_STATE_REMOVING:
    'affiliate_agency_link_management_state_removing',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_TITLE:
    'affiliate_agency_link_management_title',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_UNDEFINED_ERROR_TOAST:
    'affiliate_agency_link_management_undefined_error_toast',
  AFFILIATE_AGENCY_LINK_MANAGEMENT_UNLINK_SUCCESS_TOAST:
    'affiliate_agency_link_management_unlink_success_toast',
  AFFILIATE_AGENCY_LINK_MANAGEMENT__NOTIFICATION_ACTION_SUCCESS_TOAST:
    'affiliate_agency_link_management__notification_action_success_toast',
  AFFILIATE_AGENCY_LOG_IN_DESKTOP_BTN: 'affiliate_agency_log_in_desktop_btn',
  AFFILIATE_AGENCY_LOG_IN_DESKTOP_BTN_SIGN_UP:
    'affiliate_agency_log_in_desktop_btn_sign_up',
  AFFILIATE_AGENCY_LOG_IN_DESKTOP_TEXT: 'affiliate_agency_log_in_desktop_text',
  AFFILIATE_AGENCY_LOG_IN_ERROR_TOAST_ACCOUNT_BANNED:
    'affiliate_agency_log_in_error_toast_account_banned',
  AFFILIATE_AGENCY_LOG_IN_SUCCESSFULLY_TOAST:
    'affiliate_agency_log_in_successfully_toast',
  AFFILIATE_AGENCY_LOG_IN_TITLE: 'affiliate_agency_log_in_title',
  AFFILIATE_AGENCY_LOG_OUT: 'affiliate_agency_log_out',
  AFFILIATE_AGENCY_SETTLE_IN_BTN_SUBMIT:
    'affiliate_agency_settle_in_btn_submit',
  AFFILIATE_AGENCY_SETTLE_IN_COMPANY_NAME:
    'affiliate_agency_settle_in_company_name',
  AFFILIATE_AGENCY_SETTLE_IN_COMPANY_NAME_TOOLTIPS:
    'affiliate_agency_settle_in_company_name_tooltips',
  AFFILIATE_AGENCY_SETTLE_IN_COMPLETED_BTN:
    'affiliate_agency_settle_in_completed_btn',
  AFFILIATE_AGENCY_SETTLE_IN_COMPLETED_TIPS:
    'affiliate_agency_settle_in_completed_tips',
  AFFILIATE_AGENCY_SETTLE_IN_COMPLETED_TOPIC:
    'affiliate_agency_settle_in_completed_topic',
  AFFILIATE_AGENCY_SETTLE_IN_CONTRACT: 'affiliate_agency_settle_in_contract',
  AFFILIATE_AGENCY_SETTLE_IN_CONTRACT_TITLE_PRIVACY_POLICY:
    'affiliate_agency_settle_in_contract_title_privacy_policy',
  AFFILIATE_AGENCY_SETTLE_IN_CONTRACT_TITLE_SERVICE_TERMS:
    'affiliate_agency_settle_in_contract_title_service_terms',
  AFFILIATE_AGENCY_SETTLE_IN_NAME_EMPTY_ERROR:
    'affiliate_agency_settle_in_name_empty_error',
  AFFILIATE_AGENCY_SETTLE_IN_NAME_FORMAT_ERROR:
    'affiliate_agency_settle_in_name_format_error',
  AFFILIATE_AGENCY_SETTLE_IN_NAME_REPEAT_ERROR:
    'affiliate_agency_settle_in_name_repeat_error',
  AFFILIATE_AGENCY_SETTLE_IN_NO_AGREEMENT_TOAST:
    'affiliate_agency_settle_in_no_agreement_toast',
  AFFILIATE_AGENCY_SETTLE_IN_NO_COMPANY_NAME_ERROR:
    'affiliate_agency_settle_in_no_company_name_error',
  AFFILIATE_AGENCY_SETTLE_IN_STATE: 'affiliate_agency_settle_in_state',
  AFFILIATE_AGENCY_SETTLE_IN_TITLE: 'affiliate_agency_settle_in_title',
  AFFILIATE_AGENCY_SIGN_UP_DESKTOP_BTN_LOG_IN:
    'affiliate_agency_sign_up_desktop_btn_log_in',
  AFFILIATE_AGENCY_SIGN_UP_DESKTOP_BTN_REGISTER:
    'affiliate_agency_sign_up_desktop_btn_register',
  AFFILIATE_AGENCY_SIGN_UP_DESKTOP_NAME:
    'affiliate_agency_sign_up_desktop_name',
  AFFILIATE_AGENCY_SIGN_UP_DESKTOP_TEXT:
    'affiliate_agency_sign_up_desktop_text',
  AFFILIATE_AGENCY_SIGN_UP_WELCOME: 'affiliate_agency_sign_up_welcome',
  AFFILIATE_ANNOUCEMENT_MARK_AS_READ: 'affiliate_annoucement_mark_as_read',
  AFFILIATE_ANNOUCEMENT_PAGE: 'affiliate_annoucement_page',
  AFFILIATE_ANNOUCEMENT_TIPS: 'affiliate_annoucement_tips',
  AFFILIATE_ANNOUCEMENT_TITLE: 'affiliate_annoucement_title',
  AFFILIATE_COMMON_BTN_CANCEL: 'affiliate_common_btn_cancel',
  AFFILIATE_COMMON_BTN_CLOSE: 'affiliate_common_btn_close',
  AFFILIATE_COMMON_BTN_DELETE: 'affiliate_common_btn_delete',
  AFFILIATE_COMMON_BTN_EDIT: 'affiliate_common_btn_edit',
  AFFILIATE_COMMON_BTN_GOT_IT: 'affiliate_common_btn_got_it',
  AFFILIATE_COMMON_BTN_LEARN_MORE: 'affiliate_common_btn_learn_more',
  AFFILIATE_COMMON_BTN_LOG_OUT: 'affiliate_common_btn_log_out',
  AFFILIATE_COMMON_BTN_NEXT: 'affiliate_common_btn_next',
  AFFILIATE_COMMON_BTN_OK: 'affiliate_common_btn_ok',
  AFFILIATE_COMMON_BTN_REMOVE: 'affiliate_common_btn_remove',
  AFFILIATE_COMMON_BTN_RETRY: 'affiliate_common_btn_retry',
  AFFILIATE_COMMON_BTN_SUBMIT: 'affiliate_common_btn_submit',
  AFFILIATE_COMMON_BTN_TOP: 'affiliate_common_btn_top',
  AFFILIATE_COMMON_CONNECT_INTERNET_AND_TRY_AGAIN:
    'affiliate_common_connect_internet_and_try_again',
  AFFILIATE_COMMON_ERROR: 'affiliate_common_error',
  AFFILIATE_COMMON_ERROR_SYSTEM_ERROR: 'affiliate_common_error_system_error',
  AFFILIATE_COMMON_ERROR_TOAST_SYSTEM_ERROR_TRY_AGAIN:
    'affiliate_common_error_toast_system_error_try_again',
  AFFILIATE_COMMON_ERROR_TRY_AGAIN_LATER:
    'affiliate_common_error_try_again_later',
  AFFILIATE_COMMON_ID: 'affiliate_common_id',
  AFFILIATE_COMMON_LIST_EMPTY_TIP: 'affiliate_common_list_empty_tip',
  AFFILIATE_COMMON_LIST_SERIAL_NUM: 'affiliate_common_list_serial_num',
  AFFILIATE_COMMON_LIST_TITLE_ACTION: 'affiliate_common_list_title_action',
  AFFILIATE_COMMON_LIST_TITLE_PRODUCT: 'affiliate_common_list_title_product',
  AFFILIATE_COMMON_LIST_VIEW_ITEM_NUM: 'affiliate_common_list_view_item_num',
  AFFILIATE_COMMON_LIVE: 'affiliate_common_live',
  AFFILIATE_COMMON_NAME_STREAMER_DESKTOP:
    'affiliate_common_name_streamer_desktop',
  AFFILIATE_COMMON_NO: 'affiliate_common_no',
  AFFILIATE_COMMON_NO_INTERNET_AND_CONNECT_TRY_AGAIN:
    'affiliate_common_no_internet_and_connect_try_again',
  AFFILIATE_COMMON_NO_INTERNET_TIP: 'affiliate_common_no_internet_tip',
  AFFILIATE_COMMON_TERM_CATEGORY: 'affiliate_common_term_category',
  AFFILIATE_COMMON_VIDEO: 'affiliate_common_video',
  AFFILIATE_COMMON_WELCOME_PAGE_WELCOME_TO:
    'affiliate_common_welcome_page_welcome_to',
  AFFILIATE_COMMON_YES: 'affiliate_common_yes',
  AFFILIATE_CONTACT_POLITE_EXPRESSION: 'affiliate_contact_polite_expression',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_BABY_AND_MATERNITY:
    'affiliate_creator_marketplace_category_baby_and_maternity',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_BEAUTY_PERSONALCARE:
    'affiliate_creator_marketplace_category_beauty_personalcare',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_BOOKS:
    'affiliate_creator_marketplace_category_books',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_COMPUTERS_OFFICEEQUIPMENT:
    'affiliate_creator_marketplace_category_computers_officeequipment',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_FASHION_ACCESSORIES:
    'affiliate_creator_marketplace_category_fashion_accessories',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_FOOD_BEVERAGE:
    'affiliate_creator_marketplace_category_food_beverage',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_FURNITURE:
    'affiliate_creator_marketplace_category_furniture',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_HEALTH:
    'affiliate_creator_marketplace_category_health',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_HOME_IMPROVEMENT:
    'affiliate_creator_marketplace_category_home_improvement',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_HOME_SUPPLIES:
    'affiliate_creator_marketplace_category_home_supplies',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_HOUSEHOLD_APPLIANCES:
    'affiliate_creator_marketplace_category_household_appliances',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_KIDS_FASHION:
    'affiliate_creator_marketplace_category_kids_fashion',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_KITCHENWARE:
    'affiliate_creator_marketplace_category_kitchenware',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_LUGGAGE_BAGS:
    'affiliate_creator_marketplace_category_luggage_bags',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_MENSWEAR_UNDERWEAR:
    'affiliate_creator_marketplace_category_menswear_underwear',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_MUSLIM_FASHION:
    'affiliate_creator_marketplace_category_muslim_fashion',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_OTHERS:
    'affiliate_creator_marketplace_category_others',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_PET_SUPPLIES:
    'affiliate_creator_marketplace_category_pet_supplies',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_PHONES_ELECTRONICS:
    'affiliate_creator_marketplace_category_phones_electronics',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_SHOES:
    'affiliate_creator_marketplace_category_shoes',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_SPORTS_OUTDOORS:
    'affiliate_creator_marketplace_category_sports_outdoors',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_TEXTILES_SOFTFURNISHINGS:
    'affiliate_creator_marketplace_category_textiles_softfurnishings',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_TOOLS_HARDWARE:
    'affiliate_creator_marketplace_category_tools_hardware',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_TOYS:
    'affiliate_creator_marketplace_category_toys',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_VEHICLE_SUPPLIES:
    'affiliate_creator_marketplace_category_vehicle_supplies',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_VIRTUAL_PRODUCT:
    'affiliate_creator_marketplace_category_virtual_product',
  AFFILIATE_CREATOR_MARKETPLACE_CATEGORY_WOMENSWEAR_UNDERWEAR:
    'affiliate_creator_marketplace_category_womenswear_underwear',
  AFFILIATE_CREATOR_MARKETPLACE_CONTACT_POPUP_SNED_EMAIL_BTN:
    'affiliate_creator_marketplace_contact_popup_sned_email_btn',
  AFFILIATE_CREATOR_MARKETPLACE_CONTACT_POPUP_SNED_WHATSAPP_BTN:
    'affiliate_creator_marketplace_contact_popup_sned_whatsapp_btn',
  AFFILIATE_CREATOR_MARKETPLACE_RECOMMENDATION_LABEL:
    'affiliate_creator_marketplace_recommendation_label',
  AFFILIATE_CROSSBORDER_ASYNC_EXPORT_LAYER_SELECT_COUNTRY:
    'affiliate_crossborder_async_export_layer_select_country',
  AFFILIATE_CROSSBORDER_CHANGE_REGION_TITLE:
    'affiliate_crossborder_change_region_title',
  AFFILIATE_CROSSBORDER_GENERAL_COUNTRY:
    'affiliate_crossborder_general_country',
  AFFILIATE_CROSSBORDER_GENERAL_OK_BTN: 'affiliate_crossborder_general_ok_btn',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_GB:
    'affiliate_crossborder_general_region_gb',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_GB_FULL:
    'affiliate_crossborder_general_region_gb_full',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_MY:
    'affiliate_crossborder_general_region_my',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_MY_FULL:
    'affiliate_crossborder_general_region_my_full',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_PH:
    'affiliate_crossborder_general_region_ph',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_PH_FULL:
    'affiliate_crossborder_general_region_ph_full',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_SG:
    'affiliate_crossborder_general_region_sg',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_SG_FULL:
    'affiliate_crossborder_general_region_sg_full',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_TH:
    'affiliate_crossborder_general_region_th',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_TH_FULL:
    'affiliate_crossborder_general_region_th_full',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_VN:
    'affiliate_crossborder_general_region_vn',
  AFFILIATE_CROSSBORDER_GENERAL_REGION_VN_FULL:
    'affiliate_crossborder_general_region_vn_full',
  AFFILIATE_CROSSBORDER_GENERAL_RESET_BTN:
    'affiliate_crossborder_general_reset_btn',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_CHANGES_SUBMIT_TOAST:
    'affiliate_crossborder_open_plan_changes_submit_toast',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_COMMISSION_MIXED:
    'affiliate_crossborder_open_plan_list_commission_mixed',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_COMMISSION_MIXED_HOVER_NEW:
    'affiliate_crossborder_open_plan_list_commission_mixed_hover_new',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_COMMISSION_MIXED_HOVER_TIPS:
    'affiliate_crossborder_open_plan_list_commission_mixed_hover_tips',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_DELETE_DIALOG_TEXT:
    'affiliate_crossborder_open_plan_list_delete_dialog_text',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_DELETE_TOAST:
    'affiliate_crossborder_open_plan_list_delete_toast',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_STATUS_CLOSED:
    'affiliate_crossborder_open_plan_list_status_closed',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_STATUS_DELETED:
    'affiliate_crossborder_open_plan_list_status_deleted',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_STATUS_DELETING:
    'affiliate_crossborder_open_plan_list_status_deleting',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_STATUS_LIVE_SOLD_OUT:
    'affiliate_crossborder_open_plan_list_status_live_sold_out',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_STATUS_RUNNING:
    'affiliate_crossborder_open_plan_list_status_running',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_STATUS_UNAVAILABLE:
    'affiliate_crossborder_open_plan_list_status_unavailable',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_STATUS_UNAVAILABLE_PLATFROM_WITHDRAWN:
    'affiliate_crossborder_open_plan_list_status_unavailable_platfrom_withdrawn',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_LIST_STATUS_WITHDRAWN:
    'affiliate_crossborder_open_plan_list_status_withdrawn',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_PRODUCT_FLITER_BY_PRODUCT_ID:
    'affiliate_crossborder_open_plan_product_fliter_by_product_id',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SAMPLE_UK:
    'affiliate_crossborder_open_plan_sample_uk',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SELECT_PRODUCT_FLITER_BY_PRODUCT_ID:
    'affiliate_crossborder_open_plan_select_product_fliter_by_product_id',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SELECT_PRODUCT_LIST_PRODUCT_ID:
    'affiliate_crossborder_open_plan_select_product_list_product_id',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SELECT_PRODUCT_STATUS_LIVE:
    'affiliate_crossborder_open_plan_select_product_status_live',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SELECT_PRODUCT_STATUS_SOLD_OUT:
    'affiliate_crossborder_open_plan_select_product_status_sold_out',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SELECT_PRODUCT_STATUS_UNAVAILABLE:
    'affiliate_crossborder_open_plan_select_product_status_unavailable',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SELECT_PRODUCT_STATUS_WITHDRAWN:
    'affiliate_crossborder_open_plan_select_product_status_withdrawn',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SET_COMMISSION_LAYER_ALL_CONTRY:
    'affiliate_crossborder_open_plan_set_commission_layer_all_contry',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SET_COMMISSION_LAYER_SPECIFIC_CONTRY:
    'affiliate_crossborder_open_plan_set_commission_layer_specific_contry',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SET_COMMISSION_LAYER_SPECIFIC_CONTRY_CLOSING_HOVER:
    'affiliate_crossborder_open_plan_set_commission_layer_specific_contry_closing_hover',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SET_COMMISSION_LAYER_TILE:
    'affiliate_crossborder_open_plan_set_commission_layer_tile',
  AFFILIATE_CROSSBORDER_OPEN_PLAN_SET_SAMPLE_TITLE:
    'affiliate_crossborder_open_plan_set_sample_title',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_CREATE_LAYER_COMMISSION_RATE_HOVER:
    'affiliate_crossborder_shop_plan_create_layer_commission_rate_hover',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_CREATE_LAYER_COMMISSION_RATE_TILTE:
    'affiliate_crossborder_shop_plan_create_layer_commission_rate_tilte',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_CREATE_LAYER_LIST_PERCENTAGE:
    'affiliate_crossborder_shop_plan_create_layer_list_percentage',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_CREATE_LAYER_LIST_STATUS:
    'affiliate_crossborder_shop_plan_create_layer_list_status',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_CREATE_LAYER_LIST_STATUS_HOVER:
    'affiliate_crossborder_shop_plan_create_layer_list_status_hover',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_COMMISSION_RATE:
    'affiliate_crossborder_shop_plan_detail_list_commission_rate',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_COMMISSION_RATE_HOVER:
    'affiliate_crossborder_shop_plan_detail_list_commission_rate_hover',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_EDIT:
    'affiliate_crossborder_shop_plan_detail_list_edit',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_PLAN_ID:
    'affiliate_crossborder_shop_plan_detail_list_plan_id',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_STATUS:
    'affiliate_crossborder_shop_plan_detail_list_status',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_STATUS_CLOSED:
    'affiliate_crossborder_shop_plan_detail_list_status_closed',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_STATUS_CLOSING:
    'affiliate_crossborder_shop_plan_detail_list_status_closing',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_STATUS_CLOSING_HOVER:
    'affiliate_crossborder_shop_plan_detail_list_status_closing_hover',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_STATUS_CLOSING_QUIT_BTN_HOVER:
    'affiliate_crossborder_shop_plan_detail_list_status_closing_quit_btn_hover',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_STATUS_CLOSING_TIPS:
    'affiliate_crossborder_shop_plan_detail_list_status_closing_tips',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_DETAIL_LIST_STATUS_RUNNING:
    'affiliate_crossborder_shop_plan_detail_list_status_running',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_EDIT_LAYER_CLOSING_HOVER:
    'affiliate_crossborder_shop_plan_edit_layer_closing_hover',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_EDIT_LAYER_FIRST_RULE:
    'affiliate_crossborder_shop_plan_edit_layer_first_rule',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_EDIT_LAYER_SECOND_RULE:
    'affiliate_crossborder_shop_plan_edit_layer_second_rule',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_EDIT_LAYER_SUBTITLE:
    'affiliate_crossborder_shop_plan_edit_layer_subtitle',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_EDIT_LAYER_TITLE:
    'affiliate_crossborder_shop_plan_edit_layer_title',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_QUIT_DIALOG_CANCEL_BTN:
    'affiliate_crossborder_shop_plan_quit_dialog_cancel_btn',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_QUIT_DIALOG_CONFIRM_BTN:
    'affiliate_crossborder_shop_plan_quit_dialog_confirm_btn',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_QUIT_DIALOG_TEXT:
    'affiliate_crossborder_shop_plan_quit_dialog_text',
  AFFILIATE_CROSSBORDER_SHOP_PLAN_QUIT_DIALOG_TITLE:
    'affiliate_crossborder_shop_plan_quit_dialog_title',
  AFFILIATE_CROSSBORDER_TARGETED_PLAN_SELECT_COUNTRY:
    'affiliate_crossborder_targeted_plan_select_country',
  AFFILIATE_CROSSBORDER_TARGETED_PLAN_SELECT_COUNTRY_TIPS_TEXT:
    'affiliate_crossborder_targeted_plan_select_country_tips_text',
  AFFILIATE_CROSSBORDER_TARGETED_PLAN_SELECT_COUNTRY_TIPS_TITLE:
    'affiliate_crossborder_targeted_plan_select_country_tips_title',
  AFFILIATE_DATA_NAVIGATION: 'affiliate_data_navigation',
  AFFILIATE_ERROR_NETWORK_FE: 'affiliate_error_network_fe',
  AFFILIATE_ERROR_NETWORK_FE_EXPLANATION:
    'affiliate_error_network_fe_explanation',
  AFFILIATE_ERROR_NON_CORE_MESSAGE_MISSING_TOAST:
    'affiliate_error_non_core_message_missing_toast',
  AFFILIATE_ERROR_REQUEST: 'affiliate_error_request',
  AFFILIATE_ERROR_REQUEST_EXPLANATION: 'affiliate_error_request_explanation',
  AFFILIATE_ERROR_RETRY_BUTTON: 'affiliate_error_retry_button',
  AFFILIATE_FIND_CREATORS_BUTTON: 'Affiliate_find_creators_button',
  AFFILIATE_FIND_CREATORS_INTRO_01: 'Affiliate_find_creators_intro_01',
  AFFILIATE_FIND_CREATORS_INTRO_02: 'Affiliate_find_creators_intro_02',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_ALL_VIDEO:
    'affiliate_find_creator_homepage_core_data_all_video',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_LIVE:
    'affiliate_find_creator_homepage_core_data_ec_live',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_LIVE_COMMENT:
    'affiliate_find_creator_homepage_core_data_ec_live_comment',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_LIVE_COMMENT_DESCRIPTION:
    'affiliate_find_creator_homepage_core_data_ec_live_comment_description',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_LIVE_LIKE:
    'affiliate_find_creator_homepage_core_data_ec_live_like',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_LIVE_LIKE_DESCRIPTION:
    'affiliate_find_creator_homepage_core_data_ec_live_like_description',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_LIVE_NUMBER:
    'affiliate_find_creator_homepage_core_data_ec_live_number',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_LIVE_NUMBER_DESCRIPTION:
    'affiliate_find_creator_homepage_core_data_ec_live_number_description',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_LIVE_PV:
    'affiliate_find_creator_homepage_core_data_ec_live_pv',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_LIVE_PV_DESCRIPTION:
    'affiliate_find_creator_homepage_core_data_ec_live_pv_description',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_VIDEO:
    'affiliate_find_creator_homepage_core_data_ec_video',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_VIDEO_COMMENT:
    'affiliate_find_creator_homepage_core_data_ec_video_comment',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_VIDEO_COMMENT_DESCRIPTION:
    'affiliate_find_creator_homepage_core_data_ec_video_comment_description',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_VIDEO_LIKE:
    'affiliate_find_creator_homepage_core_data_ec_video_like',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_VIDEO_LIKE_DESCRIPTION:
    'affiliate_find_creator_homepage_core_data_ec_video_like_description',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_VIDEO_NUMBER:
    'affiliate_find_creator_homepage_core_data_ec_video_number',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_VIDEO_NUMBER_DESCRIPTION:
    'affiliate_find_creator_homepage_core_data_ec_video_number_description',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_VIDEO_VV:
    'affiliate_find_creator_homepage_core_data_ec_video_vv',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_CORE_DATA_EC_VIDEO_VV_DESCRIPTION:
    'affiliate_find_creator_homepage_core_data_ec_video_vv_description',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_PRODUCT_DESCRIPTION:
    'affiliate_find_creator_homepage_product_description',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_SENDPLAN_BTN:
    'affiliate_find_creator_homepage_sendplan_btn',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_VIDEO_RANKING_VIEW_BTN:
    'affiliate_find_creator_homepage_video_ranking_view_btn',
  AFFILIATE_FIND_CREATOR_HOMEPAGE_VIDEO_RANKING_VIEW_PRODUCT_BTN:
    'affiliate_find_creator_homepage_video_ranking_view_product_btn',
  AFFILIATE_FIND_CREATOR_LIST_PRODUCT_CATEGORY_DESCRIPTION:
    'affiliate_find_creator_list_product_category_description',
  AFFILIATE_FIND_CREATOR_LIST_PRODUCT_CATEGORY_FILTER:
    'affiliate_find_creator_list_product_category_filter',
  AFFILIATE_FIND_CREATOR_LIST_PRODUCT_CATEGORY_FILTER_PLACEHOLDER:
    'affiliate_find_creator_list_product_category_filter_placeholder',
  AFFILIATE_FIND_CREATOR_LIST_PRODUCT_CATEGORY_FILTER_SEARCH_NO_RESULT:
    'affiliate_find_creator_list_product_category_filter_search_no_result',
  AFFILIATE_FIND_CREATOR_LIST_PRODUCT_CATEGORY_FILTER_SEARCH_PLACEHOLDER:
    'affiliate_find_creator_list_product_category_filter_search_placeholder',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_AGENCY:
    'affiliate_find_cretaor_homepage_agency',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_AGENCY_POPUP_DESCRIPTION:
    'affiliate_find_cretaor_homepage_agency_popup_description',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_AGENCY_POPUP_EMAIL:
    'affiliate_find_cretaor_homepage_agency_popup_email',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_AGENCY_POPUP_PHONE_NUMBER:
    'affiliate_find_cretaor_homepage_agency_popup_phone_number',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_AGENCY_POPUP_TITLE:
    'affiliate_find_cretaor_homepage_agency_popup_title',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CONTENT_TYPE_DESCRIPTION:
    'affiliate_find_cretaor_homepage_content_type_description',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CONTENT_TYPE_LIVESTREAM:
    'affiliate_find_cretaor_homepage_content_type_livestream',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CONTENT_TYPE_VIDEO:
    'affiliate_find_cretaor_homepage_content_type_video',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE:
    'affiliate_find_cretaor_homepage_creator_score',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE_POPUP_DESCRIPTION1:
    'affiliate_find_cretaor_homepage_creator_score_popup_description1',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE_POPUP_DESCRIPTION2:
    'affiliate_find_cretaor_homepage_creator_score_popup_description2',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE_POPUP_LIVE_SCORE:
    'affiliate_find_cretaor_homepage_creator_score_popup_live_score',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE_POPUP_LIVE_SCORE_DESCRIPTION:
    'affiliate_find_cretaor_homepage_creator_score_popup_live_score_description',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE_POPUP_TITLE:
    'affiliate_find_cretaor_homepage_creator_score_popup_title',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE_POPUP_VIDEO_SCORE:
    'affiliate_find_cretaor_homepage_creator_score_popup_video_score',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE_POPUP_VIDEO_SCORE_DESCRIPTION:
    'affiliate_find_cretaor_homepage_creator_score_popup_video_score_description',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE_RANKING:
    'affiliate_find_cretaor_homepage_creator_score_ranking',
  AFFILIATE_FIND_CRETAOR_HOMEPAGE_CREATOR_SCORE_RANKING_DESCRIPTION:
    'affiliate_find_cretaor_homepage_creator_score_ranking_description',
  AFFILIATE_FIND_CRETAOR_LIST_AGENCY_FILTER:
    'affiliate_find_cretaor_list_agency_filter',
  AFFILIATE_FIND_CRETAOR_LIST_AGENCY_FILTER_DESCRIPTION:
    'affiliate_find_cretaor_list_agency_filter_description',
  AFFILIATE_FIND_CRETAOR_LIST_CONTENT_TYPE_FILTER:
    'affiliate_find_cretaor_list_content_type_filter',
  AFFILIATE_FIND_CRETAOR_LIST_CONTENT_TYPE_FILTER_DESCRIPTION:
    'affiliate_find_cretaor_list_content_type_filter_description',
  AFFILIATE_FIND_CRETAOR_LIST_CREATOR_SCORE_FILTER:
    'affiliate_find_cretaor_list_creator_score_filter',
  AFFILIATE_FIND_CRETAOR_LIST_CREATOR_SCORE_FILTER_DESCRIPTION:
    'affiliate_find_cretaor_list_creator_score_filter_description',
  AFFILIATE_GENERAL_ERROR_NETWORK: 'affiliate_general_error_network',
  AFFILIATE_GENERAL_ERROR_UNKNOWN: 'affiliate_general_error_unknown',
  AFFILIATE_GENERAL_ERROR_VIOLATION: 'affiliate_general_error_violation',
  AFFILIATE_GENERAL_ERROR_WITHDRAWN: 'affiliate_general_error_withdrawn',
  AFFILIATE_GOING_OFFLINE_NOTICE_DESCRIPTION:
    'affiliate_going_offline_notice_description',
  AFFILIATE_GOING_OFFLINE_NOTICE_DESCRIPTION_2:
    'affiliate_going_offline_notice_description2',
  AFFILIATE_GOING_OFFLINE_NOTICE_TITLE: 'affiliate_going_offline_notice_title',
  AFFILIATE_GOING_OFFLINE_NOTICE_LINK: 'affiliate_going_offline_notice_link',
  AFFILIATE_GUIDANCE_POPUP_ACCOUNTS_TEXT:
    'affiliate_guidance_popup_accounts_text',
  AFFILIATE_GUIDANCE_POPUP_ACCOUNTS_TITLE:
    'affiliate_guidance_popup_accounts_title',
  AFFILIATE_GUIDANCE_POPUP_AFFILIATE_GUIDE_TEXT:
    'affiliate_guidance_popup_affiliate_guide_text',
  AFFILIATE_GUIDANCE_POPUP_AFFILIATE_GUIDE_TITLE:
    'affiliate_guidance_popup_affiliate_guide_title',
  AFFILIATE_GUIDANCE_POPUP_BTN_DONE: 'affiliate_guidance_popup_btn_done',
  AFFILIATE_GUIDANCE_POPUP_DATA_COMPASS_TEXT:
    'affiliate_guidance_popup_data_compass_text',
  AFFILIATE_GUIDANCE_POPUP_DATA_COMPASS_TITLE:
    'affiliate_guidance_popup_data_compass_title',
  AFFILIATE_GUIDANCE_POPUP_FIND_CREATORS_CONTACT_BTN:
    'affiliate_guidance_popup_find_creators_contact_btn',
  AFFILIATE_GUIDANCE_POPUP_FIND_CREATORS_CONTACT_ICON:
    'affiliate_guidance_popup_find_creators_contact_icon',
  AFFILIATE_GUIDANCE_POPUP_FIND_CREATORS_SEARCH:
    'affiliate_guidance_popup_find_creators_search',
  AFFILIATE_GUIDANCE_POPUP_FIND_CREATORS_TEXT:
    'affiliate_guidance_popup_find_creators_text',
  AFFILIATE_GUIDANCE_POPUP_FIND_CREATORS_TITLE:
    'affiliate_guidance_popup_find_creators_title',
  AFFILIATE_GUIDANCE_POPUP_GOT_IT_BTN: 'affiliate_guidance_popup_got_it_btn',
  AFFILIATE_GUIDANCE_POPUP_HOME_GET_STARTED_BTN:
    'affiliate_guidance_popup_home_get_started_btn',
  AFFILIATE_GUIDANCE_POPUP_HOME_WELCOME_TEXT_BUY_PRODUCT:
    'affiliate_guidance_popup_home_welcome_text_buy_product',
  AFFILIATE_GUIDANCE_POPUP_HOME_WELCOME_TEXT_COMMISSION_SETTLEMENT:
    'affiliate_guidance_popup_home_welcome_text_commission_settlement',
  AFFILIATE_GUIDANCE_POPUP_HOME_WELCOME_TEXT_FIND_CREATORS:
    'affiliate_guidance_popup_home_welcome_text_find_creators',
  AFFILIATE_GUIDANCE_POPUP_HOME_WELCOME_TEXT_VIEW_DATA:
    'affiliate_guidance_popup_home_welcome_text_view_data',
  AFFILIATE_GUIDANCE_POPUP_HOME_WELCOME_TITLE1:
    'affiliate_guidance_popup_home_welcome_title1',
  AFFILIATE_GUIDANCE_POPUP_HOME_WELCOME_TITLE2:
    'affiliate_guidance_popup_home_welcome_title2',
  AFFILIATE_GUIDANCE_POPUP_NEXT_BTN: 'affiliate_guidance_popup_next_btn',
  AFFILIATE_GUIDANCE_POPUP_OPEN_PLAN_TEXT:
    'affiliate_guidance_popup_open_plan_text',
  AFFILIATE_GUIDANCE_POPUP_OPEN_PLAN_TITLE:
    'affiliate_guidance_popup_open_plan_title',
  AFFILIATE_GUIDANCE_POPUP_PLAN_SET_PLAN_TEXT:
    'affiliate_guidance_popup_plan_set_plan_text',
  AFFILIATE_GUIDANCE_POPUP_PLAN_SET_PLAN_TITLE:
    'affiliate_guidance_popup_plan_set_plan_title',
  AFFILIATE_GUIDANCE_POPUP_SHOP_PLAN_TEXT:
    'affiliate_guidance_popup_shop_plan_text',
  AFFILIATE_GUIDANCE_POPUP_SHOP_PLAN_TITLE:
    'affiliate_guidance_popup_shop_plan_title',
  AFFILIATE_GUIDANCE_POPUP_SKIP_BTN: 'affiliate_guidance_popup_skip_btn',
  AFFILIATE_GUIDANCE_POPUP_TARGET_PLAN_TEXT:
    'affiliate_guidance_popup_target_plan_text',
  AFFILIATE_GUIDANCE_POPUP_TARGET_PLAN_TITLE:
    'affiliate_guidance_popup_target_plan_title',
  AFFILIATE_HOMEPAGE_AFFILIATE_GUIDE_UNIT:
    'affiliate_homepage_affiliate_guide_unit',
  AFFILIATE_HOMEPAGE_ANNOUCEMENT_TITLE: 'affiliate_homepage_annoucement_title',
  AFFILIATE_HOMEPAGE_ANNOUCEMENT_VIEW_ALL_BTN:
    'affiliate_homepage_annoucement_view_all_btn',
  AFFILIATE_HOMEPAGE_BTN: 'affiliate_homepage_btn',
  AFFILIATE_HOMEPAGE_DATA_CHART_LINE_01:
    'affiliate_homepage_data_chart_line_01',
  AFFILIATE_HOMEPAGE_DATA_CHART_LINE_02:
    'affiliate_homepage_data_chart_line_02',
  AFFILIATE_HOMEPAGE_DATA_CHART_TITLE: 'affiliate_homepage_data_chart_title',
  AFFILIATE_HOMEPAGE_DATA_ENTRANCE_VIEW_MORE:
    'affiliate_homepage_data_entrance_view_more',
  AFFILIATE_HOMEPAGE_DATA_ERROR_NETWORK:
    'affiliate_homepage_data_error_network',
  AFFILIATE_HOMEPAGE_DATA_ERROR_NETWORK_EXPLANATION:
    'affiliate_homepage_data_error_network_explanation',
  AFFILIATE_HOMEPAGE_DATA_ERROR_RETRY_BTN:
    'affiliate_homepage_data_error_retry_btn',
  AFFILIATE_HOMEPAGE_DATA_METRIC_COMMISSION:
    'affiliate_homepage_data_metric_commission',
  AFFILIATE_HOMEPAGE_DATA_METRIC_COMMISSION_BUBBLE:
    'affiliate_homepage_data_metric_commission_bubble',
  AFFILIATE_HOMEPAGE_DATA_METRIC_COMPARE:
    'affiliate_homepage_data_metric_compare',
  AFFILIATE_HOMEPAGE_DATA_METRIC_PRODUCT_VIEWS:
    'affiliate_homepage_data_metric_product_views',
  AFFILIATE_HOMEPAGE_DATA_METRIC_PRODUCT_VIEWS_BUBBLE:
    'affiliate_homepage_data_metric_product_views_bubble',
  AFFILIATE_HOMEPAGE_DATA_METRIC_REVENUE:
    'affiliate_homepage_data_metric_revenue',
  AFFILIATE_HOMEPAGE_DATA_METRIC_REVENUE_BUBBLE:
    'affiliate_homepage_data_metric_revenue_bubble',
  AFFILIATE_HOMEPAGE_DATA_METRIC_ROI: 'affiliate_homepage_data_metric_roi',
  AFFILIATE_HOMEPAGE_DATA_METRIC_ROI_BUBBLE:
    'affiliate_homepage_data_metric_roi_bubble',
  AFFILIATE_HOMEPAGE_DATA_METRIC_SALES: 'affiliate_homepage_data_metric_sales',
  AFFILIATE_HOMEPAGE_DATA_METRIC_SALES_BUBBLE:
    'affiliate_homepage_data_metric_sales_bubble',
  AFFILIATE_HOMEPAGE_DATA_TITLE: 'affiliate_homepage_data_title',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_CREATORS_BLANK_EXPLANATION:
    'affiliate_homepage_data_toplist_creators_blank_explanation',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_CREATORS_BLANK_TITLE:
    'affiliate_homepage_data_toplist_creators_blank_title',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_CREATORS_METRIC:
    'affiliate_homepage_data_toplist_creators_metric',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_CREATORS_ROI:
    'affiliate_homepage_data_toplist_creators_roi',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_CREATORS_TITLE:
    'affiliate_homepage_data_toplist_creators_title',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_PRODUCTS_BLANK_EXPLANATION:
    'affiliate_homepage_data_toplist_products_blank_explanation',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_PRODUCTS_BLANK_TITLE:
    'affiliate_homepage_data_toplist_products_blank_title',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_PRODUCTS_METRIC_REVENUE:
    'affiliate_homepage_data_toplist_products_metric_revenue',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_PRODUCTS_METRIC_SALES:
    'affiliate_homepage_data_toplist_products_metric_sales',
  AFFILIATE_HOMEPAGE_DATA_TOPLIST_PRODUCTS_TITLE:
    'affiliate_homepage_data_toplist_products_title',
  AFFILIATE_HOMEPAGE_DATA_UPDATE_TIME: 'affiliate_homepage_data_update_time',
  AFFILIATE_HOMEPAGE_FIND_CREATOR_CARD_SCORE:
    'affiliate_homepage_find_creator_card_score',
  AFFILIATE_HOMEPAGE_FIND_CREATOR_CARD_SCORE_TOOLTIP:
    'affiliate_homepage_find_creator_card_score_tooltip',
  AFFILIATE_HOMEPAGE_FIND_CREATOR_SUBTITLE_CATEGORY:
    'affiliate_homepage_find_creator_subtitle_category',
  AFFILIATE_HOMEPAGE_FIND_CREATOR_SUBTITLE_FOLLOWERS:
    'affiliate_homepage_find_creator_subtitle_followers',
  AFFILIATE_HOMEPAGE_FIND_CREATOR_SUBTITLE_SCORE:
    'affiliate_homepage_find_creator_subtitle_score',
  AFFILIATE_HOMEPAGE_FIND_CREATOR_TITLE:
    'affiliate_homepage_find_creator_title',
  AFFILIATE_HOMEPAGE_GUIDE_DOC_SUBTITLE_01:
    'affiliate_homepage_guide_doc_subtitle_01',
  AFFILIATE_HOMEPAGE_GUIDE_DOC_SUBTITLE_02:
    'affiliate_homepage_guide_doc_subtitle_02',
  AFFILIATE_HOMEPAGE_GUIDE_DOC_SUBTITLE_03:
    'affiliate_homepage_guide_doc_subtitle_03',
  AFFILIATE_HOMEPAGE_GUIDE_DOC_SUBTITLE_04:
    'affiliate_homepage_guide_doc_subtitle_04',
  AFFILIATE_HOMEPAGE_GUIDE_DOC_TITLE_01:
    'affiliate_homepage_guide_doc_title_01',
  AFFILIATE_HOMEPAGE_GUIDE_DOC_TITLE_02:
    'affiliate_homepage_guide_doc_title_02',
  AFFILIATE_HOMEPAGE_GUIDE_DOC_TITLE_03:
    'affiliate_homepage_guide_doc_title_03',
  AFFILIATE_HOMEPAGE_GUIDE_DOC_TITLE_04:
    'affiliate_homepage_guide_doc_title_04',
  AFFILIATE_HOMEPAGE_NAVIGATION: 'affiliate_homepage_navigation',
  AFFILIATE_HOMEPAGE_NAVIGATION_NOTES_CREATOR_MARKETPLACE:
    'affiliate_homepage_navigation_notes_creator_marketplace',
  AFFILIATE_HOMEPAGE_NAVIGATION_NOTES_OPEN_PLAN:
    'affiliate_homepage_navigation_notes_open_plan',
  AFFILIATE_HOMEPAGE_NAVIGATION_TITLE_CREATOR_MARKETPLACE:
    'affiliate_homepage_navigation_title_creator_marketplace',
  AFFILIATE_HOMEPAGE_NAVIGATION_TITLE_OPEN_PLAN:
    'affiliate_homepage_navigation_title_open_plan',
  AFFILIATE_HOMEPAGE_NEWCOMER_TIPS_LIST01:
    'affiliate_homepage_newcomer_tips_list01',
  AFFILIATE_HOMEPAGE_NEWCOMER_TIPS_LIST02:
    'affiliate_homepage_newcomer_tips_list02',
  AFFILIATE_HOMEPAGE_NEWCOMER_TIPS_LIST03:
    'affiliate_homepage_newcomer_tips_list03',
  AFFILIATE_HOMEPAGE_NEWCOMER_TIPS_SUBTITLE:
    'affiliate_homepage_newcomer_tips_subtitle',
  AFFILIATE_HOMEPAGE_NEWCOMER_TIPS_TITLE:
    'affiliate_homepage_newcomer_tips_title',
  AFFILIATE_HOMEPAGE_SIDEBAR_SAMPLE_REQUEST:
    'affiliate_homepage_sidebar_sample_request',
  AFFILIATE_HOME_BANNER_AFFILIATE_GUIDE:
    'affiliate_home_banner_affiliate_guide',
  AFFILIATE_HOME_BANNER_AFFILIATE_GUIDE_BTN:
    'affiliate_home_banner_affiliate_guide_btn',
  AFFILIATE_IM_ENTRY_NAME_CREATOR_MARKETPLACE:
    'affiliate_im_entry_name_creator_marketplace',
  AFFILIATE_IM_ENTRY_NAME_TARGETED_PLAN:
    'affiliate_im_entry_name_targeted_plan',
  AFFILIATE_IM_INSTANT_NOTICE_POPUP_REPLY:
    'affiliate_im_instant_notice_popup_reply',
  AFFILIATE_IM_INSTANT_NOTICE_POPUP_TITLE:
    'affiliate_im_instant_notice_popup_title',
  AFFILIATE_IM_NEWCOMER_HOMEPAGE_TIPS_CONTENT:
    'affiliate_im_newcomer_homepage_tips_content',
  AFFILIATE_IM_NEWCOMER_HOMEPAGE_TIPS_TITLE:
    'affiliate_im_newcomer_homepage_tips_title',
  AFFILIATE_IM_NEWCOMER_OTHERPAGES_TIPS:
    'affiliate_im_newcomer_otherpages_tips',
  AFFILIATE_IM_NON_INSTANT_NOTICE_POPUP_EXPLANATION:
    'affiliate_im_non_instant_notice_popup_explanation',
  AFFILIATE_IM_NON_INSTANT_NOTICE_POPUP_TITLE:
    'affiliate_im_non_instant_notice_popup_title',
  AFFILIATE_IM_TARGETED_PLAN_FAILURE_NOTIFICATION:
    'affiliate_im_targeted_plan_failure_notification',
  AFFILIATE_IM_TARGETED_PLAN_POPUP_COLUMN_NAME:
    'affiliate_im_targeted_plan_popup_column_name',
  AFFILIATE_IM_TARGETED_PLAN_POPUP_SELECTED_CREATORS:
    'affiliate_im_targeted_plan_popup_selected_creators',
  AFFILIATE_IM_TARGETED_PLAN_POPUP_SHARE_BUTTON:
    'affiliate_im_targeted_plan_popup_share_button',
  AFFILIATE_IM_TARGETED_PLAN_POPUP_TITLE:
    'affiliate_im_targeted_plan_popup_title',
  AFFILIATE_LOG_OUT: 'affiliate_log_out',
  AFFILIATE_NAVIGATION_BAR_FIND_CREATORS:
    'Affiliate_navigation_bar_find_creators',
  AFFILIATE_NAVIGATION_GUIDE: 'affiliate_navigation_guide',
  AFFILIATE_NAVIGATION_GUIDE_DOC_CHECK_ORDERS:
    'affiliate_navigation_guide_doc_check_orders',
  AFFILIATE_NAVIGATION_GUIDE_DOC_CONNECT_CREATORS:
    'affiliate_navigation_guide_doc_connect_creators',
  AFFILIATE_NAVIGATION_GUIDE_DOC_CREATE_PLAN:
    'affiliate_navigation_guide_doc_create_plan',
  AFFILIATE_NAVIGATION_GUIDE_DOC_GIVE_FREE_SAMPLES:
    'affiliate_navigation_guide_doc_give_free_samples',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_CEATOR:
    'affiliate_order_async_export_error_ceator',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_CREATOR_NULL:
    'affiliate_order_async_export_error_creator_null',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_DATE_EXCEED:
    'affiliate_order_async_export_error_date_exceed',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_DATE_NULL:
    'affiliate_order_async_export_error_date_null',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_GENERAL:
    'affiliate_order_async_export_error_general',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_LIMIT:
    'affiliate_order_async_export_error_limit',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_NETWORK:
    'affiliate_order_async_export_error_network',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_PLAN_NULL:
    'affiliate_order_async_export_error_plan_null',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_PRODUCT_NULL:
    'affiliate_order_async_export_error_product_null',
  AFFILIATE_ORDER_ASYNC_EXPORT_ERROR_SERVER:
    'affiliate_order_async_export_error_server',
  AFFILIATE_ORDER_ASYNC_EXPORT_EXPORT_HISTORY_BTN:
    'affiliate_order_async_export_export_history_btn',
  AFFILIATE_ORDER_ASYNC_EXPORT_EXPORT_ORDERS_BTN:
    'affiliate_order_async_export_export_orders_btn',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_CANCEL:
    'affiliate_order_async_export_layer_cancel',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_CREATE_TIME:
    'affiliate_order_async_export_layer_create_time',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_CREATE_TIME_END:
    'affiliate_order_async_export_layer_create_time_end',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_CREATE_TIME_START:
    'affiliate_order_async_export_layer_create_time_start',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_DATE_RANGE_TEXT:
    'affiliate_order_async_export_layer_date_range_text',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_EXPORT:
    'affiliate_order_async_export_layer_export',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FLITER_CREATOR:
    'affiliate_order_async_export_layer_fliter_creator',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FLITER_CREATOR_PLACEHOLDER:
    'affiliate_order_async_export_layer_fliter_creator_placeholder',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FLITER_OPTIONAL:
    'affiliate_order_async_export_layer_fliter_optional',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FLITER_PLAN:
    'affiliate_order_async_export_layer_fliter_plan',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FLITER_PLAN_PLACEHOLDER:
    'affiliate_order_async_export_layer_fliter_plan_placeholder',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FLITER_PRODUCT:
    'affiliate_order_async_export_layer_fliter_product',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FLITER_PRODUCT_PLACEHOLDER:
    'affiliate_order_async_export_layer_fliter_product_placeholder',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FLITER_TITLE:
    'affiliate_order_async_export_layer_fliter_title',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FORMAT:
    'affiliate_order_async_export_layer_format',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FORMAT_CSV:
    'affiliate_order_async_export_layer_format_csv',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_FORMAT_EXCEL:
    'affiliate_order_async_export_layer_format_excel',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_MAXIMUM_TIPS:
    'affiliate_order_async_export_layer_maximum_tips',
  AFFILIATE_ORDER_ASYNC_EXPORT_LAYER_TITLE:
    'affiliate_order_async_export_layer_title',
  AFFILIATE_ORDER_ASYNC_EXPORT_TIPS_BTN:
    'affiliate_order_async_export_tips_btn',
  AFFILIATE_ORDER_ASYNC_EXPORT_TIPS_TEXT:
    'affiliate_order_async_export_tips_text',
  AFFILIATE_ORDER_ASYNC_EXPORT_TIPS_TITLE:
    'affiliate_order_async_export_tips_title',
  AFFILIATE_ORDER_ASYNC_HISTORY_ACTION: 'affiliate_order_async_history_action',
  AFFILIATE_ORDER_ASYNC_HISTORY_DOCUMENT_NAME:
    'affiliate_order_async_history_document_name',
  AFFILIATE_ORDER_ASYNC_HISTORY_DOWNLOAD_BTN:
    'affiliate_order_async_history_download_btn',
  AFFILIATE_ORDER_ASYNC_HISTORY_EXPORTED:
    'affiliate_order_async_history_exported',
  AFFILIATE_ORDER_ASYNC_HISTORY_EXPORTING:
    'affiliate_order_async_history_exporting',
  AFFILIATE_ORDER_ASYNC_HISTORY_EXPORTING_PLUAR:
    'affiliate_order_async_history_exporting_pluar',
  AFFILIATE_ORDER_ASYNC_HISTORY_EXPORTING_SINGULAR:
    'affiliate_order_async_history_exporting_singular',
  AFFILIATE_ORDER_ASYNC_HISTORY_SUBTITLE:
    'affiliate_order_async_history_subtitle',
  AFFILIATE_ORDER_ASYNC_HISTORY_TITLE: 'affiliate_order_async_history_title',
  AFFILIATE_ORDER_ERROR: 'affiliate_order_error',
  AFFILIATE_ORDER_EXPORT_ALL_BTN: 'affiliate_order_export_all_btn',
  AFFILIATE_ORDER_EXPORT_FAIL_TOAST: 'affiliate_order_export_fail_toast',
  AFFILIATE_ORDER_EXPORT_SELECTED_BTN: 'affiliate_order_export_selected_btn',
  AFFILIATE_ORDER_LIST_ACTUAL_AMOUNT: 'affiliate_order_list_actual_amount',
  AFFILIATE_ORDER_LIST_ACTUAL_AMOUNT_TOOLTIPS:
    'affiliate_order_list_actual_amount_tooltips',
  AFFILIATE_ORDER_LIST_ACTUAL_COMMISSION:
    'affiliate_order_list_actual_commission',
  AFFILIATE_ORDER_LIST_ACTUAL_COMMISSION_TOOLTIP:
    'affiliate_order_list_actual_commission_tooltip',
  AFFILIATE_ORDER_LIST_COMMISSION_RATE: 'affiliate_order_list_commission_rate',
  AFFILIATE_ORDER_LIST_COMPLETED_TIME: 'affiliate_order_list_completed_time',
  AFFILIATE_ORDER_LIST_CREATED_TIME: 'affiliate_order_list_created_time',
  AFFILIATE_ORDER_LIST_ESTIMATED_AMOUNT:
    'affiliate_order_list_estimated_amount',
  AFFILIATE_ORDER_LIST_ESTIMATED_AMOUNT_TOOLTIPS:
    'affiliate_order_list_estimated_amount_tooltips',
  AFFILIATE_ORDER_LIST_ESTIMATED_COMMISSION:
    'affiliate_order_list_estimated_commission',
  AFFILIATE_ORDER_LIST_ESTIMATED_COMMISSION_TOOLTIPS:
    'affiliate_order_list_estimated_commission_tooltips',
  AFFILIATE_ORDER_LIST_FILTER_CREATE_DATE_FROM:
    'affiliate_order_list_filter_create_date_from',
  AFFILIATE_ORDER_LIST_FILTER_CREATE_DATE_TO:
    'affiliate_order_list_filter_create_date_to',
  AFFILIATE_ORDER_LIST_FILTER_CREATE_USERNAME:
    'affiliate_order_list_filter_create_username',
  AFFILIATE_ORDER_LIST_FILTER_ORDER_ID: 'affiliate_order_list_filter_order_id',
  AFFILIATE_ORDER_LIST_FILTER_PLAN_ID: 'affiliate_order_list_filter_plan_id',
  AFFILIATE_ORDER_LIST_FILTER_PRODUCT_ID:
    'affiliate_order_list_filter_product_id',
  AFFILIATE_ORDER_LIST_FILTER_SEARCH_ORDER_PLACEHOLDER:
    'affiliate_order_list_filter_search_order_placeholder',
  AFFILIATE_ORDER_LIST_FILTER_SELECT_DATE_MAX_LIMIT_ERROR_TOAST:
    'affiliate_order_list_filter_select_date_max_limit_error_toast',
  AFFILIATE_ORDER_LIST_ORDER_ID: 'affiliate_order_list_order_id',
  AFFILIATE_ORDER_LIST_ORDER_STATUS: 'affiliate_order_list_order_status',
  AFFILIATE_ORDER_LIST_PAGE_NUM: 'affiliate_order_list_page_num',
  AFFILIATE_ORDER_LIST_PLAN_ID: 'affiliate_order_list_plan_id',
  AFFILIATE_ORDER_LIST_PRICE_QUANTITY: 'affiliate_order_list_price_quantity',
  AFFILIATE_ORDER_LIST_PRODUCT: 'affiliate_order_list_product',
  AFFILIATE_ORDER_LIST_PRODUCT_ID: 'affiliate_order_list_product_Id',
  // AFFILIATE_ORDER_LIST_PRODUCT_ID: 'affiliate_order_list_product_id',
  AFFILIATE_ORDER_LIST_PROMOTION_CREATOR:
    'affiliate_order_list_promotion_creator',
  AFFILIATE_ORDER_LIST_PROMOTION_TYPE: 'affiliate_order_list_promotion_type',
  AFFILIATE_ORDER_LIST_PROMOTION_TYPE_LIVESTREAM:
    'affiliate_order_list_promotion_type_livestream',
  AFFILIATE_ORDER_LIST_PROMOTION_TYPE_PROMOTION_PAGE:
    'affiliate_order_list_promotion_type_promotion_page',
  AFFILIATE_ORDER_LIST_PROMOTION_TYPE_SHOWCASE:
    'affiliate_order_list_promotion_type_showcase',
  AFFILIATE_ORDER_LIST_PROMOTION_TYPE_VIDEO:
    'affiliate_order_list_promotion_type_video',
  AFFILIATE_ORDER_LIST_REFUND_QUANTITY: 'affiliate_order_list_refund_quantity',
  AFFILIATE_ORDER_LIST_RETURN_QUANTITY: 'affiliate_order_list_return_quantity',
  AFFILIATE_ORDER_LIST_RETURN_QUANTITY_NEW:
    'affiliate_order_list_return_quantity_new',
  AFFILIATE_ORDER_LIST_RETURN_REFUND: 'affiliate_order_list_return_refund',
  AFFILIATE_ORDER_LIST_RETURN_REFUND_TOOLTIPS:
    'affiliate_order_list_return_refund_tooltips',
  AFFILIATE_ORDER_LIST_SETTLED_TIME: 'affiliate_order_list_settled_time',
  AFFILIATE_ORDER_PAGE_TEXT: 'affiliate_order_page_text',
  AFFILIATE_ORDER_PAGE_TITLE: 'affiliate_order_page_title',
  AFFILIATE_ORDER_STATUS_ALL: 'affiliate_order_status_all',
  AFFILIATE_ORDER_STATUS_CANCELLED: 'affiliate_order_status_cancelled',
  AFFILIATE_ORDER_STATUS_COMPLETED: 'affiliate_order_status_completed',
  AFFILIATE_ORDER_STATUS_PROCESSING: 'affiliate_order_status_processing',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE:
    'affiliate_plan_open_add_new_free_sample',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_ACTIVITY_CYCLE:
    'affiliate_plan_open_add_new_free_sample_activity_cycle',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_ACTIVITY_CYCLE_EMPTY_ERROR:
    'affiliate_plan_open_add_new_free_sample_activity_cycle_empty_error',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_ACTIVITY_CYCLE_END_DATE:
    'affiliate_plan_open_add_new_free_sample_activity_cycle_end_date',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_ACTIVITY_CYCLE_START_DATE:
    'affiliate_plan_open_add_new_free_sample_activity_cycle_start_date',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_ACTIVITY_CYCLE_TOOLTIPS:
    'affiliate_plan_open_add_new_free_sample_activity_cycle_tooltips',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_QUANTITY:
    'affiliate_plan_open_add_new_free_sample_quantity',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_QUANTITY_ACTIVITY_CYCLE_EMPTY_ERROR:
    'affiliate_plan_open_add_new_free_sample_quantity_activity_cycle_empty_error',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_QUANTITY_EMPTY_ERROR:
    'affiliate_plan_open_add_new_free_sample_quantity_empty_error',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_QUANTITY_PLACEHOLDER:
    'affiliate_plan_open_add_new_free_sample_quantity_placeholder',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_QUANTITY_TOOPTIPS:
    'affiliate_plan_open_add_new_free_sample_quantity_tooptips',
  AFFILIATE_PLAN_OPEN_ADD_NEW_FREE_SAMPLE_TOOLTIPS:
    'affiliate_plan_open_add_new_free_sample_tooltips',
  AFFILIATE_PLAN_OPEN_FREE_SAMPLE_ONLINE_TIPS:
    'affiliate_plan_open_free_sample_online_tips',
  AFFILIATE_PLAN_OPEN_LIST_FREE_SAMPLE: 'affiliate_plan_open_list_free_sample',
  AFFILIATE_PLAN_OPEN_LIST_FREE_SAMPLE_EXPIRED:
    'affiliate_plan_open_list_free_sample_expired',
  AFFILIATE_PLAN_OPEN_LIST_FREE_SAMPLE_LEFT_NUM:
    'affiliate_plan_open_list_free_sample_left_num',
  AFFILIATE_PLAN_OPEN_LIST_FREE_SAMPLE_LEFT_NUM_TOOLTIPS_DATE:
    'affiliate_plan_open_list_free_sample_left_num_tooltips_date',
  AFFILIATE_PLAN_OPEN_LIST_FREE_SAMPLE_LEFT_NUM_TOOLTIPS_NUM:
    'affiliate_plan_open_list_free_sample_left_num_tooltips_num',
  AFFILIATE_PLAN_OPEN_LIST_FREE_SAMPLE_NOT_START:
    'affiliate_plan_open_list_free_sample_not_start',
  AFFILIATE_PLAN_OPEN_LIST_FREE_SAMPLE_NO_SAMPLE:
    'affiliate_plan_open_list_free_sample_no_sample',
  AFFILIATE_PLAN_OPEN_LIST_FREE_SAMPLE_TOOLTIPS:
    'affiliate_plan_open_list_free_sample_tooltips',
  AFFILIATE_SAMPLE_PLATFORM_DELIVERY_SHIPPING_INSTRUCTIONS:
    'affiliate_sample_platform_delivery_shipping_instructions',
  AFFILIATE_SAMPLE_REQUEST_ACTION_APPROVE_BTN:
    'affiliate_sample_request_action_approve_btn',
  AFFILIATE_SAMPLE_REQUEST_ACTION_REJECT_BTN:
    'affiliate_sample_request_action_reject_btn',
  AFFILIATE_SAMPLE_REQUEST_CREATOR_FOLLOWERS:
    'affiliate_sample_request_creator_followers',
  AFFILIATE_SAMPLE_REQUEST_DIALOG_APPROVE_BTN:
    'affiliate_sample_request_dialog_approve_btn',
  AFFILIATE_SAMPLE_REQUEST_DIALOG_CANCEL_BTN:
    'affiliate_sample_request_dialog_cancel_btn',
  AFFILIATE_SAMPLE_REQUEST_DIALOG_CONFIM_BTN:
    'affiliate_sample_request_dialog_confim_btn',
  AFFILIATE_SAMPLE_REQUEST_DIALOG_DELETE_BTN:
    'affiliate_sample_request_dialog_delete_btn',
  AFFILIATE_SAMPLE_REQUEST_DIALOG_OK_BTN:
    'affiliate_sample_request_dialog_ok_btn',
  AFFILIATE_SAMPLE_REQUEST_FAIL_TO_SHIP_NO_REASON:
    'affiliate_sample_request_fail_to_ship_no_reason',
  AFFILIATE_SAMPLE_REQUEST_FAIL_TO_SHIP_REASON:
    'affiliate_sample_request_fail_to_ship_reason',
  AFFILIATE_SAMPLE_REQUEST_FILTER_FOLLOWER:
    'affiliate_sample_request_filter_follower',
  AFFILIATE_SAMPLE_REQUEST_FILTER_FOLLOWER_10K50K:
    'affiliate_sample_request_filter_follower_10k50k',
  AFFILIATE_SAMPLE_REQUEST_FILTER_FOLLOWER_1K10K:
    'affiliate_sample_request_filter_follower_1k10k',
  AFFILIATE_SAMPLE_REQUEST_FILTER_FOLLOWER_50K100K:
    'affiliate_sample_request_filter_follower_50k100k',
  AFFILIATE_SAMPLE_REQUEST_FILTER_FOLLOWER_CUSTOMIZE:
    'affiliate_sample_request_filter_follower_customize',
  AFFILIATE_SAMPLE_REQUEST_FILTER_FOLLOWER_MORE100K:
    'affiliate_sample_request_filter_follower_more100k',
  AFFILIATE_SAMPLE_REQUEST_FILTER_FOLLOWER_UNDER1K:
    'affiliate_sample_request_filter_follower_under1k',
  AFFILIATE_SAMPLE_REQUEST_LIST_ACCOUNT_UNAVAILABLE:
    'affiliate_sample_request_list_account_unavailable',
  AFFILIATE_SAMPLE_REQUEST_LIST_ACTION: 'affiliate_sample_request_list_action',
  AFFILIATE_SAMPLE_REQUEST_LIST_ACTION_COMPLETE_REQUEST_BTN:
    'affiliate_sample_request_list_action_complete_request_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_ACTION_DELETE_BTN:
    'affiliate_sample_request_list_action_delete_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_ACTION_LOGISTICS_BTN:
    'affiliate_sample_request_list_action_logistics_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_ACTION_MORE_INFO_BTN:
    'affiliate_sample_request_list_action_more_info_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_ACTION_SHIP_BTN:
    'affiliate_sample_request_list_action_ship_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_ACTION_VIEW_LOGISTICS_BTN:
    'affiliate_sample_request_list_action_view_logistics_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_ACTION_VIEW_PERFORMANCE_BTN:
    'affiliate_sample_request_list_action_view_performance_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVED_DIALOG_FAIL_PENDING_NUM_PLURAL:
    'affiliate_sample_request_list_approved_dialog_fail_pending_num_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVED_DIALOG_FAIL_PENDING_NUM_SINGULAR:
    'affiliate_sample_request_list_approved_dialog_fail_pending_num_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVED_DIALOG_PENDING:
    'affiliate_sample_request_list_approved_dialog_pending',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVED_DIALOG_SUCCESS_PENDING_NEXT_STEP:
    'affiliate_sample_request_list_approved_dialog_success_pending_next_step',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVED_DIALOG_SUCCESS_PENDING_NUM_PLURAL:
    'affiliate_sample_request_list_approved_dialog_success_pending_num_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVED_DIALOG_SUCCESS_PENDING_NUM_SINGULAR:
    'affiliate_sample_request_list_approved_dialog_success_pending_num_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVE_DIALOG_DOUBLE_CONFIRM:
    'affiliate_sample_request_list_approve_dialog_double_confirm',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVE_DIALOG_DOUBLE_CONFIRM_PLURAL:
    'affiliate_sample_request_list_approve_dialog_double_confirm_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVE_DIALOG_OUT_OF_STOCK_PLURAL:
    'affiliate_sample_request_list_approve_dialog_out_of_stock_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_APPROVE_DIALOG_OUT_OF_STOCK_SINGLUAR:
    'affiliate_sample_request_list_approve_dialog_out_of_stock_singluar',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_DELETE_RECORD_CONFIRM_DIALOG_TEXT:
    'affiliate_sample_request_list_cancel_delete_record_confirm_dialog_text',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_DELETE_RECORD_CONFIRM_DIALOG_TEXT_SINGULAR:
    'affiliate_sample_request_list_cancel_delete_record_confirm_dialog_text_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_DELETE_RECORD_CONFIRM_DIALOG_TITLE:
    'affiliate_sample_request_list_cancel_delete_record_confirm_dialog_title',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_DELETE_RECORD_CONFIRM_DIALOG_TITLE_SINGULAR:
    'affiliate_sample_request_list_cancel_delete_record_confirm_dialog_title_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_DELETE_RECORD_SUCCESS_TOAST_PLURAL:
    'affiliate_sample_request_list_cancel_delete_record_success_toast_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_DELETE_RECORD_SUCCESS_TOAST_SINGULAR:
    'affiliate_sample_request_list_cancel_delete_record_success_toast_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_REASON:
    'affiliate_sample_request_list_cancel_reason',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_REASON_CREATOR_NO_FULFILLMENT:
    'affiliate_sample_request_list_cancel_reason_creator_no_fulfillment',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_REASON_EXPIRED:
    'affiliate_sample_request_list_cancel_reason_expired',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_REASON_PLAN_DELETED:
    'affiliate_sample_request_list_cancel_reason_plan_deleted',
  AFFILIATE_SAMPLE_REQUEST_LIST_CANCEL_REASON_REJECTED:
    'affiliate_sample_request_list_cancel_reason_rejected',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMMISSION:
    'affiliate_sample_request_list_commission',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_CONTENT_TITLE:
    'affiliate_sample_request_list_completed_performance_record_content_title',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_DELIVERY_TYPE:
    'affiliate_sample_request_list_completed_performance_record_delivery_type',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_DELIVERY_TYPE_LIVE:
    'affiliate_sample_request_list_completed_performance_record_delivery_type_live',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_DELIVERY_TYPE_VIDEO:
    'affiliate_sample_request_list_completed_performance_record_delivery_type_video',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_EMPTY_TEXT:
    'affiliate_sample_request_list_completed_performance_record_empty_text',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_EMPTY_TITLE:
    'affiliate_sample_request_list_completed_performance_record_empty_title',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_LIVE_UNAVAILABLE_TIPS:
    'affiliate_sample_request_list_completed_performance_record_live_unavailable_tips',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_ORDERS:
    'affiliate_sample_request_list_completed_performance_record_orders',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_ORDERS_TOOLTIPS:
    'affiliate_sample_request_list_completed_performance_record_orders_tooltips',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_PUBLISH_TIME:
    'affiliate_sample_request_list_completed_performance_record_publish_time',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_TITLE:
    'affiliate_sample_request_list_completed_performance_record_title',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_VIEWS:
    'affiliate_sample_request_list_completed_performance_record_views',
  AFFILIATE_SAMPLE_REQUEST_LIST_COMPLETED_PERFORMANCE_RECORD_VIEWS_TOOLTIPS:
    'affiliate_sample_request_list_completed_performance_record_views_tooltips',
  AFFILIATE_SAMPLE_REQUEST_LIST_CONTENT_PENDING_COMPLETE_REQUEST_DIALOG_COMPLETE_BTN:
    'affiliate_sample_request_list_content_pending_complete_request_dialog_complete_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_CONTENT_PENDING_COMPLETE_REQUEST_DIALOG_TEXT:
    'affiliate_sample_request_list_content_pending_complete_request_dialog_text',
  AFFILIATE_SAMPLE_REQUEST_LIST_CONTENT_PENDING_COMPLETE_REQUEST_DIALOG_TITLE:
    'affiliate_sample_request_list_content_pending_complete_request_dialog_title',
  AFFILIATE_SAMPLE_REQUEST_LIST_CONTENT_PENDING_COMPLETE_REQUEST_SUCCESS_TOAST:
    'affiliate_sample_request_list_content_pending_complete_request_success_toast',
  AFFILIATE_SAMPLE_REQUEST_LIST_CREATOR_SAMPLE_NUM_PLURAL:
    'affiliate_sample_request_list_creator_sample_num_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_CREATOR_SAMPLE_NUM_SINGULAR:
    'affiliate_sample_request_list_creator_sample_num_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_EDIT_BTN:
    'affiliate_sample_request_list_edit_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_EMPTY: 'affiliate_sample_request_list_empty',
  AFFILIATE_SAMPLE_REQUEST_LIST_EXPIRE: 'affiliate_sample_request_list_expire',
  AFFILIATE_SAMPLE_REQUEST_LIST_EXPIRE_DAY_PLURAL:
    'affiliate_sample_request_list_expire_day_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_EXPIRE_DAY_SINGULAR:
    'affiliate_sample_request_list_expire_day_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_EXPIRE_HOUR_PLURAL:
    'affiliate_sample_request_list_expire_hour_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_EXPIRE_HOUR_SINGULAR:
    'affiliate_sample_request_list_expire_hour_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_FREE_SAMPLE_CANCELLED:
    'affiliate_sample_request_list_free_sample_cancelled',
  AFFILIATE_SAMPLE_REQUEST_LIST_HISTORY_NUM_PLURAL:
    'affiliate_sample_request_list_history_num_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_HISTORY_NUM_SINGULAR:
    'affiliate_sample_request_list_history_num_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_INVALID_PRODUCT_ERROR:
    'affiliate_sample_request_list_invalid_product_error',
  AFFILIATE_SAMPLE_REQUEST_LIST_LOAD_FAIL_TEXT:
    'affiliate_sample_request_list_load_fail_text',
  AFFILIATE_SAMPLE_REQUEST_LIST_LOAD_FAIL_TITLE:
    'affiliate_sample_request_list_load_fail_title',
  AFFILIATE_SAMPLE_REQUEST_LIST_MORE_INFO_POPUP:
    'affiliate_sample_request_list_more_info_popup',
  AFFILIATE_SAMPLE_REQUEST_LIST_MORE_INFO_POPUP_ADDRESS:
    'affiliate_sample_request_list_more_info_popup_address',
  AFFILIATE_SAMPLE_REQUEST_LIST_MORE_INFO_POPUP_CONTACT_NUM:
    'affiliate_sample_request_list_more_info_popup_contact_num',
  AFFILIATE_SAMPLE_REQUEST_LIST_MORE_INFO_POPUP_EMAIL:
    'affiliate_sample_request_list_more_info_popup_email',
  AFFILIATE_SAMPLE_REQUEST_LIST_MORE_INFO_POPUP_NAME:
    'affiliate_sample_request_list_more_info_popup_name',
  AFFILIATE_SAMPLE_REQUEST_LIST_MORE_INFO_POPUP_POSTCODE:
    'affiliate_sample_request_list_more_info_popup_postcode',
  AFFILIATE_SAMPLE_REQUEST_LIST_MORE_INFO_POPUP_WHATSAPP:
    'affiliate_sample_request_list_more_info_popup_whatsapp',
  AFFILIATE_SAMPLE_REQUEST_LIST_NEW_TAG:
    'affiliate_sample_request_list_new_tag',
  AFFILIATE_SAMPLE_REQUEST_LIST_NOT_SELECT_ITEM_ERROR_TOAST:
    'affiliate_sample_request_list_not_select_item_error_toast',
  AFFILIATE_SAMPLE_REQUEST_LIST_PAGE_NUM:
    'affiliate_sample_request_list_page_num',
  AFFILIATE_SAMPLE_REQUEST_LIST_PENDING_DEADLINE_TIPS:
    'affiliate_sample_request_list_pending_deadline_tips',
  AFFILIATE_SAMPLE_REQUEST_LIST_PENDING_PROCESS_DEADLINE_TIPS:
    'affiliate_sample_request_list_pending_process_deadline_tips',
  AFFILIATE_SAMPLE_REQUEST_LIST_PRODUCT:
    'affiliate_sample_request_list_product',
  AFFILIATE_SAMPLE_REQUEST_LIST_PRODUCT_ID:
    'affiliate_sample_request_list_product_id',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_PLURAL:
    'affiliate_sample_request_list_reject_popup_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_REASON:
    'affiliate_sample_request_list_reject_popup_reason',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_REASON_OTHERS:
    'affiliate_sample_request_list_reject_popup_reason_others',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_REASON_PLACEHOLDER:
    'affiliate_sample_request_list_reject_popup_reason_placeholder',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_REASON_PRODUCT_OUT_OF_STOCK:
    'affiliate_sample_request_list_reject_popup_reason_product_out_of_stock',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_REASON_PRODUCT_UNAVAILABLE:
    'affiliate_sample_request_list_reject_popup_reason_product_unavailable',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_REASON_VIOLATE_REQUIREMENT:
    'affiliate_sample_request_list_reject_popup_reason_violate_requirement',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_REJECT_BTN:
    'affiliate_sample_request_list_reject_popup_reject_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_SINGULAR:
    'affiliate_sample_request_list_reject_popup_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_POPUP_TIPS:
    'affiliate_sample_request_list_reject_popup_tips',
  AFFILIATE_SAMPLE_REQUEST_LIST_REJECT_SUCCESS_TOAST:
    'affiliate_sample_request_list_reject_success_toast',
  AFFILIATE_SAMPLE_REQUEST_LIST_REQUEST_NUM_PLURAL:
    'affiliate_sample_request_list_request_num_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_REQUEST_NUM_SINGULAR:
    'affiliate_sample_request_list_request_num_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_DEADLINE_TIPS_NEW:
    'affiliate_sample_request_list_rts_deadline_tips_new',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_EXPIRE_ALERT_TIPS:
    'affiliate_sample_request_list_rts_expire_alert_tips',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_OVERDUE_NUM_TIPS_PLURAL:
    'affiliate_sample_request_list_rts_overdue_num_tips_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_OVERDUE_NUM_TIPS_SINGULAR:
    'affiliate_sample_request_list_rts_overdue_num_tips_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_PACKAGE_SHIP_FAIL_TOAST_INVALID_PACKAGE_ID:
    'affiliate_sample_request_list_rts_package_ship_fail_toast_invalid_package_id',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_PACKAGE_SHIP_FAIL_TOAST_INVALID_TRACKING_ID_PROVIDER:
    'affiliate_sample_request_list_rts_package_ship_fail_toast_invalid_tracking_id_provider',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_PACKAGE_SHIP_FAIL_TOAST_INVALID_TRACKING_ID_REPEAT:
    'affiliate_sample_request_list_rts_package_ship_fail_toast_invalid_tracking_id_repeat',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_PACKAGE_SHIP_FAIL_TOAST_NOT_ALLOW:
    'affiliate_sample_request_list_rts_package_ship_fail_toast_not_allow',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_PACKAGE_SHIP_FAIL_TOAST_NOT_SELLER_FULFILLMENT:
    'affiliate_sample_request_list_rts_package_ship_fail_toast_not_seller_fulfillment',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_PACKAGE_SHIP_FAIL_TOAST_REPEAT:
    'affiliate_sample_request_list_rts_package_ship_fail_toast_repeat',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_PACKAGE_SHIP_FAIL_TOAST_SERVER:
    'affiliate_sample_request_list_rts_package_ship_fail_toast_server',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_DUE_DATE:
    'affiliate_sample_request_list_rts_shipment_due_date',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_PACKAGE_BTN:
    'affiliate_sample_request_list_rts_shipment_popup_package_btn',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_PACKAGE_FAIL:
    'affiliate_sample_request_list_rts_shipment_popup_package_fail',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_PROVIDER:
    'affiliate_sample_request_list_rts_shipment_popup_provider',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_PROVIDER_EMPTY_ERROR:
    'affiliate_sample_request_list_rts_shipment_popup_provider_empty_error',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_PROVIDER_OPTION_OTHERS:
    'affiliate_sample_request_list_rts_shipment_popup_provider_option_others',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_PROVIDER_SELECT_PLACEHOLDER:
    'affiliate_sample_request_list_rts_shipment_popup_provider_select_placeholder',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_SAME_ADDRESS_TIPS:
    'affiliate_sample_request_list_rts_shipment_popup_same_address_tips',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_SAME_LIST_NUM_PLURAL:
    'affiliate_sample_request_list_rts_shipment_popup_same_list_num_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_SAMPLE_LIST:
    'affiliate_sample_request_list_rts_shipment_popup_sample_list',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_TITLE:
    'affiliate_sample_request_list_rts_shipment_popup_title',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_TRACKING_NUM:
    'affiliate_sample_request_list_rts_shipment_popup_tracking_num',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_TRACKING_NUM_EMPTY_ERROR:
    'affiliate_sample_request_list_rts_shipment_popup_tracking_num_empty_error',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_POPUP_TRACKING_NUM_PLACEHOLDER:
    'affiliate_sample_request_list_rts_shipment_popup_tracking_num_placeholder',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_STATUS_OVERDUE:
    'affiliate_sample_request_list_rts_shipment_status_overdue',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIPMENT_SUCCESS_TOAST:
    'affiliate_sample_request_list_rts_shipment_success_toast',
  AFFILIATE_SAMPLE_REQUEST_LIST_RTS_SHIP_DEADLINE_TIPS:
    'affiliate_sample_request_list_rts_ship_deadline_tips',
  AFFILIATE_SAMPLE_REQUEST_LIST_SAMPLE_NUM_PLURAL:
    'affiliate_sample_request_list_sample_num_plural',
  AFFILIATE_SAMPLE_REQUEST_LIST_SAMPLE_NUM_SINGULAR:
    'affiliate_sample_request_list_sample_num_singular',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_DELIVERY_OPTION:
    'affiliate_sample_request_list_shipped_logistics_info_delivery_option',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_DELIVERY_OPTION_STANDARD:
    'affiliate_sample_request_list_shipped_logistics_info_delivery_option_standard',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_DROPOFF_TIP_1:
    'affiliate_sample_request_list_shipped_logistics_info_dropoff_tip_1',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_DROPOFF_TIP_2:
    'affiliate_sample_request_list_shipped_logistics_info_dropoff_tip_2',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_EDIT_FAIL_TOAST:
    'affiliate_sample_request_list_shipped_logistics_info_edit_fail_toast',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_EDIT_POPUP_PROVIDER:
    'affiliate_sample_request_list_shipped_logistics_info_edit_popup_provider',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_EDIT_POPUP_TITLE:
    'affiliate_sample_request_list_shipped_logistics_info_edit_popup_title',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_EDIT_POPUP_TRACKING_NUM:
    'affiliate_sample_request_list_shipped_logistics_info_edit_popup_tracking_num',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_EST_DATE:
    'affiliate_sample_request_list_shipped_logistics_info_est_date',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_PICKUP_DATE:
    'affiliate_sample_request_list_shipped_logistics_info_pickup_date',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_PICKUP_TIME:
    'affiliate_sample_request_list_shipped_logistics_info_pickup_time',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_POPUP_DYNAMIC:
    'affiliate_sample_request_list_shipped_logistics_info_popup_dynamic',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_POPUP_DYNAMIC_DATA_UNAVAILABLE:
    'affiliate_sample_request_list_shipped_logistics_info_popup_dynamic_data_unavailable',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_POPUP_DYNAMIC_DATA_UNAVAILABLE_TEXT:
    'affiliate_sample_request_list_shipped_logistics_info_popup_dynamic_data_unavailable_text',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_POPUP_PROVIDER:
    'affiliate_sample_request_list_shipped_logistics_info_popup_provider',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_POPUP_TITLE:
    'affiliate_sample_request_list_shipped_logistics_info_popup_title',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_POPUP_TRACKING_NUM:
    'affiliate_sample_request_list_shipped_logistics_info_popup_tracking_num',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_POPUP_VIEW_FAIL:
    'affiliate_sample_request_list_shipped_logistics_info_popup_view_fail',
  AFFILIATE_SAMPLE_REQUEST_LIST_SHIPPED_LOGISTICS_INFO_QTY:
    'affiliate_sample_request_list_shipped_logistics_info_qty',
  AFFILIATE_SAMPLE_REQUEST_LIST_SKU: 'affiliate_sample_request_list_sku',
  AFFILIATE_SAMPLE_REQUEST_LIST_STATUS: 'affiliate_sample_request_list_status',
  AFFILIATE_SAMPLE_REQUEST_LIST_STATUS_CANCELLED:
    'affiliate_sample_request_list_status_cancelled',
  AFFILIATE_SAMPLE_REQUEST_LIST_STATUS_COMPLETED:
    'affiliate_sample_request_list_status_completed',
  AFFILIATE_SAMPLE_REQUEST_LIST_STATUS_CONTENT_PENDING:
    'affiliate_sample_request_list_status_content_pending',
  AFFILIATE_SAMPLE_REQUEST_LIST_STATUS_PENDING:
    'affiliate_sample_request_list_status_pending',
  AFFILIATE_SAMPLE_REQUEST_LIST_STATUS_RTS:
    'affiliate_sample_request_list_status_rts',
  AFFILIATE_SAMPLE_REQUEST_LIST_STATUS_SHIPPED:
    'affiliate_sample_request_list_status_shipped',
  AFFILIATE_SAMPLE_REQUEST_LIST_SYSTEM_ERROR_TOAST_REDUCE_SELECT:
    'affiliate_sample_request_list_system_error_toast_reduce_select',
  AFFILIATE_SAMPLE_REQUEST_LIST_TAB_ALL:
    'affiliate_sample_request_list_tab_all',
  AFFILIATE_SAMPLE_REQUEST_LIST_TAB_CANCELLED:
    'affiliate_sample_request_list_tab_cancelled',
  AFFILIATE_SAMPLE_REQUEST_LIST_TAB_COMPLETED:
    'affiliate_sample_request_list_tab_completed',
  AFFILIATE_SAMPLE_REQUEST_LIST_TAB_CONTENT_PENDING:
    'affiliate_sample_request_list_tab_content_pending',
  AFFILIATE_SAMPLE_REQUEST_LIST_TAB_PENDING:
    'affiliate_sample_request_list_tab_pending',
  AFFILIATE_SAMPLE_REQUEST_LIST_TAB_READY_TO_SHIP:
    'affiliate_sample_request_list_tab_ready_to_ship',
  AFFILIATE_SAMPLE_REQUEST_LIST_TAB_SHIPPED:
    'affiliate_sample_request_list_tab_shipped',
  AFFILIATE_SAMPLE_REQUEST_NETWORK_ERROR:
    'affiliate_sample_request_network_error',
  AFFILIATE_SAMPLE_REQUEST_NETWORK_ERROR_CONNECT_INTERNET:
    'affiliate_sample_request_network_error_connect_internet',
  AFFILIATE_SAMPLE_REQUEST_NETWORK_ERROR_RETRY_BTN:
    'affiliate_sample_request_network_error_retry_btn',
  AFFILIATE_SAMPLE_REQUEST_PAGE_TITLE: 'affiliate_sample_request_page_title',
  AFFILIATE_SAMPLE_REQUEST_POPUP_PARTIAL_REQUEST_PARTIAL_REQUEST_UNSUPPORTED_TITLE:
    'affiliate_sample_request_popup_partial_request_partial_request_unsupported_title',
  AFFILIATE_SAMPLE_REQUEST_POPUP_PARTIAL_REQUEST_UNSUPPORTED_CONTENT:
    'affiliate_sample_request_popup_partial_request_unsupported_content',
  AFFILIATE_SAMPLE_REQUEST_POPUP_PARTIAL_REQUEST_UNSUPPORTED_DONT_SHOW:
    'affiliate_sample_request_popup_partial_request_unsupported_dont_show',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_BTN:
    'affiliate_sample_request_rts_arrange_shipment_btn',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_CANCEL_TEXT:
    'affiliate_sample_request_rts_arrange_shipment_popup_cancel_text',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_FULFILLBYSELLER_BTN:
    'affiliate_sample_request_rts_arrange_shipment_popup_fulfillbyseller_btn',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_FULFILLBYSELLER_DESCIPTION:
    'affiliate_sample_request_rts_arrange_shipment_popup_fulfillbyseller_desciption',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_STANARD_DESCIPTION:
    'affiliate_sample_request_rts_arrange_shipment_popup_stanard_desciption',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_STANDARD_BTN:
    'affiliate_sample_request_rts_arrange_shipment_popup_standard_btn',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_TEXT:
    'affiliate_sample_request_rts_arrange_shipment_popup_text',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_TOAST:
    'affiliate_sample_request_rts_arrange_shipment_popup_toast',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_TOOLTIP_MULTIPLE_PARCEL:
    'affiliate_sample_request_rts_arrange_shipment_popup_tooltip_multiple_parcel',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_TOOLTIP_VIOLATION:
    'affiliate_sample_request_rts_arrange_shipment_popup_tooltip_violation',
  AFFILIATE_SAMPLE_REQUEST_RTS_ARRANGE_SHIPMENT_POPUP_TOOLTIP_XBORDER:
    'affiliate_sample_request_rts_arrange_shipment_popup_tooltip_xborder',
  AFFILIATE_SAMPLE_REQUEST_RTS_AWAIT_COLLECTION_FAILED_TO_CANCEL_TOAST:
    'affiliate_sample_request_rts_await_collection_failed_to_cancel_toast',
  AFFILIATE_SAMPLE_REQUEST_RTS_AWAIT_COLLECTION_LIST_METHOD:
    'affiliate_sample_request_rts_await_collection_list_method',
  AFFILIATE_SAMPLE_REQUEST_RTS_AWAIT_COLLECTION_LIST_PACKAGE_ID:
    'affiliate_sample_request_rts_await_collection_list_package_id',
  AFFILIATE_SAMPLE_REQUEST_RTS_AWAIT_COLLECTION_LIST_PROVIDER:
    'affiliate_sample_request_rts_await_collection_list_provider',
  AFFILIATE_SAMPLE_REQUEST_RTS_AWAIT_COLLECTION_LIST_SEARCH_PACKAGE_ID:
    'affiliate_sample_request_rts_await_collection_list_search_package_id',
  AFFILIATE_SAMPLE_REQUEST_RTS_AWAIT_COLLECTION_LIST_SEARCH_PACKAGE_ID_PLACEHOLDER:
    'affiliate_sample_request_rts_await_collection_list_search_package_id_placeholder',
  AFFILIATE_SAMPLE_REQUEST_RTS_AWAIT_COLLECTION_NAME:
    'affiliate_sample_request_rts_await_collection_name',
  AFFILIATE_SAMPLE_REQUEST_RTS_AWAIT_SHIPMENT_NAME:
    'affiliate_sample_request_rts_await_shipment_name',
  AFFILIATE_SAMPLE_REQUEST_RTS_COLLECTION_SERVICE_POPUP_DROPOFF:
    'affiliate_sample_request_rts_collection_service_popup_dropoff',
  AFFILIATE_SAMPLE_REQUEST_RTS_COLLECTION_SERVICE_POPUP_DROPOFF_DESCRIPTION:
    'affiliate_sample_request_rts_collection_service_popup_dropoff_description',
  AFFILIATE_SAMPLE_REQUEST_RTS_COLLECTION_SERVICE_POPUP_PICKUP:
    'affiliate_sample_request_rts_collection_service_popup_pickup',
  AFFILIATE_SAMPLE_REQUEST_RTS_COLLECTION_SERVICE_POPUP_PICKUP_DESCRIPTION:
    'affiliate_sample_request_rts_collection_service_popup_pickup_description',
  AFFILIATE_SAMPLE_REQUEST_RTS_COLLECTION_SERVICE_POPUP_PROVIDER:
    'affiliate_sample_request_rts_collection_service_popup_provider',
  AFFILIATE_SAMPLE_REQUEST_RTS_COLLECTION_SERVICE_POPUP_TITLE:
    'affiliate_sample_request_rts_collection_service_popup_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_COLLECTION_SERVICE_POPUP_UNAVAILABLE:
    'affiliate_sample_request_rts_collection_service_popup_unavailable',
  AFFILIATE_SAMPLE_REQUEST_RTS_COLLECTION_SERVICE_POPUP_UNAVAILABLE_MULTIPLE_CARRIER:
    'affiliate_sample_request_rts_collection_service_popup_unavailable_multiple_carrier',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_POPUP_CONFIRMATION:
    'affiliate_sample_request_rts_dropoff_popup_confirmation',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_POPUP_FIND_POINT_BTN:
    'affiliate_sample_request_rts_dropoff_popup_find_point_btn',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_POPUP_TEXT:
    'affiliate_sample_request_rts_dropoff_popup_text',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_POPUP_TITLE:
    'affiliate_sample_request_rts_dropoff_popup_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_FAIL_POPUP_DESCRIPTION:
    'affiliate_sample_request_rts_dropoff_result_fail_popup_description',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_FAIL_POPUP_DESCRIPTION_TIPS:
    'affiliate_sample_request_rts_dropoff_result_fail_popup_description_tips',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_FAIL_POPUP_LIST_CREATOR_NAME:
    'affiliate_sample_request_rts_dropoff_result_fail_popup_list_creator_name',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_FAIL_POPUP_LIST_DESCRIPTION:
    'affiliate_sample_request_rts_dropoff_result_fail_popup_list_description',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_FAIL_POPUP_LIST_PRODUCTID:
    'affiliate_sample_request_rts_dropoff_result_fail_popup_list_productid',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_FAIL_POPUP_LIST_REASON:
    'affiliate_sample_request_rts_dropoff_result_fail_popup_list_reason',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_FAIL_POPUP_QUANTITY_PLURAL:
    'affiliate_sample_request_rts_dropoff_result_fail_popup_quantity_plural',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_FAIL_POPUP_QUANTITY_SINGULAR:
    'affiliate_sample_request_rts_dropoff_result_fail_popup_quantity_singular',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_POPUP_FAIL:
    'affiliate_sample_request_rts_dropoff_result_popup_fail',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_POPUP_TEXT_PLURAL:
    'affiliate_sample_request_rts_dropoff_result_popup_text_plural',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_POPUP_TEXT_SINGULAR:
    'affiliate_sample_request_rts_dropoff_result_popup_text_singular',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_POPUP_TIPS:
    'affiliate_sample_request_rts_dropoff_result_popup_tips',
  AFFILIATE_SAMPLE_REQUEST_RTS_DROPOFF_RESULT_POPUP_TITLE:
    'affiliate_sample_request_rts_dropoff_result_popup_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_ROUTE_NOT_AVAILABLE_CONTENT:
    'affiliate_sample_request_rts_lgstcs_route_not_available_content',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_ROUTE_NOT_AVAILABLE_TITLE:
    'affiliate_sample_request_rts_lgstcs_route_not_available_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_NOT_AVAILABLE_CONTENT:
    'affiliate_sample_request_rts_lgstcs_service_not_available_content',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_NOT_AVAILABLE_TITLE:
    'affiliate_sample_request_rts_lgstcs_service_not_available_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_POPUP_ALERT:
    'affiliate_sample_request_rts_lgstcs_service_popup_alert',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_POPUP_CANNOT_CHANGE_ALERT:
    'affiliate_sample_request_rts_lgstcs_service_popup_cannot_change_alert',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_POPUP_EXTRA_BTN:
    'affiliate_sample_request_rts_lgstcs_service_popup_extra_btn',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_POPUP_EXTRA_DESCRIPTION:
    'affiliate_sample_request_rts_lgstcs_service_popup_extra_description',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_POPUP_NO_SERVICE_TEXT:
    'affiliate_sample_request_rts_lgstcs_service_popup_no_service_text',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_POPUP_STANDARD_BTN:
    'affiliate_sample_request_rts_lgstcs_service_popup_standard_btn',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_POPUP_STANDARD_DESCRIPTION:
    'affiliate_sample_request_rts_lgstcs_service_popup_standard_description',
  AFFILIATE_SAMPLE_REQUEST_RTS_LGSTCS_SERVICE_POPUP_TITLE:
    'affiliate_sample_request_rts_lgstcs_service_popup_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_POPUP_CHECKBOX:
    'affiliate_sample_request_rts_pickup_popup_checkbox',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_POPUP_DATE:
    'affiliate_sample_request_rts_pickup_popup_date',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_POPUP_PLACEHOLDER:
    'affiliate_sample_request_rts_pickup_popup_placeholder',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_POPUP_TEXT:
    'affiliate_sample_request_rts_pickup_popup_text',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_POPUP_TIME:
    'affiliate_sample_request_rts_pickup_popup_time',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_POPUP_TIME_UNAVAILABLE:
    'affiliate_sample_request_rts_pickup_popup_time_unavailable',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_POPUP_TITLE:
    'affiliate_sample_request_rts_pickup_popup_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_FAIL_POPUP_DESCRIPTION_1:
    'affiliate_sample_request_rts_pickup_result_fail_popup_description_1',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_FAIL_POPUP_DESCRIPTION_ALL:
    'affiliate_sample_request_rts_pickup_result_fail_popup_description_all',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_FAIL_POPUP_DESCRIPTION_ALL_TIPS:
    'affiliate_sample_request_rts_pickup_result_fail_popup_description_all_tips',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_FAIL_POPUP_DESCRIPTION_PART:
    'affiliate_sample_request_rts_pickup_result_fail_popup_description_part',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_FAIL_POPUP_FAIL_QUANTITY_PLURAL:
    'affiliate_sample_request_rts_pickup_result_fail_popup_fail_quantity_plural',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_FAIL_POPUP_FAIL_QUANTITY_SINGULAR:
    'affiliate_sample_request_rts_pickup_result_fail_popup_fail_quantity_singular',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_FAIL_POPUP_LIST_CREATOR_NAME:
    'affiliate_sample_request_rts_pickup_result_fail_popup_list_creator_name',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_FAIL_POPUP_LIST_PRODUCTID:
    'affiliate_sample_request_rts_pickup_result_fail_popup_list_productid',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_FAIL_POPUP_LIST_REASON:
    'affiliate_sample_request_rts_pickup_result_fail_popup_list_reason',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_POPUP_DATE:
    'affiliate_sample_request_rts_pickup_result_popup_date',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_POPUP_PROVIDER:
    'affiliate_sample_request_rts_pickup_result_popup_provider',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_POPUP_TEXT_DESCIPTION_LINE1:
    'affiliate_sample_request_rts_pickup_result_popup_text_desciption_line1',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_POPUP_TEXT_DESCIPTION_LINE2:
    'affiliate_sample_request_rts_pickup_result_popup_text_desciption_line2',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_POPUP_TEXT_PLURAL:
    'affiliate_sample_request_rts_pickup_result_popup_text_plural',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_POPUP_TEXT_SINGULAR:
    'affiliate_sample_request_rts_pickup_result_popup_text_singular',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_POPUP_TIME:
    'affiliate_sample_request_rts_pickup_result_popup_time',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_POPUP_TITLE:
    'affiliate_sample_request_rts_pickup_result_popup_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_PICKUP_RESULT_POPUP_TRACKINGID:
    'affiliate_sample_request_rts_pickup_result_popup_trackingid',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_BTN:
    'affiliate_sample_request_rts_print_btn',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_POPUP_CHOOSE_DOCUMENT:
    'affiliate_sample_request_rts_print_popup_choose_document',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_POPUP_CHOOSE_SIZE:
    'affiliate_sample_request_rts_print_popup_choose_size',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_POPUP_CHOOSE_SIZE_A5:
    'affiliate_sample_request_rts_print_popup_choose_size_a5',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_POPUP_CHOOSE_SIZE_A6:
    'affiliate_sample_request_rts_print_popup_choose_size_a6',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_POPUP_PACKING_LIST:
    'affiliate_sample_request_rts_print_popup_packing_list',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_POPUP_PRINT_ALL:
    'affiliate_sample_request_rts_print_popup_print_all',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_POPUP_PRINT_BTN:
    'affiliate_sample_request_rts_print_popup_print_btn',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_POPUP_SHIPPING_LABEL:
    'affiliate_sample_request_rts_print_popup_shipping_label',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_POPUP_TITLE:
    'affiliate_sample_request_rts_print_popup_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_RESULT_POPUP_FAIL:
    'affiliate_sample_request_rts_print_result_popup_fail',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_RESULT_POPUP_FAIL_PRINT_AGAIN_BTN:
    'affiliate_sample_request_rts_print_result_popup_fail_print_again_btn',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_RESULT_POPUP_ORDER_ID:
    'affiliate_sample_request_rts_print_result_popup_order_id',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_RESULT_POPUP_PACKING_LIST:
    'affiliate_sample_request_rts_print_result_popup_packing_list',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_RESULT_POPUP_SHIPPING_LABEL:
    'affiliate_sample_request_rts_print_result_popup_shipping_label',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_RESULT_POPUP_SUCCESS:
    'affiliate_sample_request_rts_print_result_popup_success',
  AFFILIATE_SAMPLE_REQUEST_RTS_PRINT_RESULT_POPUP_TITLE:
    'affiliate_sample_request_rts_print_result_popup_title',
  AFFILIATE_SAMPLE_REQUEST_RTS_SELF_DELIVERY_FAILED_DISTRICT_DIFF_TOAST:
    'affiliate_sample_request_rts_self_delivery_failed_district_diff_toast',
  AFFILIATE_SAMPLE_REQUEST_RTS_SELF_DELIVERY_FAILED_MULTIPULE_ROUTES_TOAST:
    'affiliate_sample_request_rts_self_delivery_failed_multipule_routes_toast',
  AFFILIATE_SAMPLE_REQUEST_SEARCH_CREATOR_HINT:
    'affiliate_sample_request_search_creator_hint',
  AFFILIATE_SAMPLE_REQUEST_SEARCH_CREATOR_KEYWORD:
    'affiliate_sample_request_search_creator_keyword',
  AFFILIATE_SAMPLE_REQUEST_SEARCH_PRODUCTID_HINT:
    'affiliate_sample_request_search_productid_hint',
  AFFILIATE_SAMPLE_REQUEST_SEARCH_PRODUCTID_KEYWORD:
    'affiliate_sample_request_search_productid_keyword',
  AFFILIATE_SAMPLE_REQUEST_SEARCH_PRODUCTNAME_HINT:
    'affiliate_sample_request_search_productname_hint',
  AFFILIATE_SAMPLE_REQUEST_SEARCH_PRODUCTNAME_KEYWORD:
    'affiliate_sample_request_search_productname_keyword',
  AFFILIATE_SAMPLE_REQUEST_SEARCH_TABLE_COLUMN_NAME_CREATOR:
    'affiliate_sample_request_search_table_column_name_creator',
  AFFILIATE_SAMPLE_REQUEST_SEARCH_TABLE_COLUMN_NAME_FOLLOWER:
    'affiliate_sample_request_search_table_column_name_follower',
  AFFILIATE_SAMPLE_REQUEST_SEARCH_TABLE_COLUMN_NAME_PRODUCT:
    'affiliate_sample_request_search_table_column_name_product',
  AFFILIATE_SAMPLE_REQUEST_SHIPPED_DROPOFF:
    'affiliate_sample_request_shipped_dropoff',
  AFFILIATE_SAMPLE_REQUEST_SHIPPED_LIST_ORDER_STATUS:
    'affiliate_sample_request_shipped_list_order_status',
  AFFILIATE_SAMPLE_REQUEST_SHIPPED_PICKUP:
    'affiliate_sample_request_shipped_pickup',
  AFFILIATE_SAMPLE_REQUEST_SHIPPED_PICKUP_DATE:
    'affiliate_sample_request_shipped_pickup_date',
  AFFILIATE_SAMPLE_REQUEST_SHIP_REJECT_POPUP_REASON_MULTIPLE_WAREHOUSE:
    'affiliate_sample_request_ship_reject_popup_reason_multiple_warehouse',
  AFFILIATE_SAMPLE_REQUEST_STATUS_DELIVERED:
    'affiliate_sample_request_status_delivered',
  AFFILIATE_SAMPLE_REQUEST_STATUS_IN_TRANSIT:
    'affiliate_sample_request_status_in_transit',
  AFFILIATE_SAMPLE_REQUEST_TOAST_NO_PRODUCT_FOUND:
    'affiliate_sample_request_toast_no_product_found',
  AFFILIATE_SAMPLE_REQUEST_TOAST_NO_SELECTED_REQUEST:
    'affiliate_sample_request_toast_no_selected_request',
  AFFILIATE_SAMPLE_REQUEST_TOAST_PARTIAL_REQUEST_UNSUPPORTED:
    'affiliate_sample_request_toast_partial_request_unsupported',
  AFFILIATE_SAMPLE_REQUEST_VIEW_BILLS_BTN:
    'affiliate_sample_request_view_bills_btn',
  AFFILIATE_SAMPLE_REQUEST_VIEW_BILLS_DESCRIPTION:
    'affiliate_sample_request_view_bills_description',
  AFFILIATE_SAMPLE_RTS_EXPORT_EXPORTING_POPUP_PREPARE_DATA:
    'affiliate_sample_rts_export_exporting_popup_prepare_data',
  AFFILIATE_SAMPLE_RTS_EXPORT_EXPORTING_POPUP_PROCESS_NUMBER:
    'affiliate_sample_rts_export_exporting_popup_process_number',
  AFFILIATE_SAMPLE_RTS_EXPORT_EXPORTING_POPUP_TITLE:
    'affiliate_sample_rts_export_exporting_popup_title',
  AFFILIATE_SAMPLE_RTS_EXPORT_EXPORTING_TOAST_NO_APPLICATION:
    'affiliate_sample_rts_export_exporting_toast_no_application',
  AFFILIATE_SAMPLE_RTS_EXPORT_EXPORTING_TOAST_NO_DATA:
    'affiliate_sample_rts_export_exporting_toast_no_data',
  AFFILIATE_SAMPLE_RTS_EXPORT_EXPORT_BTN:
    'affiliate_sample_rts_export_export_btn',
  AFFILIATE_SAMPLE_RTS_EXPORT_POPUP_EXCEL_FORMAT:
    'affiliate_sample_rts_export_popup_excel_format',
  AFFILIATE_SAMPLE_RTS_EXPORT_POPUP_SELECT_TIME:
    'affiliate_sample_rts_export_popup_select_time',
  AFFILIATE_SAMPLE_RTS_EXPORT_POPUP_SELECT_TIME_CREATION:
    'affiliate_sample_rts_export_popup_select_time_creation',
  AFFILIATE_SAMPLE_RTS_EXPORT_POPUP_SELECT_TIME_END_DATE:
    'affiliate_sample_rts_export_popup_select_time_end_date',
  AFFILIATE_SAMPLE_RTS_EXPORT_POPUP_SELECT_TIME_START_DATE:
    'affiliate_sample_rts_export_popup_select_time_start_date',
  AFFILIATE_SAMPLE_RTS_EXPORT_POPUP_TITLE:
    'affiliate_sample_rts_export_popup_title',
  AFFILIATE_SAMPLE_SYSTEM_ERROR: 'affiliate_sample_system_error',
  AFFILIATE_SAMPLE_SYSTEM_ERROR_RETRY_BTN:
    'affiliate_sample_system_error_retry_btn',
  AFFILIATE_SELLER_ACCOUNT_INFO: 'affiliate_seller_account_info',
  AFFILIATE_SELLER_ACCOUNT_INFO_ENTRANCE:
    'affiliate_seller_account_info_entrance',
  AFFILIATE_SELLER_CONTACT_INFO_ENTRANCE:
    'affiliate_seller_contact_info_entrance',
  AFFILIATE_SELLER_CONTACT_INFO_PLAN_BUTTON:
    'affiliate_seller_contact_info_plan_button',
  AFFILIATE_SELLER_CONTACT_INFO_PLAN_ENTRANCE:
    'affiliate_seller_contact_info_plan_entrance',
  AFFILIATE_SELLER_CONTACT_INFO_SETUP_BUTTON:
    'affiliate_seller_contact_info_setup_button',
  AFFILIATE_SELLER_CONTACT_INFO_SETUP_DESCRIPTION:
    'affiliate_seller_contact_info_setup_description',
  AFFILIATE_SELLER_CONTACT_INFO_SETUP_NOPERMISSION_BUTTON:
    'affiliate_seller_contact_info_setup_nopermission_button',
  AFFILIATE_SELLER_CONTACT_INFO_SETUP_NOPERMISSION_DESCRIPTION:
    'affiliate_seller_contact_info_setup_nopermission_description',
  AFFILIATE_SELLER_CONTACT_INFO_SETUP_NOPERMISSION_TITLE:
    'affiliate_seller_contact_info_setup_nopermission_title',
  AFFILIATE_SELLER_CONTACT_INFO_SETUP_TITLE:
    'affiliate_seller_contact_info_setup_title',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_CANCEL:
    'affiliate_seller_contact_info_set_up_popup_cancel',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_CREATE:
    'affiliate_seller_contact_info_set_up_popup_create',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_DESCRIPTION:
    'affiliate_seller_contact_info_set_up_popup_description',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_EMAIL:
    'affiliate_seller_contact_info_set_up_popup_email',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_EMAIL_DESCRIPTION:
    'affiliate_seller_contact_info_set_up_popup_email_description',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_ERROR_MESSAGE:
    'affiliate_seller_contact_info_set_up_popup_error_message',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_SWITCH_CLOSE_CANCEL:
    'affiliate_seller_contact_info_set_up_popup_switch_close_cancel',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_SWITCH_CLOSE_DESCRIPTION:
    'affiliate_seller_contact_info_set_up_popup_switch_close_description',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_SWITCH_CLOSE_SURE:
    'affiliate_seller_contact_info_set_up_popup_switch_close_sure',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_SWITCH_CLOSE_TITLE:
    'affiliate_seller_contact_info_set_up_popup_switch_close_title',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_SWITCH_DESCRIPTION:
    'affiliate_seller_contact_info_set_up_popup_switch_description',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_SWITCH_TITLE:
    'affiliate_seller_contact_info_set_up_popup_switch_title',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_TITLE:
    'affiliate_seller_contact_info_set_up_popup_title',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_WHATSAPP:
    'affiliate_seller_contact_info_set_up_popup_whatsapp',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_WHATSAPP_DESCRIPTION:
    'affiliate_seller_contact_info_set_up_popup_whatsapp_description',
  AFFILIATE_SELLER_CONTACT_INFO_SET_UP_POPUP_WHATSAPP_INCORRECT_FORMAT:
    'affiliate_seller_contact_info_set_up_popup_whatsapp_incorrect_format',
  AFFILIATE_SELLER_NO_PERMISSION: 'affiliate_seller_no_permission',
  AFFILIATE_SELLER_NO_PERMISSION_BUTTON_RETRY:
    'affiliate_seller_no_permission_button_retry',
  AFFILIATE_SELLER_NO_PERMISSION_BUTTON_TO_SELLER_CENTER:
    'affiliate_seller_no_permission_button_to_seller_center',
  AFFILIATE_SELLER_NO_PERMISSION_DEACTIVE:
    'affiliate_seller_no_permission_deactive',
  AFFILIATE_SELLER_NO_PERMISSION_FAILED:
    'affiliate_seller_no_permission_failed',
  AFFILIATE_SELLER_NO_PERMISSION_PENDING:
    'affiliate_seller_no_permission_pending',
  AFFILIATE_SIDEBAR_AFFILIATE_ORDER: 'affiliate_sidebar_affiliate_order',
  AFFILIATE_SIDEBAR_FIND_CREATORS_CREATOR_MARKETPLACE:
    'affiliate_sidebar_find_creators_creator_marketplace',
  AFFILIATE_SIDEBAR_FIND_CREATORS_INVITATION_HISTORY:
    'affiliate_sidebar_find_creators_invitation_history',
  AFFILIATE_TARGETED_PLAN_CREATE_PLAN_CLICK_ERROR:
    'affiliate_targeted_plan_create_plan_click_error',
  COMMON_TIMEPICKER_END_TIME: 'common_timepicker_end_time',
  COMMON_TIMEPICKER_START_TIME: 'common_timepicker_start_time',
  COMMON_TYPOGRAPHY_COPIED: 'common_typography_copied',
  CREATOR_MARKETPLACE_ACG: 'creator_marketplace_acg',
  CREATOR_MARKETPLACE_ADVANCED_DANCE: 'creator_marketplace_advanced_dance',
  CREATOR_MARKETPLACE_ANIMAL: 'creator_marketplace_animal',
  CREATOR_MARKETPLACE_ANIMALS: 'creator_marketplace_Animals',
  CREATOR_MARKETPLACE_ART: 'creator_marketplace_Art',
  CREATOR_MARKETPLACE_ASSORTED_DIVERSITY:
    'creator_marketplace_assorted_diversity',
  CREATOR_MARKETPLACE_ASSORTED_NON_DIVERSITY:
    'creator_marketplace_assorted_non_diversity',
  CREATOR_MARKETPLACE_AUTO_VEHICLE: 'creator_marketplace_Auto_Vehicle',
  CREATOR_MARKETPLACE_BASIC_DANCE: 'creator_marketplace_basic_dance',
  CREATOR_MARKETPLACE_BEAUTY: 'creator_marketplace_beauty',
  CREATOR_MARKETPLACE_BEAUTYCARE: 'creator_marketplace_BeautyCare',
  CREATOR_MARKETPLACE_CHECK_ACCOUNT: 'creator_marketplace_check_account',
  CREATOR_MARKETPLACE_COMEDY: 'creator_marketplace_Comedy',
  CREATOR_MARKETPLACE_COMICS_CARTOON_ANIME:
    'creator_marketplace_Comics_Cartoon_Anime',
  CREATOR_MARKETPLACE_COMIC_CARTOON: 'creator_marketplace_comic_cartoon',
  CREATOR_MARKETPLACE_CONTACT_ICON_TOOLTIP:
    'creator_marketplace_contact_icon_tooltip',
  CREATOR_MARKETPLACE_CONTACT_ME_BTN: 'creator_marketplace_contact_me_btn',
  CREATOR_MARKETPLACE_CONTACT_SEND_EMAIL:
    'creator_marketplace_contact_send_email',
  CREATOR_MARKETPLACE_CONTACT_SEND_MESSAGE:
    'creator_marketplace_contact_send_message',
  CREATOR_MARKETPLACE_CONTACT_TOOLTIPS: 'creator_marketplace_contact_tooltips',
  CREATOR_MARKETPLACE_COSPLAY: 'creator_marketplace_Cosplay',
  CREATOR_MARKETPLACE_CREATIVE_TRICK: 'creator_marketplace_creative_trick',
  CREATOR_MARKETPLACE_CREATOR_CONTACT_CHANNEL_ONLY_EMAIL:
    'creator_marketplace_creator_contact_channel_only_email',
  CREATOR_MARKETPLACE_CREATOR_CONTACT_CHANNEL_ONLY_WHATSAPP:
    'creator_marketplace_creator_contact_channel_only_whatsapp',
  CREATOR_MARKETPLACE_CREATOR_CONTACT_CHANNEL_WHATSAPP_EMAIL:
    'creator_marketplace_creator_contact_channel_whatsapp_email',
  CREATOR_MARKETPLACE_CUSTOMIZE_APPLY: 'creator_marketplace_customize_apply',
  CREATOR_MARKETPLACE_DAILY_LIFE: 'creator_marketplace_Daily_Life',
  CREATOR_MARKETPLACE_DANCE: 'creator_marketplace_Dance',
  CREATOR_MARKETPLACE_DATA_AGE: 'creator_marketplace_data_age',
  CREATOR_MARKETPLACE_DATA_AGE_BUBBLE: 'creator_marketplace_data_age_bubble',
  CREATOR_MARKETPLACE_DATA_AVG_VIDEO_VIEWS:
    'creator_marketplace_data_avg_video_views',
  CREATOR_MARKETPLACE_DATA_CARD_PROMOTE_PRODUCTS:
    'creator_marketplace_data_card_promote_products',
  CREATOR_MARKETPLACE_DATA_CONTENT: 'creator_marketplace_data_content',
  CREATOR_MARKETPLACE_DATA_CONTENT_PLACEHOLDER:
    'creator_marketplace_data_content_placeholder',
  CREATOR_MARKETPLACE_DATA_FOLLOWERS1: 'creator_marketplace_data_followers1',
  CREATOR_MARKETPLACE_DATA_FOLLOWERS2: 'creator_marketplace_data_followers2',
  CREATOR_MARKETPLACE_DATA_FOLLOWERS3: 'creator_marketplace_data_followers3',
  CREATOR_MARKETPLACE_DATA_FOLLOWERS: 'creator_marketplace_data_followers',
  CREATOR_MARKETPLACE_DATA_GENDER: 'creator_marketplace_data_gender',
  CREATOR_MARKETPLACE_DATA_GENDER_BUBBLE:
    'creator_marketplace_data_gender_bubble',
  CREATOR_MARKETPLACE_DATA_GENDER_F: 'creator_marketplace_data_gender_f',
  CREATOR_MARKETPLACE_DATA_GENDER_M: 'creator_marketplace_data_gender_m',
  CREATOR_MARKETPLACE_DATA_REGION_PLACEHOLDER:
    'creator_marketplace_data_region_placeholder',
  CREATOR_MARKETPLACE_DATA_SEARCH_PLACEHOLDER:
    'creator_marketplace_data_search_placeholder',
  CREATOR_MARKETPLACE_DATA_SORTBY: 'creator_marketplace_data_sortby',
  CREATOR_MARKETPLACE_DATA_SORTBY_DEFAULT:
    'creator_marketplace_data_sortby_default',
  CREATOR_MARKETPLACE_DATA_SORTBY_PRODUCTS:
    'creator_marketplace_data_sortby_products',
  CREATOR_MARKETPLACE_DATA_SORTBY_PUBLISH:
    'creator_marketplace_data_sortby_publish',
  CREATOR_MARKETPLACE_DATA_SORTBY_VIDEO_VIEWS:
    'creator_marketplace_data_sortby_video_views',
  CREATOR_MARKETPLACE_DATA_TITLE: 'creator_marketplace_data_title',
  CREATOR_MARKETPLACE_DEFINITION_TOOLTIPS_BTN:
    'creator_marketplace_definition_tooltips_btn',
  CREATOR_MARKETPLACE_DERIVATIVE: 'creator_marketplace_derivative',
  CREATOR_MARKETPLACE_DETAIL_CORE_COMMENT:
    'creator_marketplace_detail_core_comment',
  CREATOR_MARKETPLACE_DETAIL_CORE_COMMENT_BUBBLE:
    'creator_marketplace_detail_core_comment_bubble',
  CREATOR_MARKETPLACE_DETAIL_CORE_ECOMMERCE:
    'creator_marketplace_detail_core_ecommerce',
  CREATOR_MARKETPLACE_DETAIL_CORE_LIKE: 'creator_marketplace_detail_core_like',
  CREATOR_MARKETPLACE_DETAIL_CORE_LIKE_BUBBLE:
    'creator_marketplace_detail_core_like_bubble',
  CREATOR_MARKETPLACE_DETAIL_CORE_PUBLISH:
    'creator_marketplace_detail_core_publish',
  CREATOR_MARKETPLACE_DETAIL_CORE_PUBLISH_BUBBLE:
    'creator_marketplace_detail_core_publish_bubble',
  CREATOR_MARKETPLACE_DETAIL_CORE_VIEWS:
    'creator_marketplace_detail_core_views',
  CREATOR_MARKETPLACE_DETAIL_CORE_VIEWS_BUBBLE:
    'creator_marketplace_detail_core_views_bubble',
  CREATOR_MARKETPLACE_DETAIL_FOLLOWER_AGE:
    'creator_marketplace_detail_follower_age',
  CREATOR_MARKETPLACE_DETAIL_FOLLOWER_GENDER:
    'creator_marketplace_detail_follower_gender',
  CREATOR_MARKETPLACE_DETAIL_NO_FOLLOWERS:
    'creator_marketplace_detail_no_followers',
  CREATOR_MARKETPLACE_DETAIL_PROFILE_FOLLOWER:
    'creator_marketplace_detail_profile_follower',
  CREATOR_MARKETPLACE_DETAIL_PROFILE_PERSONAL:
    'creator_marketplace_detail_profile_personal',
  CREATOR_MARKETPLACE_DETAIL_PROFILE_PRODUCT:
    'creator_marketplace_detail_profile_product',
  CREATOR_MARKETPLACE_DETAIL_TAB1: 'creator_marketplace_detail_tab1',
  CREATOR_MARKETPLACE_DETAIL_TAB2: 'creator_marketplace_detail_tab2',
  CREATOR_MARKETPLACE_DETAIL_TAB3: 'creator_marketplace_detail_tab3',
  CREATOR_MARKETPLACE_DETAIL_TAB4: 'creator_marketplace_detail_tab4',
  CREATOR_MARKETPLACE_DETAIL_TITLE1: 'creator_marketplace_detail_title1',
  CREATOR_MARKETPLACE_DETAIL_TITLE2: 'creator_marketplace_detail_title2',
  CREATOR_MARKETPLACE_DETAIL_TITLE3: 'creator_marketplace_detail_title3',
  CREATOR_MARKETPLACE_DETAIL_TITLE4: 'creator_marketplace_detail_title4',
  CREATOR_MARKETPLACE_DETAIL_TITLE5: 'creator_marketplace_detail_title5',
  CREATOR_MARKETPLACE_DETAIL_TREND_COMMENT:
    'creator_marketplace_detail_trend_comment',
  CREATOR_MARKETPLACE_DETAIL_TREND_LIKE:
    'creator_marketplace_detail_trend_like',
  CREATOR_MARKETPLACE_DETAIL_TREND_TAB1:
    'creator_marketplace_detail_trend_tab1',
  CREATOR_MARKETPLACE_DETAIL_TREND_TAB2:
    'creator_marketplace_detail_trend_tab2',
  CREATOR_MARKETPLACE_DETAIL_TREND_VIEWS:
    'creator_marketplace_detail_trend_views',
  CREATOR_MARKETPLACE_DETAIL_VIDEOS_TIME:
    'creator_marketplace_detail_videos_time',
  CREATOR_MARKETPLACE_DIALOGUE_LIPSYNC: 'creator_marketplace_dialogue_lipsync',
  CREATOR_MARKETPLACE_DIY_HANDCRAFTS: 'creator_marketplace_DIY_Handcrafts',
  CREATOR_MARKETPLACE_DIY_LIFE_HACKS: 'creator_marketplace_diy_life_hacks',
  CREATOR_MARKETPLACE_DRAMA: 'creator_marketplace_drama',
  CREATOR_MARKETPLACE_EDUCATION: 'creator_marketplace_Education',
  CREATOR_MARKETPLACE_ENTERTAINMENT_CULTURE:
    'creator_marketplace_Entertainment_Culture',
  CREATOR_MARKETPLACE_ENTERTAINMENT_INFOS:
    'creator_marketplace_entertainment_infos',
  CREATOR_MARKETPLACE_ERROR_LIST_EMPTY: 'creator_marketplace_error_list_empty',
  CREATOR_MARKETPLACE_ERROR_VIDEO_EMPTY:
    'creator_marketplace_error_video_empty',
  CREATOR_MARKETPLACE_ERROR_VIDEO_EMPTY_RETRY:
    'creator_marketplace_error_video_empty_retry',
  CREATOR_MARKETPLACE_EXTREME_SPORT: 'creator_marketplace_extreme_sport',
  CREATOR_MARKETPLACE_EXTREME_SPORTS: 'creator_marketplace_Extreme_Sports',
  CREATOR_MARKETPLACE_FAMILY: 'creator_marketplace_Family',
  CREATOR_MARKETPLACE_FASHION_BEAUTY: 'creator_marketplace_fashion_beauty',
  CREATOR_MARKETPLACE_FILTER_CUSTOMIZE: 'creator_marketplace_filter_customize',
  CREATOR_MARKETPLACE_FILTER_REGION: 'creator_marketplace_filter_region',
  CREATOR_MARKETPLACE_FIND_CREATORS: 'creator_marketplace_find_creators',
  CREATOR_MARKETPLACE_FINGERDANACE_BASICDANCE:
    'creator_marketplace_FingerDanace_BasicDance',
  CREATOR_MARKETPLACE_FISHING_HUNTING_CAMPING:
    'creator_marketplace_Fishing_Hunting_Camping',
  CREATOR_MARKETPLACE_FITNESS_HEALTH: 'creator_marketplace_Fitness_Health',
  CREATOR_MARKETPLACE_FOLLOWER_PLURAL: 'creator_marketplace_follower_plural',
  CREATOR_MARKETPLACE_FOLLOWER_SINGLE: 'creator_marketplace_follower_single',
  CREATOR_MARKETPLACE_FOODTOUR_MUKBANGS:
    'creator_marketplace_FoodTour_Mukbangs',
  CREATOR_MARKETPLACE_FOOD_BEVERAGE: 'creator_marketplace_food_beverage',
  CREATOR_MARKETPLACE_FOOD_DISPLAY_COOKING:
    'creator_marketplace_food_display_cooking',
  CREATOR_MARKETPLACE_FOOD_DRINK: 'creator_marketplace_Food_Drink',
  CREATOR_MARKETPLACE_FOOD_DRINK_COOKING:
    'creator_marketplace_Food_Drink_Cooking',
  CREATOR_MARKETPLACE_FOOD_GUIDE_RECOMMENDATION:
    'creator_marketplace_food_guide_recommendation',
  CREATOR_MARKETPLACE_GAME: 'creator_marketplace_game',
  CREATOR_MARKETPLACE_GAMES: 'creator_marketplace_Games',
  CREATOR_MARKETPLACE_GB: 'creator_marketplace_GB',
  CREATOR_MARKETPLACE_HOME_GARDEN: 'creator_marketplace_Home_Garden',
  CREATOR_MARKETPLACE_HUMANITIES_BUSINESS_FINANCE:
    'creator_marketplace_Humanities_Business_Finance',
  CREATOR_MARKETPLACE_ID: 'creator_marketplace_ID',
  CREATOR_MARKETPLACE_INVITATION_DETAIL:
    'creator_marketplace_invitation_detail',
  CREATOR_MARKETPLACE_INVITATION_DETAIL_COLLABORATION_TYPE_BOTH:
    'creator_marketplace_invitation_detail_collaboration_type_both',
  CREATOR_MARKETPLACE_INVITATION_DETAIL_CONTACT_INFO_GUIDE:
    'creator_marketplace_invitation_detail_contact_info_guide',
  CREATOR_MARKETPLACE_INVITATION_DETAIL_OPTION_NO:
    'creator_marketplace_invitation_detail_option_no',
  CREATOR_MARKETPLACE_INVITATION_DETAIL_OPTION_YES:
    'creator_marketplace_invitation_detail_option_yes',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_FILTER_END_DATE_PLACEHOLDER:
    'creator_marketplace_invitation_history_filter_end_date_placeholder',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_FILTER_SEARCH_NAME_PLACEHOLDER:
    'creator_marketplace_invitation_history_filter_search_name_placeholder',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_FILTER_START_DATE_PLACEHOLDER:
    'creator_marketplace_invitation_history_filter_start_date_placeholder',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_LIST_ACTION:
    'creator_marketplace_invitation_history_list_action',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_LIST_CONTACT_INFO:
    'creator_marketplace_invitation_history_list_contact_info',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_LIST_CREATOR:
    'creator_marketplace_invitation_history_list_creator',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_LIST_INVITATION_TIMES:
    'creator_marketplace_invitation_history_list_invitation_times',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_LIST_LATEST_INVITATION_TIME:
    'creator_marketplace_invitation_history_list_latest_invitation_time',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_LIST_LATEST_TIME_PLURAL:
    'creator_marketplace_invitation_history_list_latest_time_plural',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_LIST_LATEST_TIME_SINGUAR:
    'creator_marketplace_invitation_history_list_latest_time_singuar',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_LIST_LATEST_TIME_TODAY:
    'creator_marketplace_invitation_history_list_latest_time_today',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_PAGE_NUM:
    'creator_marketplace_invitation_history_page_num',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_PAGE_TITLE:
    'creator_marketplace_invitation_history_page_title',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_RESEND_INVITATION_BTN:
    'creator_marketplace_invitation_history_resend_invitation_btn',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_VIEW_BTN:
    'creator_marketplace_invitation_history_view_btn',
  CREATOR_MARKETPLACE_INVITATION_HISTORY_VIEW_DETAIL_BTN:
    'creator_marketplace_invitation_history_view_detail_btn',
  CREATOR_MARKETPLACE_INVITED_STATE: 'creator_marketplace_invited_state',
  CREATOR_MARKETPLACE_INVITED_TOOLTIPS: 'creator_marketplace_invited_tooltips',
  CREATOR_MARKETPLACE_INVITE_ACCOUNT_FORMAT_ERROR:
    'creator_marketplace_invite_account_format_error',
  CREATOR_MARKETPLACE_INVITE_BTN: 'creator_marketplace_invite_btn',
  CREATOR_MARKETPLACE_INVITE_CANCEL_BTN:
    'creator_marketplace_invite_cancel_btn',
  CREATOR_MARKETPLACE_INVITE_CONTACT_METHOD:
    'creator_marketplace_invite_contact_method',
  CREATOR_MARKETPLACE_INVITE_CONTACT_METHOD_EMPTY_ERROR:
    'creator_marketplace_invite_contact_method_empty_error',
  CREATOR_MARKETPLACE_INVITE_CONTACT_METHOD_SAVE:
    'creator_marketplace_invite_contact_method_save',
  CREATOR_MARKETPLACE_INVITE_COOPERATIVE_TYPE:
    'creator_marketplace_invite_cooperative_type',
  CREATOR_MARKETPLACE_INVITE_COOPERATIVE_TYPE_EMPTY_TOAST:
    'creator_marketplace_invite_cooperative_type_empty_toast',
  CREATOR_MARKETPLACE_INVITE_COOPERATIVE_TYPE_FIXED_PAYMENTS:
    'creator_marketplace_invite_cooperative_type_fixed_payments',
  CREATOR_MARKETPLACE_INVITE_COOPERATIVE_TYPS_SALES_COMMISSION:
    'creator_marketplace_invite_cooperative_typs_sales_commission',
  CREATOR_MARKETPLACE_INVITE_CREATOR_TITLE:
    'creator_marketplace_invite_creator_title',
  CREATOR_MARKETPLACE_INVITE_DISCARD_DIALOG:
    'creator_marketplace_invite_discard_dialog',
  CREATOR_MARKETPLACE_INVITE_DISCARD_DIALOG_BTN_CANCEL:
    'creator_marketplace_invite_discard_dialog_btn_cancel',
  CREATOR_MARKETPLACE_INVITE_DISCARD_DIALOG_BTN_DISCARD:
    'creator_marketplace_invite_discard_dialog_btn_discard',
  CREATOR_MARKETPLACE_INVITE_EMAIL: 'creator_marketplace_invite_email',
  CREATOR_MARKETPLACE_INVITE_EMAIL_FORMAT_ERROR:
    'creator_marketplace_invite_email_format_error',
  CREATOR_MARKETPLACE_INVITE_EMAIL_PLACEHOLDER:
    'creator_marketplace_invite_email_placeholder',
  CREATOR_MARKETPLACE_INVITE_FIXED_PAYMENT_TOOLTIPS:
    'creator_marketplace_invite_fixed_payment_tooltips',
  CREATOR_MARKETPLACE_INVITE_FREE_SAMPLE_CHOICE:
    'creator_marketplace_invite_free_sample_choice',
  CREATOR_MARKETPLACE_INVITE_INVITATION_MESSAGE:
    'creator_marketplace_invite_invitation_message',
  CREATOR_MARKETPLACE_INVITE_INVITATION_MESSAGE_EMPTY_ERROR:
    'creator_marketplace_invite_invitation_message_empty_error',
  CREATOR_MARKETPLACE_INVITE_INVITATION_MESSAGE_PLACEHOLDER:
    'creator_marketplace_invite_invitation_message_placeholder',
  CREATOR_MARKETPLACE_INVITE_INVITATION_MESSAGE_TEXT:
    'creator_marketplace_invite_invitation_message_text',
  CREATOR_MARKETPLACE_INVITE_MULTIPLE_CHOICE_TIPS:
    'creator_marketplace_invite_multiple_choice_tips',
  CREATOR_MARKETPLACE_INVITE_SALES_COMMISSION_TOOLTIPS:
    'creator_marketplace_invite_sales_commission_tooltips',
  CREATOR_MARKETPLACE_INVITE_SEND_BTN: 'creator_marketplace_invite_send_btn',
  CREATOR_MARKETPLACE_INVITE_SEND_SUCCESS_DIALOG_BTN_OK:
    'creator_marketplace_invite_send_success_dialog_btn_ok',
  CREATOR_MARKETPLACE_INVITE_SEND_SUCCESS_DIALOG_TEXT:
    'creator_marketplace_invite_send_success_dialog_text',
  CREATOR_MARKETPLACE_INVITE_SEND_SUCCESS_DIALOG_TITLE:
    'creator_marketplace_invite_send_success_dialog_title',
  CREATOR_MARKETPLACE_INVITE_TOOLTIPS: 'creator_marketplace_invite_tooltips',
  CREATOR_MARKETPLACE_INVITE_WHATSAPP_ACCOUNT:
    'creator_marketplace_invite_whatsapp_account',
  CREATOR_MARKETPLACE_INVITE_WHATSAPP_ACCOUNT_PLACEHOLDER:
    'creator_marketplace_invite_whatsapp_account_placeholder',
  CREATOR_MARKETPLACE_LIFE_HACKS: 'creator_marketplace_Life_Hacks',
  CREATOR_MARKETPLACE_LIFE_RECREATION: 'creator_marketplace_Life_Recreation',
  CREATOR_MARKETPLACE_LIPSYNC: 'creator_marketplace_Lipsync',
  CREATOR_MARKETPLACE_MAGIC: 'creator_marketplace_Magic',
  CREATOR_MARKETPLACE_MOTIVATION: 'creator_marketplace_Motivation',
  CREATOR_MARKETPLACE_MOTIVATION_ADVICE:
    'creator_marketplace_motivation_advice',
  CREATOR_MARKETPLACE_MOVIES_TVWORKS: 'creator_marketplace_Movies_TVworks',
  CREATOR_MARKETPLACE_MOVIE_TV_SERIES_MUSIC:
    'creator_marketplace_movie_tv_series_music',
  CREATOR_MARKETPLACE_MUKBANG: 'creator_marketplace_mukbang',
  CREATOR_MARKETPLACE_MUSIC: 'creator_marketplace_music',
  CREATOR_MARKETPLACE_MUSIC_LIPSYNC: 'creator_marketplace_music_lipsync',
  CREATOR_MARKETPLACE_MUSIC_THEATRE_STAGE:
    'creator_marketplace_Music_Theatre_Stage',
  CREATOR_MARKETPLACE_MY: 'creator_marketplace_MY',
  CREATOR_MARKETPLACE_NATURAL_ENVIRONMENT:
    'creator_marketplace_Natural_Environment',
  CREATOR_MARKETPLACE_NON_CATEGORY: 'creator_marketplace_non_category',
  CREATOR_MARKETPLACE_NON_VERTICAL: 'creator_marketplace_non_vertical',
  CREATOR_MARKETPLACE_NON_VIDEO_GAMES: 'creator_marketplace_non_video_games',
  CREATOR_MARKETPLACE_NO_CLASSIFICATION:
    'creator_marketplace_No Classification',
  CREATOR_MARKETPLACE_OCCUPATION: 'creator_marketplace_occupation',
  CREATOR_MARKETPLACE_ODDLY_SATISFYING: 'creator_marketplace_Oddly_Satisfying',
  CREATOR_MARKETPLACE_OTHERS: 'creator_marketplace_Others',
  CREATOR_MARKETPLACE_OTHER_ANIMALS: 'creator_marketplace_Other_Animals',
  CREATOR_MARKETPLACE_OTHER_BEAUTY: 'creator_marketplace_other_beauty',
  CREATOR_MARKETPLACE_OTHER_BEAUTYCARE: 'creator_marketplace_Other_BeautyCare',
  CREATOR_MARKETPLACE_OTHER_FASHION: 'creator_marketplace_Other_Fashion',
  CREATOR_MARKETPLACE_OTHER_TALENT: 'creator_marketplace_Other_Talent',
  CREATOR_MARKETPLACE_OUTDOOR_ACTIVITIES:
    'creator_marketplace_Outdoor_Activities',
  CREATOR_MARKETPLACE_OUTFITS: 'creator_marketplace_Outfits',
  CREATOR_MARKETPLACE_PERSONALDEVELOPMENT:
    'creator_marketplace_PersonalDevelopment',
  CREATOR_MARKETPLACE_PET: 'creator_marketplace_pet',
  CREATOR_MARKETPLACE_PETS: 'creator_marketplace_Pets',
  CREATOR_MARKETPLACE_PH: 'creator_marketplace_PH',
  CREATOR_MARKETPLACE_PHOTOGRAPHY: 'creator_marketplace_Photography',
  CREATOR_MARKETPLACE_POP_CULTURE: 'creator_marketplace_pop_culture',
  CREATOR_MARKETPLACE_POP_ENTERTAINMENT:
    'creator_marketplace_Pop_Entertainment',
  CREATOR_MARKETPLACE_PRODUCT_PLURAL: 'creator_marketplace_product_plural',
  CREATOR_MARKETPLACE_PRODUCT_SINGLE: 'creator_marketplace_product_single',
  CREATOR_MARKETPLACE_PROFESSIONAL_SPECIALEFFECTS:
    'creator_marketplace_Professional_SpecialEffects',
  CREATOR_MARKETPLACE_RANDOM_SHOOT: 'creator_marketplace_random_shoot',
  CREATOR_MARKETPLACE_RELATIONSHIP: 'creator_marketplace_relationship',
  CREATOR_MARKETPLACE_RELATIONSHIPS: 'creator_marketplace_Relationships',
  CREATOR_MARKETPLACE_RESET_BTN: 'creator_marketplace_reset_btn',
  CREATOR_MARKETPLACE_ROMANCE: 'creator_marketplace_Romance',
  CREATOR_MARKETPLACE_SCENERY_PLANTS: 'creator_marketplace_Scenery_Plants',
  CREATOR_MARKETPLACE_SCHOOL_EDUCATION: 'creator_marketplace_School_Education',
  CREATOR_MARKETPLACE_SCIENCE: 'creator_marketplace_Science',
  CREATOR_MARKETPLACE_SCRIPTED_DRAMA: 'creator_marketplace_Scripted_Drama',
  CREATOR_MARKETPLACE_SELFIE: 'creator_marketplace_Selfie',
  CREATOR_MARKETPLACE_SELLER_OFFLINE_NOTIFY_BODY:
    'creator_marketplace_seller_offline_notify_body',
  CREATOR_MARKETPLACE_SELLER_OFFLINE_NOTIFY_TITLE:
    'creator_marketplace_seller_offline_notify_title',
  CREATOR_MARKETPLACE_SINGING_DANCING: 'creator_marketplace_Singing_Dancing',
  CREATOR_MARKETPLACE_SINGING_INSTRUMENTS:
    'creator_marketplace_Singing_Instruments',
  CREATOR_MARKETPLACE_SOCIETY: 'creator_marketplace_Society',
  CREATOR_MARKETPLACE_SOFTWARE_APPS: 'creator_marketplace_Software_APPs',
  CREATOR_MARKETPLACE_SPORT: 'creator_marketplace_sport',
  CREATOR_MARKETPLACE_SPORTS: 'creator_marketplace_Sports',
  CREATOR_MARKETPLACE_SPORT_NEWS: 'creator_marketplace_Sport_News',
  CREATOR_MARKETPLACE_SUPERNATURAL_HORROR:
    'creator_marketplace_Supernatural_Horror',
  CREATOR_MARKETPLACE_TALENT: 'creator_marketplace_talent',
  CREATOR_MARKETPLACE_TECHNOLOGY: 'creator_marketplace_technology',
  CREATOR_MARKETPLACE_TECHNOLOGY_EDUCATION:
    'creator_marketplace_technology_education',
  CREATOR_MARKETPLACE_TECHNOLOGY_PHOTOGRAPHY:
    'creator_marketplace_Technology_Photography',
  CREATOR_MARKETPLACE_TECHPRODUCTS_INFOS:
    'creator_marketplace_TechProducts_Infos',
  CREATOR_MARKETPLACE_TH: 'creator_marketplace_TH',
  CREATOR_MARKETPLACE_TOYS_COLLECTIONS: 'creator_marketplace_Toys_Collections',
  CREATOR_MARKETPLACE_TRADITIONAL_SPORT:
    'creator_marketplace_traditional_sport',
  CREATOR_MARKETPLACE_TRADITIONAL_SPORTS:
    'creator_marketplace_Traditional_Sports',
  CREATOR_MARKETPLACE_TRANSITION: 'creator_marketplace_transition',
  CREATOR_MARKETPLACE_TRANSPORTATION_VEHICLE:
    'creator_marketplace_transportation_vehicle',
  CREATOR_MARKETPLACE_TRAVEL: 'creator_marketplace_Travel',
  CREATOR_MARKETPLACE_TUTORIAL: 'creator_marketplace_tutorial',
  CREATOR_MARKETPLACE_VIDEO: 'creator_marketplace_video',
  CREATOR_MARKETPLACE_VIDEO_GAMES: 'creator_marketplace_video_games',
  CREATOR_MARKETPLACE_VN: 'creator_marketplace_VN',
  CREATOR_MARKETPLACE_VOICE_ACTOR: 'creator_marketplace_voice_actor',
  CREATOR_MARKETPLACE_VTUBER: 'creator_marketplace_vtuber',
  CREATOR_MARKETPLACE_WORK_JOBS: 'creator_marketplace_Work_Jobs',
  CREATOR_SCREEN_LIVE_BASE_INFOON_GOING:
    'creator_screen_live_base_infoon_going',
  CREATOR_SCREEN_LIVE_BASE_INFO_DURATION:
    'creator_screen_live_base_info_duration',
  CREATOR_SCREEN_LIVE_BASE_INFO_END: 'creator_screen_live_base_info_end',
  CREATOR_SCREEN_LIVE_BASE_INFO_START_TIME:
    'creator_screen_live_base_info_start_time',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_COMMENTS_BUTTON:
    'creator_screen_live_broadcast_live_comments_button',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_COMMENTS_BUTTON_TOOLTIP:
    'creator_screen_live_broadcast_live_comments_button_tooltip',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_DOWNLOAD_AUTHORIZED_BUTTON:
    'creator_screen_live_broadcast_live_download_authorized_button',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_DOWNLOAD_BUTTON:
    'creator_screen_live_broadcast_live_download_button',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_DOWNLOAD_CANCEL_BUTTON:
    'creator_screen_live_broadcast_live_download_cancel_button',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_DOWNLOAD_CONFIRMATION:
    'creator_screen_live_broadcast_live_download_confirmation',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_DOWNLOAD_CONFIRMATION_CONTENT:
    'creator_screen_live_broadcast_live_download_confirmation_content',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_DOWNLOAD_FINISHED:
    'creator_screen_live_broadcast_live_download_finished',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_DOWNLOAD_PROCESSING:
    'creator_screen_live_broadcast_live_download_processing',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_MSG1:
    'creator_screen_live_broadcast_live_msg1',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_MSG2:
    'creator_screen_live_broadcast_live_msg2',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_MSG3:
    'creator_screen_live_broadcast_live_msg3',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_MSG4:
    'creator_screen_live_broadcast_live_msg4',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_STREAM_BUTTON:
    'creator_screen_live_broadcast_live_stream_button',
  CREATOR_SCREEN_LIVE_BROADCAST_LIVE_STREAM_BUTTON_TOOLTIP:
    'creator_screen_live_broadcast_live_stream_button_tooltip',
  CREATOR_SCREEN_LIVE_BROADCAST_TITLE_BROADCAST:
    'creator_screen_live_broadcast_title_broadcast',
  CREATOR_SCREEN_LIVE_BROADCAST_TITLE_COMMENTS:
    'creator_screen_live_broadcast_title_comments',
  CREATOR_SCREEN_LIVE_BROADCAST_TITLE_REPLAY:
    'creator_screen_live_broadcast_title_replay',
  CREATOR_SCREEN_LIVE_CORE_DATA_AVG_DURATION:
    'creator_screen_live_core_data_avg_duration',
  CREATOR_SCREEN_LIVE_CORE_DATA_AVG_DURATION_HOVER:
    'creator_screen_live_core_data_avg_duration_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_BUYERS: 'creator_screen_live_core_data_buyers',
  CREATOR_SCREEN_LIVE_CORE_DATA_BUYERS_HOVER:
    'creator_screen_live_core_data_buyers_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_CO: 'creator_screen_live_core_data_co',
  CREATOR_SCREEN_LIVE_CORE_DATA_COMMENT_PV:
    'creator_screen_live_core_data_comment_pv',
  CREATOR_SCREEN_LIVE_CORE_DATA_COMMENT_PV_HOVER:
    'creator_screen_live_core_data_comment_pv_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_CO_HOVER:
    'creator_screen_live_core_data_co_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_CREATED_ORDERS:
    'creator_screen_live_core_data_created_orders',
  CREATOR_SCREEN_LIVE_CORE_DATA_CREATED_ORDERS_HOVER:
    'creator_screen_live_core_data_created_orders_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_CTR: 'creator_screen_live_core_data_ctr',
  CREATOR_SCREEN_LIVE_CORE_DATA_CTR_HOVER:
    'creator_screen_live_core_data_ctr_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_CUMULATIVE_VIEWERS:
    'creator_screen_live_core_data_cumulative_viewers',
  CREATOR_SCREEN_LIVE_CORE_DATA_CUMULATIVE_VIEWERS_HOVER:
    'creator_screen_live_core_data_cumulative_viewers_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_GMV: 'creator_screen_live_core_data_gmv',
  CREATOR_SCREEN_LIVE_CORE_DATA_GPM: 'creator_screen_live_core_data_gpm',
  CREATOR_SCREEN_LIVE_CORE_DATA_GPM_HOVER:
    'creator_screen_live_core_data_gpm_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_NEW_FOLLOWERS:
    'creator_screen_live_core_data_new_followers',
  CREATOR_SCREEN_LIVE_CORE_DATA_NEW_FOLLOWERS_HOVER:
    'creator_screen_live_core_data_new_followers_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_PAID_ORDERS:
    'creator_screen_live_core_data_paid_orders',
  CREATOR_SCREEN_LIVE_CORE_DATA_PAID_ORDERS_HOVER:
    'creator_screen_live_core_data_paid_orders_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_PAYMENT_RATE:
    'creator_screen_live_core_data_payment_rate',
  CREATOR_SCREEN_LIVE_CORE_DATA_PAYMENT_RATE_HOVER:
    'creator_screen_live_core_data_payment_rate_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_PCU: 'creator_screen_live_core_data_pcu',
  CREATOR_SCREEN_LIVE_CORE_DATA_PCU_HOVER:
    'creator_screen_live_core_data_pcu_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_PRODUCT_REACH:
    'creator_screen_live_core_data_product_reach',
  CREATOR_SCREEN_LIVE_CORE_DATA_PRODUCT_REACH_HOVER:
    'creator_screen_live_core_data_product_reach_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_PRODUCT_VIEW:
    'creator_screen_live_core_data_product_view',
  CREATOR_SCREEN_LIVE_CORE_DATA_PRODUCT_VIEW_HOVER:
    'creator_screen_live_core_data_product_view_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_SALES: 'creator_screen_live_core_data_sales',
  CREATOR_SCREEN_LIVE_CORE_DATA_SALES_HOVER:
    'creator_screen_live_core_data_sales_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_SET_INDICATOR_BUTTON:
    'creator_screen_live_core_data_set_indicator_button',
  CREATOR_SCREEN_LIVE_CORE_DATA_SHARE_PV:
    'creator_screen_live_core_data_share_pv',
  CREATOR_SCREEN_LIVE_CORE_DATA_SHARE_PV_HOVER:
    'creator_screen_live_core_data_share_pv_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_UNIT_PRICE:
    'creator_screen_live_core_data_unit_price',
  CREATOR_SCREEN_LIVE_CORE_DATA_UNIT_PRICE_HOVER:
    'creator_screen_live_core_data_unit_price_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_VIEWERS:
    'creator_screen_live_core_data_viewers',
  CREATOR_SCREEN_LIVE_CORE_DATA_VIEWERS_HOVER:
    'creator_screen_live_core_data_viewers_hover',
  CREATOR_SCREEN_LIVE_CORE_DATA_WATCH_PV:
    'creator_screen_live_core_data_watch_pv',
  CREATOR_SCREEN_LIVE_CORE_DATA_WATCH_PV_HOVER:
    'creator_screen_live_core_data_watch_pv_hover',
  CREATOR_SCREEN_LIVE_ERROR: 'creator_screen_live_error',
  CREATOR_SCREEN_LIVE_ERROR_BUTTON: 'creator_screen_live_error_button',
  CREATOR_SCREEN_LIVE_ERROR_BUTTON_LIVE_LIST:
    'creator_screen_live_error_button_live_list',
  CREATOR_SCREEN_LIVE_ERROR_BUTTON_RELOAD:
    'creator_screen_live_error_button_reload',
  CREATOR_SCREEN_LIVE_ERROR_MSG1: 'creator_screen_live_error_msg1',
  CREATOR_SCREEN_LIVE_ERROR_MSG2: 'creator_screen_live_error_msg2',
  CREATOR_SCREEN_LIVE_ERROR_MSG3: 'creator_screen_live_error_msg3',
  CREATOR_SCREEN_LIVE_ERROR_MSG4: 'creator_screen_live_error_msg4',
  CREATOR_SCREEN_LIVE_ERROR_MSG5: 'creator_screen_live_error_msg5',
  CREATOR_SCREEN_LIVE_GMV_TREND_NO_DATA:
    'creator_screen_live_gmv_trend_no_data',
  CREATOR_SCREEN_LIVE_GMV_TREND_REVENUE_METRIC:
    'creator_screen_live_gmv_trend_revenue_metric',
  CREATOR_SCREEN_LIVE_GMV_TREND_TITLE: 'creator_screen_live_gmv_trend_title',
  CREATOR_SCREEN_LIVE_INTERACTIVE_TREND_COMMENT_PV:
    'creator_screen_live_interactive_trend_comment_pv',
  CREATOR_SCREEN_LIVE_INTERACTIVE_TREND_NO_DATA:
    'creator_screen_live_interactive_trend_no_data',
  CREATOR_SCREEN_LIVE_INTERACTIVE_TREND_SHARE_PV:
    'creator_screen_live_interactive_trend_share_pv',
  CREATOR_SCREEN_LIVE_INTERACTIVE_TREND_TITLE:
    'creator_screen_live_interactive_trend_title',
  CREATOR_SCREEN_LIVE_INTERACTIVE_TREND_WATCH_PV:
    'creator_screen_live_interactive_trend_watch_pv',
  CREATOR_SCREEN_LIVE_NO_PERMISSION: 'creator_screen_live_no_permission',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_CHANNEL_BANK_ALFA:
    'creator_screen_live_payment_rate_channel_bank_alfa',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_CHANNEL_BANK_CARD:
    'creator_screen_live_payment_rate_channel_bank_card',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_CHANNEL_BANK_COD:
    'creator_screen_live_payment_rate_channel_bank_cod',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_CHANNEL_BANK_DAN:
    'creator_screen_live_payment_rate_channel_bank_dan',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_CHANNEL_BANK_GO_PAY:
    'creator_screen_live_payment_rate_channel_bank_go_pay',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_CHANNEL_BANK_INDOMARET:
    'creator_screen_live_payment_rate_channel_bank_indomaret',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_CHANNEL_BANK_OVO:
    'creator_screen_live_payment_rate_channel_bank_ovo',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_CHANNEL_BANK_TRANSFER:
    'creator_screen_live_payment_rate_channel_bank_transfer',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_CREATED_ORDERS:
    'creator_screen_live_payment_rate_created_orders',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_NO_DATA:
    'creator_screen_live_payment_rate_no_data',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_PAID_ORDERS:
    'creator_screen_live_payment_rate_paid_orders',
  CREATOR_SCREEN_LIVE_PAYMENT_RATE_TREND_TITLE:
    'creator_screen_live_payment_rate_trend_title',
  CREATOR_SCREEN_LIVE_POPULARITY_TREND_ENTER_PEOPLE:
    'creator_screen_live_popularity_trend_enter_people',
  CREATOR_SCREEN_LIVE_POPULARITY_TREND_LEAVE_PEOPLE:
    'creator_screen_live_popularity_trend_leave_people',
  CREATOR_SCREEN_LIVE_POPULARITY_TREND_NO_DATA:
    'creator_screen_live_popularity_trend_no_data',
  CREATOR_SCREEN_LIVE_POPULARITY_TREND_ONLINE_PEOPLE:
    'creator_screen_live_popularity_trend_online_people',
  CREATOR_SCREEN_LIVE_POPULARITY_TREND_TITLE:
    'creator_screen_live_popularity_trend_title',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CLICK_PV:
    'creator_screen_live_product_list_click_pv',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CLICK_PV_HOVER:
    'creator_screen_live_product_list_click_pv_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CO: 'creator_screen_live_product_list_co',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CO_HOVER:
    'creator_screen_live_product_list_co_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CREATED_ORDERS:
    'creator_screen_live_product_list_created_orders',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CREATED_ORDERS_HOVER:
    'creator_screen_live_product_list_created_orders_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CREATED_USERS:
    'creator_screen_live_product_list_created_users',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CREATED_USERS_HOVER:
    'creator_screen_live_product_list_created_users_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CTR: 'creator_screen_live_product_list_ctr',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_CTR_HOVER:
    'creator_screen_live_product_list_ctr_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_GMV: 'creator_screen_live_product_list_gmv',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_GMV_HOVER:
    'creator_screen_live_product_list_gmv_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_GPM: 'creator_screen_live_product_list_gpm',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_GPM_HOVER:
    'creator_screen_live_product_list_gpm_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_INVENTORY_SURPLUS:
    'creator_screen_live_product_list_inventory_surplus',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_INVENTORY_SURPLUS_HOVER:
    'creator_screen_live_product_list_inventory_surplus_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_NO_DATA:
    'creator_screen_live_product_list_no_data',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_PAID_ORDERS:
    'creator_screen_live_product_list_paid_orders',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_PAID_ORDERS_HOVER:
    'creator_screen_live_product_list_paid_orders_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_PAID_USERS:
    'creator_screen_live_product_list_paid_users',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_PAID_USERS_HOVER:
    'creator_screen_live_product_list_paid_users_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_PAYMENT_RATE:
    'creator_screen_live_product_list_payment_rate',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_PAYMENT_RATE_HOVER:
    'creator_screen_live_product_list_payment_rate_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_PRODUCT:
    'creator_screen_live_product_list_product',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_PRODUCT_VIEW:
    'creator_screen_live_product_list_product_view',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_PRODUCT_VIEW_HOVER:
    'creator_screen_live_product_list_product_view_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_SALES:
    'creator_screen_live_product_list_sales',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_SALES_HOVER:
    'creator_screen_live_product_list_sales_hover',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_SEARCH_PLACEHOLDER:
    'creator_screen_live_product_list_search_placeholder',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_SET_INDICATOR_BUTTON:
    'creator_screen_live_product_list_set_indicator_button',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_SORT:
    'creator_screen_live_product_list_sort',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_TIME_1_MIN:
    'creator_screen_live_product_list_time_1_min',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_TIME_5_MIN:
    'creator_screen_live_product_list_time_5_min',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_TIME_TOTAL:
    'creator_screen_live_product_list_time_total',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_TITLE:
    'creator_screen_live_product_list_title',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_UNIT_PRICE:
    'creator_screen_live_product_list_unit_price',
  CREATOR_SCREEN_LIVE_PRODUCT_LIST_UNIT_PRICE_HOVER:
    'creator_screen_live_product_list_unit_price_hover',
  CREATOR_SCREEN_LIVE_SET_INDICATOR_CANCEL:
    'creator_screen_live_set_indicator_cancel',
  CREATOR_SCREEN_LIVE_SET_INDICATOR_DESCRIPTION:
    'creator_screen_live_set_indicator_description',
  CREATOR_SCREEN_LIVE_SET_INDICATOR_OK: 'creator_screen_live_set_indicator_ok',
  CREATOR_SCREEN_LIVE_SET_INDICATOR_TITLE:
    'creator_screen_live_set_indicator_title',
  CREATOR_SCREEN_LIVE_SWITCH_ROOM_BUTTON:
    'creator_screen_live_switch_room_button',
  CREATOR_SCREEN_LIVE_TITLE: 'creator_screen_live_title',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE: 'creator_screen_live_traffic_source',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_CARD_CLICK:
    'creator_screen_live_traffic_source_channel_card_click',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_CARD_DRAW:
    'creator_screen_live_traffic_source_channel_card_draw',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_FEED:
    'creator_screen_live_traffic_source_channel_feed',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_HEAD_CLICK:
    'creator_screen_live_traffic_source_channel_head_click',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_HEAD_DRAW:
    'creator_screen_live_traffic_source_channel_head_draw',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_HOMEPAGE_FOLLOW:
    'creator_screen_live_traffic_source_channel_homepage_follow',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_HOMEPAGE_HOT:
    'creator_screen_live_traffic_source_channel_homepage_hot',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_INNER_PUSH:
    'creator_screen_live_traffic_source_channel_inner_push',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_LIVE:
    'creator_screen_live_traffic_source_channel_live',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_MESSAGE:
    'creator_screen_live_traffic_source_channel_message',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_OTHERS:
    'creator_screen_live_traffic_source_channel_others',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_OTHER_HOMEPAGE:
    'creator_screen_live_traffic_source_channel_other_homepage',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_CHANNEL_PUSH:
    'creator_screen_live_traffic_source_channel_push',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_HOVER:
    'creator_screen_live_traffic_source_hover',
  CREATOR_SCREEN_LIVE_TRAFFIC_SOURCE_NO_DATA:
    'creator_screen_live_traffic_source_no_data',
  CREATOR_SCREEN_LIVE_USER_PROFILE_AGE_PROPORTION_NO_DATA:
    'creator_screen_live_user_profile_age_proportion_no_data',
  CREATOR_SCREEN_LIVE_USER_PROFILE_AGE_PROPORTION_TITLE:
    'creator_screen_live_user_profile_age_proportion_title',
  CREATOR_SCREEN_LIVE_USER_PROFILE_AGE_TITLE_A_MODEL:
    'creator_screen_live_user_profile_age_title_a_model',
  CREATOR_SCREEN_LIVE_USER_PROFILE_COUNTRIES_NO_DATA:
    'creator_screen_live_user_profile_countries_no_data',
  CREATOR_SCREEN_LIVE_USER_PROFILE_COUNTRIES_TITLE:
    'creator_screen_live_user_profile_countries_title',
  CREATOR_SCREEN_LIVE_USER_PROFILE_COUNTRIES_TOP5:
    'creator_screen_live_user_profile_countries_top5',
  CREATOR_SCREEN_LIVE_USER_PROFILE_FANS:
    'creator_screen_live_user_profile_fans',
  CREATOR_SCREEN_LIVE_USER_PROFILE_FANS_NO_DATA:
    'creator_screen_live_user_profile_fans_no_data',
  CREATOR_SCREEN_LIVE_USER_PROFILE_FANS_TITLE:
    'creator_screen_live_user_profile_fans_title',
  CREATOR_SCREEN_LIVE_USER_PROFILE_GENDER_FEMALE:
    'creator_screen_live_user_profile_gender_female',
  CREATOR_SCREEN_LIVE_USER_PROFILE_GENDER_MALE:
    'creator_screen_live_user_profile_gender_male',
  CREATOR_SCREEN_LIVE_USER_PROFILE_GENDER_NO_DATA:
    'creator_screen_live_user_profile_gender_no_data',
  CREATOR_SCREEN_LIVE_USER_PROFILE_GENDER_TITLE:
    'creator_screen_live_user_profile_gender_title',
  CREATOR_SCREEN_LIVE_USER_PROFILE_GENDER_TITLE_A_MODEL:
    'creator_screen_live_user_profile_gender_title_a_model',
  CREATOR_SCREEN_LIVE_USER_PROFILE_NON_FANS:
    'creator_screen_live_user_profile_non_fans',
  CREATOR_SCREEN_LIVE_USER_PROFILE_TITLE_PAID:
    'creator_screen_live_user_profile_title_paid',
  CREATOR_SCREEN_LIVE_USER_PROFILE_TITLE_WATCH:
    'creator_screen_live_user_profile_title_watch',
  DATA_DATAOVERVIEW_DATA_COMPARE1: 'data_dataoverview_data_compare1',
  DATA_DATAOVERVIEW_DATA_COMPARE2: 'data_dataoverview_data_compare2',
  DATA_DATAOVERVIEW_DATA_COMPARE3: 'data_dataoverview_data_compare3',
  DATA_DATAOVERVIEW_DATA_METRIC_COMMISSION_BUBBLE_OFFLINE:
    'data_dataoverview_data_metric_commission_bubble_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_COMMISSION_OFFLINE:
    'data_dataoverview_data_metric_commission_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_COMPARE:
    'data_dataoverview_data_metric_compare',
  DATA_DATAOVERVIEW_DATA_METRIC_CUSTOMERS_BUBBLE_LIVE:
    'data_dataoverview_data_metric_customers_bubble_live',
  DATA_DATAOVERVIEW_DATA_METRIC_CUSTOMERS_LIVE:
    'data_dataoverview_data_metric_customers_live',
  DATA_DATAOVERVIEW_DATA_METRIC_CUSTOMERS_OFFLINE:
    'data_dataoverview_data_metric_customers_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_ORDERS_BUBBLE_LIVE:
    'data_dataoverview_data_metric_orders_bubble_live',
  DATA_DATAOVERVIEW_DATA_METRIC_ORDERS_LIVE:
    'data_dataoverview_data_metric_orders_live',
  DATA_DATAOVERVIEW_DATA_METRIC_ORDERS_OFFLINE:
    'data_dataoverview_data_metric_orders_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_PRODUCTS_BUBBLE_LIVE:
    'data_dataoverview_data_metric_products_bubble_live',
  DATA_DATAOVERVIEW_DATA_METRIC_PRODUCTS_LIVE:
    'data_dataoverview_data_metric_products_live',
  DATA_DATAOVERVIEW_DATA_METRIC_PRODUCTS_OFFLINE:
    'data_dataoverview_data_metric_products_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_PRODUCT_REACH_BUBBLE_LIVE:
    'data_dataoverview_data_metric_product_reach_bubble_live',
  DATA_DATAOVERVIEW_DATA_METRIC_PRODUCT_REACH_LIVE:
    'data_dataoverview_data_metric_product_reach_live',
  DATA_DATAOVERVIEW_DATA_METRIC_PRODUCT_REACH_OFFLINE:
    'data_dataoverview_data_metric_product_reach_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_PRODUCT_VIEWS_BUBBLE_LIVE:
    'data_dataoverview_data_metric_product_views_bubble_live',
  DATA_DATAOVERVIEW_DATA_METRIC_PRODUCT_VIEWS_LIVE:
    'data_dataoverview_data_metric_product_views_live',
  DATA_DATAOVERVIEW_DATA_METRIC_PRODUCT_VIEWS_OFFLINE:
    'data_dataoverview_data_metric_product_views_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_REFUND_BUBBLE_OFFLINE:
    'data_dataoverview_data_metric_refund_bubble_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_REFUND_OFFLINE:
    'data_dataoverview_data_metric_refund_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_REVENUE_BUBBLE_LIVE:
    'data_dataoverview_data_metric_revenue_bubble_live',
  DATA_DATAOVERVIEW_DATA_METRIC_REVENUE_LIVE:
    'data_dataoverview_data_metric_revenue_live',
  DATA_DATAOVERVIEW_DATA_METRIC_REVENUE_OFFLINE:
    'data_dataoverview_data_metric_revenue_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_ROI_BUBBLE_OFFLINE:
    'data_dataoverview_data_metric_roi_bubble_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_ROI_OFFLINE:
    'data_dataoverview_data_metric_roi_offline',
  DATA_DATAOVERVIEW_DATA_METRIC_SALES_BUBBLE_LIVE:
    'data_dataoverview_data_metric_sales_bubble_live',
  DATA_DATAOVERVIEW_DATA_METRIC_SALES_LIVE:
    'data_dataoverview_data_metric_sales_live',
  DATA_DATAOVERVIEW_DATA_METRIC_SALES_OFFLINE:
    'data_dataoverview_data_metric_sales_offline',
  DATA_DATAOVERVIEW_DATA_PERIOD_FILTER1:
    'data_dataoverview_data_period_filter1',
  DATA_DATAOVERVIEW_DATA_PERIOD_FILTER2:
    'data_dataoverview_data_period_filter2',
  DATA_DATAOVERVIEW_DATA_PERIOD_FILTER3:
    'data_dataoverview_data_period_filter3',
  DATA_DATAOVERVIEW_DATA_TITLE1: 'data_dataoverview_data_title1',
  DATA_DATAOVERVIEW_DATA_TITLE2: 'data_dataoverview_data_title2',
  DATA_DATAOVERVIEW_DATA_TITLE: 'data_dataoverview_data_title',
  DATA_DATAOVERVIEW_DATA_UPDATE_TIME: 'data_dataoverview_data_update_time',
  DATA_DATAOVERVIEW_SELECT_TREND_METRIC_INDEX:
    'data_dataoverview_select_trend_metric_index',
  EARNING_LIST_EXPORTED_REPORT_LIST_TITLE:
    'earning_list_exported_report_list_title',
  EARNING_LIST_EXPORT_FAIL: 'earning_list_export_fail',
  EARNING_LIST_EXPORT_HISTORY: 'earning_list_export_history',
  EARNING_LIST_EXPORT_HISTORY_LIST_ACTION:
    'earning_list_export_history_list_action',
  EARNING_LIST_EXPORT_HISTORY_LIST_ACTION_DOWNLOAD_BTN:
    'earning_list_export_history_list_action_download_btn',
  EARNING_LIST_EXPORT_HISTORY_LIST_REPORT_NAME:
    'earning_list_export_history_list_report_name',
  EARNING_LIST_EXPORT_HISTORY_LIST_STATUS_EXPORTED:
    'earning_list_export_history_list_status_exported',
  EARNING_LIST_EXPORT_HISTORY_LIST_STATUS_EXPORTING:
    'earning_list_export_history_list_status_exporting',
  EARNING_LIST_EXPORT_HISTORY_LIST_TIPS:
    'earning_list_export_history_list_tips',
  EARNING_LIST_EXPORT_SELECT_DATE_RANGE_EXPORT_BTN:
    'earning_list_export_select_date_range_export_btn',
  EARNING_LIST_EXPORT_SELECT_DATE_RANGE_TIPS:
    'earning_list_export_select_date_range_tips',
  EARNING_LIST_EXPORT_SELECT_DATE_RANGE_TITLE:
    'earning_list_export_select_date_range_title',
  EARTH_HOVER_COUNTRIES: 'Earth_Hover_Countries',
  EARTH_HOVER_COUNTRY: 'Earth_Hover_Country',
  EARTH_HOVER_SHOPIFY: 'Earth_Hover_Shopify',
  EARTH_HOVER_SHOPIFY_AND_COUNTRIES: 'Earth_Hover_Shopify_And_Countries',
  ECOM_ACCOUNT_STANDING_PORTAL: 'ecom_account_standing_portal',
  ECOM_ADDPRODUCT_CREATOR_STOCK_TIP_ADDED_TITLE:
    'ecom_addproduct_creator_stock_tip_added_title',
  ECOM_ADDPRODUCT_CREATOR_STOCK_TIP_BEFORE_ADD_TITLE:
    'ecom_addproduct_creator_stock_tip_before_add_title',
  ECOM_ADDPRODUCT_CREATOR_STOCK_TIP_BEFORE_ADD_TITLE_PC:
    'ecom_addproduct_creator_stock_tip_before_add_title_pc',
  ECOM_ADDPRODUCT_CREATOR_STOCK_TIP_PERIOD_TEXT:
    'ecom_addproduct_creator_stock_tip_period_text',
  ECOM_ADDPRODUCT_CREATOR_STOCK_TIP_STATUS_AVAILABLE:
    'ecom_addproduct_creator_stock_tip_status_available',
  ECOM_ADDPRODUCT_CREATOR_STOCK_TIP_STATUS_EXPIRING:
    'ecom_addproduct_creator_stock_tip_status_expiring',
  ECOM_ADDPRODUCT_CREATOR_STOCK_TIP_STATUS_EXPIRING_PC:
    'ecom_addproduct_creator_stock_tip_status_expiring_pc',
  ECOM_ADDPRODUCT_CREATOR_STOCK_TIP_STATUS_UPCOMING:
    'ecom_addproduct_creator_stock_tip_status_upcoming',
  ECOM_ADDPRODUCT_PRODUCT_CARD_ADDED_BUTTON:
    'ecom_addproduct_product_card_added_button',
  ECOM_AFFILIATE_CREATOR_MARKETPLACE_ACCOUNT_TOOLTIPS:
    'ecom_affiliate_creator_marketplace_account_tooltips',
  ECOM_AFFILIATE_FREE_SAMPLE_STATUS_MANAGEMENT_CREATOR_WITHDRAW:
    'ecom_affiliate_free_sample_status_management_creator_withdraw',
  ECOM_AFFILIATE_FREE_SAMPLE_STATUS_MANAGEMENT_NOT_SHIP_CANCELLED:
    'ecom_affiliate_free_sample_status_management_not_ship_cancelled',
  ECOM_COMMISSION_BOUNUS_BANNER: 'ecom_commission_bounus_banner',
  ECOM_COMMISSION_BOUNUS_BANNER_TAG: 'ecom_commission_bounus_banner_tag',
  ECOM_COMMISSION_BOUNUS_COMMISSION_DETAIL_DIALOG_TITLE:
    'ecom_commission_bounus_commission_detail_dialog_title',
  ECOM_COMMISSION_BOUNUS_DIALOG_CONTENT:
    'ecom_commission_bounus_dialog_content',
  ECOM_COMMISSION_BOUNUS_DIALOG_CTA: 'ecom_commission_bounus_dialog_cta',
  ECOM_COMMISSION_BOUNUS_DIALOG_TITLE: 'ecom_commission_bounus_dialog_title',
  ECOM_COMMISSION_BOUNUS_TAG: 'ecom_commission_bounus_tag',
  ECOM_COMMISSION_BOUNUS_TOOLTIP: 'ecom_commission_bounus_tooltip',
  ECOM_CREATOR_CONTACT_INFO_ADD_SUCCESSFULLY:
    'ecom_creator_contact_info_add_successfully',
  ECOM_CREATOR_CONTACT_INFO_ADD_UNSUCCESSFULLY:
    'ecom_creator_contact_info_add_unsuccessfully',
  ECOM_CREATOR_CONTACT_INFO_FB: 'ecom_creator_contact_info_fb',
  ECOM_CREATOR_CONTACT_INFO_FB_ACCOUNT: 'ecom_creator_contact_info_fb_account',
  ECOM_CREATOR_CONTACT_INFO_FB_ACCOUNT_DESCRIPTION:
    'ecom_creator_contact_info_fb_account_description',
  ECOM_CREATOR_CONTACT_INFO_FB_DETAIL: 'ecom_creator_contact_info_fb_detail',
  ECOM_CREATOR_CONTACT_INFO_FB_ERROR: 'ecom_creator_contact_info_fb_error',
  ECOM_CREATOR_CONTACT_INFO_FB_TITLE: 'ecom_creator_contact_info_fb_title',
  ECOM_CREATOR_CONTACT_INFO_FB_TITLE_DESCRIPTION:
    'ecom_creator_contact_info_fb_title_description',
  ECOM_CREATOR_CONTACT_INFO_FB_TITLE_EDIT:
    'ecom_creator_contact_info_fb_title_edit',
  ECOM_CREATOR_CONTACT_INFO_GUIDE: 'ecom_creator_contact_info_guide',
  ECOM_CREATOR_CONTACT_INFO_LINE: 'ecom_creator_contact_info_line',
  ECOM_CREATOR_CONTACT_INFO_LINE_ACCOUNT:
    'ecom_creator_contact_info_line_account',
  ECOM_CREATOR_CONTACT_INFO_LINE_ADD_DETAIL:
    'ecom_creator_contact_info_line_add_detail',
  ECOM_CREATOR_CONTACT_INFO_LINE_ADD_PLACEHOLDER:
    'ecom_creator_contact_info_line_add_placeholder',
  ECOM_CREATOR_CONTACT_INFO_LINE_ADD_TITLE:
    'ecom_creator_contact_info_line_add_title',
  ECOM_CREATOR_CONTACT_INFO_LINE_ERROR: 'ecom_creator_contact_info_line_error',
  ECOM_CREATOR_CONTACT_INFO_LINE_UPDATE_TITLE:
    'ecom_creator_contact_info_line_update_title',
  ECOM_CREATOR_CONTACT_INFO_VIBER: 'ecom_creator_contact_info_viber',
  ECOM_CREATOR_CONTACT_INFO_VIBER_ACCOUNT:
    'ecom_creator_contact_info_viber_account',
  ECOM_CREATOR_CONTACT_INFO_VIBER_ACCOUNT_DESCRIPTION:
    'ecom_creator_contact_info_viber_account_description',
  ECOM_CREATOR_CONTACT_INFO_VIBER_DESCRIPTION:
    'ecom_creator_contact_info_viber_description',
  ECOM_CREATOR_CONTACT_INFO_VIBER_DETAIL:
    'ecom_creator_contact_info_viber_detail',
  ECOM_CREATOR_CONTACT_INFO_VIBER_ERROR:
    'ecom_creator_contact_info_viber_error',
  ECOM_CREATOR_CONTACT_INFO_VIBER_TITLE:
    'ecom_creator_contact_info_viber_title',
  ECOM_CREATOR_CONTACT_INFO_VIBER_TITLE_EDIT:
    'ecom_creator_contact_info_viber_title_edit',
  ECOM_CREATOR_CONTACT_INFO_ZALO: 'ecom_creator_contact_info_zalo',
  ECOM_CREATOR_CONTACT_INFO_ZALO_ACCOUNT:
    'ecom_creator_contact_info_zalo_account',
  ECOM_CREATOR_CONTACT_INFO_ZALO_ADD_DETAIL:
    'ecom_creator_contact_info_zalo_add_detail',
  ECOM_CREATOR_CONTACT_INFO_ZALO_ADD_PLACEHOLDER:
    'ecom_creator_contact_info_zalo_add_placeholder',
  ECOM_CREATOR_CONTACT_INFO_ZALO_ADD_TITLE:
    'ecom_creator_contact_info_zalo_add_title',
  ECOM_CREATOR_CONTACT_INFO_ZALO_ERROR: 'ecom_creator_contact_info_zalo_error',
  ECOM_CREATOR_CONTACT_INFO_ZALO_UPDATE_TITLE:
    'ecom_creator_contact_info_zalo_update_title',
  ECOM_CREATOR_CREATOR_MARKETPLACE_CONTACT_INFO_LINE:
    'ecom_creator_creator_marketplace_contact_info_line',
  ECOM_CREATOR_CREATOR_MARKETPLACE_CONTACT_ZALO_LINE:
    'ecom_creator_creator_marketplace_contact_zalo_line',
  ECOM_CREATOR_DASHBOARD_HOME_BTN_DETAIL:
    'ecom_creator_dashboard_home_btn_detail',
  ECOM_CREATOR_DASHBOARD_HOME_INDICATOR_AFFILIATE_ORDERS:
    'ecom_creator_dashboard_home_indicator_affiliate_orders',
  ECOM_CREATOR_DASHBOARD_HOME_INDICATOR_AFFILIATE_REVENUE:
    'ecom_creator_dashboard_home_indicator_affiliate_revenue',
  ECOM_CREATOR_DASHBOARD_HOME_MSG8: 'ecom_creator_dashboard_home_msg8',
  ECOM_CREATOR_DASHBOARD_HOME_MSG8_AFFILIATE_REVENUE:
    'ecom_creator_dashboard_home_msg8_affiliate_revenue',
  ECOM_CREATOR_DASHBOARD_HOME_MSG9: 'ecom_creator_dashboard_home_msg9',
  ECOM_CREATOR_DASHBOARD_HOME_MSG9_AFFILIATE_ORDERS:
    'ecom_creator_dashboard_home_msg9_affiliate_orders',
  ECOM_CREATOR_DASHBOARD_HOME_TIME_CUSTOM:
    'ecom_creator_dashboard_home_time_custom',
  ECOM_CREATOR_DASHBOARD_HOME_TITLE_AFFILIATE:
    'ecom_creator_dashboard_home_title_affiliate',
  ECOM_CREATOR_DASHBOARD_HOME_TITLE_AFFILIATE_DESC:
    'ecom_creator_dashboard_home_title_affiliate_desc',
  ECOM_CREATOR_DASHBOARD_HOME_TITLE_OVERVIEW_DELAY:
    'ecom_creator_dashboard_home_title_overview_delay',
  ECOM_CREATOR_SELLER_CONTACT_INFO_LINE_PLACEHOLDER:
    'ecom_creator_seller_contact_info_line_placeholder',
  ECOM_CREATOR_SELLER_CONTACT_INFO_LINE_PLACEHOLDER_2:
    'ecom_creator_seller_contact_info_line_placeholder_2',
  ECOM_CREATOR_SELLER_CONTACT_INFO_ZALO_PLACEHOLDER:
    'ecom_creator_seller_contact_info_zalo_placeholder',
  ECOM_CREATOR_SELLER_CONTACT_INFO_ZALO_PLACEHOLDER_2:
    'ecom_creator_seller_contact_info_zalo_placeholder_2',
  ECOM_CREATOR_STOCK_DETAIL_AVAILABLE_TEXT:
    'ecom_creator_stock_detail_available_text',
  ECOM_CREATOR_STOCK_DETAIL_BATCH_TOOL_BUTTON:
    'ecom_creator_stock_detail_batch_tool_button',
  ECOM_CREATOR_STOCK_DETAIL_CHANGES_FAILED_EXPIRED_TOAST:
    'ecom_creator_stock_detail_changes_failed_expired_toast',
  ECOM_CREATOR_STOCK_DETAIL_CHANGES_FAILED_SOLD_OUT_TOAST:
    'ecom_creator_stock_detail_changes_failed_sold_out_toast',
  ECOM_CREATOR_STOCK_DETAIL_CHANGES_SAVED_TOAST:
    'ecom_creator_stock_detail_changes_saved_toast',
  ECOM_CREATOR_STOCK_DETAIL_CURRENT_INVITE_TITLE:
    'ecom_creator_stock_detail_current_invite_title',
  ECOM_CREATOR_STOCK_DETAIL_EDIT_EXCEED_LIMIT_TOOLTIP:
    'ecom_creator_stock_detail_edit_exceed_limit_tooltip',
  ECOM_CREATOR_STOCK_DETAIL_EDIT_TITLE: 'ecom_creator_stock_detail_edit_title',
  ECOM_CREATOR_STOCK_DETAIL_HEADER_DELETED_TEXT:
    'ecom_creator_stock_detail_header_deleted_text',
  ECOM_CREATOR_STOCK_DETAIL_HEADER_ON_SALE_ITEM_TEXT:
    'ecom_creator_stock_detail_header_on_sale_item_text',
  ECOM_CREATOR_STOCK_DETAIL_HEADER_ON_SALE_ITEM_TEXT_PC:
    'ecom_creator_stock_detail_header_on_sale_item_text_pc',
  ECOM_CREATOR_STOCK_DETAIL_HEADER_PERIOD_TEXT:
    'ecom_creator_stock_detail_header_period_text',
  ECOM_CREATOR_STOCK_DETAIL_HEADER_PERIOD_TEXT_PC:
    'ecom_creator_stock_detail_header_period_text_pc',
  ECOM_CREATOR_STOCK_DETAIL_HEADER_SOLD_OUT_TEXT:
    'ecom_creator_stock_detail_header_sold_out_text',
  ECOM_CREATOR_STOCK_DETAIL_HEADER_TOTAL_ITEM_TEXT:
    'ecom_creator_stock_detail_header_total_item_text',
  ECOM_CREATOR_STOCK_DETAIL_HEADER_TOTAL_ITEM_TEXT_PC:
    'ecom_creator_stock_detail_header_total_item_text_pc',
  ECOM_CREATOR_STOCK_DETAIL_INSUFFICIENT_STOCK_TOAST:
    'ecom_creator_stock_detail_insufficient_stock_toast',
  ECOM_CREATOR_STOCK_DETAIL_ITEMS_GIVEN_STOCK_TITLE:
    'ecom_creator_stock_detail_items_given_stock_title',
  ECOM_CREATOR_STOCK_DETAIL_ITEM_TEXT: 'ecom_creator_stock_detail_item_text',
  ECOM_CREATOR_STOCK_DETAIL_NO_REST_TEXT:
    'ecom_creator_stock_detail_no_rest_text',
  ECOM_CREATOR_STOCK_DETAIL_ON_SALE_ITEM_TEXT:
    'ecom_creator_stock_detail_on_sale_item_text',
  ECOM_CREATOR_STOCK_DETAIL_PAGE_TITLE: 'ecom_creator_stock_detail_page_title',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_ON_SALE_ITEM_CONTENT:
    'ecom_creator_stock_detail_popup_on_sale_item_content',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_ON_SALE_ITEM_TITLE:
    'ecom_creator_stock_detail_popup_on_sale_item_title',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_RETURN_CONTENT:
    'ecom_creator_stock_detail_popup_return_content',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_RETURN_CONTENT_PC:
    'ecom_creator_stock_detail_popup_return_content_pc',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_RETURN_LEARN_BUTTON:
    'ecom_creator_stock_detail_popup_return_learn_button',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_RETURN_TITLE:
    'ecom_creator_stock_detail_popup_return_title',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_UNSAVE_CONTENT:
    'ecom_creator_stock_detail_popup_unsave_content',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_UNSAVE_DISCARD_BUTTON:
    'ecom_creator_stock_detail_popup_unsave_discard_button',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_UNSAVE_LEAVE_BUTTON:
    'ecom_creator_stock_detail_popup_unsave_leave_button',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_UNSAVE_PC_CONTENT:
    'ecom_creator_stock_detail_popup_unsave_pc_content',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_UNSAVE_STAY_BUTTON:
    'ecom_creator_stock_detail_popup_unsave_stay_button',
  ECOM_CREATOR_STOCK_DETAIL_POPUP_UNSAVE_TITLE:
    'ecom_creator_stock_detail_popup_unsave_title',
  ECOM_CREATOR_STOCK_DETAIL_REMAINING_TEXT:
    'ecom_creator_stock_detail_remaining_text',
  ECOM_CREATOR_STOCK_DETAIL_REMAINING_TITLE:
    'ecom_creator_stock_detail_remaining_title',
  ECOM_CREATOR_STOCK_DETAIL_RETURNED_TOAST:
    'ecom_creator_stock_detail_returned_toast',
  ECOM_CREATOR_STOCK_DETAIL_RETURN_BUTTON:
    'ecom_creator_stock_detail_return_button',
  ECOM_CREATOR_STOCK_DETAIL_SAVE_CHANGES_BUTTON:
    'ecom_creator_stock_detail_save_changes_button',
  ECOM_CREATOR_STOCK_DETAIL_SOLD_TITLE: 'ecom_creator_stock_detail_sold_title',
  ECOM_CREATOR_STOCK_DETAIL_STOCK_ON_SALE_TITLE:
    'ecom_creator_stock_detail_stock_on_sale_title',
  ECOM_CREATOR_STOCK_DETAIL_STOCK_ON_SALE_TOAST:
    'ecom_creator_stock_detail_stock_on_sale_toast',
  ECOM_CREATOR_STOCK_DETAIL_TIP_STOCK_LEARN_BUTTON:
    'ecom_creator_stock_detail_tip_stock_learn_button',
  ECOM_CREATOR_STOCK_DETAIL_TIP_STOCK_TEXT:
    'ecom_creator_stock_detail_tip_stock_text',
  ECOM_CREATOR_STOCK_DETAIL_TIP_STOCK_TEXT_PC:
    'ecom_creator_stock_detail_tip_stock_text_pc',
  ECOM_CREATOR_STOCK_DETAIL_UPCOMING_INVITE_TITLE:
    'ecom_creator_stock_detail_upcoming_invite_title',
  ECOM_CREATOR_STOCK_DETAIL_UPCOMING_INVITE_TITLE_PC:
    'ecom_creator_stock_detail_upcoming_invite_title_pc',
  ECOM_CREATOR_STOCK_DETAIL_UPDATE_BUTTON:
    'ecom_creator_stock_detail_update_button',
  ECOM_CREATOR_STOCK_MESSAGE_AVAILABLE: 'ecom_creator_stock_message_available',
  ECOM_CREATOR_STOCK_MESSAGE_AVAILABLE_CONTENT:
    'ecom_creator_stock_message_available_content',
  ECOM_CREATOR_STOCK_MESSAGE_AVAILABLE_TITLE:
    'ecom_creator_stock_message_available_title',
  ECOM_CREATOR_STOCK_MESSAGE_DELETED: 'ecom_creator_stock_message_deleted',
  ECOM_CREATOR_STOCK_MESSAGE_DELETED_CONTENT:
    'ecom_creator_stock_message_deleted_content',
  ECOM_CREATOR_STOCK_MESSAGE_DELETED_TITLE:
    'ecom_creator_stock_message_deleted_title',
  ECOM_CREATOR_STOCK_MESSAGE_EXPIRED: 'ecom_creator_stock_message_expired',
  ECOM_CREATOR_STOCK_MESSAGE_EXPIRED_CONTENT:
    'ecom_creator_stock_message_expired_content',
  ECOM_CREATOR_STOCK_MESSAGE_EXPIRED_TITLE:
    'ecom_creator_stock_message_expired_title',
  ECOM_CREATOR_STOCK_MESSAGE_NEW: 'ecom_creator_stock_message_new',
  ECOM_CREATOR_STOCK_MESSAGE_NEW_CONTENT:
    'ecom_creator_stock_message_new_content',
  ECOM_CREATOR_STOCK_MESSAGE_NEW_TITLE: 'ecom_creator_stock_message_new_title',
  ECOM_CREATOR_STOCK_MESSAGE_SOLD_OUT: 'ecom_creator_stock_message_sold_out',
  ECOM_CREATOR_STOCK_MESSAGE_SOLD_OUT_CONTENT:
    'ecom_creator_stock_message_sold_out_content',
  ECOM_CREATOR_STOCK_MESSAGE_SOLD_OUT_TITLE:
    'ecom_creator_stock_message_sold_out_title',
  ECOM_FREE_SAMPLE_DIALOG_CONTENT_DELETE:
    'ecom_free_sample_dialog_content_delete',
  ECOM_FREE_SAMPLE_DIALOG_CONTENT_DISCARD:
    'ecom_free_sample_dialog_content_discard',
  ECOM_FREE_SAMPLE_DIALOG_CTA_PRIMARY_DELETE:
    'ecom_free_sample_dialog_cta_primary_delete',
  ECOM_FREE_SAMPLE_DIALOG_CTA_PRIMARY_DISCARD:
    'ecom_free_sample_dialog_cta_primary_discard',
  ECOM_FREE_SAMPLE_DIALOG_CTA_SECONDARY_DELETE:
    'ecom_free_sample_dialog_cta_secondary_delete',
  ECOM_FREE_SAMPLE_DIALOG_CTA_SECONDARY_DISCARD:
    'ecom_free_sample_dialog_cta_secondary_discard',
  ECOM_FREE_SAMPLE_DIALOG_TITLE_DELETE: 'ecom_free_sample_dialog_title_delete',
  ECOM_FREE_SAMPLE_DIALOG_TITLE_DISCARD:
    'ecom_free_sample_dialog_title_discard',
  ECOM_FREE_SAMPLE_FIELD_NAME_SAMPLE_QUANTITY:
    'ecom_free_sample_field_name_sample_quantity',
  ECOM_FREE_SAMPLE_FIELD_NAME_SAMPLE_QUANTITY_BLANK_DESCRIPTION:
    'ecom_free_sample_field_name_sample_quantity_blank_description',
  ECOM_FREE_SAMPLE_FIELD_NAME_SAMPLE_QUANTITY_DESCRIPTION:
    'ecom_free_sample_field_name_sample_quantity_description',
  ECOM_FREE_SAMPLE_FIELD_NAME_VALIDITY_PERIOD:
    'ecom_free_sample_field_name_validity_period',
  ECOM_FREE_SAMPLE_FIELD_NAME_VALIDITY_PERIOD_INDEFINITE:
    'ecom_free_sample_field_name_validity_period_indefinite',
  ECOM_FREE_SAMPLE_FIELD_NAME_VALIDITY_PERIOD_TERMINAL:
    'ecom_free_sample_field_name_validity_period_terminal',
  ECOM_FREE_SAMPLE_FIELD_NAME_VALIDITY_PERIOD_TERMINAL_BLANK_DESCRIPTION:
    'ecom_free_sample_field_name_validity_period_terminal_blank_description',
  ECOM_FREE_SAMPLE_FIELD_NAME_VALIDITY_PERIOD_TERMINAL_DESCRIPTION:
    'ecom_free_sample_field_name_validity_period_terminal_description',
  ECOM_FREE_SAMPLE_IN_BULK_CTA: 'ecom_free_sample_in_bulk_cta',
  ECOM_FREE_SAMPLE_IN_BULK_DIALOG_CONTENT_SAVE:
    'ecom_free_sample_in_bulk_dialog_content_save',
  ECOM_FREE_SAMPLE_IN_BULK_DIALOG_CTA_PRIMARY_SAVE:
    'ecom_free_sample_in_bulk_dialog_cta_primary_save',
  ECOM_FREE_SAMPLE_IN_BULK_DIALOG_CTA_SECONDARY_SAVE:
    'ecom_free_sample_in_bulk_dialog_cta_secondary_save',
  ECOM_FREE_SAMPLE_IN_BULK_DIALOG_SAVE_TOAST:
    'ecom_free_sample_in_bulk_dialog_save_toast',
  ECOM_FREE_SAMPLE_REQUEST_PAGE_BLANK_CTA:
    'ecom_free_sample_request_page_blank_cta',
  ECOM_FREE_SAMPLE_REQUEST_PAGE_BLANK_FIRST:
    'ecom_free_sample_request_page_blank_first',
  ECOM_FREE_SAMPLE_REQUEST_PAGE_BLANK_SECOND:
    'ecom_free_sample_request_page_blank_second',
  ECOM_FREE_SAMPLE_RULE_CTA_DELETE: 'ecom_free_sample_rule_cta_delete',
  ECOM_FREE_SAMPLE_RULE_CTA_DISCARD: 'ecom_free_sample_rule_cta_discard',
  ECOM_FREE_SAMPLE_RULE_CTA_SUBMIT: 'ecom_free_sample_rule_cta_submit',
  ECOM_FREE_SAMPLE_STATUS_INTRODUCTION: 'ecom_free_sample_status_introduction',
  ECOM_FREE_SAMPLE_STATUS_ONLINE: 'ecom_free_sample_status_online',
  ECOM_FREE_SAMPLE_STATUS_ONLINE_DISCRIPTION_QUNATITY:
    'ecom_free_sample_status_online_discription_qunatity',
  ECOM_FREE_SAMPLE_STATUS_ONLINE_DISCRIPTION_REMINDER:
    'ecom_free_sample_status_online_discription_reminder',
  ECOM_FREE_SAMPLE_STATUS_ONLINE_DISCRIPTION_VALIDITY_PERIOD:
    'ecom_free_sample_status_online_discription_validity_period',
  ECOM_FREE_SAMPLE_STATUS_ONLINE_NOT_CREATED:
    'ecom_free_sample_status_online_not_created',
  ECOM_FREE_SAMPLE_STATUS_UNAVAILABLE: 'ecom_free_sample_status_unavailable',
  ECOM_GIVE_AWAY_PRIZE_ADD: 'ecom_give_away_prize_add',
  ECOM_GIVE_AWAY_PRIZE_ADD_ITEM: 'ecom_give_away_prize_add_item',
  ECOM_GIVE_AWAY_PRIZE_ADD_TITLE: 'ecom_give_away_prize_add_title',
  ECOM_GIVE_AWAY_PRIZE_ADD_TOAST2: 'ecom_give_away_prize_add_toast2',
  ECOM_GIVE_AWAY_PRIZE_ADD_TOAST: 'ecom_give_away_prize_add_toast',
  ECOM_GIVE_AWAY_PRIZE_CONFIRM: 'ecom_give_away_prize_confirm',
  ECOM_GIVE_AWAY_PRIZE_DELETE2: 'ecom_give_away_prize_delete2',
  ECOM_GIVE_AWAY_PRIZE_DELETE: 'ecom_give_away_prize_delete',
  ECOM_GIVE_AWAY_PRIZE_DELETE_KEEP: 'ecom_give_away_prize_delete_keep',
  ECOM_GIVE_AWAY_PRIZE_DELETE_REMOVE: 'ecom_give_away_prize_delete_Remove',
  ECOM_GIVE_AWAY_PRIZE_DELETE_TOAST2: 'ecom_give_away_prize_delete_toast2',
  ECOM_GIVE_AWAY_PRIZE_EMPTY: 'ecom_give_away_prize_empty',
  ECOM_GIVE_AWAY_PRIZE_EMPTY_BUTTON: 'ecom_give_away_prize_empty_button',
  ECOM_GIVE_AWAY_PRIZE_EMPTY_WORDING: 'ecom_give_away_prize_empty_wording',
  ECOM_GIVE_AWAY_PRIZE_GIVEAWAYS: 'ecom_give_away_prize_giveaways',
  ECOM_GIVE_AWAY_PRIZE_LEFT: 'ecom_give_away_prize_left',
  ECOM_GIVE_AWAY_PRIZE_NEW: 'ecom_give_away_prize_new',
  ECOM_GIVE_AWAY_PRIZE_NUMBERS: 'ecom_give_away_prize_numbers',
  ECOM_GIVE_AWAY_PRIZE_PRODUCTS: 'ecom_give_away_prize_products',
  ECOM_GIVE_AWAY_PRIZE_QUANTITY: 'ecom_give_away_prize_quantity',
  ECOM_GIVE_AWAY_PRIZE_READY: 'ecom_give_away_prize_Ready',
  ECOM_GIVE_AWAY_PRIZE_SET_ITEM: 'ecom_give_away_prize_set_item',
  ECOM_GIVE_AWAY_PRIZE_STOCK: 'ecom_give_away_prize_stock',
  ECOM_GIVE_AWAY_PRIZE_TIP: 'ecom_give_away_prize_tip',
  ECOM_GIVE_AWAY_PRIZE_UNAVAILABLE: 'ecom_give_away_prize_unavailable',
  ECOM_LIVE_ADD_PRODUCT_INSTANT_PRODUCT_ROUTE_INVALID:
    'ecom_live_add_product_instant_product_route_invalid',
  ECOM_LIVE_ADD_PRODUCT_NO_INSTANT_PRODUCT_PERMISSSION:
    'ecom_live_add_product_no_instant_product_permisssion',
  ECOM_MESSAGE_CENTER_EMPTY_TEXT: 'ecom_message_center_empty_text',
  ECOM_MESSAGE_CENTER_NEW_ABOVE_TEXT: 'ecom_message_center_new_above_text',
  ECOM_MESSAGE_CENTER_TITLE: 'ecom_message_center_title',
  ECOM_NOTIFICATION_CREATOR_STOCK_EMPTY_BUTTON:
    'ecom_notification_creator_stock_empty_button',
  ECOM_NOTIFICATION_CREATOR_STOCK_EMPTY_CONTENT:
    'ecom_notification_creator_stock_empty_content',
  ECOM_NOTIFICATION_CREATOR_STOCK_EMPTY_TITLE:
    'ecom_notification_creator_stock_empty_title',
  ECOM_NOTIFICATION_CREATOR_STOCK_ENTRANCE_TITLE:
    'ecom_notification_creator_stock_entrance_title',
  ECOM_NOTIFICATION_CREATOR_STOCK_ENTRANCE_TOOLTIP:
    'ecom_notification_creator_stock_entrance_tooltip',
  ECOM_PARTNER_COLLECTION_NEW_PARTNER_TOOLKIT:
    'ecom_partner_collection_new_partner_toolkit',
  ECOM_PARTNER_COLLECTION_ZERO_COMMISSION_STATUS:
    'ecom_partner_collection_zero_commission_status',
  ECOM_PARTNER_COLLECTION_ZERO_COMMISSION_TOOLKIT:
    'ecom_partner_collection_zero_commission_toolkit',
  ECOM_PARTNER_PLATFORM_ACCOUNTS: 'ecom_partner_platform_accounts',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT:
    'ecom_partner_platform_accounts_link_ttaccount',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_NEW:
    'ecom_partner_platform_accounts_link_ttaccount_add_new',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_BOUND:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_bound',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_CANCLE:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_cancle',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_DETAILS1:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_details1',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_DETAILS2:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_details2',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_DETAILS3:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_details3',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_DETAILS:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_details',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_EMPTY:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_empty',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_EXPIRATION:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_expiration',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_FAILURE:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_failure',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_INVALID:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_invalid',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_NOTICE:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_SUBMIT:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_submit',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_SUCCESS:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_success',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_TITLE:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_ADD_POPUP_TTHANDLE:
    'ecom_partner_platform_accounts_link_ttaccount_add_popup_tthandle',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_EMPTY_NOTICE:
    'ecom_partner_platform_accounts_link_ttaccount_empty_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_ACTION:
    'ecom_partner_platform_accounts_link_ttaccount_table_action',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_ACTION_UNBIND:
    'ecom_partner_platform_accounts_link_ttaccount_table_action_unbind',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_COPY:
    'ecom_partner_platform_accounts_link_ttaccount_table_copy',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_COPY_FAILURE:
    'ecom_partner_platform_accounts_link_ttaccount_table_copy_failure',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_COPY_SUCCESS:
    'ecom_partner_platform_accounts_link_ttaccount_table_copy_success',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_NAME:
    'ecom_partner_platform_accounts_link_ttaccount_table_name',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_STATUS:
    'ecom_partner_platform_accounts_link_ttaccount_table_status',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_STATUS_BIND:
    'ecom_partner_platform_accounts_link_ttaccount_table_status_bind',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_STATUS_PENDING:
    'ecom_partner_platform_accounts_link_ttaccount_table_status_pending',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TABLE_UID:
    'ecom_partner_platform_accounts_link_ttaccount_table_uid',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_TITLE:
    'ecom_partner_platform_accounts_link_ttaccount_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_UNBIND_POPUP_CANCEL:
    'ecom_partner_platform_accounts_link_ttaccount_unbind_popup_cancel',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_UNBIND_POPUP_DETAIL:
    'ecom_partner_platform_accounts_link_ttaccount_unbind_popup_detail',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_UNBIND_POPUP_FAIL:
    'ecom_partner_platform_accounts_link_ttaccount_unbind_popup_fail',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_UNBIND_POPUP_SUCCESS:
    'ecom_partner_platform_accounts_link_ttaccount_unbind_popup_success',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_UNBIND_POPUP_TITLE:
    'ecom_partner_platform_accounts_link_ttaccount_unbind_popup_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_LINK_TTACCOUNT_UNBIND_POPUP_UNBIND:
    'ecom_partner_platform_accounts_link_ttaccount_unbind_popup_unbind',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE:
    'ecom_partner_platform_accounts_profile',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_BUSINESS_INFO:
    'ecom_partner_platform_accounts_profile_company_info_business_info',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_CONTACT_INFO:
    'ecom_partner_platform_accounts_profile_company_info_contact_info',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_CURRENT_BUSINESS_ROLE:
    'ecom_partner_platform_accounts_profile_company_info_current_business_role',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_LANGUAGE1:
    'ecom_partner_platform_accounts_profile_company_info_language1',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_LANGUAGE2:
    'ecom_partner_platform_accounts_profile_company_info_language2',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_LANGUAGE3:
    'ecom_partner_platform_accounts_profile_company_info_language3',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_ADD:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_add',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_ADD_FULL:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_add_full',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_BASE:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_base',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_DECLINE_BODY1:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_decline_body1',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_DECLINE_BODY2:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_decline_body2',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_DECLINE_BODY3:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_decline_body3',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_DECLINE_TITLE:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_decline_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_TIP1:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_tip1',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_TIP2:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_tip2',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_TIP3:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_tip3',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_TITLE:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_MANAGE_BUSINESS_ROLE_YOUR_ROLE:
    'ecom_partner_platform_accounts_profile_company_info_manage_business_role_your_role',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_OPERATION_INFO:
    'ecom_partner_platform_accounts_profile_company_info_operation_info',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_COMPANY_INFO_QUALIFICATION_INFO:
    'ecom_partner_platform_accounts_profile_company_info_qualification_info',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO:
    'ecom_partner_platform_accounts_profile_head_account_info',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO_BASIC:
    'ecom_partner_platform_accounts_profile_head_account_info_basic',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO_BASIC_CHANGE:
    'ecom_partner_platform_accounts_profile_head_account_info_basic_change',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO_BASIC_CHANGE_INFO:
    'ecom_partner_platform_accounts_profile_head_account_info_basic_change_info',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO_BASIC_EMAIL:
    'ecom_partner_platform_accounts_profile_head_account_info_basic_email',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO_BASIC_LOGIN_TTACCOUNTS:
    'ecom_partner_platform_accounts_profile_head_account_info_basic_login_ttaccounts',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO_BASIC_LOGIN_TTACCOUNTS_NOTICE:
    'ecom_partner_platform_accounts_profile_head_account_info_basic_login_ttaccounts_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO_BASIC_PASSWORD:
    'ecom_partner_platform_accounts_profile_head_account_info_basic_password',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO_BASIC_PHONE:
    'ecom_partner_platform_accounts_profile_head_account_info_basic_phone',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_ACCOUNT_INFO_BASIC_SET_PASSWORD:
    'ecom_partner_platform_accounts_profile_head_account_info_basic_set_password',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO:
    'ecom_partner_platform_accounts_profile_head_company_info',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BASIC:
    'ecom_partner_platform_accounts_profile_head_company_info_basic',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BASIC_ACCOUNT:
    'ecom_partner_platform_accounts_profile_head_company_info_basic_account',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BASIC_NAME:
    'ecom_partner_platform_accounts_profile_head_company_info_basic_name',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BASIC_OPERATION:
    'ecom_partner_platform_accounts_profile_head_company_info_basic_operation',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BASIC_SERVICE:
    'ecom_partner_platform_accounts_profile_head_company_info_basic_service',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS:
    'ecom_partner_platform_accounts_profile_head_company_info_business',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_ADDRESS:
    'ecom_partner_platform_accounts_profile_head_company_info_business_address',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_CERTIFICATE:
    'ecom_partner_platform_accounts_profile_head_company_info_business_certificate',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_CERTIFICATE_NUMBER:
    'ecom_partner_platform_accounts_profile_head_company_info_business_certificate_number',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_CERTIFICATE_TYPE:
    'ecom_partner_platform_accounts_profile_head_company_info_business_certificate_type',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_DATE:
    'ecom_partner_platform_accounts_profile_head_company_info_business_date',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_ENTITY:
    'ecom_partner_platform_accounts_profile_head_company_info_business_entity',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_ID:
    'ecom_partner_platform_accounts_profile_head_company_info_business_id',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_LOC:
    'ecom_partner_platform_accounts_profile_head_company_info_business_loc',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_NATURE:
    'ecom_partner_platform_accounts_profile_head_company_info_business_nature',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_BUSINESS_PRODUCT:
    'ecom_partner_platform_accounts_profile_head_company_info_business_product',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_CONTACT:
    'ecom_partner_platform_accounts_profile_head_company_info_contact',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_CONTACT_EMAIL:
    'ecom_partner_platform_accounts_profile_head_company_info_contact_email',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_CONTACT_NAME:
    'ecom_partner_platform_accounts_profile_head_company_info_contact_name',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_CONTACT_PHONE:
    'ecom_partner_platform_accounts_profile_head_company_info_contact_phone',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_COMPANY_INFO_DOWNLOAD:
    'ecom_partner_platform_accounts_profile_head_company_info_download',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS:
    'ecom_partner_platform_accounts_profile_head_payments',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_ADD:
    'ecom_partner_platform_accounts_profile_head_payments_add',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_ADD_INDEX:
    'ecom_partner_platform_accounts_profile_head_payments_add_index',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_ADD_INDEX_FOUR:
    'ecom_partner_platform_accounts_profile_head_payments_add_index_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_BANKNUM_FOUR:
    'ecom_partner_platform_accounts_profile_head_payments_banknum_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_CHANGE:
    'ecom_partner_platform_accounts_profile_head_payments_change',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_METHOD:
    'ecom_partner_platform_accounts_profile_head_payments_method',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_PAYONEERID:
    'ecom_partner_platform_accounts_profile_head_payments_Payoneerid',
  // ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_PAYONEERID:
  //   'ecom_partner_platform_accounts_profile_head_payments_payoneerid',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_SETTLEMENT:
    'ecom_partner_platform_accounts_profile_head_payments_settlement',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_STATUS:
    'ecom_partner_platform_accounts_profile_head_payments_status',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_STATUS_INVALID:
    'ecom_partner_platform_accounts_profile_head_payments_status_invalid',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_STATUS_SUCCEED:
    'ecom_partner_platform_accounts_profile_head_payments_status_succeed',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_HEAD_PAYMENTS_STATUS_VALIDATING:
    'ecom_partner_platform_accounts_profile_head_payments_status_validating',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_ACTIVATING_ACTION_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_activating_action_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_ACTIVATING_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_activating_text_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_ACTIVATING_TITLE_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_activating_title_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_BUTTON_BANK_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_button_bank_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_BUTTON_LATER:
    'ecom_partner_platform_accounts_profile_popup_activated_button_later',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_BUTTON_LATER_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_button_later_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_BUTTON_PAYONEER:
    'ecom_partner_platform_accounts_profile_popup_activated_button_Payoneer',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_BUTTON_PAYONEER_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_button_Payoneer_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_FAILED_BUTTON_CANCEL:
    'ecom_partner_platform_accounts_profile_popup_activated_failed_button_cancel',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_FAILED_BUTTON_CANCEL_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_failed_button_cancel_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_FAILED_BUTTON_RETRY:
    'ecom_partner_platform_accounts_profile_popup_activated_failed_button_retry',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_FAILED_BUTTON_RETRY_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_failed_button_retry_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_FAILED_TEXT:
    'ecom_partner_platform_accounts_profile_popup_activated_failed_text',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_FAILED_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_failed_text_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_FAILED_TITLE:
    'ecom_partner_platform_accounts_profile_popup_activated_failed_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_FAILED_TITLE_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_failed_title_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_TEXT:
    'ecom_partner_platform_accounts_profile_popup_activated_text',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_text_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_TEXT_L2L_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_text_L2L_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_TITLE:
    'ecom_partner_platform_accounts_profile_popup_activated_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_ACTIVATED_TITLE_FOUR:
    'ecom_partner_platform_accounts_profile_popup_activated_title_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_ALTER_TO_EMAIL:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_alter_to_email',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_ALTER_TO_PHONE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_alter_to_phone',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_CANCEL:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_cancel',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEW:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_new',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEW_CANCEL:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_new_cancel',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEW_CONFIRM:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_new_confirm',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEW_CONFLICT:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_new_conflict',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEW_NOTICE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_new_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEW_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_new_send_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEW_SUCCESS:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_new_success',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEW_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_new_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEW_VERIFICATION_CODE_NOTICE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_new_verification_code_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_NEXT:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_next',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_PHONE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_phone',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_send_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_STEP1:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_step1',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_STEP2:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_step2',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_TITLE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_EMAIL_VERIFICATION_CODE_NOTICE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_email_verification_code_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_ALTER_TO_EMAIL:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_alter_to_email',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_ALTER_TO_PHONE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_alter_to_phone',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_CANCEL:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_cancel',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_EMAIL:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_email',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEW:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_new',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEW_CANCEL:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_new_cancel',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEW_CONFIRM:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_new_confirm',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEW_CONFLICT:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_new_conflict',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEW_NOTICE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_new_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEW_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_new_send_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEW_SUCCESS:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_new_success',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEW_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_new_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEW_VERIFICATION_CODE_NOTICE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_new_verification_code_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_NEXT:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_next',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_send_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_STEP1:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_step1',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_STEP2:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_step2',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_TITLE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_ACCOUNT_PHONE_VERIFICATION_CODE_NOTICE:
    'ecom_partner_platform_accounts_profile_popup_modify_account_phone_verification_code_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_CANCEL_MODIFICATION:
    'ecom_partner_platform_accounts_profile_popup_modify_password_cancel_modification',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_CONFIRM:
    'ecom_partner_platform_accounts_profile_popup_modify_password_confirm',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_CONFIRM_MODIFICATION:
    'ecom_partner_platform_accounts_profile_popup_modify_password_confirm_modification',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_CONFIRM_NOTICE:
    'ecom_partner_platform_accounts_profile_popup_modify_password_confirm_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_EMAIL:
    'ecom_partner_platform_accounts_profile_popup_modify_password_email',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_EMAIL_ALTER_TO_PHONE:
    'ecom_partner_platform_accounts_profile_popup_modify_password_email_alter_to_phone',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_NEW:
    'ecom_partner_platform_accounts_profile_popup_modify_password_new',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_NEW_NOTICE:
    'ecom_partner_platform_accounts_profile_popup_modify_password_new_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_NEW_RULE:
    'ecom_partner_platform_accounts_profile_popup_modify_password_new_rule',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_PHONE:
    'ecom_partner_platform_accounts_profile_popup_modify_password_phone',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_PHONE_ALTER_TO_EMAIL:
    'ecom_partner_platform_accounts_profile_popup_modify_password_phone_alter_to_email',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_PHONE_CANCEL:
    'ecom_partner_platform_accounts_profile_popup_modify_password_phone_cancel',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_PHONE_NEXT:
    'ecom_partner_platform_accounts_profile_popup_modify_password_phone_next',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_PHONE_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_password_phone_send_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_PHONE_VERIFICATION_CODE:
    'ecom_partner_platform_accounts_profile_popup_modify_password_phone_verification_code',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_PHONE_VERIFICATION_CODE_NOTICE:
    'ecom_partner_platform_accounts_profile_popup_modify_password_phone_verification_code_notice',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_STEP1:
    'ecom_partner_platform_accounts_profile_popup_modify_password_step1',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_STEP2:
    'ecom_partner_platform_accounts_profile_popup_modify_password_step2',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_SUCCESS:
    'ecom_partner_platform_accounts_profile_popup_modify_password_success',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_MODIFY_PASSWORD_TITLE:
    'ecom_partner_platform_accounts_profile_popup_modify_password_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_NOT_ACTIVATED_BUTTON_ACTIVATE:
    'ecom_partner_platform_accounts_profile_popup_not_activated_button_activate',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_NOT_ACTIVATED_BUTTON_ACTIVATE_FOUR:
    'ecom_partner_platform_accounts_profile_popup_not_activated_button_activate_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_NOT_ACTIVATED_BUTTON_LATER:
    'ecom_partner_platform_accounts_profile_popup_not_activated_button_later',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_NOT_ACTIVATED_BUTTON_LATER_FOUR:
    'ecom_partner_platform_accounts_profile_popup_not_activated_button_later_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_NOT_ACTIVATED_TEXT:
    'ecom_partner_platform_accounts_profile_popup_not_activated_text',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_NOT_ACTIVATED_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_popup_not_activated_text_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_NOT_ACTIVATED_TITLE:
    'ecom_partner_platform_accounts_profile_popup_not_activated_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_NOT_ACTIVATED_TITLE_FOUR:
    'ecom_partner_platform_accounts_profile_popup_not_activated_title_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_SET_PASSWORD:
    'ecom_partner_platform_accounts_profile_popup_set_password',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_POPUP_SET_PASSWORD_SUCCESS:
    'ecom_partner_platform_accounts_profile_popup_set_password_success',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TITLE:
    'ecom_partner_platform_accounts_profile_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ACTIVATING_TEXT:
    'ecom_partner_platform_accounts_profile_tooltips_activating_text',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ACTIVATING_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_activating_text_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ACTIVATING_TITLE:
    'ecom_partner_platform_accounts_profile_tooltips_activating_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ACTIVATING_TITLE_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_activating_title_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ADD_BANK_BUTTON_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_add_bank_button_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ADD_BANK_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_add_bank_text_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ADD_PAYONEER_BUTTON:
    'ecom_partner_platform_accounts_profile_tooltips_add_Payoneer_button',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ADD_PAYONEER_BUTTON_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_add_Payoneer_button_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ADD_PAYONEER_TEXT:
    'ecom_partner_platform_accounts_profile_tooltips_add_Payoneer_text',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_ADD_PAYONEER_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_add_Payoneer_text_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_BANK_INVALID_BUTTON_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_bank_invalid_button_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_BANK_INVALID_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_bank_invalid_text_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_NOT_ACTIVATED_BUTTON:
    'ecom_partner_platform_accounts_profile_tooltips_not_activated_button',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_NOT_ACTIVATED_BUTTON_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_not_activated_button_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_NOT_ACTIVATED_TEXT:
    'ecom_partner_platform_accounts_profile_tooltips_not_activated_text',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_NOT_ACTIVATED_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_not_activated_text_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_NOT_ACTIVATED_TITLE:
    'ecom_partner_platform_accounts_profile_tooltips_not_activated_title',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_NOT_ACTIVATED_TITLE_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_not_activated_title_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_PAYONEER_INVALID_BUTTON:
    'ecom_partner_platform_accounts_profile_tooltips_Payoneer_invalid_button',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_PAYONEER_INVALID_BUTTON_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_Payoneer_invalid_button_four',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_PAYONEER_INVALID_TEXT:
    'ecom_partner_platform_accounts_profile_tooltips_Payoneer_invalid_text',
  ECOM_PARTNER_PLATFORM_ACCOUNTS_PROFILE_TOOLTIPS_PAYONEER_INVALID_TEXT_FOUR:
    'ecom_partner_platform_accounts_profile_tooltips_Payoneer_invalid_text_four',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_BINDINGTIME:
    'ecom_partner_platform_creator_commission_apply_popup_bindingtime',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_BUTTON:
    'ecom_partner_platform_creator_commission_apply_popup_button',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_DATE_FAILURE:
    'ecom_partner_platform_creator_commission_apply_popup_date_failure',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_DDL:
    'ecom_partner_platform_creator_commission_apply_popup_ddl',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_DDL_EXPLICATION:
    'ecom_partner_platform_creator_commission_apply_popup_ddl_explication',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_EXPIRATION:
    'ecom_partner_platform_creator_commission_apply_popup_expiration',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_LEGAL:
    'ecom_partner_platform_creator_commission_apply_popup_legal',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_PROPORTION:
    'ecom_partner_platform_creator_commission_apply_popup_proportion',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_REMARK:
    'ecom_partner_platform_creator_commission_apply_popup_remark',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_TITLE:
    'ecom_partner_platform_creator_commission_apply_popup_title',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_APPLY_POPUP_USERNAME:
    'ecom_partner_platform_creator_commission_apply_popup_username',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_CREATOR_EMPTY_BUTTON:
    'ecom_partner_platform_creator_commission_creator_empty_button',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_CREATOR_EMPTY_TEXT:
    'ecom_partner_platform_creator_commission_creator_empty_text',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_CREATOR_EMPTY_TITLE:
    'ecom_partner_platform_creator_commission_creator_empty_title',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_LINK_FAILURE:
    'ecom_partner_platform_creator_commission_link_failure',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_NOT_ACTIVATED_BUTTON:
    'ecom_partner_platform_creator_commission_not_activated_button',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_NOT_ACTIVATED_TEXT:
    'ecom_partner_platform_creator_commission_not_activated_text',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_NOT_ACTIVATED_TITLE:
    'ecom_partner_platform_creator_commission_not_activated_title',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_APPROVE_DOUBLE_CHECK:
    'ecom_partner_platform_creator_commission_pending_withdraw_approve_double_check',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_APPROVE_DOUBLE_CHECK_APPROVE:
    'ecom_partner_platform_creator_commission_pending_withdraw_approve_double_check_approve',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_APPROVE_DOUBLE_CHECK_CANCEL:
    'ecom_partner_platform_creator_commission_pending_withdraw_approve_double_check_cancel',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_APPROVE_DOUBLE_CHECK_SUCCESS:
    'ecom_partner_platform_creator_commission_pending_withdraw_approve_double_check_success',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_REJECT_DOUBLE_CHECK:
    'ecom_partner_platform_creator_commission_pending_withdraw_reject_double_check',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_REJECT_DOUBLE_CHECK_CANCEL:
    'ecom_partner_platform_creator_commission_pending_withdraw_reject_double_check_cancel',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_REJECT_DOUBLE_CHECK_REJECT:
    'ecom_partner_platform_creator_commission_pending_withdraw_reject_double_check_reject',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_REJECT_DOUBLE_CHECK_SUCCESS:
    'ecom_partner_platform_creator_commission_pending_withdraw_reject_double_check_success',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_REQUEST_CREATOR:
    'ecom_partner_platform_creator_commission_pending_withdraw_request_creator',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_PENDING_WITHDRAW_REQUEST_PARTNER:
    'ecom_partner_platform_creator_commission_pending_withdraw_request_partner',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_POPUP_SUBMIT_FAILURE_BUTTON:
    'ecom_partner_platform_creator_commission_popup_submit_failure_button',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_POPUP_SUBMIT_FAILURE_TEXT:
    'ecom_partner_platform_creator_commission_popup_submit_failure_text',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_POPUP_SUBMIT_FAILURE_TITLE:
    'ecom_partner_platform_creator_commission_popup_submit_failure_title',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_POPUP_SUBMIT_SUCCESS:
    'ecom_partner_platform_creator_commission_popup_submit_success',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_POPUP_TIPS:
    'ecom_partner_platform_creator_commission_popup_tips',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_STATUS_ALL:
    'ecom_partner_platform_creator_commission_status_all',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_STATUS_AUTHORIZED:
    'ecom_partner_platform_creator_commission_status_authorized',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_STATUS_PENDING_AUTHORIZATION:
    'ecom_partner_platform_creator_commission_status_pending_authorization',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_STATUS_PENDING_WITHDRAW:
    'ecom_partner_platform_creator_commission_status_pending_withdraw',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_STATUS_UNAUTHORIZED:
    'ecom_partner_platform_creator_commission_status_unauthorized',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_ACTION:
    'ecom_partner_platform_creator_commission_table_action',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_ACTION_APPLIED:
    'ecom_partner_platform_creator_commission_table_action_applied',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_ACTION_APPLIED_TEXT:
    'ecom_partner_platform_creator_commission_table_action_applied_text',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_ACTION_APPLY:
    'ecom_partner_platform_creator_commission_table_action_apply',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_ACTION_RELEASE:
    'ecom_partner_platform_creator_commission_table_action_release',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_AUTHORIZATION:
    'ecom_partner_platform_creator_commission_table_authorization',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_AUTHORIZATION_FILTER_3DAYS:
    'ecom_partner_platform_creator_commission_table_authorization_filter_3days',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_AUTHORIZATION_FILTER_ALL:
    'ecom_partner_platform_creator_commission_table_authorization_filter_all',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_CANCELLATION_EXPIRATION_DAYS:
    'ecom_partner_platform_creator_commission_table_cancellation_expiration_days',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_CANCELLATION_EXPIRATION_DAYS_PLURAL:
    'ecom_partner_platform_creator_commission_table_cancellation_expiration_days_plural',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_CREATOR:
    'ecom_partner_platform_creator_commission_table_creator',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_DDL:
    'ecom_partner_platform_creator_commission_table_ddl',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_DDL_INDEX:
    'ecom_partner_platform_creator_commission_table_ddl_index',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_EMPTY:
    'ecom_partner_platform_creator_commission_table_empty',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TABLE_REMARKS:
    'ecom_partner_platform_creator_commission_table_remarks',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_TITLE:
    'ecom_partner_platform_creator_commission_title',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_WITHDRAW_POPUP_DDL:
    'ecom_partner_platform_creator_commission_withdraw_popup_ddl',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_WITHDRAW_POPUP_LEGAL:
    'ecom_partner_platform_creator_commission_withdraw_popup_legal',
  ECOM_PARTNER_PLATFORM_CREATOR_COMMISSION_WITHDRAW_POPUP_TITLE:
    'ecom_partner_platform_creator_commission_withdraw_popup_title',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_COMFIRM_DOUBLE_CHECK:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_comfirm_double_check',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_COMFIRM_DOUBLE_CHECK_CANCEL:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_comfirm_double_check_cancel',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_COMFIRM_DOUBLE_CHECK_CONFIRM:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_comfirm_double_check_confirm',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_COMFIRM_DOUBLE_CHECK_SUCCESS:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_comfirm_double_check_success',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_DECLINE_DOUBLE_CHECK:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_decline_double_check',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_DECLINE_DOUBLE_CHECK_CANCEL:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_decline_double_check_cancel',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_DECLINE_DOUBLE_CHECK_DECLINE:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_decline_double_check_decline',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_DECLINE_DOUBLE_CHECK_SUCCESS:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_decline_double_check_success',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_POPUP_DDL:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_popup_ddl',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_POPUP_LEGAL:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_popup_legal',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_CANCEL_REQUEST_POPUP_TITLE:
    'ecom_partner_platform_creator_settlement_agreement_cancel_request_popup_title',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_NOT_ACTIVATED_BUTTON_FOUR:
    'ecom_partner_platform_creator_settlement_agreement_not_activated_button_four',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_NOT_ACTIVATED_TEXT_FOUR:
    'ecom_partner_platform_creator_settlement_agreement_not_activated_text_four',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_NOT_ACTIVATED_TITLE_FOUR:
    'ecom_partner_platform_creator_settlement_agreement_not_activated_title_four',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_REQUEST_POPUP_BINDINGTIME:
    'ecom_partner_platform_creator_settlement_agreement_request_popup_bindingtime',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_REQUEST_POPUP_LEGAL:
    'ecom_partner_platform_creator_settlement_agreement_request_popup_legal',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_REQUEST_POPUP_PROPORTION:
    'ecom_partner_platform_creator_settlement_agreement_request_popup_proportion',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_REQUEST_POPUP_PROPORTION_ERROR:
    'ecom_partner_platform_creator_settlement_agreement_request_popup_proportion_error',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_REQUEST_POPUP_PROPORTION_INDEX:
    'ecom_partner_platform_creator_settlement_agreement_request_popup_proportion_index',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_REQUEST_POPUP_TITLE:
    'ecom_partner_platform_creator_settlement_agreement_request_popup_title',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_STATUS_AGREED:
    'ecom_partner_platform_creator_settlement_agreement_status_agreed',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_STATUS_AGREEMENT_REQUESTED:
    'ecom_partner_platform_creator_settlement_agreement_status_agreement_requested',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_STATUS_CANCELLATION_REQUESTED:
    'ecom_partner_platform_creator_settlement_agreement_status_cancellation_requested',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_STATUS_DISAGREED:
    'ecom_partner_platform_creator_settlement_agreement_status_disagreed',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_TABLE_AGREEMENT:
    'ecom_partner_platform_creator_settlement_agreement_table_agreement',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_TABLE_AGREEMENT_ACTION_REQUESTED_TEXT:
    'ecom_partner_platform_creator_settlement_agreement_table_agreement_action_requested_text',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_TABLE_DDL_INDEX:
    'ecom_partner_platform_creator_settlement_agreement_table_ddl_index',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_TABLE_PERCENTAGE:
    'ecom_partner_platform_creator_settlement_agreement_table_percentage',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_TABLE_PERCENTAGE_TIPS:
    'ecom_partner_platform_creator_settlement_agreement_table_percentage_tips',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_AGREEMENT_TITLE:
    'ecom_partner_platform_creator_settlement_agreement_title',
  ECOM_PARTNER_PLATFORM_CREATOR_SETTLEMENT_ORDERS_TITLE:
    'ecom_partner_platform_creator_settlement_orders_title',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_BINDING_TIME:
    'ecom_partner_platform_data_authorization_popup_binding_time',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_DURATION:
    'ecom_partner_platform_data_authorization_popup_duration',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_LEGAL_DETAIL:
    'ecom_partner_platform_data_authorization_popup_legal_detail',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_LEGAL_MUST_BOX:
    'ecom_partner_platform_data_authorization_popup_legal_must_box',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_LIVE_INFO_DURATION:
    'ecom_partner_platform_data_authorization_popup_live_info_duration',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_LIVE_INFO_TIME:
    'ecom_partner_platform_data_authorization_popup_live_info_time',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_LIVE_INFO_TITLE:
    'ecom_partner_platform_data_authorization_popup_live_info_title',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_TIP:
    'ecom_partner_platform_data_authorization_popup_tip',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_TITLE:
    'ecom_partner_platform_data_authorization_popup_title',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_TOAST_AGREEMENT_ERROR:
    'ecom_partner_platform_data_authorization_popup_toast_agreement_error',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_TOAST_TIME_BLANK:
    'ecom_partner_platform_data_authorization_popup_toast_time_blank',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_TOAST_TIME_ERROR:
    'ecom_partner_platform_data_authorization_popup_toast_time_error',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_TOAST_UNAVAILBLE:
    'ecom_partner_platform_data_authorization_popup_toast_unavailble',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_TOAST_UNAVAILBLE_EXPLICATION:
    'ecom_partner_platform_data_authorization_popup_toast_unavailble_explication',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_POPUP_USERNAME:
    'ecom_partner_platform_data_authorization_popup_username',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_SEARCHBOX_INDICATION:
    'ecom_partner_platform_data_authorization_searchbox_indication',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_STATE_BLANK:
    'ecom_partner_platform_data_authorization_state_blank',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_STATE_BLANK_BTN:
    'ecom_partner_platform_data_authorization_state_blank_btn',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_STATE_BLANK_INDICATION:
    'ecom_partner_platform_data_authorization_state_blank_indication',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TAB:
    'ecom_partner_platform_data_authorization_tab',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_ACTION_APPLY:
    'ecom_partner_platform_data_authorization_table_action_apply',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_ACTION_TOOLTIP:
    'ecom_partner_platform_data_authorization_table_action_tooltip',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_ACTION_VIEW_DATA:
    'ecom_partner_platform_data_authorization_table_action_view_data',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_HEADER_ACTION:
    'ecom_partner_platform_data_authorization_table_header_action',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_HEADER_CREATOR:
    'ecom_partner_platform_data_authorization_table_header_creator',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_HEADER_EFFECTIVE_DATE:
    'ecom_partner_platform_data_authorization_table_header_effective_date',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_TAG_ALL:
    'ecom_partner_platform_data_authorization_table_tag_all',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_TAG_AUTHORIZED:
    'ecom_partner_platform_data_authorization_table_tag_authorized',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_TAG_PENDING:
    'ecom_partner_platform_data_authorization_table_tag_pending',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TABLE_TAG_UNAUTHORIZED:
    'ecom_partner_platform_data_authorization_table_tag_unauthorized',
  ECOM_PARTNER_PLATFORM_DATA_AUTHORIZATION_TIP:
    'ecom_partner_platform_data_authorization_tip',
  ECOM_PARTNER_PLATFORM_FINANCE: 'ecom_partner_platform_finance',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION:
    'ecom_partner_platform_finance_commission',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_APPLY_PAYMENT_TOAST_FAILED:
    'ecom_partner_platform_finance_commission_apply_payment_toast_failed',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_APPLY_PAYMENT_TOAST_SUCCEED:
    'ecom_partner_platform_finance_commission_apply_payment_toast_succeed',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_NOT_PAID:
    'ecom_partner_platform_finance_commission_data_not_paid',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_NOT_PAID_REASON_1:
    'ecom_partner_platform_finance_commission_data_not_paid_reason_1',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_NOT_PAID_REASON_2:
    'ecom_partner_platform_finance_commission_data_not_paid_reason_2',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_NOT_PAID_REASON_3:
    'ecom_partner_platform_finance_commission_data_not_paid_reason_3',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_PAID:
    'ecom_partner_platform_finance_commission_data_paid',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_PAID_INDEX:
    'ecom_partner_platform_finance_commission_data_paid_index',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_PAID_MONTH:
    'ecom_partner_platform_finance_commission_data_paid_month',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_PAID_TOTAL:
    'ecom_partner_platform_finance_commission_data_paid_total',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_PAID_WEEK:
    'ecom_partner_platform_finance_commission_data_paid_week',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_TITLE:
    'ecom_partner_platform_finance_commission_data_title',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_DATA_UPDATE:
    'ecom_partner_platform_finance_commission_data_update',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_NOT_ACTIVATED_BUTTON:
    'ecom_partner_platform_finance_commission_not_activated_button',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_NOT_ACTIVATED_TEXT:
    'ecom_partner_platform_finance_commission_not_activated_text',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_NOT_ACTIVATED_TITLE:
    'ecom_partner_platform_finance_commission_not_activated_title',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_REMARKS:
    'ecom_partner_platform_finance_commission_remarks',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ACT_COMMISSION_BASE:
    'ecom_partner_platform_finance_commission_table_act_commission_base',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ACT_COMMISSION_BASE_TIPS:
    'ecom_partner_platform_finance_commission_table_act_commission_base_tips',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ACT_COMMISSION_PAYMENT:
    'ecom_partner_platform_finance_commission_table_act_commission_payment',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ACT_COMMISSION_PAYMENT_TIPS:
    'ecom_partner_platform_finance_commission_table_act_commission_payment_tips',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_COLLECT_COMMISSION_PAYMENT:
    'ecom_partner_platform_finance_commission_table_collect_commission_payment',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_COLLECT_COMMISSION_PAYMENT_TIPS:
    'ecom_partner_platform_finance_commission_table_collect_commission_payment_tips',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_COMMISSION_RATE:
    'ecom_partner_platform_finance_commission_table_commission_rate',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_COMMISSION_RATE_TAG:
    'ecom_partner_platform_finance_commission_table_commission_rate_tag',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_COMMISSION_RATE_TIPS:
    'ecom_partner_platform_finance_commission_table_commission_rate_tips',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_COMPLETE_TIME:
    'ecom_partner_platform_finance_commission_table_complete_time',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_CONTENT:
    'ecom_partner_platform_finance_commission_table_content',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_CONTENT_LIVE:
    'ecom_partner_platform_finance_commission_table_content_live',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_CONTENT_SHOWCASE:
    'ecom_partner_platform_finance_commission_table_content_showcase',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_CONTENT_VIDEO:
    'ecom_partner_platform_finance_commission_table_content_video',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_CREATE_TIME:
    'ecom_partner_platform_finance_commission_table_create_time',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_CREATOR:
    'ecom_partner_platform_finance_commission_table_creator',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_EST_COMMISSION_BASE:
    'ecom_partner_platform_finance_commission_table_est_commission_base',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_EST_COMMISSION_BASE_TIPS:
    'ecom_partner_platform_finance_commission_table_est_commission_base_tips',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_EST_COMMISSION_PAYMENT:
    'ecom_partner_platform_finance_commission_table_est_commission_payment',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_EST_COMMISSION_PAYMENT_TIPS:
    'ecom_partner_platform_finance_commission_table_est_commission_payment_tips',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_EXPORT_ALL:
    'ecom_partner_platform_finance_commission_table_export_all',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_EXPORT_ALL_ERROR:
    'ecom_partner_platform_finance_commission_table_export_all_error',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_EXPORT_SELECT:
    'ecom_partner_platform_finance_commission_table_export_select',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_EXPORT_SERVER_ERROR:
    'ecom_partner_platform_finance_commission_table_export_server_error',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_FAILED:
    'ecom_partner_platform_finance_commission_table_failed',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ORDER:
    'ecom_partner_platform_finance_commission_table_order',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ORDER_STATUS:
    'ecom_partner_platform_finance_commission_table_order_status',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ORDER_STATUS_CANCELLED:
    'ecom_partner_platform_finance_commission_table_order_status_cancelled',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ORDER_STATUS_COMPLETED:
    'ecom_partner_platform_finance_commission_table_order_status_completed',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ORDER_STATUS_FAILED:
    'ecom_partner_platform_finance_commission_table_order_status_failed',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ORDER_STATUS_PAID:
    'ecom_partner_platform_finance_commission_table_order_status_paid',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ORDER_STATUS_PENDING:
    'ecom_partner_platform_finance_commission_table_order_status_pending',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_ORDER_STATUS_PROCESSING:
    'ecom_partner_platform_finance_commission_table_order_status_processing',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_PAID:
    'ecom_partner_platform_finance_commission_table_paid',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_PAYMENT:
    'ecom_partner_platform_finance_commission_table_payment',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_PENDING:
    'ecom_partner_platform_finance_commission_table_pending',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_PLAN:
    'ecom_partner_platform_finance_commission_table_plan',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_PRICE:
    'ecom_partner_platform_finance_commission_table_price',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_PRODUCT:
    'ecom_partner_platform_finance_commission_table_product',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_RETURN:
    'ecom_partner_platform_finance_commission_table_return',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_RETURN_TIPS:
    'ecom_partner_platform_finance_commission_table_return_tips',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_SEARCHBOX_DATE:
    'ecom_partner_platform_finance_commission_table_searchbox_date',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_SEARCHBOX_USER:
    'ecom_partner_platform_finance_commission_table_searchbox_user',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TABLE_SETTLE_TIME:
    'ecom_partner_platform_finance_commission_table_settle_time',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TITLE:
    'ecom_partner_platform_finance_commission_title',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TOOLTIPS_BUTTON:
    'ecom_partner_platform_finance_commission_tooltips_button',
  ECOM_PARTNER_PLATFORM_FINANCE_COMMISSION_TOOLTIPS_TEXT:
    'ecom_partner_platform_finance_commission_tooltips_text',
  ECOM_PARTNER_PLATFORM_FINANCE_SETTLEMENT_ORDERS:
    'ecom_partner_platform_finance_settlement_orders',
  ECOM_PARTNER_PLATFORM_FINANCE_SETTLEMENT_ORDERS_DATA_NOT_PAID_REASON_1:
    'ecom_partner_platform_finance_settlement_orders_data_not_paid_reason_1',
  ECOM_PARTNER_PLATFORM_FINANCE_SETTLEMENT_ORDERS_DATA_NOT_PAID_REASON_2:
    'ecom_partner_platform_finance_settlement_orders_data_not_paid_reason_2',
  ECOM_PARTNER_PLATFORM_FINANCE_SETTLEMENT_ORDERS_DATA_NOT_PAID_REASON_3:
    'ecom_partner_platform_finance_settlement_orders_data_not_paid_reason_3',
  ECOM_PARTNER_PLATFORM_FINANCE_SETTLEMENT_ORDERS_TABLE_SETTLEMENT_MCN__PERCENTAGE:
    'ecom_partner_platform_finance_settlement_orders_table_settlement_mcn__percentage',
  ECOM_PARTNER_PLATFORM_FINANCE_SETTLEMENT_ORDERS_TABLE_SETTLEMENT_MCN__TIPS:
    'ecom_partner_platform_finance_settlement_orders_table_settlement_mcn__tips',
  ECOM_PARTNER_PLATFORM_FINANCE_SETTLEMENT_ORDERS_TABLE_SETTLEMENT_TO_MCN:
    'ecom_partner_platform_finance_settlement_orders_table_settlement_to_mcn',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_CANCLE:
    'ecom_partner_platform_livehost_upload_popup_cancle',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_CANCLE_ACTION_BACK:
    'ecom_partner_platform_livehost_upload_popup_cancle_action_back',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_CANCLE_ACTION_CONFIRM:
    'ecom_partner_platform_livehost_upload_popup_cancle_action_confirm',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_CANCLE_DETAIL:
    'ecom_partner_platform_livehost_upload_popup_cancle_detail',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_CANCLE_TITLE:
    'ecom_partner_platform_livehost_upload_popup_cancle_title',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_CITY:
    'ecom_partner_platform_livehost_upload_popup_city',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_CITY_NOTICE:
    'ecom_partner_platform_livehost_upload_popup_city_notice',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_CV:
    'ecom_partner_platform_livehost_upload_popup_cv',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_CV_UPLOAD:
    'ecom_partner_platform_livehost_upload_popup_cv_upload',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_DELETE:
    'ecom_partner_platform_livehost_upload_popup_delete',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_DELETE_ACTION_BACK:
    'ecom_partner_platform_livehost_upload_popup_delete_action_back',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_DELETE_ACTION_CONFIRM:
    'ecom_partner_platform_livehost_upload_popup_delete_action_confirm',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_DELETE_DETAIL:
    'ecom_partner_platform_livehost_upload_popup_delete_detail',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_DELETE_FAIL:
    'ecom_partner_platform_livehost_upload_popup_delete_fail',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_DELETE_SUCCESS:
    'ecom_partner_platform_livehost_upload_popup_delete_success',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_DELETE_TITLE:
    'ecom_partner_platform_livehost_upload_popup_delete_title',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_DOWNLOAD:
    'ecom_partner_platform_livehost_upload_popup_download',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_EMAIL:
    'ecom_partner_platform_livehost_upload_popup_email',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_EMAIL_NOTICE:
    'ecom_partner_platform_livehost_upload_popup_email_notice',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_EXPERIENCE:
    'ecom_partner_platform_livehost_upload_popup_experience',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_EXPERIENCE_NOTICE:
    'ecom_partner_platform_livehost_upload_popup_experience_notice',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_EXPERIENCE_OPTION1:
    'ecom_partner_platform_livehost_upload_popup_experience_option1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_EXPERIENCE_OPTION2:
    'ecom_partner_platform_livehost_upload_popup_experience_option2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_FILE_DELETE:
    'ecom_partner_platform_livehost_upload_popup_file_delete',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_GENDER:
    'ecom_partner_platform_livehost_upload_popup_gender',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_GENDER_NOTICE:
    'ecom_partner_platform_livehost_upload_popup_gender_notice',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_GENDER_OPTION1:
    'ecom_partner_platform_livehost_upload_popup_gender_option1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_GENDER_OPTION2:
    'ecom_partner_platform_livehost_upload_popup_gender_option2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_LANGUAGE_CERTIFICATE_DETAIL1:
    'ecom_partner_platform_livehost_upload_popup_language_certificate_detail1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_LANGUAGE_CERTIFICATE_DETAIL2:
    'ecom_partner_platform_livehost_upload_popup_language_certificate_detail2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_LANGUAGE_CERTIFICATE_DETAIL:
    'ecom_partner_platform_livehost_upload_popup_language_certificate_detail',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_LANGUAGE_CERTIFICATE_TITLE:
    'ecom_partner_platform_livehost_upload_popup_language_certificate_title',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_LANGUAGE_CERTIFICATE_UPLOAD:
    'ecom_partner_platform_livehost_upload_popup_language_certificate_upload',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_LANGUAGE_QUIZ_DETAIL1:
    'ecom_partner_platform_livehost_upload_popup_language_quiz_detail1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_LANGUAGE_QUIZ_DETAIL2:
    'ecom_partner_platform_livehost_upload_popup_language_quiz_detail2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_LANGUAGE_QUIZ_TITLE:
    'ecom_partner_platform_livehost_upload_popup_language_quiz_title',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_LANGUAGE_QUIZ_UPLOAD:
    'ecom_partner_platform_livehost_upload_popup_language_quiz_upload',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_NAME:
    'ecom_partner_platform_livehost_upload_popup_name',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_NAME_NOTICE:
    'ecom_partner_platform_livehost_upload_popup_name_notice',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_NATIONALITY:
    'ecom_partner_platform_livehost_upload_popup_nationality',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_NATIONALITY_NOTICE:
    'ecom_partner_platform_livehost_upload_popup_nationality_notice',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_PREVIEW:
    'ecom_partner_platform_livehost_upload_popup_preview',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_PRODUCT:
    'ecom_partner_platform_livehost_upload_popup_product',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_PRODUCT_NOTICE:
    'ecom_partner_platform_livehost_upload_popup_product_notice',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_PRODUCT_OPTION1:
    'ecom_partner_platform_livehost_upload_popup_product_option1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_PRODUCT_OPTION2:
    'ecom_partner_platform_livehost_upload_popup_product_option2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_PRODUCT_OPTION3:
    'ecom_partner_platform_livehost_upload_popup_product_option3',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_PRODUCT_OPTION4:
    'ecom_partner_platform_livehost_upload_popup_product_option4',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_PRODUCT_OPTION5:
    'ecom_partner_platform_livehost_upload_popup_product_option5',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_PRODUCT_OTHERS:
    'ecom_partner_platform_livehost_upload_popup_product_others',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_REUPLOAD:
    'ecom_partner_platform_livehost_upload_popup_reupload',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_REUPLOAD_ACTION_BACK:
    'ecom_partner_platform_livehost_upload_popup_reupload_action_back',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_REUPLOAD_ACTION_CONFIRM:
    'ecom_partner_platform_livehost_upload_popup_reupload_action_confirm',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_REUPLOAD_DETAIL:
    'ecom_partner_platform_livehost_upload_popup_reupload_detail',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_REUPLOAD_TITLE:
    'ecom_partner_platform_livehost_upload_popup_reupload_title',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SALARY1:
    'ecom_partner_platform_livehost_upload_popup_salary1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SALARY1_OPTION1:
    'ecom_partner_platform_livehost_upload_popup_salary1_option1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SALARY1_OPTION2:
    'ecom_partner_platform_livehost_upload_popup_salary1_option2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SALARY2:
    'ecom_partner_platform_livehost_upload_popup_salary2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SALARY2_OPTION1:
    'ecom_partner_platform_livehost_upload_popup_salary2_option1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SALARY2_OPTION2:
    'ecom_partner_platform_livehost_upload_popup_salary2_option2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SALARY:
    'ecom_partner_platform_livehost_upload_popup_salary',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SALARY_NOTICE:
    'ecom_partner_platform_livehost_upload_popup_salary_notice',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SUBMIT:
    'ecom_partner_platform_livehost_upload_popup_submit',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SUBMIT_ACTION_BACK:
    'ecom_partner_platform_livehost_upload_popup_submit_action_back',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SUBMIT_ACTION_CONFIRM:
    'ecom_partner_platform_livehost_upload_popup_submit_action_confirm',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SUBMIT_DETAIL:
    'ecom_partner_platform_livehost_upload_popup_submit_detail',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_SUBMIT_TITLE:
    'ecom_partner_platform_livehost_upload_popup_submit_title',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TITLE:
    'ecom_partner_platform_livehost_upload_popup_title',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_EMPTY_EMAIL:
    'ecom_partner_platform_livehost_upload_popup_toast_empty_email',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_EMPTY_NAME:
    'ecom_partner_platform_livehost_upload_popup_toast_empty_name',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_FORMAT:
    'ecom_partner_platform_livehost_upload_popup_toast_format',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_LENGTH:
    'ecom_partner_platform_livehost_upload_popup_toast_length',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_SIZE:
    'ecom_partner_platform_livehost_upload_popup_toast_size',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_UPLOADING1:
    'ecom_partner_platform_livehost_upload_popup_toast_uploading1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_UPLOADING2:
    'ecom_partner_platform_livehost_upload_popup_toast_uploading2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_UPLOAD_FAILURE1:
    'ecom_partner_platform_livehost_upload_popup_toast_upload_failure1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_UPLOAD_FAILURE2:
    'ecom_partner_platform_livehost_upload_popup_toast_upload_failure2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_TOAST_UPLOAD_SUCCESS:
    'ecom_partner_platform_livehost_upload_popup_toast_upload_success',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_VIDEO_DETAIL1:
    'ecom_partner_platform_livehost_upload_popup_video_detail1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_VIDEO_DETAIL2:
    'ecom_partner_platform_livehost_upload_popup_video_detail2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_VIDEO_DETAIL3:
    'ecom_partner_platform_livehost_upload_popup_video_detail3',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_VIDEO_DETAIL4:
    'ecom_partner_platform_livehost_upload_popup_video_detail4',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_VIDEO_DETAIL5:
    'ecom_partner_platform_livehost_upload_popup_video_detail5',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_VIDEO_DETAIL:
    'ecom_partner_platform_livehost_upload_popup_video_detail',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_VIDEO_TITLE:
    'ecom_partner_platform_livehost_upload_popup_video_title',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_VIDEO_UPLOAD:
    'ecom_partner_platform_livehost_upload_popup_video_upload',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_WORK:
    'ecom_partner_platform_livehost_upload_popup_work',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_WORK_NOTICE:
    'ecom_partner_platform_livehost_upload_popup_work_notice',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_WORK_OPTION1:
    'ecom_partner_platform_livehost_upload_popup_work_option1',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_WORK_OPTION2:
    'ecom_partner_platform_livehost_upload_popup_work_option2',
  ECOM_PARTNER_PLATFORM_LIVEHOST_UPLOAD_POPUP_WORK_OPTION3:
    'ecom_partner_platform_livehost_upload_popup_work_option3',
  ECOM_PARTNER_PLATFORM_LOGIN_ALTER_SIGNUP2:
    'ecom_partner_platform_login_alter_signup2',
  ECOM_PARTNER_PLATFORM_LOGIN_ALTER_SIGNUP:
    'ecom_partner_platform_login_alter_signup',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL: 'ecom_partner_platform_login_email',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_ALTER_TO_CODE:
    'ecom_partner_platform_login_email_alter_to_code',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_ALTER_TO_PASSWORD:
    'ecom_partner_platform_login_email_alter_to_password',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_ALTER_TO_PHONE_NUMBER:
    'ecom_partner_platform_login_email_alter_to_phone_number',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_ALTER_TO_TIKTOK:
    'ecom_partner_platform_login_email_alter_to_tiktok',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_CODE_NOTICE:
    'ecom_partner_platform_login_email_code_notice',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_LOGIN:
    'ecom_partner_platform_login_email_login',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_NOTICE:
    'ecom_partner_platform_login_email_notice',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_OR: 'ecom_partner_platform_login_email_or',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_PASSWORD:
    'ecom_partner_platform_login_email_password',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_PASSWORD_FORGET:
    'ecom_partner_platform_login_email_password_forget',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_PASSWORD_NOTICE:
    'ecom_partner_platform_login_email_password_notice',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_login_email_send_verification_code',
  ECOM_PARTNER_PLATFORM_LOGIN_EMAIL_VERIFICATION_CODE:
    'ecom_partner_platform_login_email_verification_code',
  ECOM_PARTNER_PLATFORM_LOGIN_GENERAL_TOAST1:
    'ecom_partner_platform_login_general_toast1',
  ECOM_PARTNER_PLATFORM_LOGIN_GENERAL_TOAST2:
    'ecom_partner_platform_login_general_toast2',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER:
    'ecom_partner_platform_login_phone_number',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_ALTER_TO_CODE:
    'ecom_partner_platform_login_phone_number_alter_to_code',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_ALTER_TO_EMAIL:
    'ecom_partner_platform_login_phone_number_alter_to_email',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_ALTER_TO_PASSWORD:
    'ecom_partner_platform_login_phone_number_alter_to_password',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_ALTER_TO_TIKTOK:
    'ecom_partner_platform_login_phone_number_alter_to_tiktok',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_LOGIN:
    'ecom_partner_platform_login_phone_number_login',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_NOTICE:
    'ecom_partner_platform_login_phone_number_notice',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_OR:
    'ecom_partner_platform_login_phone_number_or',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_PASSWORD:
    'ecom_partner_platform_login_phone_number_password',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_PASSWORD_FORGET:
    'ecom_partner_platform_login_phone_number_password_forget',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_PASSWORD_NOTICE:
    'ecom_partner_platform_login_phone_number_password_notice',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_login_phone_number_send_verification_code',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_NUMBER_VERIFICATION_CODE:
    'ecom_partner_platform_login_phone_number_verification_code',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_PASSWORD_ACCOUNT_WRONG:
    'ecom_partner_platform_login_phone_password_account_wrong',
  ECOM_PARTNER_PLATFORM_LOGIN_PHONE_PASSWORD_EMPTY:
    'ecom_partner_platform_login_phone_password_empty',
  ECOM_PARTNER_PLATFORM_LOG_IN2: 'ecom_partner_platform_log_in2',
  ECOM_PARTNER_PLATFORM_LOG_IN: 'ecom_partner_platform_log_in',
  ECOM_PARTNER_PLATFORM_LOG_IN_CHOOSE_ROLE_ACTION:
    'ecom_partner_platform_log_in_choose_role_action',
  ECOM_PARTNER_PLATFORM_LOG_IN_CHOOSE_ROLE_BASE:
    'ecom_partner_platform_log_in_choose_role_base',
  ECOM_PARTNER_PLATFORM_LOG_IN_CHOOSE_ROLE_TIPS:
    'ecom_partner_platform_log_in_choose_role_tips',
  ECOM_PARTNER_PLATFORM_LOG_IN_CHOOSE_ROLE_TITLE:
    'ecom_partner_platform_log_in_choose_role_title',
  ECOM_PARTNER_PLATFORM_MANAGE_CREATOR_COMMISSION_AUTHORIZATION:
    'ecom_partner_platform_manage_creator_commission_authorization',
  ECOM_PARTNER_PLATFORM_MANAGE_CREATOR_LINK_MARKET_FAILURE:
    'ecom_partner_platform_manage_creator_link_market_failure',
  ECOM_PARTNER_PLATFORM_MANAGE_CREATOR_SETTLEMENT_AGREEMENT:
    'ecom_partner_platform_manage_creator_settlement_agreement',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST:
    'ecom_partner_platform_manage_livehost',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_ACTION_AVAILABLE:
    'ecom_partner_platform_manage_livehost_action_available',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_ACTION_UNAVAILABLE:
    'ecom_partner_platform_manage_livehost_action_unavailable',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_ACTION_VIEW:
    'ecom_partner_platform_manage_livehost_action_view',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_ADD_NEW:
    'ecom_partner_platform_manage_livehost_add_new',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_EMPTY_NOTICE:
    'ecom_partner_platform_manage_livehost_empty_notice',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_HEAD_ALL:
    'ecom_partner_platform_manage_livehost_head_all',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_HEAD_PENDING:
    'ecom_partner_platform_manage_livehost_head_pending',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_HEAD_QUALIFIED:
    'ecom_partner_platform_manage_livehost_head_qualified',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_HEAD_UNQUALIFIED:
    'ecom_partner_platform_manage_livehost_head_unqualified',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_SEARCH:
    'ecom_partner_platform_manage_livehost_search',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_TABLE_CITY:
    'ecom_partner_platform_manage_livehost_table_city',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_TABLE_DATE:
    'ecom_partner_platform_manage_livehost_table_date',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_TABLE_DETAIL:
    'ecom_partner_platform_manage_livehost_table_detail',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_TABLE_NAME:
    'ecom_partner_platform_manage_livehost_table_name',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_TABLE_NATIONALITY:
    'ecom_partner_platform_manage_livehost_table_nationality',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_TABLE_QUALIFICATION:
    'ecom_partner_platform_manage_livehost_table_qualification',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_TABLE_STATUS:
    'ecom_partner_platform_manage_livehost_table_status',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_TABLE_STATUS_NOTICE:
    'ecom_partner_platform_manage_livehost_table_status_notice',
  ECOM_PARTNER_PLATFORM_MANAGE_LIVEHOST_TITLE:
    'ecom_partner_platform_manage_livehost_title',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP:
    'ecom_partner_platform_manage_partnership',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_ACCEPTED:
    'ecom_partner_platform_manage_partnership_accepted',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_ACTION_RENEW:
    'ecom_partner_platform_manage_partnership_action_renew',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_ACTION_RENEW_NOTICE1:
    'ecom_partner_platform_manage_partnership_action_renew_notice1',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_ACTION_RENEW_NOTICE2:
    'ecom_partner_platform_manage_partnership_action_renew_notice2',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_ACTION_RENEW_NOTICE3:
    'ecom_partner_platform_manage_partnership_action_renew_notice3',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_ACTION_RENEW_NOTICE4:
    'ecom_partner_platform_manage_partnership_action_renew_notice4',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_ADD_NEW_DATE_NOTICE:
    'ecom_partner_platform_manage_partnership_add_new_date_notice',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_DECLINED:
    'ecom_partner_platform_manage_partnership_declined',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_EMPTY_NOTICE:
    'ecom_partner_platform_manage_partnership_empty_notice',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_EXPIRED:
    'ecom_partner_platform_manage_partnership_expired',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_EXTEND_POPUP_TITLE:
    'ecom_partner_platform_manage_partnership_extend_popup_title',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_ALL1:
    'ecom_partner_platform_manage_partnership_head_all1',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_ALL:
    'ecom_partner_platform_manage_partnership_head_all',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_BOUND:
    'ecom_partner_platform_manage_partnership_head_bound',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_PENDING_RELEASE:
    'ecom_partner_platform_manage_partnership_head_pending_release',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_PENDING_REQUEST:
    'ecom_partner_platform_manage_partnership_head_pending_request',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_UNBOUND:
    'ecom_partner_platform_manage_partnership_head_unbound',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_UNBOUND_BINDING_EXPIRED:
    'ecom_partner_platform_manage_partnership_head_unbound_binding_expired',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_UNBOUND_DECLINED:
    'ecom_partner_platform_manage_partnership_head_unbound_declined',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_UNBOUND_REQUEST_EXPIRED:
    'ecom_partner_platform_manage_partnership_head_unbound_request_expired',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_HEAD_UNBOUND_UNLINK:
    'ecom_partner_platform_manage_partnership_head_unbound_unlink',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_PENDING:
    'ecom_partner_platform_manage_partnership_pending',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEARCH1:
    'ecom_partner_platform_manage_partnership_search1',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEARCH:
    'ecom_partner_platform_manage_partnership_search',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_NEW:
    'ecom_partner_platform_manage_partnership_send_new',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_ACCOUNT:
    'ecom_partner_platform_manage_partnership_send_popup_account',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_ACCOUNT_NOTICE:
    'ecom_partner_platform_manage_partnership_send_popup_account_notice',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_CANCEL:
    'ecom_partner_platform_manage_partnership_send_popup_cancel',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_COMPANY:
    'ecom_partner_platform_manage_partnership_send_popup_company',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_COMPANY_NOTICE:
    'ecom_partner_platform_manage_partnership_send_popup_company_notice',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_DETAILS:
    'ecom_partner_platform_manage_partnership_send_popup_details',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_EXPIRATION:
    'ecom_partner_platform_manage_partnership_send_popup_expiration',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_FAILURE:
    'ecom_partner_platform_manage_partnership_send_popup_failure',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_NAME:
    'ecom_partner_platform_manage_partnership_send_popup_name',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_NAME_NOTICE:
    'ecom_partner_platform_manage_partnership_send_popup_name_notice',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_SUBMIT:
    'ecom_partner_platform_manage_partnership_send_popup_submit',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_SUCCESS:
    'ecom_partner_platform_manage_partnership_send_popup_success',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TIME:
    'ecom_partner_platform_manage_partnership_send_popup_time',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TIME_NOTICE1:
    'ecom_partner_platform_manage_partnership_send_popup_time_notice1',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TIME_NOTICE2:
    'ecom_partner_platform_manage_partnership_send_popup_time_notice2',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TIME_NOTICE3:
    'ecom_partner_platform_manage_partnership_send_popup_time_notice3',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TITLE:
    'ecom_partner_platform_manage_partnership_send_popup_title',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_CHARACTER:
    'ecom_partner_platform_manage_partnership_send_popup_toast_character',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_EMPTY_ACCOUNT:
    'ecom_partner_platform_manage_partnership_send_popup_toast_empty_account',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_EMPTY_COMPANY:
    'ecom_partner_platform_manage_partnership_send_popup_toast_empty_company',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_EMPTY_NAME:
    'ecom_partner_platform_manage_partnership_send_popup_toast_empty_name',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_EMPTY_TIME:
    'ecom_partner_platform_manage_partnership_send_popup_toast_empty_time',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_INVALID:
    'ecom_partner_platform_manage_partnership_send_popup_toast_invalid',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_PERMISSION:
    'ecom_partner_platform_manage_partnership_send_popup_toast_permission',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_QUALIFICATION:
    'ecom_partner_platform_manage_partnership_send_popup_toast_qualification',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_REQUIRED1:
    'ecom_partner_platform_manage_partnership_send_popup_toast_required1',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_REQUIRED:
    'ecom_partner_platform_manage_partnership_send_popup_toast_required',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_SEND_POPUP_TOAST_TIME:
    'ecom_partner_platform_manage_partnership_send_popup_toast_time',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_ACCOUNT:
    'ecom_partner_platform_manage_partnership_table_account',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_ACTION:
    'ecom_partner_platform_manage_partnership_table_action',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_BOUND_STATUS:
    'ecom_partner_platform_manage_partnership_table_bound_status',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_BOUND_SUBSTATUS_BINDING_EXPIRED:
    'ecom_partner_platform_manage_partnership_table_bound_substatus_binding_expired',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_BOUND_SUBSTATUS_DECLINED:
    'ecom_partner_platform_manage_partnership_table_bound_substatus_declined',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_BOUND_SUBSTATUS_REQUEST_EXPIRED:
    'ecom_partner_platform_manage_partnership_table_bound_substatus_request_expired',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_BOUND_SUBSTATUS_UNLINKED:
    'ecom_partner_platform_manage_partnership_table_bound_substatus_unlinked',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_COMPANY:
    'ecom_partner_platform_manage_partnership_table_company',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_CREATOR:
    'ecom_partner_platform_manage_partnership_table_creator',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_DATE:
    'ecom_partner_platform_manage_partnership_table_date',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_DATE_EXPIRE:
    'ecom_partner_platform_manage_partnership_table_date_expire',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_DATE_NOTICE:
    'ecom_partner_platform_manage_partnership_table_date_notice',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_EXPIRATION_REMINDER1:
    'ecom_partner_platform_manage_partnership_table_expiration_reminder1',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_EXPIRATION_REMINDER2:
    'ecom_partner_platform_manage_partnership_table_expiration_reminder2',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_NAME:
    'ecom_partner_platform_manage_partnership_table_name',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TABLE_STATUS:
    'ecom_partner_platform_manage_partnership_table_status',
  ECOM_PARTNER_PLATFORM_MANAGE_PARTNERSHIP_TITLE:
    'ecom_partner_platform_manage_partnership_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER: 'ecom_partner_platform_message_center',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_ALL:
    'ecom_partner_platform_message_center_all',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_ALLREAD:
    'ecom_partner_platform_message_center_allread',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_ANNOUNCEMENT_EMPTY:
    'ecom_partner_platform_message_center_announcement_empty',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_ERROR:
    'ecom_partner_platform_message_center_error',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_ERROR_EXPLICATION:
    'ecom_partner_platform_message_center_error_explication',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_ERROR_RETRY:
    'ecom_partner_platform_message_center_error_retry',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_FINANCE_COMMISSION_BODY:
    'ecom_partner_platform_message_center_finance_commission_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_FINANCE_COMMISSION_TITLE:
    'ecom_partner_platform_message_center_finance_commission_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_FINANCE_SETTLEMENT_BODY:
    'ecom_partner_platform_message_center_finance_settlement_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_FINANCE_SETTLEMENT_TITLE:
    'ecom_partner_platform_message_center_finance_settlement_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_CANCELLATION_BODY:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_cancellation_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_CANCELLATION_RECALL_BODY:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_cancellation_recall_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_CANCELLATION_RECALL_TITLE:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_cancellation_recall_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_CANCELLATION_TITLE:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_cancellation_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_FAILURE_BODY:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_failure_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_FAILURE_TITLE:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_failure_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_PARTNER_CANCELLATION_FAILURE_BODY:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_partner_cancellation_failure_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_PARTNER_CANCELLATION_FAILURE_TITLE:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_partner_cancellation_failure_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_PARTNER_CANCELLATION_SUCCESS_BODY:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_partner_cancellation_success_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_PARTNER_CANCELLATION_SUCCESS_TITLE:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_partner_cancellation_success_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_SUCCESS_BODY:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_success_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_COMMISSION_AUTHORIZATION_SUCCESS_TITLE:
    'ecom_partner_platform_message_center_manage_creators_commission_authorization_success_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_DATA_AUTHORIZATION_FAILURE_BODY:
    'ecom_partner_platform_message_center_manage_creators_data_authorization_failure_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_DATA_AUTHORIZATION_FAILURE_TITLE:
    'ecom_partner_platform_message_center_manage_creators_data_authorization_failure_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_DATA_AUTHORIZATION_SUCCESS_BODY:
    'ecom_partner_platform_message_center_manage_creators_data_authorization_success_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_DATA_AUTHORIZATION_SUCCESS_TITLE:
    'ecom_partner_platform_message_center_manage_creators_data_authorization_success_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_EXPIRED_BODY:
    'ecom_partner_platform_message_center_manage_creators_link_expired_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_EXPIRED_TITLE:
    'ecom_partner_platform_message_center_manage_creators_link_expired_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_EXPIRE_REMIND_BODY:
    'ecom_partner_platform_message_center_manage_creators_link_expire_remind_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_EXPIRE_REMIND_TITLE:
    'ecom_partner_platform_message_center_manage_creators_link_expire_remind_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_FAIL_REFUSE_BODY:
    'ecom_partner_platform_message_center_manage_creators_link_fail_refuse_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_FAIL_REFUSE_TITLE:
    'ecom_partner_platform_message_center_manage_creators_link_fail_refuse_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_FAIL_UNRESPONSE_BODY:
    'ecom_partner_platform_message_center_manage_creators_link_fail_unresponse_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_FAIL_UNRESPONSE_TITLE:
    'ecom_partner_platform_message_center_manage_creators_link_fail_unresponse_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_SUCCESS_BODY:
    'ecom_partner_platform_message_center_manage_creators_link_success_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_LINK_SUCCESS_TITLE:
    'ecom_partner_platform_message_center_manage_creators_link_success_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_CANCELLATION_BODY:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_cancellation_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_CANCELLATION_RECALL_BODY:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_cancellation_recall_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_CANCELLATION_RECALL_TITLE:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_cancellation_recall_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_CANCELLATION_TITLE:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_cancellation_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_FAILURE_BODY:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_failure_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_FAILURE_TITLE:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_failure_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_PARTNER_CANCELLATION_FAILURE_BODY:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_partner_cancellation_failure_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_PARTNER_CANCELLATION_FAILURE_TITLE:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_partner_cancellation_failure_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_PARTNER_CANCELLATION_SUCCESS_BODY:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_partner_cancellation_success_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_PARTNER_CANCELLATION_SUCCESS_TITLE:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_partner_cancellation_success_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_SUCCESS_BODY:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_success_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_SETTLEMENT_AGREEMENT_SUCCESS_TITLE:
    'ecom_partner_platform_message_center_manage_creators_settlement_agreement_success_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_UNLINK_REQUEST_BODY:
    'ecom_partner_platform_message_center_manage_creators_unlink_request_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_UNLINK_REQUEST_RECALL_BODY:
    'ecom_partner_platform_message_center_manage_creators_unlink_request_recall_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_UNLINK_REQUEST_RECALL_TITLE:
    'ecom_partner_platform_message_center_manage_creators_unlink_request_recall_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MANAGE_CREATORS_UNLINK_REQUEST_TITLE:
    'ecom_partner_platform_message_center_manage_creators_unlink_request_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_ACTION_GO:
    'ecom_partner_platform_message_center_message_action_go',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TAG_IMPORTANT:
    'ecom_partner_platform_message_center_message_tag_important',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_BUTTON_CANCEL:
    'ecom_partner_platform_message_center_message_type_button_cancel',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_BUTTON_SAVE:
    'ecom_partner_platform_message_center_message_type_button_save',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_FIRST_ANNOUNCEMENT:
    'ecom_partner_platform_message_center_message_type_first_announcement',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_FIRST_FINANCE:
    'ecom_partner_platform_message_center_message_type_first_finance',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_FIRST_NEWFEATURE:
    'ecom_partner_platform_message_center_message_type_first_newfeature',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_FIRST_PARTNERSHIPS:
    'ecom_partner_platform_message_center_message_type_first_partnerships',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_FIRST_TALENT:
    'ecom_partner_platform_message_center_message_type_first_talent',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_FIRST_VIOLATION:
    'ecom_partner_platform_message_center_message_type_first_violation',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_CAMPAIGN:
    'ecom_partner_platform_message_center_message_type_second_campaign',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_COMMISSION:
    'ecom_partner_platform_message_center_message_type_second_commission',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_COMMISSION_AUTHORIZATION:
    'ecom_partner_platform_message_center_message_type_second_commission_authorization',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_DATA_AUTHORIZATION:
    'ecom_partner_platform_message_center_message_type_second_data_authorization',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_INCENTIVE:
    'ecom_partner_platform_message_center_message_type_second_incentive',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_MANAGE_CREATOR:
    'ecom_partner_platform_message_center_message_type_second_manage_creator',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_QUESTIONNAIRE:
    'ecom_partner_platform_message_center_message_type_second_questionnaire',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_SETTLEMENT:
    'ecom_partner_platform_message_center_message_type_second_settlement',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_SETTLEMENT_AGREEMENT:
    'ecom_partner_platform_message_center_message_type_second_settlement_agreement',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_SECOND_TERMINATION:
    'ecom_partner_platform_message_center_message_type_second_termination',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_MESSAGE_TYPE_UNSUBSCRIBE:
    'ecom_partner_platform_message_center_message_type_unsubscribe',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_NEW_MESSAGE:
    'ecom_partner_platform_message_center_new_message',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_NOMESSAGE:
    'ecom_partner_platform_message_center_nomessage',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_POPUP_AGREE:
    'ecom_partner_platform_message_center_popup_agree',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_POPUP_BODY:
    'ecom_partner_platform_message_center_popup_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_POPUP_SKIP:
    'ecom_partner_platform_message_center_popup_skip',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_POPUP_TIPS:
    'ecom_partner_platform_message_center_popup_tips',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_POPUP_TITLE:
    'ecom_partner_platform_message_center_popup_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_SETTINGS_ACTION:
    'ecom_partner_platform_message_center_settings_action',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_SETTINGS_SAVE_FALIOR:
    'ecom_partner_platform_message_center_settings_save_falior',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_SETTINGS_SAVE_SUCCESS:
    'ecom_partner_platform_message_center_settings_save_success',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_SETTINGS_TITLE:
    'ecom_partner_platform_message_center_settings_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_SETTINGS_WELCOME:
    'ecom_partner_platform_message_center_settings_welcome',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_TALENT_COLLABORATION_FAILURE_BODY:
    'ecom_partner_platform_message_center_talent_collaboration_failure_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_TALENT_COLLABORATION_FAILURE_TITLE:
    'ecom_partner_platform_message_center_talent_collaboration_failure_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_TALENT_COLLABORATION_SUCCESS_BODY:
    'ecom_partner_platform_message_center_talent_collaboration_success_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_TALENT_COLLABORATION_SUCCESS_TITLE:
    'ecom_partner_platform_message_center_talent_collaboration_success_title',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_UNREAD:
    'ecom_partner_platform_message_center_unread',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_VIOLATION_TERMINATION_BODY:
    'ecom_partner_platform_message_center_violation_termination_body',
  ECOM_PARTNER_PLATFORM_MESSAGE_CENTER_VIOLATION_TERMINATION_TITLE:
    'ecom_partner_platform_message_center_violation_termination_title',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_CONFIRM:
    'ecom_partner_platform_reset_password_confirm',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_CONFIRM_NOTICE:
    'ecom_partner_platform_reset_password_confirm_notice',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_EMAIL:
    'ecom_partner_platform_reset_password_email',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_NEW:
    'ecom_partner_platform_reset_password_new',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_NEW_NOTICE:
    'ecom_partner_platform_reset_password_new_notice',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_NEW_RULE:
    'ecom_partner_platform_reset_password_new_rule',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_PHONE:
    'ecom_partner_platform_reset_password_phone',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_PHONE_NUMBER:
    'ecom_partner_platform_reset_password_phone_number',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_PHONE_NUMBER_CODE_NOTICE:
    'ecom_partner_platform_reset_password_phone_number_code_notice',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_PHONE_NUMBER_NOTICE:
    'ecom_partner_platform_reset_password_phone_number_notice',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_PHONE_NUMBER_SEND_CODE:
    'ecom_partner_platform_reset_password_phone_number_send_code',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_PHONE_NUMBER_VERIFICATION_CODE:
    'ecom_partner_platform_reset_password_phone_number_verification_code',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_RESET_TOAST:
    'ecom_partner_platform_reset_password_reset_toast',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_SUBMIT:
    'ecom_partner_platform_reset_password_submit',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_SUBMIT_EMAIL_NONEXIST:
    'ecom_partner_platform_reset_password_submit_email_nonexist',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_SUBMIT_PHONE_NONEXIST:
    'ecom_partner_platform_reset_password_submit_phone_nonexist',
  ECOM_PARTNER_PLATFORM_RESET_PASSWORD_TITLE:
    'ecom_partner_platform_reset_password_title',
  ECOM_PARTNER_PLATFORM_SEARCHBOX_NETWORK_ERROR:
    'ecom_partner_platform_searchbox_network_error',
  ECOM_PARTNER_PLATFORM_SEARCHBOX_NETWORK_ERROR_TEXT:
    'ecom_partner_platform_searchbox_network_error_text',
  ECOM_PARTNER_PLATFORM_SEARCHBOX_SERVER_ERROR:
    'ecom_partner_platform_searchbox_server_error',
  ECOM_PARTNER_PLATFORM_SEARCHBOX_SERVER_ERROR_TEXT:
    'ecom_partner_platform_searchbox_server_error_text',
  ECOM_PARTNER_PLATFORM_SEARCHBOX_USERNAME_ERROR:
    'ecom_partner_platform_searchbox_username_error',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_BACK:
    'ecom_partner_platform_settle_in_action_back',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_BACK_INCOMPLETE:
    'ecom_partner_platform_settle_in_action_back_incomplete',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_NEXT:
    'ecom_partner_platform_settle_in_action_next',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SAVE:
    'ecom_partner_platform_settle_in_action_save',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SAVE_FAILURE:
    'ecom_partner_platform_settle_in_action_save_failure',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SAVE_SUCCESS:
    'ecom_partner_platform_settle_in_action_save_success',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SUBMIT:
    'ecom_partner_platform_settle_in_action_submit',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SUBMIT_FAILURE2:
    'ecom_partner_platform_settle_in_action_submit_failure2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SUBMIT_FAILURE:
    'ecom_partner_platform_settle_in_action_submit_failure',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SUBMIT_POPUP_BODY:
    'ecom_partner_platform_settle_in_action_submit_popup_body',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SUBMIT_POPUP_CANCEL:
    'ecom_partner_platform_settle_in_action_submit_popup_cancel',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SUBMIT_POPUP_CONFIRM:
    'ecom_partner_platform_settle_in_action_submit_popup_confirm',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SUBMIT_POPUP_TITLE:
    'ecom_partner_platform_settle_in_action_submit_popup_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ACTION_SUBMIT_SUCCES:
    'ecom_partner_platform_settle_in_action_submit_succes',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION:
    'ecom_partner_platform_settle_in_basic_business_information',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_BUSINESS_ADDRESS:
    'ecom_partner_platform_settle_in_basic_business_information_business_address',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_BUSINESS_ADDRESS_NOTICE3:
    'ecom_partner_platform_settle_in_basic_business_information_business_address_notice3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_BUSINESS_ADDRESS_TIPS:
    'ecom_partner_platform_settle_in_basic_business_information_business_address_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_BUSINESS_SCOPE:
    'ecom_partner_platform_settle_in_basic_business_information_business_scope',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_BUSINESS_SCOPE_NOTICE:
    'ecom_partner_platform_settle_in_basic_business_information_business_scope_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_BUSINESS_SCOPE_TIPS:
    'ecom_partner_platform_settle_in_basic_business_information_business_scope_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_BACKGROUND:
    'ecom_partner_platform_settle_in_basic_business_information_company_background',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_BACKGROUND_TIPS:
    'ecom_partner_platform_settle_in_basic_business_information_company_background_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_INTRODUCTION:
    'ecom_partner_platform_settle_in_basic_business_information_company_introduction',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_INTRODUCTION_TIPS1:
    'ecom_partner_platform_settle_in_basic_business_information_company_introduction_tips1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_INTRODUCTION_TIPS2:
    'ecom_partner_platform_settle_in_basic_business_information_company_introduction_tips2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_INTRODUCTION_UPLOAD:
    'ecom_partner_platform_settle_in_basic_business_information_company_introduction_upload',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_LOGO:
    'ecom_partner_platform_settle_in_basic_business_information_company_logo',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_LOGO_TIPS:
    'ecom_partner_platform_settle_in_basic_business_information_company_logo_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_LOGO_UPLOAD:
    'ecom_partner_platform_settle_in_basic_business_information_company_logo_upload',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_NAME:
    'ecom_partner_platform_settle_in_basic_business_information_company_name',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_NAME_NOTICE:
    'ecom_partner_platform_settle_in_basic_business_information_company_name_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_COMPANY_NAME_TIPS:
    'ecom_partner_platform_settle_in_basic_business_information_company_name_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_SERVICE_INDUSTRY:
    'ecom_partner_platform_settle_in_basic_business_information_service_industry',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_SERVICE_INDUSTRY_NOTICE:
    'ecom_partner_platform_settle_in_basic_business_information_service_industry_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_BUSINESS_INFORMATION_SERVICE_INDUSTRY_TIPS:
    'ecom_partner_platform_settle_in_basic_business_information_service_industry_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_CONTACT_INFORMATION:
    'ecom_partner_platform_settle_in_basic_contact_information',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_CONTACT_INFORMATION_EMAIL:
    'ecom_partner_platform_settle_in_basic_contact_information_email',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_CONTACT_INFORMATION_EMAIL_NOTICE:
    'ecom_partner_platform_settle_in_basic_contact_information_email_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_CONTACT_INFORMATION_PERSON:
    'ecom_partner_platform_settle_in_basic_contact_information_person',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_CONTACT_INFORMATION_PERSON_NOTICE:
    'ecom_partner_platform_settle_in_basic_contact_information_person_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_CONTACT_INFORMATION_PHONE_NUMBER:
    'ecom_partner_platform_settle_in_basic_contact_information_phone_number',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_CONTACT_INFORMATION_PHONE_NUMBER_NOTICE:
    'ecom_partner_platform_settle_in_basic_contact_information_phone_number_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_CONTACT_INFORMATION_TIP:
    'ecom_partner_platform_settle_in_basic_contact_information_tip',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION:
    'ecom_partner_platform_settle_in_basic_operation_information',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CONTENT_EXPERIENCE:
    'ecom_partner_platform_settle_in_basic_operation_information_content_experience',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CONTENT_EXPERIENCE_TIP1:
    'ecom_partner_platform_settle_in_basic_operation_information_content_experience_tip1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CONTENT_EXPERIENCE_TIP2:
    'ecom_partner_platform_settle_in_basic_operation_information_content_experience_tip2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CREATOR_ACCOUNT:
    'ecom_partner_platform_settle_in_basic_operation_information_creator_account',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CREATOR_ACCOUNT_NOTICE:
    'ecom_partner_platform_settle_in_basic_operation_information_creator_account_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CREATOR_ACCOUNT_TIPS:
    'ecom_partner_platform_settle_in_basic_operation_information_creator_account_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CREATOR_AGREEMENT:
    'ecom_partner_platform_settle_in_basic_operation_information_creator_agreement',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CREATOR_AGREEMENT_TIPS1:
    'ecom_partner_platform_settle_in_basic_operation_information_creator_agreement_tips1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CREATOR_AGREEMENT_TIPS2:
    'ecom_partner_platform_settle_in_basic_operation_information_creator_agreement_tips2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_CREATOR_AGREEMENT_UPLOAD:
    'ecom_partner_platform_settle_in_basic_operation_information_creator_agreement_upload',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_MERCHANT_ACCOUNT:
    'ecom_partner_platform_settle_in_basic_operation_information_merchant_account',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_MERCHANT_ACCOUNT_NOTICE:
    'ecom_partner_platform_settle_in_basic_operation_information_merchant_account_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_MERCHANT_AGREEMENT:
    'ecom_partner_platform_settle_in_basic_operation_information_merchant_agreement',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_MERCHANT_AGREEMENT_TIP1:
    'ecom_partner_platform_settle_in_basic_operation_information_merchant_agreement_tip1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_MERCHANT_AGREEMENT_TIP2:
    'ecom_partner_platform_settle_in_basic_operation_information_merchant_agreement_tip2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_MERCHANT_AGREEMENT_UPLOAD:
    'ecom_partner_platform_settle_in_basic_operation_information_merchant_agreement_upload',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_REFERRAL_BONUS:
    'ecom_partner_platform_settle_in_basic_operation_information_referral_bonus',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_REFERRAL_BONUS_OPTION1:
    'ecom_partner_platform_settle_in_basic_operation_information_referral_bonus_option1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_REFERRAL_BONUS_OPTION2:
    'ecom_partner_platform_settle_in_basic_operation_information_referral_bonus_option2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_REFERRAL_BONUS_TYPE:
    'ecom_partner_platform_settle_in_basic_operation_information_referral_bonus_type',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_REFERRAL_BONUS_TYPE_NOTICE:
    'ecom_partner_platform_settle_in_basic_operation_information_referral_bonus_type_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_REFERRAL_BONUS_TYPE_TIPS:
    'ecom_partner_platform_settle_in_basic_operation_information_referral_bonus_type_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_SALES_CERTIFICATE:
    'ecom_partner_platform_settle_in_basic_operation_information_sales_certificate',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_SALES_CERTIFICATE_TIPS1:
    'ecom_partner_platform_settle_in_basic_operation_information_sales_certificate_tips1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_SALES_CERTIFICATE_TIPS2:
    'ecom_partner_platform_settle_in_basic_operation_information_sales_certificate_tips2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_SALES_CERTIFICATE_UPLOAD:
    'ecom_partner_platform_settle_in_basic_operation_information_sales_certificate_upload',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_WORK_ENVIRONMENT:
    'ecom_partner_platform_settle_in_basic_operation_information_work_environment',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_WORK_ENVIRONMENT_TIP1:
    'ecom_partner_platform_settle_in_basic_operation_information_work_environment_tip1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_BASIC_OPERATION_INFORMATION_WORK_ENVIRONMENT_TIP2:
    'ecom_partner_platform_settle_in_basic_operation_information_work_environment_tip2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_CONGRATULATION_BODY1:
    'ecom_partner_platform_settle_in_congratulation_body1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_CONGRATULATION_BODY2:
    'ecom_partner_platform_settle_in_congratulation_body2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_CONGRATULATION_TITLE:
    'ecom_partner_platform_settle_in_congratulation_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON10:
    'ecom_partner_platform_settle_in_declined_reason10',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON11:
    'ecom_partner_platform_settle_in_declined_reason11',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON1:
    'ecom_partner_platform_settle_in_declined_reason1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON2:
    'ecom_partner_platform_settle_in_declined_reason2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON3:
    'ecom_partner_platform_settle_in_declined_reason3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON4:
    'ecom_partner_platform_settle_in_declined_reason4',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON5:
    'ecom_partner_platform_settle_in_declined_reason5',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON6:
    'ecom_partner_platform_settle_in_declined_reason6',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON7:
    'ecom_partner_platform_settle_in_declined_reason7',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON8:
    'ecom_partner_platform_settle_in_declined_reason8',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINED_REASON9:
    'ecom_partner_platform_settle_in_declined_reason9',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINE_REASON_BODY1:
    'ecom_partner_platform_settle_in_decline_reason_body1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINE_REASON_BODY2:
    'ecom_partner_platform_settle_in_decline_reason_body2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_DECLINE_REASON_TITLE:
    'ecom_partner_platform_settle_in_decline_reason_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_BASE1:
    'ecom_partner_platform_settle_in_language_base1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_BASE2:
    'ecom_partner_platform_settle_in_language_base2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_BASE3:
    'ecom_partner_platform_settle_in_language_base3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_BASE4:
    'ecom_partner_platform_settle_in_language_base4',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_BASE5:
    'ecom_partner_platform_settle_in_language_base5',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_BASE6:
    'ecom_partner_platform_settle_in_language_base6',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_BASE7:
    'ecom_partner_platform_settle_in_language_base7',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_BASE8:
    'ecom_partner_platform_settle_in_language_base8',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_BASE9:
    'ecom_partner_platform_settle_in_language_base9',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_ENGLISH:
    'ecom_partner_platform_settle_in_language_english',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_MARKET1:
    'ecom_partner_platform_settle_in_language_market1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_MARKET2:
    'ecom_partner_platform_settle_in_language_market2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_MARKET3:
    'ecom_partner_platform_settle_in_language_market3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_MARKET4:
    'ecom_partner_platform_settle_in_language_market4',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_MARKET5:
    'ecom_partner_platform_settle_in_language_market5',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_LANGUAGE_MARKET6:
    'ecom_partner_platform_settle_in_language_market6',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_PROCESS1:
    'ecom_partner_platform_settle_in_process1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_PROCESS2:
    'ecom_partner_platform_settle_in_process2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_PROCESS3:
    'ecom_partner_platform_settle_in_process3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_PROCESS_TITLE:
    'ecom_partner_platform_settle_in_process_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION:
    'ecom_partner_platform_settle_in_qualification_information',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_IMAGE:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_image',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_IMAGE_SUBTITLE1:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_image_subtitle1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_IMAGE_SUBTITLE2:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_image_subtitle2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_IMAGE_TIP1:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_image_tip1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_IMAGE_TIP2:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_image_tip2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_IMAGE_UPLOAD:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_image_upload',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_TYPE:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_type',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_TYPE_NOTICE:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_type_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_TYPE_OPTION1:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_type_option1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_TYPE_OPTION2:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_type_option2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_TYPE_OPTION3:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_type_option3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_TYPE_OPTION4:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_type_option4',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_BUSINESS_ID_TYPE_TIPS:
    'ecom_partner_platform_settle_in_qualification_information_business_ID_type_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_IMAGE:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_image',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_IMAGE_SUBTITLE1:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_image_subtitle1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_IMAGE_SUBTITLE2:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_image_subtitle2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_IMAGE_TIP1:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_image_tip1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_IMAGE_TIP2:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_image_tip2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_IMAGE_UPLOAD:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_image_upload',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_NUMBER:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_number',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_NUMBER_NOTICE:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_number_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_NUMBER_TIPS:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_number_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_TYPE:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_type',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_TYPE_NOTICE:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_type_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_TYPE_OPTION1:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_type_option1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_TYPE_OPTION2:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_type_option2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_TYPE_OPTION3:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_type_option3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_TYPE_OPTION4:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_type_option4',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_TYPE_OPTION5:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_type_option5',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_CERTIFICATE_TYPE_OPTION6:
    'ecom_partner_platform_settle_in_qualification_information_entity_certificate_type_option6',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_NAME:
    'ecom_partner_platform_settle_in_qualification_information_entity_name',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_NAME_NOTICE:
    'ecom_partner_platform_settle_in_qualification_information_entity_name_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_ENTITY_NAME_TIPS:
    'ecom_partner_platform_settle_in_qualification_information_entity_name_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_INTRODUCTION:
    'ecom_partner_platform_settle_in_qualification_information_introduction',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_REGISTER_DATE:
    'ecom_partner_platform_settle_in_qualification_information_register_date',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUALIFICATION_INFORMATION_REGISTER_DATE_NOTICE:
    'ecom_partner_platform_settle_in_qualification_information_register_date_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_QUESTION_OPTIONAL:
    'ecom_partner_platform_settle_in_question_optional',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION:
    'ecom_partner_platform_settle_in_role_selection',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_ADD:
    'ecom_partner_platform_settle_in_role_selection_add',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE:
    'ecom_partner_platform_settle_in_role_selection_base',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_NOTICE:
    'ecom_partner_platform_settle_in_role_selection_base_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION10:
    'ecom_partner_platform_settle_in_role_selection_base_option10',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION11:
    'ecom_partner_platform_settle_in_role_selection_base_option11',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION1:
    'ecom_partner_platform_settle_in_role_selection_base_option1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION2:
    'ecom_partner_platform_settle_in_role_selection_base_option2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION3:
    'ecom_partner_platform_settle_in_role_selection_base_option3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION4:
    'ecom_partner_platform_settle_in_role_selection_base_option4',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION5:
    'ecom_partner_platform_settle_in_role_selection_base_option5',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION6:
    'ecom_partner_platform_settle_in_role_selection_base_option6',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION7:
    'ecom_partner_platform_settle_in_role_selection_base_option7',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION8:
    'ecom_partner_platform_settle_in_role_selection_base_option8',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_OPTION9:
    'ecom_partner_platform_settle_in_role_selection_base_option9',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_BASE_TIPS:
    'ecom_partner_platform_settle_in_role_selection_base_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_DELETE:
    'ecom_partner_platform_settle_in_role_selection_delete',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_DELETE_POPUP_BODY:
    'ecom_partner_platform_settle_in_role_selection_delete_popup_body',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_DELETE_POPUP_CANCEL:
    'ecom_partner_platform_settle_in_role_selection_delete_popup_cancel',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_DELETE_POPUP_CONFIRM:
    'ecom_partner_platform_settle_in_role_selection_delete_popup_confirm',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_DELETE_POPUP_TITLE:
    'ecom_partner_platform_settle_in_role_selection_delete_popup_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_INTRODUCTION:
    'ecom_partner_platform_settle_in_role_selection_introduction',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_MARKET:
    'ecom_partner_platform_settle_in_role_selection_market',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_MARKET_NOTICE:
    'ecom_partner_platform_settle_in_role_selection_market_notice',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_MARKET_OPTION1:
    'ecom_partner_platform_settle_in_role_selection_market_option1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_MARKET_OPTION2:
    'ecom_partner_platform_settle_in_role_selection_market_option2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_MARKET_OPTION3:
    'ecom_partner_platform_settle_in_role_selection_market_option3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_MARKET_OPTION4:
    'ecom_partner_platform_settle_in_role_selection_market_option4',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_MARKET_OPTION5:
    'ecom_partner_platform_settle_in_role_selection_market_option5',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_MARKET_OPTION6:
    'ecom_partner_platform_settle_in_role_selection_market_option6',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_MARKET_OPTION7:
    'ecom_partner_platform_settle_in_role_selection_market_option7',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_NEXT:
    'ecom_partner_platform_settle_in_role_selection_next',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_SAVE:
    'ecom_partner_platform_settle_in_role_selection_save',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_TYPE:
    'ecom_partner_platform_settle_in_role_selection_type',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_TYPE_OPTION1:
    'ecom_partner_platform_settle_in_role_selection_type_option1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_TYPE_OPTION2:
    'ecom_partner_platform_settle_in_role_selection_type_option2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_TYPE_OPTION3:
    'ecom_partner_platform_settle_in_role_selection_type_option3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_ROLE_SELECTION_TYPE_TIPS:
    'ecom_partner_platform_settle_in_role_selection_type_tips',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_APPROVED_ACTION:
    'ecom_partner_platform_settle_in_status_approved_action',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_APPROVED_BODY:
    'ecom_partner_platform_settle_in_status_approved_body',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_APPROVED_TITLE:
    'ecom_partner_platform_settle_in_status_approved_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_DECLINE_ACTION:
    'ecom_partner_platform_settle_in_status_decline_action',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_DECLINE_BODY:
    'ecom_partner_platform_settle_in_status_decline_body',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_DECLINE_REASON_BODY2:
    'ecom_partner_platform_settle_in_status_decline_reason_body2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_DECLINE_REASON_TITLE1:
    'ecom_partner_platform_settle_in_status_decline_reason_title1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_DECLINE_REASON_TITLE2:
    'ecom_partner_platform_settle_in_status_decline_reason_title2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_DECLINE_TITLE:
    'ecom_partner_platform_settle_in_status_decline_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_APPROVED_BODY1:
    'ecom_partner_platform_settle_in_status_multiple_role_approved_body1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_APPROVED_BODY2:
    'ecom_partner_platform_settle_in_status_multiple_role_approved_body2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_APPROVED_CHOOSE_ROLE:
    'ecom_partner_platform_settle_in_status_multiple_role_approved_choose_role',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_APPROVED_CHOOSE_ROLE_ACTION:
    'ecom_partner_platform_settle_in_status_multiple_role_approved_choose_role_action',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_APPROVED_CHOOSE_ROLE_BASE:
    'ecom_partner_platform_settle_in_status_multiple_role_approved_choose_role_base',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_APPROVED_TITLE:
    'ecom_partner_platform_settle_in_status_multiple_role_approved_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_DECLINED_BODY1:
    'ecom_partner_platform_settle_in_status_multiple_role_declined_body1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_DECLINED_BODY2:
    'ecom_partner_platform_settle_in_status_multiple_role_declined_body2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_DECLINED_BODY3:
    'ecom_partner_platform_settle_in_status_multiple_role_declined_body3',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_DECLINED_TITLE:
    'ecom_partner_platform_settle_in_status_multiple_role_declined_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_PENDING_BODY1:
    'ecom_partner_platform_settle_in_status_multiple_role_pending_body1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_PENDING_BODY2:
    'ecom_partner_platform_settle_in_status_multiple_role_pending_body2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_MULTIPLE_ROLE_PENDING_TITLE:
    'ecom_partner_platform_settle_in_status_multiple_role_pending_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_PENDING_BODY:
    'ecom_partner_platform_settle_in_status_pending_body',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_STATUS_PENDING_TITLE:
    'ecom_partner_platform_settle_in_status_pending_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TERMINATION_BODY1:
    'ecom_partner_platform_settle_in_termination_body1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TERMINATION_BODY2:
    'ecom_partner_platform_settle_in_termination_body2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TERMINATION_TITLE:
    'ecom_partner_platform_settle_in_termination_title',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_ACCOUNT_FANS_NUMBER:
    'ecom_partner_platform_settle_in_toast_account_fans_number',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_ACCOUNT_NUMBER1:
    'ecom_partner_platform_settle_in_toast_account_number1',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_ACCOUNT_NUMBER2:
    'ecom_partner_platform_settle_in_toast_account_number2',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_ACCOUNT_VALIDITY:
    'ecom_partner_platform_settle_in_toast_account_validity',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_CHARACTER_LENGTH:
    'ecom_partner_platform_settle_in_toast_character_length',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_COMPANY_NAME_REPETITION:
    'ecom_partner_platform_settle_in_toast_company_name_repetition',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_COMPANY_NAME_VALIDITY:
    'ecom_partner_platform_settle_in_toast_company_name_validity',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_FILE_FAILURE:
    'ecom_partner_platform_settle_in_toast_file_failure',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_FILE_NUMBER:
    'ecom_partner_platform_settle_in_toast_file_number',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_FILE_SIZE:
    'ecom_partner_platform_settle_in_toast_file_size',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_FILE_TYPE:
    'ecom_partner_platform_settle_in_toast_file_type',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_FILE_UPLOADING:
    'ecom_partner_platform_settle_in_toast_file_uploading',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_TOAST_REQUIRED:
    'ecom_partner_platform_settle_in_toast_required',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_WITHOUT_TERMS_COMPANY:
    'ecom_partner_platform_settle_in_without_terms_company',
  ECOM_PARTNER_PLATFORM_SETTLE_IN_WITHOUT_TERMS_COMPANY_SUBMIT:
    'ecom_partner_platform_settle_in_without_terms_company_submit',
  ECOM_PARTNER_PLATFORM_SETTLE_TIKTOK_ICON_ERROR:
    'ecom_partner_platform_settle_tiktok_icon_error',
  ECOM_PARTNER_PLATFORM_SETTLE_TIKTOK_ICON_STATUS:
    'ecom_partner_platform_settle_tiktok_icon_status',
  ECOM_PARTNER_PLATFORM_SIGNUP: 'ecom_partner_platform_signup',
  ECOM_PARTNER_PLATFORM_SIGNUP_ALTER_TIKTOK:
    'ecom_partner_platform_signup_alter_tiktok',
  ECOM_PARTNER_PLATFORM_SIGNUP_CONFIRM_PASSWORD:
    'ecom_partner_platform_signup_confirm_password',
  ECOM_PARTNER_PLATFORM_SIGNUP_CONFIRM_PASSWORD_CONFLICT:
    'ecom_partner_platform_signup_confirm_password_conflict',
  ECOM_PARTNER_PLATFORM_SIGNUP_CONFIRM_PASSWORD_EMPTY:
    'ecom_partner_platform_signup_confirm_password_empty',
  ECOM_PARTNER_PLATFORM_SIGNUP_CONFIRM_PASSWORD_NOTICE:
    'ecom_partner_platform_signup_confirm_password_notice',
  ECOM_PARTNER_PLATFORM_SIGNUP_CONFIRM_PASSWORD_WEAK:
    'ecom_partner_platform_signup_confirm_password_weak',
  ECOM_PARTNER_PLATFORM_SIGNUP_EMAIL: 'ecom_partner_platform_signup_email',
  ECOM_PARTNER_PLATFORM_SIGNUP_EMAIL_ADDRESS_CONFLICT_WITH_TT:
    'ecom_partner_platform_signup_email_address_conflict_with_tt',
  ECOM_PARTNER_PLATFORM_SIGNUP_EMAIL_CONFLICT:
    'ecom_partner_platform_signup_email_conflict',
  ECOM_PARTNER_PLATFORM_SIGNUP_EMAIL_EMPTY:
    'ecom_partner_platform_signup_email_empty',
  ECOM_PARTNER_PLATFORM_SIGNUP_EMAIL_ERROR:
    'ecom_partner_platform_signup_email_error',
  ECOM_PARTNER_PLATFORM_SIGNUP_EMAIL_NOTICE:
    'ecom_partner_platform_signup_email_notice',
  ECOM_PARTNER_PLATFORM_SIGNUP_PASSWORD:
    'ecom_partner_platform_signup_password',
  ECOM_PARTNER_PLATFORM_SIGNUP_PASSWORD_NOTICE:
    'ecom_partner_platform_signup_password_notice',
  ECOM_PARTNER_PLATFORM_SIGNUP_PASSWORD_RULE:
    'ecom_partner_platform_signup_password_rule',
  ECOM_PARTNER_PLATFORM_SIGNUP_PASSWORD_WRONG:
    'ecom_partner_platform_signup_password_wrong',
  ECOM_PARTNER_PLATFORM_SIGNUP_PHONE_NUMBER:
    'ecom_partner_platform_signup_phone_number',
  ECOM_PARTNER_PLATFORM_SIGNUP_PHONE_NUMBER_CONFLICT:
    'ecom_partner_platform_signup_phone_number_conflict',
  ECOM_PARTNER_PLATFORM_SIGNUP_PHONE_NUMBER_CONFLICT_WITH_TT:
    'ecom_partner_platform_signup_phone_number_conflict_with_tt',
  ECOM_PARTNER_PLATFORM_SIGNUP_PHONE_NUMBER_EMPTY:
    'ecom_partner_platform_signup_phone_number_empty',
  ECOM_PARTNER_PLATFORM_SIGNUP_PHONE_NUMBER_ERROR:
    'ecom_partner_platform_signup_phone_number_error',
  ECOM_PARTNER_PLATFORM_SIGNUP_PHONE_NUMBER_INEXISTENCE:
    'ecom_partner_platform_signup_phone_number_inexistence',
  ECOM_PARTNER_PLATFORM_SIGNUP_PHONE_NUMBER_NOTICE:
    'ecom_partner_platform_signup_phone_number_notice',
  ECOM_PARTNER_PLATFORM_SIGNUP_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_signup_send_verification_code',
  ECOM_PARTNER_PLATFORM_SIGNUP_SUBMIT: 'ecom_partner_platform_signup_submit',
  ECOM_PARTNER_PLATFORM_SIGNUP_TIKTOK: 'ecom_partner_platform_signup_tiktok',
  ECOM_PARTNER_PLATFORM_SIGNUP_VERIFICATION_CODE:
    'ecom_partner_platform_signup_verification_code',
  ECOM_PARTNER_PLATFORM_SIGNUP_VERIFICATION_CODE_COUNTINGDOWN:
    'ecom_partner_platform_signup_verification_code_countingdown',
  ECOM_PARTNER_PLATFORM_SIGNUP_VERIFICATION_CODE_EMPTY:
    'ecom_partner_platform_signup_verification_code_empty',
  ECOM_PARTNER_PLATFORM_SIGNUP_VERIFICATION_CODE_EXPIRED:
    'ecom_partner_platform_signup_verification_code_expired',
  ECOM_PARTNER_PLATFORM_SIGNUP_VERIFICATION_CODE_FREQUENT:
    'ecom_partner_platform_signup_verification_code_frequent',
  ECOM_PARTNER_PLATFORM_SIGNUP_VERIFICATION_CODE_NOTICE:
    'ecom_partner_platform_signup_verification_code_notice',
  ECOM_PARTNER_PLATFORM_SIGNUP_VERIFICATION_CODE_RESEND:
    'ecom_partner_platform_signup_verification_code_resend',
  ECOM_PARTNER_PLATFORM_SIGNUP_VERIFICATION_CODE_WRONG:
    'ecom_partner_platform_signup_verification_code_wrong',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION:
    'ecom_partner_platform_verify_contact_information',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_COMBINE_LOGIN_NOTICE:
    'ecom_partner_platform_verify_contact_information_combine_login_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_CREATE_NEW:
    'ecom_partner_platform_verify_contact_information_create_new',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_COMBINE_NOTICE:
    'ecom_partner_platform_verify_contact_information_email_combine_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_COMBINE_NOTICE_TITLE:
    'ecom_partner_platform_verify_contact_information_email_combine_notice_title',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_CONFLICT_NOTICE:
    'ecom_partner_platform_verify_contact_information_email_conflict_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_CONFLICT_NOTICE_TITLE:
    'ecom_partner_platform_verify_contact_information_email_conflict_notice_title',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_ENTER:
    'ecom_partner_platform_verify_contact_information_email_enter',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_ENTER_NOTICE:
    'ecom_partner_platform_verify_contact_information_email_enter_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_ENTER_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_verify_contact_information_email_enter_send_verification_code',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_ENTER_SUBMIT:
    'ecom_partner_platform_verify_contact_information_email_enter_submit',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_ENTER_VERIFICATION_CODE:
    'ecom_partner_platform_verify_contact_information_email_enter_verification_code',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_ENTER_VERIFICATION_CODE_NOTICE:
    'ecom_partner_platform_verify_contact_information_email_enter_verification_code_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_EMAIL_NOTICE:
    'ecom_partner_platform_verify_contact_information_email_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_LOGIN:
    'ecom_partner_platform_verify_contact_information_login',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_COMBINE_CREATE_NEW:
    'ecom_partner_platform_verify_contact_information_phone_combine_create_new',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_COMBINE_LOGIN:
    'ecom_partner_platform_verify_contact_information_phone_combine_login',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_COMBINE_NOTICE:
    'ecom_partner_platform_verify_contact_information_phone_combine_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_COMBINE_NOTICE_TITLE:
    'ecom_partner_platform_verify_contact_information_phone_combine_notice_title',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_CONFLICT_NOTICE:
    'ecom_partner_platform_verify_contact_information_phone_conflict_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_CONFLICT_NOTICE_TITLE:
    'ecom_partner_platform_verify_contact_information_phone_conflict_notice_title',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_EMAIL_COMBINE_NOTICE:
    'ecom_partner_platform_verify_contact_information_phone_email_combine_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_EMAIL_COMBINE_NOTICE_TITLE:
    'ecom_partner_platform_verify_contact_information_phone_email_combine_notice_title',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_EMAIL_CONFLICT_NOTICE:
    'ecom_partner_platform_verify_contact_information_phone_email_conflict_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_EMAIL_CONFLICT_NOTICE_TITLE:
    'ecom_partner_platform_verify_contact_information_phone_email_conflict_notice_title',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_ENTER:
    'ecom_partner_platform_verify_contact_information_phone_enter',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_ENTER_NOTICE:
    'ecom_partner_platform_verify_contact_information_phone_enter_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_ENTER_SEND_VERIFICATION_CODE:
    'ecom_partner_platform_verify_contact_information_phone_enter_send_verification_code',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_ENTER_SUBMIT:
    'ecom_partner_platform_verify_contact_information_phone_enter_submit',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_ENTER_VERIFICATION_CODE:
    'ecom_partner_platform_verify_contact_information_phone_enter_verification_code',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_ENTER_VERIFICATION_CODE_NOTICE:
    'ecom_partner_platform_verify_contact_information_phone_enter_verification_code_notice',
  ECOM_PARTNER_PLATFORM_VERIFY_CONTACT_INFORMATION_PHONE_NOTICE:
    'ecom_partner_platform_verify_contact_information_phone_notice',
  ECOM_PARTNER_PLATFORM_WELCOME_AGREE_RULES:
    'ecom_partner_platform_welcome_agree_rules',
  ECOM_PARTNER_PLATFORM_WELCOME_AGREE_RULES_NEW:
    'ecom_partner_platform_welcome_agree_rules_new',
  ECOM_PARTNER_PLATFORM_WELCOME_PHONE_EMAIL_SIGNUP:
    'ecom_partner_platform_welcome_phone_email_signup',
  ECOM_PARTNER_PLATFORM_WELCOME_TIKTOK_ICON_ERROR:
    'ecom_partner_platform_welcome_tiktok_icon_error',
  ECOM_PARTNER_PLATFORM_WELCOME_TIKTOK_SIGNUP:
    'ecom_partner_platform_welcome_tiktok_signup',
  EC_CREATOR_PLATFORM_COMMON_AVERAGE_ORDER_VALUE:
    'ec_creator_platform_common_average_order_value',
  EC_CREATOR_PLATFORM_COMMON_BETTER_THAN_PEER:
    'ec_creator_platform_common_better_than_peer',
  EC_CREATOR_PLATFORM_COMMON_CAT_BABY_MATERNITY:
    'ec_creator_platform_common_cat_baby_maternity',
  EC_CREATOR_PLATFORM_COMMON_CAT_BEAUTY_PERSONAL_CARE:
    'ec_creator_platform_common_cat_beauty_personal_care',
  EC_CREATOR_PLATFORM_COMMON_CAT_BOOKS_MAGAZINES_AUDIO:
    'ec_creator_platform_common_cat_books_magazines_audio',
  EC_CREATOR_PLATFORM_COMMON_CAT_COMMERCIAL_APPLIANCES:
    'ec_creator_platform_common_cat_commercial_appliances',
  EC_CREATOR_PLATFORM_COMMON_CAT_COMPUTERS_OFFICE_EQUIPMENT:
    'ec_creator_platform_common_cat_computers_office_equipment',
  EC_CREATOR_PLATFORM_COMMON_CAT_FASHION_ACCESSORIES:
    'ec_creator_platform_common_cat_fashion_accessories',
  EC_CREATOR_PLATFORM_COMMON_CAT_FOOD_BEVERAGES:
    'ec_creator_platform_common_cat_food_beverages',
  EC_CREATOR_PLATFORM_COMMON_CAT_FURNITURE:
    'ec_creator_platform_common_cat_furniture',
  EC_CREATOR_PLATFORM_COMMON_CAT_HEALTH:
    'ec_creator_platform_common_cat_health',
  EC_CREATOR_PLATFORM_COMMON_CAT_HOBBIES_COLLECTIBLES:
    'ec_creator_platform_common_cat_hobbies_collectibles',
  EC_CREATOR_PLATFORM_COMMON_CAT_HOME_IMPROVEMENT:
    'ec_creator_platform_common_cat_home_improvement',
  EC_CREATOR_PLATFORM_COMMON_CAT_HOME_SUPPLIES:
    'ec_creator_platform_common_cat_home_supplies',
  EC_CREATOR_PLATFORM_COMMON_CAT_HOUSEHOLD_APPLIANCES:
    'ec_creator_platform_common_cat_household_appliances',
  EC_CREATOR_PLATFORM_COMMON_CAT_JEWELLERY_ACCESSORIES_DERIVATIVES:
    'ec_creator_platform_common_cat_jewellery_accessories_derivatives',
  EC_CREATOR_PLATFORM_COMMON_CAT_KIDS_FASHION:
    'ec_creator_platform_common_cat_kids_fashion',
  EC_CREATOR_PLATFORM_COMMON_CAT_KITCHENWARE:
    'ec_creator_platform_common_cat_kitchenware',
  EC_CREATOR_PLATFORM_COMMON_CAT_LARGE_APPLIANCES:
    'ec_creator_platform_common_cat_large_appliances',
  EC_CREATOR_PLATFORM_COMMON_CAT_LIQUOR_AND_BEVERAGES:
    'ec_creator_platform_common_cat_liquor_and_beverages',
  EC_CREATOR_PLATFORM_COMMON_CAT_LUGGAGE_BAGS:
    'ec_creator_platform_common_cat_luggage_bags',
  EC_CREATOR_PLATFORM_COMMON_CAT_MENSWEAR_UNDERWEAR:
    'ec_creator_platform_common_cat_menswear_underwear',
  EC_CREATOR_PLATFORM_COMMON_CAT_MIXED: 'ec_creator_platform_common_cat_mixed',
  EC_CREATOR_PLATFORM_COMMON_CAT_MUSLIM_FASHION:
    'ec_creator_platform_common_cat_muslim_fashion',
  EC_CREATOR_PLATFORM_COMMON_CAT_NO_MAIN_CATEGORY:
    'ec_creator_platform_common_cat_no_main_category',
  EC_CREATOR_PLATFORM_COMMON_CAT_NULL: 'ec_creator_platform_common_cat_null',
  EC_CREATOR_PLATFORM_COMMON_CAT_PET_SUPPLIES:
    'ec_creator_platform_common_cat_pet_supplies',
  EC_CREATOR_PLATFORM_COMMON_CAT_PHONES_ELECTRONICS:
    'ec_creator_platform_common_cat_phones_electronics',
  EC_CREATOR_PLATFORM_COMMON_CAT_SHOES: 'ec_creator_platform_common_cat_shoes',
  EC_CREATOR_PLATFORM_COMMON_CAT_SPORTS_OUTDOOR:
    'ec_creator_platform_common_cat_sports_outdoor',
  EC_CREATOR_PLATFORM_COMMON_CAT_TEXTILES_CAT_SOFT_FURNISHINGS:
    'ec_creator_platform_common_cat_textiles_cat_soft_furnishings',
  EC_CREATOR_PLATFORM_COMMON_CAT_TOOLS_HARDWARE:
    'ec_creator_platform_common_cat_tools_hardware',
  EC_CREATOR_PLATFORM_COMMON_CAT_TOYS: 'ec_creator_platform_common_cat_toys',
  EC_CREATOR_PLATFORM_COMMON_CAT_VEHICLE_SUPPLIES:
    'ec_creator_platform_common_cat_vehicle_supplies',
  EC_CREATOR_PLATFORM_COMMON_CAT_WOMENSWEAR_UNDERWEAR:
    'ec_creator_platform_common_cat_womenswear_underwear',
  EC_CREATOR_PLATFORM_COMMON_CTR: 'ec_creator_platform_common_CTR',
  EC_CREATOR_PLATFORM_COMMON_EQUAL_TO_PEER:
    'ec_creator_platform_common_equal_to_peer',
  EC_CREATOR_PLATFORM_COMMON_GOT_IT: 'ec_creator_platform_common_got_it',
  EC_CREATOR_PLATFORM_COMMON_GO_TO_VIEW:
    'ec_creator_platform_common_go_to_view',
  EC_CREATOR_PLATFORM_COMMON_HELPFUL: 'ec_creator_platform_common_helpful',
  EC_CREATOR_PLATFORM_COMMON_LAST_X_DAYS:
    'ec_creator_platform_common_last_x_days',
  EC_CREATOR_PLATFORM_COMMON_LESS_THAN_PEER:
    'ec_creator_platform_common_less_than_peer',
  EC_CREATOR_PLATFORM_COMMON_LIVE_DURATION:
    'ec_creator_platform_common_live_duration',
  EC_CREATOR_PLATFORM_COMMON_MORE_DETAIL:
    'ec_creator_platform_common_more_detail',
  EC_CREATOR_PLATFORM_COMMON_PER_HOUR_WATCH_TIME:
    'ec_creator_platform_common_per_hour_watch_time',
  EC_CREATOR_PLATFORM_COMMON_PRODUCT_CO:
    'ec_creator_platform_common_product_CO',
  EC_CREATOR_PLATFORM_COMMON_PRODUCT_CTR:
    'ec_creator_platform_common_product_CTR',
  EC_CREATOR_PLATFORM_COMMON_PRODUCT_NAME:
    'ec_creator_platform_common_product_name',
  EC_CREATOR_PLATFORM_COMMON_RANK_IN_SIMILAR_ACCOUNTS:
    'ec_creator_platform_common_rank_in_similar_accounts',
  EC_CREATOR_PLATFORM_COMMON_RATIO_OF_ENTER:
    'ec_creator_platform_common_ratio_of_enter',
  EC_CREATOR_PLATFORM_COMMON_REVENUE_IN_LAST_X_DAYS:
    'ec_creator_platform_common_revenue_in_last_x_days',
  EC_CREATOR_PLATFORM_COMMON_TOP_ONE_RANK_IN_SIMILAR_ACCOUNTS:
    'ec_creator_platform_common_top_one_rank_in_similar_accounts',
  EC_CREATOR_PLATFORM_COMMON_UNHELPFUL: 'ec_creator_platform_common_unhelpful',
  EC_CREATOR_PLATFORM_COMMON_VS_LAST_MOUNTH:
    'ec_creator_platform_common_vs_last_mounth',
  EC_CREATOR_PLATFORM_COMMON_VS_LAST_WEEK:
    'ec_creator_platform_common_vs_last_week',
  EC_CREATOR_PLATFORM_COMMON_VS_LAST_X_TIME:
    'ec_creator_platform_common_vs_last_x_time',
  EC_CREATOR_PLATFORM_DASHBOARD_CHART_BAD_CONCLUSION:
    'ec_creator_platform_dashboard_chart_bad_conclusion',
  EC_CREATOR_PLATFORM_DASHBOARD_CHART_CONCLUSION_TEXT:
    'ec_creator_platform_dashboard_chart_conclusion_text',
  EC_CREATOR_PLATFORM_DASHBOARD_CHART_EXCELLENT_CONSLUSION:
    'ec_creator_platform_dashboard_chart_excellent_conslusion',
  EC_CREATOR_PLATFORM_DASHBOARD_CHART_GOOD_CONCLUSION:
    'ec_creator_platform_dashboard_chart_good_conclusion',
  EC_CREATOR_PLATFORM_DASHBOARD_CHART_HOVER_CONCLUSION:
    'ec_creator_platform_dashboard_chart_hover_conclusion',
  EC_CREATOR_PLATFORM_DASHBOARD_CHART_NORMAL_CONCLUSION:
    'ec_creator_platform_dashboard_chart_normal_conclusion',
  EC_CREATOR_PLATFORM_DATA_COMPASS: 'ec_creator_platform_data_compass',
  EC_CREATOR_PLATFORM_DATA_COMPASS_DATA_OVERVIEW_TITLE:
    'ec_creator_platform_data_compass_data_overview_title',
  EC_CREATOR_PLATFORM_DATA_COMPASS_LIVE_DETAILS_TITLE:
    'ec_creator_platform_data_compass_live_details_title',
  EC_CREATOR_PLATFORM_DATA_COMPASS_LIVE_DIAGNOSIS_TITLE:
    'ec_creator_platform_data_compass_live_diagnosis_title',
  EC_CREATOR_PLATFORM_DATA_MODULE_ERROR_CONNECT_INTERNET:
    'ec_creator_platform_data_module_error_connect_internet',
  EC_CREATOR_PLATFORM_DATA_MODULE_ERROR_NETWORK_ERROR:
    'ec_creator_platform_data_module_error_network_error',
  EC_CREATOR_PLATFORM_DATA_MODULE_ERROR_NO_DATA:
    'ec_creator_platform_data_module_error_no_data',
  EC_CREATOR_PLATFORM_DATA_MODULE_ERROR_RETRY:
    'ec_creator_platform_data_module_error_retry',
  EC_CREATOR_PLATFORM_DATA_MODULE_ERROR_SERVER_ERROR:
    'ec_creator_platform_data_module_error_server_error',
  EC_CREATOR_PLATFORM_DATA_MODULE_ERROR_TRY_AGAIN:
    'ec_creator_platform_data_module_error_try_again',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_ESTIMATED_COMMISSION:
    'ec_creator_platform_data_overview_core_data_estimated_commission',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_ESTIMATED_COMMISSION_DESCRIPTION:
    'ec_creator_platform_data_overview_core_data_estimated_commission_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_ESTIMATED_COMMISSION__DESCRIPTION:
    'ec_creator_platform_data_overview_core_data_estimated_commission _description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_REVENUE:
    'ec_creator_platform_data_overview_core_data_live_revenue',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_REVENUE_DESCRIPTION:
    'ec_creator_platform_data_overview_core_data_live_revenue_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_SHOWS:
    'ec_creator_platform_data_overview_core_data_live_shows',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_SHOWS_DESCRIPTION:
    'ec_creator_platform_data_overview_core_data_live_shows_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_NEW_FOLLOWERS:
    'ec_creator_platform_data_overview_core_data_new_followers',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_NEW_FOLLOWERS_DESCRIPTION:
    'ec_creator_platform_data_overview_core_data_new_followers_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_ORDERS:
    'ec_creator_platform_data_overview_core_data_orders',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_ORDERS_DESCRIPTION:
    'ec_creator_platform_data_overview_core_data_orders_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_REVENUE:
    'ec_creator_platform_data_overview_core_data_revenue',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_REVENUE_DESCRIPTION:
    'ec_creator_platform_data_overview_core_data_revenue_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_TITLE:
    'ec_creator_platform_data_overview_core_data_title',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_TITLE_DESCRIPTION1:
    'ec_creator_platform_data_overview_core_data_title_description1',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_TITLE_DESCRIPTION2:
    'ec_creator_platform_data_overview_core_data_title_description2',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_VIDEOS:
    'ec_creator_platform_data_overview_core_data_videos',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_VIDEOS_DESCRIPTION:
    'ec_creator_platform_data_overview_core_data_videos_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_VIDEO_REVENUE:
    'ec_creator_platform_data_overview_core_data_video_revenue',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_CORE_DATA_VIDEO_REVENUE_DESCRIPTION:
    'ec_creator_platform_data_overview_core_data_video_revenue_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_METRIC_CARD_VS_LAST_28_DAYS:
    'ec_creator_platform_data_overview_metric_card_vs_last_28_days',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_METRIC_CARD_VS_LAST_7_DAYS:
    'ec_creator_platform_data_overview_metric_card_vs_last_7_days',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_METRIC_CARD_VS_LAST_PREVIOUS_DAYS:
    'ec_creator_platform_data_overview_metric_card_vs_last_previous_days',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_METRIC_CARD_YESTERDAY:
    'ec_creator_platform_data_overview_metric_card_yesterday',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_DURATION:
    'ec_creator_platform_data_overview_recent_lives_duration',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_LIVE_SCREEN_BUTTON:
    'ec_creator_platform_data_overview_recent_lives_live_screen_button',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_NEW_FOLLOWERS:
    'ec_creator_platform_data_overview_recent_lives_new_followers',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_NEW_FOLLOWERS_DESCRIPTION:
    'ec_creator_platform_data_overview_recent_lives_new_followers_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_ORDERS:
    'ec_creator_platform_data_overview_recent_lives_orders',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_ORDERS_DESCRIPTION:
    'ec_creator_platform_data_overview_recent_lives_orders_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_REVENUE:
    'ec_creator_platform_data_overview_recent_lives_revenue',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_REVENUE_DESCRIPTION:
    'ec_creator_platform_data_overview_recent_lives_revenue_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_START_TIME:
    'ec_creator_platform_data_overview_recent_lives_start_time',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_STATUS:
    'ec_creator_platform_data_overview_recent_lives_status',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_TITLE:
    'ec_creator_platform_data_overview_recent_lives_title',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_TITLE_DESCRIPTION:
    'ec_creator_platform_data_overview_recent_lives_title_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_TOTAL_VIEWERS:
    'ec_creator_platform_data_overview_recent_lives_total_viewers',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_RECENT_LIVES_TOTAL_VIEWERS_DESCRIPTION:
    'ec_creator_platform_data_overview_recent_lives_total_viewers_description',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_TIME_SELECT_DATA_UPDATED:
    'ec_creator_platform_data_overview_time_select_data_updated',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_TIME_SELECT_LAST_28_DAYS:
    'ec_creator_platform_data_overview_time_select_last_28_days',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_TIME_SELECT_LAST_7_DAYS:
    'ec_creator_platform_data_overview_time_select_last_7_days',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_TIME_SELECT_NATURAL_DAY:
    'ec_creator_platform_data_overview_time_select_natural_day',
  EC_CREATOR_PLATFORM_DATA_OVERVIEW_TIME_SELECT_TODAY:
    'ec_creator_platform_data_overview_time_select_today',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_ALL_LIVEROOMS_LIST_TITLE:
    'ec_creator_platform_live_analysis_all_liverooms_list_title',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_BUTTON_EXPORT:
    'ec_creator_platform_live_analysis_button_export',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_BUTTON_METRICS:
    'ec_creator_platform_live_analysis_button_metrics',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_AVERAGE_PRICE:
    'ec_creator_platform_live_analysis_core_data_average_price',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_AVERAGE_PRICE_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_average_price_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_BUYERS:
    'ec_creator_platform_live_analysis_core_data_buyers',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_BUYERS_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_buyers_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_CARD_INTERACTIVE:
    'ec_creator_platform_Live_analysis_core_data_card_interactive',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_CARD_PRODUCT:
    'ec_creator_platform_Live_analysis_core_data_card_product',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_CARD_TRAFFIC:
    'ec_creator_platform_Live_analysis_core_data_card_traffic',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_CARD_TRANSACTION:
    'ec_creator_platform_Live_analysis_core_data_card_transaction',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_COMMNETS:
    'ec_creator_platform_live_analysis_core_data_commnets',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_COMMNETS_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_commnets_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_COMPARE_DESC:
    'ec_creator_platform_Live_analysis_core_data_compare_desc',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_COMPARE_LAST_28_DAYS:
    'ec_creator_platform_Live_analysis_core_data_compare_last_28_days',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_COMPARE_LAST_7_DAYS:
    'ec_creator_platform_Live_analysis_core_data_compare_last_7_days',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_CTR:
    'ec_creator_platform_live_analysis_core_data_ctr',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_CTR_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_ctr_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_C_O:
    'ec_creator_platform_live_analysis_core_data_c_o',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_C_O_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_c_o_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_ITEMS_SOLD:
    'ec_creator_platform_live_analysis_core_data_items_sold',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_ITEMS_SOLD_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_items_sold_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_LIKES:
    'ec_creator_platform_live_analysis_core_data_likes',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_LIKES_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_likes_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_LIVEROOM_IMPRESSION:
    'ec_creator_platform_live_analysis_core_data_liveroom_impression',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_LIVEROOM_IMPRESSION_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_liveroom_impression_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_NEW_FOLLOWERS:
    'ec_creator_platform_live_analysis_core_data_new_followers',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_NEW_FOLLOWERS_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_new_followers_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_ORDERS:
    'ec_creator_platform_live_analysis_core_data_orders',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_ORDERS_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_orders_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_PRODUCT_CLICKS:
    'ec_creator_platform_live_analysis_core_data_product_clicks',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_PRODUCT_CLICKS_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_product_clicks_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_PRODUCT_IMPRESSION:
    'ec_creator_platform_live_analysis_core_data_product_impression',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_PRODUCT_IMPRESSION_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_product_impression_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_REVENUE:
    'ec_creator_platform_live_analysis_core_data_revenue',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_REVENUE_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_revenue_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_TITLE:
    'ec_creator_platform_Live_analysis_core_data_title',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_VIEWS:
    'ec_creator_platform_live_analysis_core_data_views',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_CORE_DATA_VIEWS_DESCRIPTION:
    'ec_creator_platform_live_analysis_core_data_views_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_BASIC_DURATION:
    'ec_creator_platform_Live_analysis_detail_basic_duration',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_BASIC_PRODUCTS:
    'ec_creator_platform_Live_analysis_detail_basic_products',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_BASIC_START_TIME:
    'ec_creator_platform_Live_analysis_detail_basic_start_time',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CONTENT_ANALYSIS_SECTION_CLICK_VIDEO_DESC:
    'ec_creator_platform_Live_analysis_detail_content_analysis_section_click_video_desc',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CONTENT_ANALYSIS_SECTION_CURRENT_PRODUCTS:
    'ec_creator_platform_Live_analysis_detail_content_analysis_section_current_products',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CONTENT_ANALYSIS_SECTION_LIVE_TREND:
    'ec_creator_platform_Live_analysis_detail_content_analysis_section_live_trend',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CONTENT_ANALYSIS_SECTION_METRICS:
    'ec_creator_platform_Live_analysis_detail_content_analysis_section_metrics',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CONTENT_ANALYSIS_SECTION_METRIC_CHOOSE_DESC:
    'ec_creator_platform_Live_analysis_detail_content_analysis_section_metric_choose_desc',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CONTENT_ANALYSIS_SECTION_METRIC_ENTERING:
    'ec_creator_platform_Live_analysis_detail_content_analysis_section_metric_entering',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CONTENT_ANALYSIS_SECTION_METRIC_LEAVING:
    'ec_creator_platform_Live_analysis_detail_content_analysis_section_metric_leaving',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CONTENT_ANALYSIS_SECTION_PINNED_PRODUCTS:
    'ec_creator_platform_Live_analysis_detail_content_analysis_section_pinned_products',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CONTENT_ANALYSIS_SECTION_TITLE:
    'ec_creator_platform_Live_analysis_detail_content_analysis_section_title',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CORE_DATA_DESCRIPTION:
    'ec_creator_platform_Live_analysis_detail_core_data_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CORE_DATA_HIDE:
    'ec_creator_platform_Live_analysis_detail_core_data_hide',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_CORE_DATA_VIEW_MORE:
    'ec_creator_platform_Live_analysis_detail_core_data_view_more',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_PAGE_TITLE:
    'ec_creator_platform_Live_analysis_detail_page_title',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_PRODUCT_ANALYSIS_SECTION_CLICK_PRODUCT_DESC:
    'ec_creator_platform_Live_analysis_detail_product_analysis_section_click_product_desc',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_PRODUCT_ANALYSIS_SECTION_EXPLAINED_PRODUCT:
    'ec_creator_platform_Live_analysis_detail_product_analysis_section_explained_product',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_PRODUCT_ANALYSIS_SECTION_ITEMS_SOLD_TREND:
    'ec_creator_platform_Live_analysis_detail_product_analysis_section_items_sold_trend',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_PRODUCT_ANALYSIS_SECTION_PRODUCT_INFO:
    'ec_creator_platform_Live_analysis_detail_product_analysis_section_product_info',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_PRODUCT_ANALYSIS_SECTION_PRODUCT_LIST:
    'ec_creator_platform_Live_analysis_detail_product_analysis_section_product_list',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_PRODUCT_ANALYSIS_SECTION_SOLD_RANKING:
    'ec_creator_platform_Live_analysis_detail_product_analysis_section_sold_ranking',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_PRODUCT_ANALYSIS_SECTION_TITLE:
    'ec_creator_platform_Live_analysis_detail_product_analysis_section_title',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_AVATAR_CLICK:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_avatar_click',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_AVATAR_CLICK_SWIPE:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_avatar_click_swipe',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_CONVERSION:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_conversion',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_CREATOR_PROFILE:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_creator_profile',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_DIRECT_MSG:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_direct_msg',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_ENTER_LIVEROOM:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_enter_liveroom',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_ENTER_LIVEROOM_DESC:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_enter_liveroom_desc',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_FOLLOWING_FEED:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_following_feed',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_FOR_YOU_FEED:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_for_you_feed',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_INBOX:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_inbox',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_LIVESTREAM_CLICK:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_livestream_click',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_LIVESTREAM_CLICK_SWIPE:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_livestream_click_swipe',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_ORDERS_PAID:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_orders_paid',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_ORDERS_PAID_DESC:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_orders_paid_desc',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_OTHERS:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_others',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_PAID_VIEWS:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_paid_views',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_PUSH_NOTIFICATIONS:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_push_notifications',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_SOURCE_RANKING:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_source_ranking',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_TITLE:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_title',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_TRAFFIC_CONVERSION:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_traffic_conversion',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_TRAFFIC_FREE:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_traffic_free',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_TRAFFIC_PAID:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_traffic_paid',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_TRAFFIC_ANALYSIS_SECTION_TRAFFIC_TREND:
    'ec_creator_platform_Live_analysis_detail_traffic_analysis_section_traffic_trend',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_AGE:
    'ec_creator_platform_live_analysis_detail_user_profile_section_age',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_ALL_INDUSTRY:
    'ec_creator_platform_live_analysis_detail_user_profile_section_all_industry',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_BUYERS:
    'ec_creator_platform_live_analysis_detail_user_profile_section_buyers',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_CITIES:
    'ec_creator_platform_live_analysis_detail_user_profile_section_cities',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_COUTRIES_TOP_5:
    'ec_creator_platform_live_analysis_detail_user_profile_section_coutries_top_5',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_DEVICE_BRANDS:
    'ec_creator_platform_live_analysis_detail_user_profile_section_device_brands',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_FANS:
    'ec_creator_platform_live_analysis_detail_user_profile_section_fans',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_FEMALE:
    'ec_creator_platform_live_analysis_detail_user_profile_section_female',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_GENDER:
    'ec_creator_platform_live_analysis_detail_user_profile_section_gender',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_INDUSTRY_PERFORMANCE:
    'ec_creator_platform_live_analysis_detail_user_profile_section_industry_performance',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_MALE:
    'ec_creator_platform_live_analysis_detail_user_profile_section_male',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_NON_FANS:
    'ec_creator_platform_live_analysis_detail_user_profile_section_non_fans',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_ORDER_AMOUNT:
    'ec_creator_platform_live_analysis_detail_user_profile_section_order_amount',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_PRODUCT_PERFORMANCE:
    'ec_creator_platform_live_analysis_detail_user_profile_section_product_performance',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_TITLE:
    'ec_creator_platform_Live_analysis_detail_user_profile_section_title',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_VIDEO_PERFORMANCE:
    'ec_creator_platform_live_analysis_detail_user_profile_section_video_performance',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_DETAIL_USER_PROFILE_SECTION_VIEWERS:
    'ec_creator_platform_live_analysis_detail_user_profile_section_viewers',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_AVG_DURATION:
    'ec_creator_platform_live_analysis_metrics_pop_avg_duration',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_AVG_DURATION_DESCRIPTION:
    'ec_creator_platform_live_analysis_metrics_pop_avg_duration_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_AVG_VIEWERS:
    'ec_creator_platform_live_analysis_metrics_pop_avg_viewers',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_AVG_VIEWERS_DESCRIPTION:
    'ec_creator_platform_live_analysis_metrics_pop_avg_viewers_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_BUTTON_CANCEL:
    'ec_creator_platform_live_analysis_metrics_pop_button_cancel',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_BUTTON_CONFIRM:
    'ec_creator_platform_live_analysis_metrics_pop_button_confirm',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_CREATED_ORDERS:
    'ec_creator_platform_live_analysis_metrics_pop_created_orders',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_CREATED_ORDERS_DESCRIPTION:
    'ec_creator_platform_live_analysis_metrics_pop_created_orders_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_CUSTOM_METRICS:
    'ec_creator_platform_live_analysis_metrics_pop_custom_metrics',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_DEFAULT:
    'ec_creator_platform_live_analysis_metrics_pop_default',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_GPM:
    'ec_creator_platform_live_analysis_metrics_pop_gpm',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_GPM_DESCRIPTION:
    'ec_creator_platform_live_analysis_metrics_pop_gpm_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_INTERACTIVE_RATE:
    'ec_creator_platform_live_analysis_metrics_pop_interactive_rate',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_INTERACTIVE_RATE_DESCRIPTION:
    'ec_creator_platform_live_analysis_metrics_pop_interactive_rate_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_LIVE_INFO:
    'ec_creator_platform_live_analysis_metrics_pop_live_info',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_PCU:
    'ec_creator_platform_live_analysis_metrics_pop_pcu',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_PCU_DESCRIPTION:
    'ec_creator_platform_live_analysis_metrics_pop_pcu_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_SELECTED_METRICS:
    'ec_creator_platform_live_analysis_metrics_pop_selected_metrics',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_SELECT_AT_LEAST:
    'ec_creator_platform_live_analysis_metrics_pop_select_at_least',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_SELECT_METRICS:
    'ec_creator_platform_live_analysis_metrics_pop_select_metrics',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_TIME_INFO:
    'ec_creator_platform_live_analysis_metrics_pop_time_info',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_METRICS_POP_VIEW_ITEMS:
    'ec_creator_platform_live_analysis_metrics_pop_view_items',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_NEW_FEATURE_POPUP_TEXT:
    'ec_creator_platform_live_analysis_new_feature_popup_text',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_PAGE_TITLE:
    'ec_creator_platform_Live_analysis_page_title',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_RANGE_SELECTOR_CUSTOM:
    'ec_creator_platform_Live_analysis_range_selector_custom',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_RANGE_SELECTOR_DAY:
    'ec_creator_platform_Live_analysis_range_selector_day',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_RANGE_SELECTOR_MONTH:
    'ec_creator_platform_Live_analysis_range_selector_month',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_RANGE_SELECTOR_WEEK:
    'ec_creator_platform_Live_analysis_range_selector_week',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_TODAY_LIVESTREAMS_BTN_DETAIL:
    'ec_creator_platform_live_analysis_today_livestreams_btn_detail',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_TODAY_LIVESTREAMS_BTN_LIVE_SCREEN:
    'ec_creator_platform_live_analysis_today_livestreams_btn_live_screen',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_TODAY_LIVESTREAMS_ONLINE_VIEWERS:
    'ec_creator_platform_live_analysis_today_livestreams_online_viewers',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_TODAY_LIVESTREAMS_ONLINE_VIEWERS_DESCRIPTION:
    'ec_creator_platform_live_analysis_today_livestreams_online_viewers_description',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_TODAY_LIVESTREAMS_TITLE:
    'ec_creator_platform_live_analysis_today_livestreams_title',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_TODAY_LIVESTREAMS_VIEWERS:
    'ec_creator_platform_live_analysis_today_livestreams_viewers',
  EC_CREATOR_PLATFORM_LIVE_ANALYSIS_TODAY_LIVESTREAMS_VIEWERS_DESCRIPTION:
    'ec_creator_platform_live_analysis_today_livestreams_viewers_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_DURATION:
    'ec_creator_platform_live_details_core_data_duration',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_DURATION_DESCRIPTION:
    'ec_creator_platform_live_details_core_data_duration_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_LIVES:
    'ec_creator_platform_live_details_core_data_lives',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_LIVES_DESCRIPTION:
    'ec_creator_platform_live_details_core_data_lives_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_NEW_FOLLOWERS:
    'ec_creator_platform_live_details_core_data_new_followers',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_NEW_FOLLOWERS_DESCRIPTION:
    'ec_creator_platform_live_details_core_data_new_followers_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_ORDERS:
    'ec_creator_platform_live_details_core_data_orders',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_ORDERS_DESCRIPTION:
    'ec_creator_platform_live_details_core_data_orders_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_PRODUCT_REACH:
    'ec_creator_platform_live_details_core_data_product_reach',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_PRODUCT_REACH_DESCRIPTION:
    'ec_creator_platform_live_details_core_data_product_reach_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_PRODUCT_VIEW:
    'ec_creator_platform_live_details_core_data_product_view',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_PRODUCT_VIEW_DESCRIPTION:
    'ec_creator_platform_live_details_core_data_product_view_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_REVENUE:
    'ec_creator_platform_live_details_core_data_revenue',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_REVENUE_DESCRIPTION:
    'ec_creator_platform_live_details_core_data_revenue_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_SALES:
    'ec_creator_platform_live_details_core_data_sales',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_SALES_DESCRIPTION:
    'ec_creator_platform_live_details_core_data_sales_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_CORE_DATA_TITLE:
    'ec_creator_platform_live_details_core_data_title',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_CUSTOM_METRICS:
    'ec_creator_platform_live_details_live_details_custom_metrics',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_EXPORT:
    'ec_creator_platform_live_details_live_details_export',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_LIVE_SCREEN_BUTTON:
    'ec_creator_platform_live_details_live_details_live_screen_button',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_ACTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_action',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_ACU:
    'ec_creator_platform_live_details_live_details_metrics_pop_ACU',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_ACU_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_acu_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_AVG_DURATION:
    'ec_creator_platform_live_details_live_details_metrics_pop_avg_duration',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_AVG_DURATION_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_avg_duration_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_BUYERS:
    'ec_creator_platform_live_details_live_details_metrics_pop_buyers',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_BUYERS_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_buyers_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_CANCLE:
    'ec_creator_platform_live_details_live_details_metrics_pop_cancle',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_COMMENT_PV:
    'ec_creator_platform_live_details_live_details_metrics_pop_comment_pv',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_COMMENT_PV_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_comment_pv_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_CREATED_ORDERS:
    'ec_creator_platform_live_details_live_details_metrics_pop_created_orders',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_CREATED_ORDERS_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_created_orders_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_CUSTOM_METRICS:
    'ec_creator_platform_live_details_live_details_metrics_pop_custom_metrics',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_DEAL_METRICS:
    'ec_creator_platform_live_details_live_details_metrics_pop_deal_metrics',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_DEFAULT:
    'ec_creator_platform_live_details_live_details_metrics_pop_default',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_LIKE_PV:
    'ec_creator_platform_live_details_live_details_metrics_pop_like_pv',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_LIKE_PV_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_like_pv_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_LIVE_INFO:
    'ec_creator_platform_live_details_live_details_metrics_pop_live_info',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_METRICS:
    'ec_creator_platform_live_details_live_details_metrics_pop_metrics',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_NEW_FOLLOWERS:
    'ec_creator_platform_live_details_live_details_metrics_pop_new_followers',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_NEW_FOLLOWERS_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_new_followers_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_ORDERS:
    'ec_creator_platform_live_details_live_details_metrics_pop_orders',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_ORDERS_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_orders_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_PAYMENT_RATE:
    'ec_creator_platform_live_details_live_details_metrics_pop_payment_rate',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_PAYMENT_RATE_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_payment_rate_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_PRODUCT_REACH:
    'ec_creator_platform_live_details_live_details_metrics_pop_product_reach',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_PRODUCT_REACH_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_product_reach_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_PRODUCT_VIEW:
    'ec_creator_platform_live_details_live_details_metrics_pop_product_view',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_PRODUCT_VIEW_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_product_view_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_REVENUE:
    'ec_creator_platform_live_details_live_details_metrics_pop_revenue',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_REVENUE_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_revenue_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_SALES:
    'ec_creator_platform_live_details_live_details_metrics_pop_sales',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_SALES_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_sales_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_SAVE:
    'ec_creator_platform_live_details_live_details_metrics_pop_save',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_SELECTED:
    'ec_creator_platform_live_details_live_details_metrics_pop_selected',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_SELECT_METRICS:
    'ec_creator_platform_live_details_live_details_metrics_pop_select_metrics',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_SELECT_RULE:
    'ec_creator_platform_live_details_live_details_metrics_pop_select_rule',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_SHARE_PV:
    'ec_creator_platform_live_details_live_details_metrics_pop_share_pv',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_SHARE_PV_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_share_pv_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_START_TIME:
    'ec_creator_platform_live_details_live_details_metrics_pop_start_time',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_TOTAL_VIEWERS:
    'ec_creator_platform_live_details_live_details_metrics_pop_total_viewers',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_TOTAL_VIEWERS_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_total_viewers_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_TRAFFIC_METRICS:
    'ec_creator_platform_live_details_live_details_metrics_pop_traffic_metrics',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_UNIT_PRICE:
    'ec_creator_platform_live_details_live_details_metrics_pop_unit_price',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_UNIT_PRICE_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_unit_price_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_WATCH_PV:
    'ec_creator_platform_live_details_live_details_metrics_pop_watch_pv',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_METRICS_POP_WATCH_PV_DESCRIPTION:
    'ec_creator_platform_live_details_live_details_metrics_pop_watch_pv_description',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_SEARCH_INPUT:
    'ec_creator_platform_live_details_live_details_search_input',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_STATUS:
    'ec_creator_platform_live_details_live_details_status',
  EC_CREATOR_PLATFORM_LIVE_DETAILS_LIVE_DETAILS_TITLE:
    'ec_creator_platform_live_details_live_details_title',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_GOOD_JOB_AND_KEEP:
    'ec_creator_platform_live_diagnosis_good_job_and_keep',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HAS_STREAMING_NO_DATA:
    'ec_creator_platform_live_diagnosis_has_streaming_no_data',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_AVERAGE_ORDER_VALUE:
    'ec_creator_platform_live_diagnosis_hover_average_order_value',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_LIVE_DURATION:
    'ec_creator_platform_live_diagnosis_hover_live_duration',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_PAGE_VIEW:
    'ec_creator_platform_live_diagnosis_hover_page_view',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_PER_HOUR_WATCH_TIME:
    'ec_creator_platform_live_diagnosis_hover_per_hour_watch_time',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_PER_WATCHS_VALUE:
    'ec_creator_platform_live_diagnosis_hover_per_watchs_value',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_PRODUCT_CO:
    'ec_creator_platform_live_diagnosis_hover_product_CO',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_PRODUCT_CTR:
    'ec_creator_platform_live_diagnosis_hover_product_CTR',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_RATIO_OF_ENTER:
    'ec_creator_platform_live_diagnosis_hover_ratio_of_enter',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_REVENUE:
    'ec_creator_platform_live_diagnosis_hover_revenue',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_HOVER_VS_OUTSTANDING:
    'ec_creator_platform_live_diagnosis_hover_vs_outstanding',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_LIVESTREAM_BAD:
    'ec_creator_platform_live_diagnosis_livestream_bad',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_LIVESTREAM_NORMAL:
    'ec_creator_platform_live_diagnosis_livestream_normal',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_NEWCOMER_MESSAGE:
    'ec_creator_platform_live_diagnosis_newcomer_message',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_NO_STREAMING_NO_DATA:
    'ec_creator_platform_live_diagnosis_no_streaming_no_data',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PAGE_VIEW:
    'ec_creator_platform_live_diagnosis_page_view',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PDP_ENTER_BAD_CTR_NEGATIVE_LESS_3:
    'ec_creator_platform_live_diagnosis_pdp_enter_bad_CTR_negative_less_3',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PDP_ENTER_BAD_CTR_NEGATIVE_MORE_OR_EQUAL_3:
    'ec_creator_platform_live_diagnosis_pdp_enter_bad_CTR_negative_more_or_equal_3',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PDP_ENTER_BAD_CTR_POSITIVE:
    'ec_creator_platform_live_diagnosis_pdp_enter_bad_CTR_positive',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PDP_ENTER_NORMAL:
    'ec_creator_platform_live_diagnosis_pdp_enter_normal',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PDP_ORDER_BAD_CO_NEGATIVE_LESS_3:
    'ec_creator_platform_live_diagnosis_pdp_order_bad_CO_negative_less_3',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PDP_ORDER_BAD_CO_NEGATIVE_MORE_OR_EQUAL_3:
    'ec_creator_platform_live_diagnosis_pdp_order_bad_CO_negative_more_or_equal_3',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PDP_ORDER_BAD_CO_POSITIVE:
    'ec_creator_platform_live_diagnosis_pdp_order_bad_CO_positive',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PDP_ORDER_NORMAL:
    'ec_creator_platform_live_diagnosis_pdp_order_normal',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_BAD_FEED_DRAW_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_bad_feed_draw_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_BAD_FEED_HEAD_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_bad_feed_head_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_BAD_FOLLOW_PAGE_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_bad_follow_page_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_BAD_GENERAL_SEARCH_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_bad_general_search_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_BAD_HOMEPAGE_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_bad_homepage_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_BAD_LIVE_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_bad_live_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_BAD_MESSAGE_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_bad_message_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_BAD_OVERALL_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_bad_overall_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_BAD_PUSH_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_bad_push_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_FEED_CARD_LOW:
    'ec_creator_platform_live_diagnosis_per_hours_watch_feed_card_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_HOURS_WATCH_NORMAL:
    'ec_creator_platform_live_diagnosis_per_hours_watch_normal',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_ORDER_VALUE_0_TO_10_GPM_NORMAL:
    'ec_creator_platform_live_diagnosis_per_order_value_0_to_10_GPM_normal',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_ORDER_VALUE_GENERAL_GPM_BAD:
    'ec_creator_platform_live_diagnosis_per_order_value_general_GPM_bad',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_ORDER_VALUE_MORE_50_GPM_BAD:
    'ec_creator_platform_live_diagnosis_per_order_value_more_50_GPM_bad',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_PER_WATCHS_VALUE:
    'ec_creator_platform_live_diagnosis_per_watchs_value',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_FEED_CARD_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_feed_card_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_FEED_DRAW_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_feed_draw_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_FEED_HEAD_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_feed_head_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_FOLLOW_PAGE_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_follow_page_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_GENERAL_SEARCH_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_general_search_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_HOMEPAGE_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_homepage_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_LIVE_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_live_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_MESSAGE_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_message_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_OVERALL_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_overall_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_BAD_PUSH_LOW:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_bad_push_low',
  EC_CREATOR_PLATFORM_LIVE_DIAGNOSIS_RATIO_OF_ENTER_NORMAL:
    'ec_creator_platform_live_diagnosis_ratio_of_enter_normal',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_ACTION:
    'ec_creator_platform_product_analysis_action',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_BUTTON_EXPORT:
    'ec_creator_platform_product_analysis_button_export',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_BUTTON_METRICS:
    'ec_creator_platform_product_analysis_button_metrics',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_CALENDAR_DAY:
    'ec_creator_platform_product_analysis_calendar_day',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_CALENDAR_MONTH:
    'ec_creator_platform_product_analysis_calendar_month',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_CALENDAR_WEEK:
    'ec_creator_platform_product_analysis_calendar_week',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_COMMISSION_DRAWER_TITLE:
    'ec_creator_platform_product_analysis_commission_drawer_title',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_COMMISSION_TOP:
    'ec_creator_platform_product_analysis_commission_top',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_CUSTOM_TIME:
    'ec_creator_platform_product_analysis_custom_time',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_DETAIL:
    'ec_creator_platform_product_analysis_detail',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_LAST_28D:
    'ec_creator_platform_product_analysis_last_28d',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_LAST_7D:
    'ec_creator_platform_product_analysis_last_7d',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_BAD_EVALUATION_RATE:
    'ec_creator_platform_product_analysis_metric_bad_evaluation_rate',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_BAD_EVALUATION_RATE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_bad_evaluation_rate_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_BUYERS:
    'ec_creator_platform_product_analysis_metric_buyers',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_BUYERS_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_buyers_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_CLICKS:
    'ec_creator_platform_product_analysis_metric_clicks',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_CLICKS_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_clicks_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_COMMISSION:
    'ec_creator_platform_product_analysis_metric_commission',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_COMMISSION_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_commission_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_COMPLAINTS_RATE:
    'ec_creator_platform_product_analysis_metric_complaints_rate',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_COMPLAINTS_RATE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_complaints_rate_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_ORDERS:
    'ec_creator_platform_product_analysis_metric_orders',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_ORDERS_LIVE:
    'ec_creator_platform_product_analysis_metric_orders_live',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_ORDERS_LIVE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_orders_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_ORDERS_SHOWCASE:
    'ec_creator_platform_product_analysis_metric_orders_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_ORDERS_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_orders_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_ORDERS_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_orders_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_ORDERS_VIDEO:
    'ec_creator_platform_product_analysis_metric_orders_video',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_ORDERS_VIDEO_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_orders_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REFUND:
    'ec_creator_platform_product_analysis_metric_refund',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REFUND_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_refund_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REFUND_UNIT_SALES:
    'ec_creator_platform_product_analysis_metric_refund_unit_sales',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REFUND_UNIT_SALES_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_refund_unit_sales_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_RETURN_RATE:
    'ec_creator_platform_product_analysis_metric_return_rate',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_RETURN_RATE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_return_rate_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REVENUE:
    'ec_creator_platform_product_analysis_metric_revenue',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REVENUE_LIVE:
    'ec_creator_platform_product_analysis_metric_revenue_live',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REVENUE_LIVE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_revenue_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REVENUE_SHOWCASE:
    'ec_creator_platform_product_analysis_metric_revenue_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REVENUE_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_revenue_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REVENUE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_revenue_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REVENUE_VIDEO:
    'ec_creator_platform_product_analysis_metric_revenue_video',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_REVENUE_VIDEO_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_revenue_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_SELECT_DEAL_METRICS:
    'ec_creator_platform_product_analysis_metric_select_deal_metrics',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_SELECT_QUALITY_METRICS:
    'ec_creator_platform_product_analysis_metric_select_quality_metrics',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_SELECT_TRAFFIC_METRICS:
    'ec_creator_platform_product_analysis_metric_select_traffic_metrics',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_UNIT_SALES:
    'ec_creator_platform_product_analysis_metric_unit_sales',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_UNIT_SALES_LIVE:
    'ec_creator_platform_product_analysis_metric_unit_sales_live',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_UNIT_SALES_LIVE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_unit_sales_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_UNIT_SALES_SHOWCASE:
    'ec_creator_platform_product_analysis_metric_unit_sales_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_UNIT_SALES_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_unit_sales_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_UNIT_SALES_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_unit_sales_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_UNIT_SALES_VIDEO:
    'ec_creator_platform_product_analysis_metric_unit_sales_video',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_UNIT_SALES_VIDEO_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_unit_sales_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_VIEWERS:
    'ec_creator_platform_product_analysis_metric_viewers',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_VIEWERS_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_viewers_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_VIEWS:
    'ec_creator_platform_product_analysis_metric_views',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_METRIC_VIEWS_TOOLTIP:
    'ec_creator_platform_product_analysis_metric_views_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_NO_PRODUCT_DATA:
    'ec_creator_platform_product_analysis_no_product_data',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_PAGE_TITLE:
    'ec_creator_platform_product_analysis_page_title',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_PRODUCT_INFO:
    'ec_creator_platform_product_analysis_product_info',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_PRODUCT_LIST_TITLE:
    'ec_creator_platform_product_analysis_product_list_title',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_PRODUCT_VIEWS_DRAWER_TITLE:
    'ec_creator_platform_product_analysis_product_views_drawer_title',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_PRODUCT_VIEWS_TOP:
    'ec_creator_platform_product_analysis_product_views_top',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_RANK:
    'ec_creator_platform_product_analysis_rank',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_RANK_LIST_MOUDLE_TITLE:
    'ec_creator_platform_product_analysis_rank_list_moudle_title',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_RETURN_RATE_DRAWER_TITLE:
    'ec_creator_platform_product_analysis_return_rate_drawer_title',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_RETURN_RATE_TOP:
    'ec_creator_platform_product_analysis_return_rate_top',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_REVENUE_DRAWER_TITLE:
    'ec_creator_platform_product_analysis_revenue_drawer_title',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_REVENUE_TOP:
    'ec_creator_platform_product_analysis_revenue_top',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_SEARCH_PLACEHOLDER:
    'ec_creator_platform_product_analysis_search_placeholder',
  EC_CREATOR_PLATFORM_PRODUCT_ANALYSIS_VIEW_ALL:
    'ec_creator_platform_product_analysis_view_all',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHANNEL_ANALYSIS_MOUDLE_TITLE:
    'ec_creator_platform_product_detail_channel_analysis_moudle_title',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TEXT_AGE:
    'ec_creator_platform_product_detail_chart_text_age',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TEXT_CONVERSION_RATE:
    'ec_creator_platform_product_detail_chart_text_conversion_rate',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TEXT_FEMALE:
    'ec_creator_platform_product_detail_chart_text_female',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TEXT_GENDER:
    'ec_creator_platform_product_detail_chart_text_gender',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TEXT_MALE:
    'ec_creator_platform_product_detail_chart_text_male',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TEXT_NO_DATA:
    'ec_creator_platform_product_detail_chart_text_no data',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TEXT_OTHERS:
    'ec_creator_platform_product_detail_chart_text_others',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TEXT_TREND:
    'ec_creator_platform_product_detail_chart_text_trend',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TITLE_BUYER:
    'ec_creator_platform_product_detail_chart_title_buyer',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TITLE_PRODUCT_CLICK_USER:
    'ec_creator_platform_product_detail_chart_title_product_click_user',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TITLE_PRODUCT_VIEWER:
    'ec_creator_platform_product_detail_chart_title_product_viewer',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_CHART_TITLE_USER_CONVERSION:
    'ec_creator_platform_product_detail_chart_title_user_conversion',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BAD_EVALUATION_RATE_LIVE:
    'ec_creator_platform_product_detail_metric_bad_evaluation_rate_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BAD_EVALUATION_RATE_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_bad_evaluation_rate_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BAD_EVALUATION_RATE_SHOWCASE:
    'ec_creator_platform_product_detail_metric_bad_evaluation_rate_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BAD_EVALUATION_RATE_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_bad_evaluation_rate_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BAD_EVALUATION_RATE_VIDEO:
    'ec_creator_platform_product_detail_metric_bad_evaluation_rate_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BAD_EVALUATION_RATE_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_bad_evaluation_rate_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BUYERS_LIVE:
    'ec_creator_platform_product_detail_metric_buyers_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BUYERS_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_buyers_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BUYERS_SHOWCASE:
    'ec_creator_platform_product_detail_metric_buyers_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BUYERS_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_buyers_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BUYERS_VIDEO:
    'ec_creator_platform_product_detail_metric_buyers_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_BUYERS_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_buyers_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_CLICKS_LIVE:
    'ec_creator_platform_product_detail_metric_clicks_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_CLICKS_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_clicks_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_CLICKS_SHOWCASE:
    'ec_creator_platform_product_detail_metric_clicks_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_CLICKS_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_clicks_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_CLICKS_VIDEO:
    'ec_creator_platform_product_detail_metric_clicks_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_CLICKS_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_clicks_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMMISSION_LIVE:
    'ec_creator_platform_product_detail_metric_commission_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMMISSION_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_commission_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMMISSION_SHOWCASE:
    'ec_creator_platform_product_detail_metric_commission_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMMISSION_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_commission_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMMISSION_VIDEO:
    'ec_creator_platform_product_detail_metric_commission_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMMISSION_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_commission_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMPLAINTS_RATE_LIVE:
    'ec_creator_platform_product_detail_metric_complaints_rate_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMPLAINTS_RATE_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_complaints_rate_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMPLAINTS_RATE_SHOWCASE:
    'ec_creator_platform_product_detail_metric_complaints_rate_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMPLAINTS_RATE_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_complaints_rate_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMPLAINTS_RATE_VIDEO:
    'ec_creator_platform_product_detail_metric_complaints_rate_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_COMPLAINTS_RATE_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_complaints_rate_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_ORDERS_LIVE:
    'ec_creator_platform_product_detail_metric_orders_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_ORDERS_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_orders_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_ORDERS_SHOWCASE:
    'ec_creator_platform_product_detail_metric_orders_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_ORDERS_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_orders_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_ORDERS_VIDEO:
    'ec_creator_platform_product_detail_metric_orders_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_ORDERS_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_orders_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_LIVE:
    'ec_creator_platform_product_detail_metric_refund_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_ORDERS:
    'ec_creator_platform_product_detail_metric_refund_orders',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_ORDERS_LIVE:
    'ec_creator_platform_product_detail_metric_refund_orders_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_ORDERS_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_orders_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_ORDERS_SHOWCASE:
    'ec_creator_platform_product_detail_metric_refund_orders_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_ORDERS_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_orders_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_ORDERS_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_orders_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_ORDERS_VIDEO:
    'ec_creator_platform_product_detail_metric_refund_orders_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_ORDERS_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_orders_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_SHOWCASE:
    'ec_creator_platform_product_detail_metric_refund_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_UNIT_SALES_LIVE:
    'ec_creator_platform_product_detail_metric_refund_unit_sales_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_UNIT_SALES_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_unit_sales_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_UNIT_SALES_SHOWCASE:
    'ec_creator_platform_product_detail_metric_refund_unit_sales_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_UNIT_SALES_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_unit_sales_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_UNIT_SALES_VIDEO:
    'ec_creator_platform_product_detail_metric_refund_unit_sales_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_UNIT_SALES_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_unit_sales_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_VIDEO:
    'ec_creator_platform_product_detail_metric_refund_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REFUND_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_refund_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_RETURN_RATE_LIVE:
    'ec_creator_platform_product_detail_metric_return_rate_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_RETURN_RATE_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_return_rate_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_RETURN_RATE_SHOWCASE:
    'ec_creator_platform_product_detail_metric_return_rate_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_RETURN_RATE_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_return_rate_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_RETURN_RATE_VIDEO:
    'ec_creator_platform_product_detail_metric_return_rate_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_RETURN_RATE_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_return_rate_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REVENUE_LIVE:
    'ec_creator_platform_product_detail_metric_revenue_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REVENUE_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_revenue_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REVENUE_SHOWCASE:
    'ec_creator_platform_product_detail_metric_revenue_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REVENUE_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_revenue_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REVENUE_VIDEO:
    'ec_creator_platform_product_detail_metric_revenue_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_REVENUE_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_revenue_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_UNIT_SALES_LIVE:
    'ec_creator_platform_product_detail_metric_unit_sales_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_UNIT_SALES_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_unit_sales_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_UNIT_SALES_SHOWCASE:
    'ec_creator_platform_product_detail_metric_unit_sales_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_UNIT_SALES_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_unit_sales_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_UNIT_SALES_VIDEO:
    'ec_creator_platform_product_detail_metric_unit_sales_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_UNIT_SALES_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_unit_sales_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWERS_LIVE:
    'ec_creator_platform_product_detail_metric_viewers_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWERS_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_viewers_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWERS_SHOWCASE:
    'ec_creator_platform_product_detail_metric_viewers_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWERS_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_viewers_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWERS_VIDEO:
    'ec_creator_platform_product_detail_metric_viewers_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWERS_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_viewers_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWS_LIVE:
    'ec_creator_platform_product_detail_metric_views_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWS_LIVE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_views_live_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWS_SHOWCASE:
    'ec_creator_platform_product_detail_metric_views_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWS_SHOWCASE_TOOLTIP:
    'ec_creator_platform_product_detail_metric_views_showcase_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWS_VIDEO:
    'ec_creator_platform_product_detail_metric_views_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_METRIC_VIEWS_VIDEO_TOOLTIP:
    'ec_creator_platform_product_detail_metric_views_video_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_PAGE_ERROR_NO_INTERNET_CONNECTION:
    'ec_creator_platform_product_detail_page_error_no_internet_connection',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_PAGE_ERROR_RETRY:
    'ec_creator_platform_product_detail_page_error_retry',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_PAGE_ERROR_TRY_AGAIN:
    'ec_creator_platform_product_detail_page_error_try_again',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_PRODUCT_PERFORMANCE_MOUDLE_TITLE:
    'ec_creator_platform_product_detail_product_performance_moudle_title',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TAB_LIVE_RANKING:
    'ec_creator_platform_product_detail_tab_live_ranking',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TAB_TEXT_ALL:
    'ec_creator_platform_product_detail_tab_text_all',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TAB_TEXT_LIVE:
    'ec_creator_platform_product_detail_tab_text_live',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TAB_TEXT_SHOWCASE:
    'ec_creator_platform_product_detail_tab_text_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TAB_TEXT_VIDEO:
    'ec_creator_platform_product_detail_tab_text_video',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TAB_VIDEO_RANKING:
    'ec_creator_platform_product_detail_tab_video_ranking',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TAG_OFF_SALE:
    'ec_creator_platform_product_detail_tag_off_sale',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TAG_ON_SALE:
    'ec_creator_platform_product_detail_tag_on_sale',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_LIVESTREAMS:
    'ec_creator_platform_product_detail_text_livestreams',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_LIVESTREAMS_TOOLTIP:
    'ec_creator_platform_product_detail_text_livestreams_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_PAGE_TITLE:
    'ec_creator_platform_product_detail_text_page_title',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_PRODUCT_CATEGORY:
    'ec_creator_platform_product_detail_text_product_category',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_PRODUCT_PRICE:
    'ec_creator_platform_product_detail_text_product_price',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_PRODUCT_QUALITY:
    'ec_creator_platform_product_detail_text_product_quality',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_PRODUCT_REFUND:
    'ec_creator_platform_product_detail_text_product_refund',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_PRODUCT_REVENUE:
    'ec_creator_platform_product_detail_text_product_revenue',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_PRODUCT_TRAFFIC:
    'ec_creator_platform_product_detail_text_product_traffic',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_SOURCE_SHOP:
    'ec_creator_platform_product_detail_text_source_shop',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_VIDEOS:
    'ec_creator_platform_product_detail_text_videos',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_VIDEOS_TOOLTIP:
    'ec_creator_platform_product_detail_text_videos_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_VIEW_DETAILS:
    'ec_creator_platform_product_detail_text_view_details',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_VIEW_RELEASE_TIME:
    'ec_creator_platform_product_detail_text_view_release_time',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_TEXT_VIEW_START_TIME:
    'ec_creator_platform_product_detail_text_view_start_time',
  EC_CREATOR_PLATFORM_PRODUCT_DETAIL_USER_ANALYSIS_MOUDLE_TITLE:
    'ec_creator_platform_product_detail_user_analysis_moudle_title',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CATEGORY_SELECTOR:
    'ec_creator_platform_product_ranking_page_category_selector',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CUSTOM_FILTER_BTN_RESET:
    'ec_creator_platform_product_ranking_page_custom_filter_btn_reset',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CUSTOM_FILTER_BTN_SEARCH:
    'ec_creator_platform_product_ranking_page_custom_filter_btn_search',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CUSTOM_FILTER_INPUT_RATE_PLACEHOLDER:
    'ec_creator_platform_product_ranking_page_custom_filter_input_rate_placeholder',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CUSTOM_FILTER_MAX_COMMISSION_RATE_PLACEHOLDER:
    'ec_creator_platform_product_ranking_page_custom_filter_max_commission_rate_placeholder',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CUSTOM_FILTER_MIN_COMMISSION_RATE_PLACEHOLDER:
    'ec_creator_platform_product_ranking_page_custom_filter_min_commission_rate_placeholder',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CUSTOM_FILTER_PRICE_PLACEHOLDER:
    'ec_creator_platform_product_ranking_page_custom_filter_price_placeholder',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CUSTOM_FILTER_VALIDATE_MIN_MORE_THAN_MAX:
    'ec_creator_platform_product_ranking_page_custom_filter_validate_min_more_than_max',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CUSTOM_FILTER_VALIDATE_NUMBER_RANGE_0_100:
    'ec_creator_platform_product_ranking_page_custom_filter_validate_number_range_0_100',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_CUSTOM_FILTER_VALIDATE_NUMBER_RANGE_0_5:
    'ec_creator_platform_product_ranking_page_custom_filter_validate_number_range_0_5',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_MESSAGE_ADD_SHOWCASE_FAILED:
    'ec_creator_platform_product_ranking_page_message_add_showcase_failed',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_MESSAGE_ADD_SHOWCASE_FAILED_PRODUCT_TAKE_DOWN:
    'ec_creator_platform_product_ranking_page_message_add_showcase_failed_product_take_down',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_MESSAGE_ADD_SHOWCASE_SUCCESS:
    'ec_creator_platform_product_ranking_page_message_add_showcase_success',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_ACTION:
    'ec_creator_platform_product_ranking_page_product_list_field_action',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_ACTION_ADDED:
    'ec_creator_platform_product_ranking_page_product_list_field_action_added',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_ACTION_ADD_TO_SHOWCASE:
    'ec_creator_platform_product_ranking_page_product_list_field_action_add_to_showcase',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_ACTION_ADD_TO_SHOWCASE_DISABLE_EXCLUDE_ALLIANCE:
    'ec_creator_platform_product_ranking_page_product_list_field_action_add_to_showcase_disable_exclude_alliance',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_ACTION_ADD_TO_SHOWCASE_DISABLE_EXCLUDE_PALN:
    'ec_creator_platform_product_ranking_page_product_list_field_action_add_to_showcase_disable_exclude_paln',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_COMMISSION_RATE:
    'ec_creator_platform_product_ranking_page_product_list_field_commission_rate',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_COMMISSION_RATE_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_commission_rate_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_CREATORS:
    'ec_creator_platform_product_ranking_page_product_list_field_creators',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_CREATORS_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_creators_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_CTR:
    'ec_creator_platform_product_ranking_page_product_list_field_ctr',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_CTR_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_ctr_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_C_O:
    'ec_creator_platform_product_ranking_page_product_list_field_c_o',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_C_O_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_c_o_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_GOOD_REVIEW_RATE:
    'ec_creator_platform_product_ranking_page_product_list_field_good_review_rate',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_GOOD_REVIEW_RATE_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_good_review_rate_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_ITEMS_SOLD:
    'ec_creator_platform_product_ranking_page_product_list_field_items_sold',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_ITEMS_SOLD_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_items_sold_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_NUMBER_OF_SALES:
    'ec_creator_platform_product_ranking_page_product_list_field_number_of_sales',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_NUMBER_OF_SALES_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_number_of_sales_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_POOR_REVIEW_RATE:
    'ec_creator_platform_product_ranking_page_product_list_field_poor_review_rate',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_POOR_REVIEW_RATE_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_poor_review_rate_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_PRICE:
    'ec_creator_platform_product_ranking_page_product_list_field_price',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_PRODUCT_INFO:
    'ec_creator_platform_product_ranking_page_product_list_field_product_info',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_RANK:
    'ec_creator_platform_product_ranking_page_product_list_field_rank',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_RANK_ADVANCES:
    'ec_creator_platform_product_ranking_page_product_list_field_rank_advances',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_RANK_ADVANCES_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_rank_advances_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_RETURN_RATE:
    'ec_creator_platform_product_ranking_page_product_list_field_return_rate',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_RETURN_RATE_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_return_rate_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_REVENUE:
    'ec_creator_platform_product_ranking_page_product_list_field_revenue',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_REVENUE_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_revenue_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_SHOP_INFO:
    'ec_creator_platform_product_ranking_page_product_list_field_shop_info',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_TRANSACTION_INDEX:
    'ec_creator_platform_product_ranking_page_product_list_field_transaction_index',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_FIELD_TRANSACTION_INDEX_TOOLTIP:
    'ec_creator_platform_product_ranking_page_product_list_field_transaction_index_tooltip',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_NO_DATA:
    'ec_creator_platform_product_ranking_page_product_list_no_data',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_TIP_CROSS_BORDER:
    'ec_creator_platform_product_ranking_page_product_list_tip_cross_border',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_PRODUCT_LIST_VIEW_ITEMS:
    'ec_creator_platform_product_ranking_page_product_list_view_items',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_INTRODUCTION_BEST_SALES:
    'ec_creator_platform_product_ranking_page_ranking_introduction_best_sales',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_INTRODUCTION_CREATORS_LOVE:
    'ec_creator_platform_product_ranking_page_ranking_introduction_creators_love',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_INTRODUCTION_CUSTOM:
    'ec_creator_platform_product_ranking_page_ranking_introduction_custom',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_INTRODUCTION_HIGH_COMMISSION:
    'ec_creator_platform_product_ranking_page_ranking_introduction_high_commission',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_INTRODUCTION_POTENTIAL:
    'ec_creator_platform_product_ranking_page_ranking_introduction_potential',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_TYPE_BEST_SALES:
    'ec_creator_platform_product_ranking_page_ranking_type_best_sales',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_TYPE_CREATORS_LOVE:
    'ec_creator_platform_product_ranking_page_ranking_type_creators_love',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_TYPE_CUSTOM:
    'ec_creator_platform_product_ranking_page_ranking_type_custom',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_TYPE_HIGH_COMMISSION:
    'ec_creator_platform_product_ranking_page_ranking_type_high_commission',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_RANKING_TYPE_POTENTIAL:
    'ec_creator_platform_product_ranking_page_ranking_type_potential',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_TIME_SELECTOR_LAST_28_DAYS:
    'ec_creator_platform_product_ranking_page_time_selector_last_28_days',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_TIME_SELECTOR_LAST_7_DAYS:
    'ec_creator_platform_product_ranking_page_time_selector_last_7_days',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_TIME_SELECTOR_YESTERDAY:
    'ec_creator_platform_product_ranking_page_time_selector_yesterday',
  EC_CREATOR_PLATFORM_PRODUCT_RANKING_PAGE_TITLE:
    'ec_creator_platform_product_ranking_page_title',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_BRANDING_ABILITY:
    'ec_creator_platform_video_analysis_branding_ability',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_BRANDING_ABILITY_TOOLTIP:
    'ec_creator_platform_video_analysis_branding_ability_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_BUTTON_EXPORT:
    'ec_creator_platform_video_analysis_button_export',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_CALENDAR_DAY:
    'ec_creator_platform_video_analysis_calendar_day',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_DRAINAGE_ABILITY:
    'ec_creator_platform_video_analysis_drainage_ability',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_DRAINAGE_ABILITY_TOOLTIP:
    'ec_creator_platform_video_analysis_drainage_ability_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_INPUT_PLACEHOLDER:
    'ec_creator_platform_video_analysis_input_placeholder',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_ITEM_DRAINAGE_PV_TOOLTIP:
    'ec_creator_platform_video_analysis_item_drainage_pv_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_ITEM_DRAINAGE_SUCCESS_RATE_TOOLTIP:
    'ec_creator_platform_video_analysis_item_drainage_success_rate_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_ITEM_DURATION:
    'ec_creator_platform_video_analysis_item_duration',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_ITEM_NEW_FOLLOWERS_TOOLTIP:
    'ec_creator_platform_video_analysis_item_new_followers_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_ITEM_RELEASE_TIME:
    'ec_creator_platform_video_analysis_item_release_time',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_ITEM_REVENUE_TOOLTIP:
    'ec_creator_platform_video_analysis_item_revenue_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_ITEM_RPM_TOOLTIP:
    'ec_creator_platform_video_analysis_item_rpm_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_ITEM_VIEW_DETAILS:
    'ec_creator_platform_video_analysis_item_view_details',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_ITEM_VV_TOOLTIP:
    'ec_creator_platform_video_analysis_item_vv_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_LAST_28D:
    'ec_creator_platform_video_analysis_last_28d',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_LAST_7D:
    'ec_creator_platform_video_analysis_last_7d',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_LIVE_DIAGNOSIS_MESSAGE:
    'ec_creator_platform_video_analysis_live_diagnosis_message',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_DRAINAGE_PV:
    'ec_creator_platform_video_analysis_metric_drainage_pv',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_DRAINAGE_PV_TOOLTIP:
    'ec_creator_platform_video_analysis_metric_drainage_pv_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_DRAINAGE_SUCCESS_RATE:
    'ec_creator_platform_video_analysis_metric_drainage_success_rate',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_DRAINAGE_SUCCESS_RATE_TOOLTIP:
    'ec_creator_platform_video_analysis_metric_drainage_success_rate_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_NEW_FOLLOWERS:
    'ec_creator_platform_video_analysis_metric_new_followers',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_NEW_FOLLOWERS_TOOLTIP:
    'ec_creator_platform_video_analysis_metric_new_followers_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_REVENUE:
    'ec_creator_platform_video_analysis_metric_revenue',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_REVENUE_TOOLTIP:
    'ec_creator_platform_video_analysis_metric_revenue_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_RPM:
    'ec_creator_platform_video_analysis_metric_rpm',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_RPM_TOOLTIP:
    'ec_creator_platform_video_analysis_metric_rpm_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_VV:
    'ec_creator_platform_video_analysis_metric_vv',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_VV_TOOLTIP:
    'ec_creator_platform_video_analysis_metric_vv_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_WATCH_FULL_VIDEO:
    'ec_creator_platform_video_analysis_metric_watch_full_video',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_METRIC_WATCH_FULL_VIDEO_TOOLTIP:
    'ec_creator_platform_video_analysis_metric_watch_full_video_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_MOUDLE_TITLE_CORE_DATA:
    'ec_creator_platform_video_analysis_moudle_title_core_data',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_PAGE_TITLE:
    'ec_creator_platform_video_analysis_page_title',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_SORT_BY:
    'ec_creator_platform_video_analysis_sort_by',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_SORT_METRIC_TIME:
    'ec_creator_platform_video_analysis_sort_metric_time',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_TRANSACTION_TRANSACTION_ABILITY:
    'ec_creator_platform_video_analysis_transaction_transaction_ability',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_TRANSACTION_TRANSACTION_ABILITY_TOOLTIP:
    'ec_creator_platform_video_analysis_transaction_transaction_ability_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_VIDEO_LIST:
    'ec_creator_platform_video_analysis_video_list',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_VIDEO_NAME:
    'ec_creator_platform_video_analysis_video_name',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_VS_LAST_28D:
    'ec_creator_platform_video_analysis_vs_last_28d',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_VS_LAST_7D:
    'ec_creator_platform_video_analysis_vs_last_7d',
  EC_CREATOR_PLATFORM_VIDEO_ANALYSIS_VS_PREVIOUS_D:
    'ec_creator_platform_video_analysis_vs_previous_d',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_EXPORT_PRODUCT_ID:
    'ec_creator_platform_video_details_export_product_id',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_BUYERS:
    'ec_creator_platform_video_details_metric_buyers',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_BUYERS_TOOLTIP:
    'ec_creator_platform_video_details_metric_buyers_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_COMMENT:
    'ec_creator_platform_video_details_metric_comment',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_COMMENT_RATE:
    'ec_creator_platform_video_details_metric_comment_rate',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_COMMENT_TOOLTIP:
    'ec_creator_platform_video_details_metric_comment_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_CTR:
    'ec_creator_platform_video_details_metric_ctr',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_CTR_TOOLTIP:
    'ec_creator_platform_video_details_metric_ctr_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_DRAINAGE_RATE:
    'ec_creator_platform_video_details_metric_drainage_rate',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_FOLLOW_RATE:
    'ec_creator_platform_video_details_metric_follow_rate',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_JUMP_RATE:
    'ec_creator_platform_video_details_metric_jump_rate',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_LIKE:
    'ec_creator_platform_video_details_metric_like',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_LIKE_RATE:
    'ec_creator_platform_video_details_metric_like_rate',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_LIKE_TOOLTIP:
    'ec_creator_platform_video_details_metric_like_tooltip',
  // EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_PRODUCT_REACH:
  //   'ec_creator_platform_video_details_metric_product reach',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_PRODUCT_REACH:
    'ec_creator_platform_video_details_metric_product_reach',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_PRODUCT_REACH_TOOLTIP:
    'ec_creator_platform_video_details_metric_product_reach_tooltip',
  // EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_PRODUCT_VIEW:
  //   'ec_creator_platform_video_details_metric_product view',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_PRODUCT_VIEW:
    'ec_creator_platform_video_details_metric_product_view',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_PRODUCT_VIEW_TOOLTIP:
    'ec_creator_platform_video_details_metric_product_view_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_SALES:
    'ec_creator_platform_video_details_metric_sales',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_SALES_TOOLTIP:
    'ec_creator_platform_video_details_metric_sales_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_SHARE:
    'ec_creator_platform_video_details_metric_share',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_METRIC_SHARE_TOOLTIP:
    'ec_creator_platform_video_details_metric_share_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_MOUDLE_TITLE_CONTENT_ANALYSIS:
    'ec_creator_platform_video_details_moudle_title_content_analysis',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_MOUDLE_TITLE_INTERACTION_ANALYSIS:
    'ec_creator_platform_video_details_moudle_title_interaction_analysis',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_MOUDLE_TITLE_TOP_VIDEOS:
    'ec_creator_platform_video_details_moudle_title_top_videos',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_MOUDLE_TITLE_TOP_VIDEOS_TOOLTIP:
    'ec_creator_platform_video_details_moudle_title_top_videos_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_MOUDLE_TITLE_TRANSACTION_ANALYSIS:
    'ec_creator_platform_video_details_moudle_title_transaction_analysis',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_MOUDLE_TITLE_VIDEO_PROFILE:
    'ec_creator_platform_video_details_moudle_title_video_profile',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_MUSIC_INFO:
    'ec_creator_platform_video_details_music_info',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_NO_DATA:
    'ec_creator_platform_video_details_no_data',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PAGE_TITLE:
    'ec_creator_platform_video_details_page_title',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_13_17:
    'ec_creator_platform_video_details_pie_chart_13_17',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_18_24:
    'ec_creator_platform_video_details_pie_chart_18_24',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_25_34:
    'ec_creator_platform_video_details_pie_chart_25_34',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_35_44:
    'ec_creator_platform_video_details_pie_chart_35_44',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_45_54:
    'ec_creator_platform_video_details_pie_chart_45_54',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_55:
    'ec_creator_platform_video_details_pie_chart_55',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_AGE:
    'ec_creator_platform_video_details_pie_chart_age',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_FEMALE:
    'ec_creator_platform_video_details_pie_chart_female',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_GENDER:
    'ec_creator_platform_video_details_pie_chart_gender',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PIE_CHART_MALE:
    'ec_creator_platform_video_details_pie_chart_male',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_PRODUCT_PRICE:
    'ec_creator_platform_video_details_product_price',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_SELECT_ALL:
    'ec_creator_platform_video_details_select_all',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_TAB_CONTENT_ANALYSIS:
    'ec_creator_platform_video_details_tab_content_analysis',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_TAB_INTERACTION_ANALYSIS:
    'ec_creator_platform_video_details_tab_interaction_analysis',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_TAB_TRANSACTION_ANALYSIS:
    'ec_creator_platform_video_details_tab_transaction_analysis',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_TAB_VIDEO_PROFILE:
    'ec_creator_platform_video_details_tab_video_profile',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_TOP_COUNTRIES_REGIONS:
    'ec_creator_platform_video_details_top_countries_regions',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_TOP_VIDEOS_PLACEHOLDER:
    'ec_creator_platform_video_details_top_videos_placeholder',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_VIDEO_PROFILE_TAB_NEW_FOLLOWERS:
    'ec_creator_platform_video_details_video_profile_tab_new_followers',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_VIDEO_PROFILE_TAB_NEW_FOLLOWERS_TOOLTIP:
    'ec_creator_platform_video_details_video_profile_tab_new_followers_tooltip',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_VIDEO_PROFILE_TAB_WATCH_USERS:
    'ec_creator_platform_video_details_video_profile_tab_watch_users',
  EC_CREATOR_PLATFORM_VIDEO_DETAILS_VIDEO_PROFILE_TAB_WATCH_USERS_TOOLTIP:
    'ec_creator_platform_video_details_video_profile_tab_watch_users_tooltip',
  EC_PARTNER_PLATFORM_COMPASS_DATA_UNAVAILABLE:
    'ec_partner_platform_compass_data_unavailable',
  EC_PARTNER_PLATFORM_COMPASS_EXPORT_BTN:
    'ec_partner_platform_compass_export_btn',
  EC_PARTNER_PLATFORM_COMPASS_METRICS_SELECTOR_BTN:
    'ec_partner_platform_compass_metrics_selector_btn',
  EC_PARTNER_PLATFORM_COMPASS_METRICS_SELECTOR_CANCEL:
    'ec_partner_platform_compass_metrics_selector_cancel',
  EC_PARTNER_PLATFORM_COMPASS_METRICS_SELECTOR_DEFAULT_BTN:
    'ec_partner_platform_compass_metrics_selector_default_btn',
  EC_PARTNER_PLATFORM_COMPASS_METRICS_SELECTOR_SAVE:
    'ec_partner_platform_compass_metrics_selector_save',
  EC_PARTNER_PLATFORM_COMPASS_METRICS_SELECTOR_SELECT_CNT:
    'ec_partner_platform_compass_metrics_selector_select_cnt',
  EC_PARTNER_PLATFORM_COMPASS_METRICS_SELECTOR_SUBTITLE:
    'ec_partner_platform_compass_metrics_selector_subtitle',
  EC_PARTNER_PLATFORM_COMPASS_METRICS_SELECTOR_TITLE:
    'ec_partner_platform_compass_metrics_selector_title',
  EC_PARTNER_PLATFORM_COMPASS_TABLE_COL_NAME:
    'ec_partner_platform_compass_table_col_name',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_AVG_DURATION:
    'ec_partner_platform_creator_analysis_metric_avg_duration',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_AVG_DURATION_DESC:
    'ec_partner_platform_creator_analysis_metric_avg_duration_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_AVG_WATCH_DURATION:
    'ec_partner_platform_creator_analysis_metric_avg_watch_duration',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_AVG_WATCH_DURATION_DESC:
    'ec_partner_platform_creator_analysis_metric_avg_watch_duration_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_AVG_WATCH_PV:
    'ec_partner_platform_creator_analysis_metric_avg_watch_pv',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_AVG_WATCH_PV_DESC:
    'ec_partner_platform_creator_analysis_metric_avg_watch_pv_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_AVG_WATCH_UV:
    'ec_partner_platform_creator_analysis_metric_avg_watch_uv',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_AVG_WATCH_UV_DESC:
    'ec_partner_platform_creator_analysis_metric_avg_watch_uv_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_EST_COMMISSION:
    'ec_partner_platform_creator_analysis_metric_est_commission',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_EST_COMMISSION_DESC:
    'ec_partner_platform_creator_analysis_metric_est_commission_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_FINISH_PLAY_RATE:
    'ec_partner_platform_creator_analysis_metric_finish_play_rate',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_FINISH_PLAY_RATE_DESC:
    'ec_partner_platform_creator_analysis_metric_finish_play_rate_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_FIRST_LIVE_DATE:
    'ec_partner_platform_creator_analysis_metric_first_live_date',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_FIRST_LIVE_DATE_DESC:
    'ec_partner_platform_creator_analysis_metric_first_live_date_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_FIRST_VIDEO_DATE:
    'ec_partner_platform_creator_analysis_metric_first_video_date',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_FIRST_VIDEO_DATE_DESC:
    'ec_partner_platform_creator_analysis_metric_first_video_date_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_FOLLOWERS:
    'ec_partner_platform_creator_analysis_metric_followers',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_FOLLOWERS_DESC:
    'ec_partner_platform_creator_analysis_metric_followers_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_GROUP_BASIC:
    'ec_partner_platform_creator_analysis_metric_group_basic',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_GROUP_LIVE_CONTENT:
    'ec_partner_platform_creator_analysis_metric_group_live_content',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_GROUP_LIVE_INTERACTION:
    'ec_partner_platform_creator_analysis_metric_group_live_interaction',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_GROUP_LIVE_MONETARY:
    'ec_partner_platform_creator_analysis_metric_group_live_monetary',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_GROUP_MONETARY:
    'ec_partner_platform_creator_analysis_metric_group_monetary',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_GROUP_VIDEO_CONTENT:
    'ec_partner_platform_creator_analysis_metric_group_video_content',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_GROUP_VIDEO_INTERACTION:
    'ec_partner_platform_creator_analysis_metric_group_video_interaction',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_GROUP_VIDEO_MONETARY:
    'ec_partner_platform_creator_analysis_metric_group_video_monetary',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVESTREAMS:
    'ec_partner_platform_creator_analysis_metric_livestreams',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVESTREAMS_DESC:
    'ec_partner_platform_creator_analysis_metric_livestreams_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_CTR:
    'ec_partner_platform_creator_analysis_metric_live_ctr',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_CTR_DESC:
    'ec_partner_platform_creator_analysis_metric_live_ctr_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_C_O:
    'ec_partner_platform_creator_analysis_metric_live_c_o',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_C_O_DESC:
    'ec_partner_platform_creator_analysis_metric_live_c_o_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_GPM:
    'ec_partner_platform_creator_analysis_metric_live_gpm',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_GPM_DESC:
    'ec_partner_platform_creator_analysis_metric_live_gpm_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_ORDERS:
    'ec_partner_platform_creator_analysis_metric_live_orders',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_ORDERS_DESC:
    'ec_partner_platform_creator_analysis_metric_live_orders_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_PRODUCT_SALES:
    'ec_partner_platform_creator_analysis_metric_live_product_sales',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_PRODUCT_SALES_DESC:
    'ec_partner_platform_creator_analysis_metric_live_product_sales_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_REVENUE:
    'ec_partner_platform_creator_analysis_metric_live_revenue',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_LIVE_REVENUE_DESC:
    'ec_partner_platform_creator_analysis_metric_live_revenue_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_NEW_FOLLOWERS:
    'ec_partner_platform_creator_analysis_metric_new_followers',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_NEW_FOLLOWERS_DESC:
    'ec_partner_platform_creator_analysis_metric_new_followers_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_ORDERS:
    'ec_partner_platform_creator_analysis_metric_orders',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_ORDERS_DESC:
    'ec_partner_platform_creator_analysis_metric_orders_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_PRODUCT_SALES:
    'ec_partner_platform_creator_analysis_metric_product_sales',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_PRODUCT_SALES_DESC:
    'ec_partner_platform_creator_analysis_metric_product_sales_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_REVENUE:
    'ec_partner_platform_creator_analysis_metric_revenue',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_REVENUE_DESC:
    'ec_partner_platform_creator_analysis_metric_revenue_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_UNINTERESTED_RATE:
    'ec_partner_platform_creator_analysis_metric_uninterested_rate',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_UNINTERESTED_RATE_DESC:
    'ec_partner_platform_creator_analysis_metric_uninterested_rate_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEOS:
    'ec_partner_platform_creator_analysis_metric_videos',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEOS_DESC:
    'ec_partner_platform_creator_analysis_metric_videos_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_CTR:
    'ec_partner_platform_creator_analysis_metric_video_ctr',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_CTR_DESC:
    'ec_partner_platform_creator_analysis_metric_video_ctr_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_C_O:
    'ec_partner_platform_creator_analysis_metric_video_c_o',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_C_O_DESC:
    'ec_partner_platform_creator_analysis_metric_video_c_o_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_GPM:
    'ec_partner_platform_creator_analysis_metric_video_gpm',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_GPM_DESC:
    'ec_partner_platform_creator_analysis_metric_video_gpm_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_ORDERS:
    'ec_partner_platform_creator_analysis_metric_video_orders',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_ORDERS_DESC:
    'ec_partner_platform_creator_analysis_metric_video_orders_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_PRODUCT_SALES:
    'ec_partner_platform_creator_analysis_metric_video_product_sales',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_PRODUCT_SALES_DESC:
    'ec_partner_platform_creator_analysis_metric_video_product_sales_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_REVENUE:
    'ec_partner_platform_creator_analysis_metric_video_revenue',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_REVENUE_DESC:
    'ec_partner_platform_creator_analysis_metric_video_revenue_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_VIEW:
    'ec_partner_platform_creator_analysis_metric_video_view',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_VIEWERS:
    'ec_partner_platform_creator_analysis_metric_video_viewers',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_VIEWERS_DESC:
    'ec_partner_platform_creator_analysis_metric_video_viewers_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_METRIC_VIDEO_VIEW_DESC:
    'ec_partner_platform_creator_analysis_metric_video_view_desc',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_TAB_AUTHORIZED:
    'ec_partner_platform_creator_analysis_tab_authorized',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_TAB_UNAUTHORIZED:
    'ec_partner_platform_creator_analysis_tab_unauthorized',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_TITLE:
    'ec_partner_platform_creator_analysis_title',
  EC_PARTNER_PLATFORM_CREATOR_ANALYSIS_UNAVAILABLE_POLICY:
    'ec_partner_platform_creator_analysis_unavailable_policy',
  EC_PARTNER_PLATFORM_DATA_COMPASS: 'ec_partner_platform_data_compass',
  EC_PARTNER_PLATFORM_DATA_COMPASS_DATA_OVERVIEW_TITLE:
    'ec_partner_platform_data_compass_data_overview_title',
  EC_PARTNER_PLATFORM_DATA_COMPASS_LIVE_ANALYSIS_TITLE:
    'ec_partner_platform_data_compass_live_analysis_title',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_ACTIVE_LIVE_CREATORS:
    'ec_partner_platform_data_overview_core_data_active_live_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_ACTIVE_LIVE_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_active_live_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_ACTIVE_LIVE_MERCHANTS:
    'ec_partner_platform_data_overview_core_data_active_live_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_ACTIVE_LIVE_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_active_live_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_ACTIVE_LIVE_PARTNER:
    'ec_partner_platform_data_overview_core_data_active_live_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_ACTIVE_LIVE_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_active_live_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_CREATORS_WITH_SALES:
    'ec_partner_platform_data_overview_core_data_creators_with_sales',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_CREATORS_WITH_SALES_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_creators_with_sales_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_RATE_CREATORS:
    'ec_partner_platform_data_overview_core_data_live_rate_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_RATE_CREATORS__DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_live_rate_creators _description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_RATE_MERCHANTS:
    'ec_partner_platform_data_overview_core_data_live_rate_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_RATE_MERCHANTS__DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_live_rate_merchants _description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_RATE_PARTNER:
    'ec_partner_platform_data_overview_core_data_live_rate_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_LIVE_RATE_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_live_rate_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_MERCHANTS_WITH_SALES:
    'ec_partner_platform_data_overview_core_data_merchants_with_sales',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_MERCHANTS_WITH_SALES_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_merchants_with_sales_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_PARTNER_WITH_SALES:
    'ec_partner_platform_data_overview_core_data_partner_with_sales',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_PARTNER_WITH_SALES_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_partner_with_sales_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_SALES_RATE_CREATORS:
    'ec_partner_platform_data_overview_core_data_sales_rate_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_SALES_RATE_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_sales_rate_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_SALES_RATE_MERCHANTS:
    'ec_partner_platform_data_overview_core_data_sales_rate_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_SALES_RATE_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_sales_rate_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_SALES_RATE_PARTNER:
    'ec_partner_platform_data_overview_core_data_sales_rate_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_SALES_RATE_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_core_data_sales_rate_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_CORE_DATA_TITLE:
    'ec_partner_platform_data_overview_core_data_title',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_AVG_CTR_CREATORS:
    'ec_partner_platform_data_overview_ec_video_trend_avg_ctr_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_AVG_CTR_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_avg_ctr_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_AVG_CTR_MERCHANTS:
    'ec_partner_platform_data_overview_ec_video_trend_avg_ctr_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_AVG_CTR_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_avg_ctr_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_AVG_CTR_PARTNER:
    'ec_partner_platform_data_overview_ec_video_trend_avg_ctr_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_AVG_CTR_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_avg_ctr_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_ORDERS_CREATORS:
    'ec_partner_platform_data_overview_ec_video_trend_orders_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_ORDERS_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_orders_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_ORDERS_MERCHANTS:
    'ec_partner_platform_data_overview_ec_video_trend_orders_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_ORDERS_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_orders_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_ORDERS_PARTNER:
    'ec_partner_platform_data_overview_ec_video_trend_orders_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_ORDERS_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_orders_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_TITLE:
    'ec_partner_platform_data_overview_ec_video_trend_title',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_UNIT_SALES_CREATORS:
    'ec_partner_platform_data_overview_ec_video_trend_unit_sales_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_UNIT_SALES_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_unit_sales_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_UNIT_SALES_MERCHANTS:
    'ec_partner_platform_data_overview_ec_video_trend_unit_sales_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_UNIT_SALES_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_unit_sales_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_UNIT_SALES_PARTNER:
    'ec_partner_platform_data_overview_ec_video_trend_unit_sales_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_UNIT_SALES_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_unit_sales_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_VIDEOS_CREATORS:
    'ec_partner_platform_data_overview_ec_video_trend_videos_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_VIDEOS_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_videos_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_VIDEOS_MERCHANTS:
    'ec_partner_platform_data_overview_ec_video_trend_videos_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_VIDEOS_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_videos_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_VIDEOS_PARTNER:
    'ec_partner_platform_data_overview_ec_video_trend_videos_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_EC_VIDEO_TREND_VIDEOS_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_ec_video_trend_videos_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_AVG_LIVE_CREATORS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_avg_live_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_AVG_LIVE_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_avg_live_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_AVG_LIVE_MERCHANTS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_avg_live_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_AVG_LIVE_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_avg_live_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_AVG_LIVE_PARTNER:
    'ec_partner_platform_data_overview_gmv_trend_gmv_avg_live_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_AVG_LIVE_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_avg_live_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_LIVE_CREATORS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_live_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_LIVE_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_live_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_LIVE_MERCHANTS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_live_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_LIVE_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_live_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_LIVE_PARTNER:
    'ec_partner_platform_data_overview_gmv_trend_gmv_live_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_LIVE_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_live_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_REFUND_CREATORS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_refund_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_REFUND_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_refund_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_REFUND_MERCHANTS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_refund_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_REFUND_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_refund_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_REFUND_PARTNER:
    'ec_partner_platform_data_overview_gmv_trend_gmv_refund_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_REFUND_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_refund_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_TOTAL_CREATORS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_total_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_TOTAL_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_total_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_TOTAL_MERCHANTS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_total_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_TOTAL_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_total_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_TOTAL_PARTNER:
    'ec_partner_platform_data_overview_gmv_trend_gmv_total_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_TOTAL_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_total_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_VIDEO_CREATORS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_video_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_VIDEO_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_video_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_VIDEO_MERCHANTS:
    'ec_partner_platform_data_overview_gmv_trend_gmv_video_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_VIDEO_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_video_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_VIDEO_PARTNER:
    'ec_partner_platform_data_overview_gmv_trend_gmv_video_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_GMV_VIDEO_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_gmv_trend_gmv_video_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_GMV_TREND_TITLE:
    'ec_partner_platform_data_overview_gmv_trend_title',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_AVG_CRT_CREATORS:
    'ec_partner_platform_data_overview_livestream_trend_avg_crt_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_AVG_CRT_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_avg_crt_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_AVG_CRT_MERCHANTS:
    'ec_partner_platform_data_overview_livestream_trend_avg_crt_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_AVG_CRT_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_avg_crt_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_AVG_CTR_PARTNER:
    'ec_partner_platform_data_overview_livestream_trend_avg_ctr_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_AVG_CTR_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_avg_ctr_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_DURATION_CREATORS:
    'ec_partner_platform_data_overview_livestream_trend_duration_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_DURATION_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_duration_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_DURATION_MERCHANTS:
    'ec_partner_platform_data_overview_livestream_trend_duration_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_DURATION_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_duration_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_DURATION_PARTNER:
    'ec_partner_platform_data_overview_livestream_trend_duration_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_DURATION_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_duration_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_LIVESTREAMS_CREATORS:
    'ec_partner_platform_data_overview_livestream_trend_livestreams_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_LIVESTREAMS_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_livestreams_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_LIVESTREAMS_MERCHANTS:
    'ec_partner_platform_data_overview_livestream_trend_livestreams_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_LIVESTREAMS_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_livestreams_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_LIVESTREAMS_PARTNER:
    'ec_partner_platform_data_overview_livestream_trend_livestreams_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_LIVESTREAMS_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_livestreams_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_ORDERS_CREATORS:
    'ec_partner_platform_data_overview_livestream_trend_orders_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_ORDERS_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_orders_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_ORDERS_MERCHANTS:
    'ec_partner_platform_data_overview_livestream_trend_orders_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_ORDERS_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_orders_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_ORDERS_PARTNER:
    'ec_partner_platform_data_overview_livestream_trend_orders_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_ORDERS_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_orders_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_TITLE:
    'ec_partner_platform_data_overview_livestream_trend_title',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_UNIT_SALES_CREATORS:
    'ec_partner_platform_data_overview_livestream_trend_unit_sales_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_UNIT_SALES_CREATORS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_unit_sales_creators_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_UNIT_SALES_MERCHANTS:
    'ec_partner_platform_data_overview_livestream_trend_unit_sales_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_UNIT_SALES_MERCHANTS_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_unit_sales_merchants_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_UNIT_SALES_PARTNER:
    'ec_partner_platform_data_overview_livestream_trend_unit_sales_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_LIVESTREAM_TREND_UNIT_SALES_PARTNER_DESCRIPTION:
    'ec_partner_platform_data_overview_livestream_trend_unit_sales_partner_description',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_METRIC_CARD_VS_LAST_LAST_DAYS:
    'ec_partner_platform_data_overview_metric_card_vs_last_last_days',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_METRIC_CARD_VS_LAST_MONTH:
    'ec_partner_platform_data_overview_metric_card_vs_last_month',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_METRIC_CARD_VS_LAST_PREVIOUS_DAYS:
    'ec_partner_platform_data_overview_metric_card_vs_last_previous_days',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_METRIC_CARD_VS_LAST_WEEK:
    'ec_partner_platform_data_overview_metric_card_vs_last_week',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_TAB_ALL_PARTNER:
    'ec_partner_platform_data_overview_tab_all_partner',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_TAB_CREATORS:
    'ec_partner_platform_data_overview_tab_creators',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_TAB_MERCHANTS:
    'ec_partner_platform_data_overview_tab_merchants',
  EC_PARTNER_PLATFORM_DATA_OVERVIEW_TITLE_TIMEZONE_LABEL:
    'ec_partner_platform_data_overview_title_timezone_label',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_APPLY_PERMISSION_BTN:
    'ec_partner_platform_live_analysis_apply_permission_btn',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_BULK_APPLY_PERMISSION_BTN:
    'ec_partner_platform_live_analysis_bulk_apply_permission_btn',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_COMMENTS_EMPTY:
    'ec_partner_platform_live_analysis_comments_empty',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_CREATED_ORDERS_DESC:
    'ec_partner_platform_live_analysis_created_orders_desc',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_CREATED_ORDERS_TITLE:
    'ec_partner_platform_live_analysis_created_orders_title',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_DETAIL_LIVE_TITLE:
    'ec_partner_platform_live_analysis_detail_live_title',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_EMPTY_CREATORS:
    'ec_partner_platform_live_analysis_empty_creators',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_GMV_DESC:
    'ec_partner_platform_live_analysis_gmv_desc',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_GMV_TITLE:
    'ec_partner_platform_live_analysis_gmv_title',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVES_ENDED:
    'ec_partner_platform_live_analysis_lives_ended',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_OVERLAY_CLICK_DESC:
    'ec_partner_platform_live_analysis_live_overlay_click_desc',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_OVERLAY_IN_DETAILED:
    'ec_partner_platform_live_analysis_live_overlay_in_detailed',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_SCREEN_REDIRECT_BTN:
    'ec_partner_platform_live_analysis_live_screen_redirect_btn',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_COLUMN_ACTION:
    'ec_partner_platform_live_analysis_live_table_column_action',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_COLUMN_DURATION:
    'ec_partner_platform_live_analysis_live_table_column_duration',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_COLUMN_GMV:
    'ec_partner_platform_live_analysis_live_table_column_gmv',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_COLUMN_NAME:
    'ec_partner_platform_live_analysis_live_table_column_name',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_COLUMN_SALES:
    'ec_partner_platform_live_analysis_live_table_column_sales',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_COLUMN_START:
    'ec_partner_platform_live_analysis_live_table_column_start',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_COLUMN_VIEWERS:
    'ec_partner_platform_live_analysis_live_table_column_viewers',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_DATE_END_PLACEHOLDER:
    'ec_partner_platform_live_analysis_live_table_date_end_placeholder',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_DATE_START_PLACEHOLDER:
    'ec_partner_platform_live_analysis_live_table_date_start_placeholder',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_LIVE_TABLE_OWNER_TITLE:
    'ec_partner_platform_live_analysis_live_table_owner_title',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_NO_ONGOING_LIVES:
    'ec_partner_platform_live_analysis_no_ongoing_lives',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PAID_ORDERS_DESC:
    'ec_partner_platform_live_analysis_paid_orders_desc',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PAID_ORDERS_TITLE:
    'ec_partner_platform_live_analysis_paid_orders_title',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PERMISSION_PENDING:
    'ec_partner_platform_live_analysis_permission_pending',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PERMISSION_REJECTED:
    'ec_partner_platform_live_analysis_permission_rejected',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PERMISSION_TOAST_FAIL:
    'ec_partner_platform_live_analysis_permission_toast_fail',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PERMISSION_UNAUTHORIZED:
    'ec_partner_platform_live_analysis_permission_unauthorized',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PRODUCT_CLICKS_DESC:
    'ec_partner_platform_live_analysis_product_clicks_desc',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PRODUCT_CLICKS_TITLE:
    'ec_partner_platform_live_analysis_product_clicks_title',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PRODUCT_IMPRESSION_DESC:
    'ec_partner_platform_live_analysis_product_impression_desc',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_PRODUCT_IMPRESSION_TITLE:
    'ec_partner_platform_live_analysis_product_impression_title',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_REMOVE_LIVESTREAMS:
    'ec_partner_platform_live_analysis_remove_livestreams',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_SALES_DESC:
    'ec_partner_platform_live_analysis_sales_desc',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_SALES_TITLE:
    'ec_partner_platform_live_analysis_sales_title',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_SEARCH_CREATORS:
    'ec_partner_platform_live_analysis_search_creators',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_SELECT_CREATOR_FIRST:
    'ec_partner_platform_live_analysis_select_creator_first',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_TAB_ALL_LIVE:
    'ec_partner_platform_live_analysis_tab_all_live',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_TAB_ALL_REDIRECT_BTN:
    'ec_partner_platform_live_analysis_tab_all_redirect_btn',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_TAB_ONGOING_LIVE:
    'ec_partner_platform_live_analysis_tab_ongoing_live',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_VIDEO_LIST_MODULE_DESC:
    'ec_partner_platform_live_analysis_video_list_module_desc',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_VIEWERS_DESC:
    'ec_partner_platform_live_analysis_viewers_desc',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_VIEWERS_TITLE:
    'ec_partner_platform_live_analysis_viewers_title',
  EC_PARTNER_PLATFORM_LIVE_ANALYSIS_VS_5_MINS:
    'ec_partner_platform_live_analysis_vs_5_mins',
  EC_PARTNER_PLATFORM_TIME_SELECT_CALENDER_DAY:
    'ec_partner_platform_time_select_calender_day',
  EC_PARTNER_PLATFORM_TIME_SELECT_CALENDER_PERIOD:
    'ec_partner_platform_time_select_calender_period',
  EC_PARTNER_PLATFORM_TIME_SELECT_LAST_28_DAYS:
    'ec_partner_platform_time_select_last_28_days',
  EC_PARTNER_PLATFORM_TIME_SELECT_LAST_7_DAYS:
    'ec_partner_platform_time_select_last_7_days',
  EC_PARTNER_PLATFORM_TIME_SELECT_NATURAL_MONTH:
    'ec_partner_platform_time_select_natural_month',
  EC_PARTNER_PLATFORM_TIME_SELECT_NATURAL_WEEK:
    'ec_partner_platform_time_select_natural_week',
  E_COMMERCE_CEATOR_MARKETPLACE_CONTACT_INF_DIALOG_CTA:
    'e_commerce_ceator_marketplace_contact_inf_dialog_cta',
  E_COMMERCE_CREATOR_MARKETPLACE_CONTACT_INF_CP:
    'e_commerce_creator_marketplace_contact_inf_cp',
  E_COMMERCE_CREATOR_MARKETPLACE_CONTACT_INF_CTA:
    'e_commerce_creator_marketplace_contact_inf_cta',
  E_COMMERCE_CREATOR_MARKETPLACE_CONTACT_INF_DIALOG_PRIMARY:
    'e_commerce_creator_marketplace_contact_inf_dialog_primary',
  E_COMMERCE_CREATOR_MARKETPLACE_CONTACT_INF_DIALOG_SECONDARY:
    'e_commerce_creator_marketplace_contact_inf_dialog_secondary',
  E_COMMERCE_CREATOR_MARKETPLACE_CONTACT_INF_DIALOG_TITLE:
    'e_commerce_creator_marketplace_contact_inf_dialog_title',
  FINANCE_PAGE_TITLE_PAYMENTS: 'finance_page_title_payments',
  LIVEGIVEAWAY_ABNORMALACTIVITIES: 'livegiveaway_abnormalactivities',
  LIVEGIVEAWAY_ADDNEW: 'livegiveaway_addnew',
  LIVEGIVEAWAY_ADDPRODUCT: 'livegiveaway_addproduct',
  LIVEGIVEAWAY_ADDPRODUCT_INTRODUCTION: 'livegiveaway_addproduct_introduction',
  LIVEGIVEAWAY_ADDRESSNOTCONFIRMED: 'livegiveaway_addressnotconfirmed',
  LIVEGIVEAWAY_ADDRESSOVERDUE: 'livegiveaway_addressoverdue',
  LIVEGIVEAWAY_ADD_AVTIVITIES: 'livegiveaway_add_avtivities',
  LIVEGIVEAWAY_AVAILABLE: 'livegiveaway_available',
  LIVEGIVEAWAY_AVTIVITIES: 'livegiveaway_avtivities',
  LIVEGIVEAWAY_BACK: 'livegiveaway_back',
  LIVEGIVEAWAY_BUTTON_PUBLISH: 'livegiveaway_button_publish',
  LIVEGIVEAWAY_CANCEL: 'livegiveaway_cancel',
  LIVEGIVEAWAY_COMMENT: 'livegiveaway_comment',
  LIVEGIVEAWAY_COMMENTCONTENT: 'livegiveaway_commentcontent',
  LIVEGIVEAWAY_COMMENTDISAPPROVED: 'livegiveaway_commentdisapproved',
  LIVEGIVEAWAY_COMMENT_INTRODUCTION: 'livegiveaway_comment_introduction',
  LIVEGIVEAWAY_COMPLETE: 'livegiveaway_complete',
  LIVEGIVEAWAY_COMPLETE_UXW: 'livegiveaway_complete_UXW',
  LIVEGIVEAWAY_CONFIRM: 'livegiveaway_confirm',
  LIVEGIVEAWAY_CONFIRMADDRESS: 'livegiveaway_confirmaddress',
  LIVEGIVEAWAY_CONFIRMED: 'livegiveaway_confirmed',
  LIVEGIVEAWAY_CONFIRMED_ADDRESS: 'livegiveaway_confirmed_address',
  LIVEGIVEAWAY_CONGRATULATIONS: 'livegiveaway_congratulations',
  LIVEGIVEAWAY_COUNTDOWN: 'livegiveaway_countdown',
  LIVEGIVEAWAY_CREATENEW: 'livegiveaway_createnew',
  LIVEGIVEAWAY_CREATENEW_PATCH: 'livegiveaway_createnew_patch',
  LIVEGIVEAWAY_CUSTOM: 'livegiveaway_custom',
  LIVEGIVEAWAY_DELETE: 'livegiveaway_delete',
  LIVEGIVEAWAY_DELETE_BUTTON: 'livegiveaway_delete_button',
  LIVEGIVEAWAY_DETAIL_1: 'livegiveaway_detail_1',
  LIVEGIVEAWAY_DISAPPOVED: 'livegiveaway_disappoved',
  LIVEGIVEAWAY_ENDSIN: 'livegiveaway_endsin',
  LIVEGIVEAWAY_END_FEEDBACK: 'livegiveaway_end_feedback',
  LIVEGIVEAWAY_ENTERNUMBER: 'livegiveaway_enternumber',
  LIVEGIVEAWAY_FAIL_FEEDBACK: 'livegiveaway_fail_feedback',
  LIVEGIVEAWAY_FEEDBACK_PUBLISH: 'livegiveaway_feedback_publish',
  LIVEGIVEAWAY_FEEDBACK_REVIEW: 'livegiveaway_feedback_review',
  LIVEGIVEAWAY_GIVEAWAYISONGOING: 'livegiveaway_giveawayisongoing',
  LIVEGIVEAWAY_HOWTOJOIN: 'livegiveaway_howtojoin',
  LIVEGIVEAWAY_INVALID: 'livegiveaway_invalid',
  LIVEGIVEAWAY_INVITE: 'livegiveaway_invite',
  LIVEGIVEAWAY_ITEMSPERWINNER: 'livegiveaway_itemsperwinner',
  LIVEGIVEAWAY_JOIN: 'livegiveaway_join',
  LIVEGIVEAWAY_JOINED: 'livegiveaway_joined',
  LIVEGIVEAWAY_KEY11: 'livegiveaway_key11',
  LIVEGIVEAWAY_MINUTES: 'livegiveaway_minutes',
  LIVEGIVEAWAY_NAME: 'livegiveaway_name',
  LIVEGIVEAWAY_NAME_A: 'livegiveaway_name_A',
  // LIVEGIVEAWAY_NAME_A: 'livegiveaway_name_a',
  LIVEGIVEAWAY_NOAVTIVITIES: 'livegiveaway_noavtivities',
  LIVEGIVEAWAY_NONE: 'livegiveaway_none',
  LIVEGIVEAWAY_NOONEJOINED: 'livegiveaway_noonejoined',
  LIVEGIVEAWAY_NUMBEROFWINNERS: 'livegiveaway_numberofwinners',
  LIVEGIVEAWAY_OK: 'livegiveaway_ok',
  LIVEGIVEAWAY_ONGOING: 'livegiveaway_ongoing',
  LIVEGIVEAWAY_PRICE: 'livegiveaway_price',
  LIVEGIVEAWAY_PRODUCTS: 'livegiveaway_products',
  LIVEGIVEAWAY_PROUDCTS: 'livegiveaway_proudcts',
  LIVEGIVEAWAY_PUBLISHANOTHER: 'livegiveaway_publishanother',
  LIVEGIVEAWAY_PUBLISH_REQUEST: 'livegiveaway_Publish_request',
  LIVEGIVEAWAY_PUBLISH_TOPIC: 'livegiveaway_publish_topic',
  LIVEGIVEAWAY_QUANTITY: 'livegiveaway_quantity',
  LIVEGIVEAWAY_READYTOPUBLISH: 'livegiveaway_readytopublish',
  LIVEGIVEAWAY_RECEIVED: 'livegiveaway_received',
  LIVEGIVEAWAY_SELECT: 'livegiveaway_select',
  LIVEGIVEAWAY_SET: 'livegiveaway_set',
  LIVEGIVEAWAY_SETQUQNTITY: 'livegiveaway_setquqntity',
  LIVEGIVEAWAY_SHIPPED: 'livegiveaway_shipped',
  LIVEGIVEAWAY_SHIPPED_UXW: 'livegiveaway_shipped_UXW',
  LIVEGIVEAWAY_SOMEONEJOINED: 'livegiveaway_someonejoined',
  LIVEGIVEAWAY_SPONSOREDBYHOST: 'livegiveaway_Sponsoredbyhost',
  LIVEGIVEAWAY_TASK: 'livegiveaway_task',
  LIVEGIVEAWAY_THANKSFORJOINING: 'livegiveaway_thanksforjoining',
  LIVEGIVEAWAY_TIPSOUTOFSTOCK: 'livegiveaway_tipsoutofstock',
  LIVEGIVEAWAY_TIPS_COUNTDOWN: 'livegiveaway_tips_countdown',
  LIVEGIVEAWAY_TIPS_NOPRODUCT: 'livegiveaway_tips_noproduct',
  LIVEGIVEAWAY_TIPS_STAYINLIVEROOM: 'livegiveaway_tips_stayinliveroom',
  LIVEGIVEAWAY_TIPS_UNDONE: 'livegiveaway_tips_undone',
  LIVEGIVEAWAY_TOAST_DELETE: 'livegiveaway_toast_delete',
  LIVEGIVEAWAY_TOAST_ONGOING: 'livegiveaway_toast_ongoing',
  LIVEGIVEAWAY_TOAST_SUCCESS: 'livegiveaway_toast_success',
  LIVEGIVEAWAY_TOAST_UNDERREVIEW2: 'livegiveaway_toast_underreview2',
  LIVEGIVEAWAY_TOAST_UNDERREVIEW: 'livegiveaway_toast_underreview',
  LIVEGIVEAWAY_TOBESHIPPED: 'livegiveaway_tobeshipped',
  LIVEGIVEAWAY_UNDERREVIEW: 'livegiveaway_underreview',
  LIVEGIVEAWAY_UNDONE: 'livegiveaway_undone',
  LIVEGIVEAWAY_UX_CREATE: 'livegiveaway_UX_create',
  LIVEGIVEAWAY_VIEWOTHERWINNERS: 'livegiveaway_viewotherwinners',
  LIVEGIVEAWAY_VIEWWINNERS: 'livegiveaway_viewwinners',
  LIVEGIVEAWAY_WINNERS: 'livegiveaway_winners',
  LIVEGIVEAWAY_WINNERSEACHINEGETS: 'livegiveaway_winnerseachinegets',
  LIVESTREAM_CAMPAIGN_CATEGORY_BEAUTY: 'livestream_campaign_category_beauty',
  LIVESTREAM_CAMPAIGN_CATEGORY_ELECTRONICS:
    'livestream_campaign_category_electronics',
  LIVESTREAM_CAMPAIGN_CATEGORY_FASHION: 'livestream_campaign_category_fashion',
  LIVESTREAM_CAMPAIGN_CATEGORY_FOOD: 'livestream_campaign_category_food',
  LIVESTREAM_CAMPAIGN_CATEGORY_HEALTH_BEAUTY:
    'livestream_campaign_category_health_beauty',
  LIVESTREAM_CAMPAIGN_CATEGORY_HEALTH_PERSONAL_CARE:
    'livestream_campaign_category_health_personal_care',
  LIVESTREAM_CAMPAIGN_CATEGORY_HOME_LIVING:
    'livestream_campaign_category_home_living',
  LIVESTREAM_CAMPAIGN_CATEGORY_MIXED: 'livestream_campaign_category_mixed',
  LIVESTREAM_CAMPAIGN_CATEGORY_MOMS_BABIES:
    'livestream_campaign_category_moms_babies',
  LIVESTREAM_CAMPAIGN_LEVEL_A1: 'livestream_campaign_level_A1',
  LIVESTREAM_CAMPAIGN_LEVEL_A2: 'livestream_campaign_level_A2',
  LIVESTREAM_CAMPAIGN_LEVEL_A: 'livestream_campaign_level_A',
  LIVESTREAM_CAMPAIGN_LEVEL_B: 'livestream_campaign_level_B',
  LIVESTREAM_CAMPAIGN_LEVEL_S: 'livestream_campaign_level_S',
  LIVESTREAM_CAMPAIGN_LEVEL_SS: 'livestream_campaign_level_SS',
  LIVESTREAM_CAMPAIGN_LEVEL_SSS: 'livestream_campaign_level_SSS',
  LIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_CRITERIA:
    'livestream_campaign_requirements_not_meet_reason_criteria',
  LIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_ENFORCEMENT_POINT:
    'livestream_campaign_requirements_not_meet_reason_enforcement_point',
  LIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_FULFILLMENT_PERFORMANCE:
    'livestream_campaign_requirements_not_meet_reason_fulfillment_performance',
  LIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_OWE:
    'livestream_campaign_requirements_not_meet_reason_owe',
  LIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_RISKY:
    'livestream_campaign_requirements_not_meet_reason_risky',
  LIVESTREAM_CAMPAIGN_REQUIREMENTS_PERFORMANCE_DETAI:
    'livestream_campaign_requirements_performance_detai',
  LLIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_CRITERIA:
    'llivestream_campaign_requirements_not_meet_reason_criteria',
  LLIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_ENFORCEMENT_POINT:
    'llivestream_campaign_requirements_not_meet_reason_enforcement_point',
  LLIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_FULFILLMENT_PERFORMANCE:
    'llivestream_campaign_requirements_not_meet_reason_fulfillment_performance',
  LLIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_OWE:
    'llivestream_campaign_requirements_not_meet_reason_owe',
  LLIVESTREAM_CAMPAIGN_REQUIREMENTS_NOT_MEET_REASON_RISKY:
    'llivestream_campaign_requirements_not_meet_reason_risky',
  LLIVESTREAM_CAMPAIGN_REQUIREMENTS_PERFORMANCE_DETAIL:
    'llivestream_campaign_requirements_performance_detail',
  OPEN_PLAN_LIST_STATUS_DELETED: 'open_plan_list_status_deleted',
  OPEN_PLAN_LIST_STATUS_DELETED_HOVER: 'open_plan_list_status_deleted_hover',
  OPEN_PLAN_LIST_STATUS_DELETING: 'open_plan_list_status_deleting',
  OPEN_PLAN_LIST_STATUS_DELETING_HOVER: 'open_plan_list_status_deleting_hover',
  OPEN_PLAN_LIST_STATUS_FLITER_OK: 'open_plan_list_status_fliter_ok',
  OPEN_PLAN_LIST_STATUS_FLITER_RESET: 'open_plan_list_status_fliter_reset',
  OPEN_PLAN_LIST_STATUS_RUNNING: 'open_plan_list_status_running',
  OPEN_PLAN_LIST_STATUS_UNAVAILABLE: 'open_plan_list_status_unavailable',
  OPEN_PLAN_LIST_STATUS_UNAVAILABLE_HOVER:
    'open_plan_list_status_unavailable_hover',
  OPEN_PLAN_MANAGE_APPLICATIONS_PAGE_TITLE:
    'open_plan_manage_applications_page_title',
  OPEN_PLAN_MANAGE_APPLICATIONS_REMINDER:
    'open_plan_manage_applications_reminder',
  OPEN_PLAN_MANAGE_APPLICATIONS_TIP: 'open_plan_manage_applications_tip',
  OPEN_PLAN_MANAGE_APPLICATION_LIST_PRODUCT_STATUS:
    'open_plan_manage_application_list_product_status',
  OPEN_PLAN_MANAGE_APPLICATION_LIST_PRODUCT_STATUS_LIVE:
    'open_plan_manage_application_list_product_status_live',
  OPEN_PLAN_MANAGE_APPLICATION_LIST_PRODUCT_STATUS_SOLD_OUT:
    'open_plan_manage_application_list_product_status_sold_out',
  OPEN_PLAN_MANAGE_APPLICATION_LIST_PRODUCT_STATUS_UNAVAILABLE:
    'open_plan_manage_application_list_product_status_unavailable',
  OPEN_PLAN_MANAGE_APPLICATION_LIST_PRODUCT_STATUS_WITHDRAWN:
    'open_plan_manage_application_list_product_status_withdrawn',
  OPEN_PLAN_MANAGE_APPLICATION_LIST_SELECTED:
    'open_plan_manage_application_list_selected',
  OPEN_PLAN_MANAGE_APPLICATION_LIST_STATUS_REJECTING:
    'open_plan_manage_application_list_status_rejecting',
  OPEN_PLAN_MANAGE_APPLICATION_LIST_STATUS_REJECTING_HOVER:
    'open_plan_manage_application_list_status_rejecting_hover',
  OPEN_PLAN_MANAGE_APPLICATION_RESET_BTN:
    'open_plan_manage_application_reset_btn',
  OPEN_PLAN_MANAGE_APPLICATION_SEARCH_BTN:
    'open_plan_manage_application_search_btn',
  OPEN_PLAN_MANAGE_APPLICATION_SEARCH_BY_CREATOR_PLACEHOLDER:
    'open_plan_manage_application_search_by_creator_placeholder',
  OPEN_PLAN_UNAVAILABLE_EXPLAIN_MANAGE_PRODUCT:
    'open_plan_unavailable_explain_manage_product',
  OPEN_PLAN_UNAVAILABLE_EXPLAIN_TEXT: 'open_plan_unavailable_explain_text',
  OPEN_PLAN_UNAVAILABLE_TOAST_PRODUCT_NOT_MEET_STANDARD_CHECK_PRODUCT_MANAGEMENT:
    'open_plan_unavailable_toast_product_not_meet_standard_check_product_management',
  PAYMENT_PAGE_FILTER_PAYMENT_ID: 'payment_page_filter_payment_id',
  PAYMENT_PAGE_FILTER_PAYMENT_TIME: 'payment_page_filter_payment_time',
  PAYMENT_PAGE_TABLE_AMOUNT_PAID: 'payment_page_table_amount_paid',
  PAYMENT_PAGE_TABLE_EXCHANGE_RATE: 'payment_page_table_exchange_rate',
  PAYMENT_PAGE_TABLE_PAYMENT_ID: 'payment_page_table_payment_id',
  PAYMENT_PAGE_TABLE_PAYMENT_TIME: 'payment_page_table_payment_time',
  PLAN_ADD_PRODUCTS_BTN: 'plan_add_products_btn',
  PLAN_CHANGE_EFFECT_IMMEDIATELY_TOAST: 'plan_change_effect_immediately_toast',
  PLAN_COMMISSION_SUGGESTION: 'plan_commission_suggestion',
  PLAN_COMMISSION_SUGGESTION_PAGE_CATEGORY_COMMISSION_TEXT:
    'plan_commission_suggestion_page_category_commission_text',
  PLAN_COMMISSION_SUGGESTION_PAGE_CATEGORY_COMMISSION_TITLE:
    'plan_commission_suggestion_page_category_commission_title',
  PLAN_COMMISSION_SUGGESTION_PAGE_COMMISSION_LIST_IN_OPEN_PLAN:
    'plan_commission_suggestion_page_commission_list_in_open_plan',
  PLAN_COMMISSION_SUGGESTION_PAGE_COMMISSION_LIST_IN_TARGETED_PLAN:
    'plan_commission_suggestion_page_commission_list_in_targeted_plan',
  PLAN_COMMISSION_SUGGESTION_PAGE_PLANS_DIFFERENCE_ANSWER_OPEN_PLAN_NAME:
    'plan_commission_suggestion_page_plans_difference_answer_open_plan_name',
  PLAN_COMMISSION_SUGGESTION_PAGE_PLANS_DIFFERENCE_ANSWER_OPEN_PLAN_TEXT:
    'plan_commission_suggestion_page_plans_difference_answer_open_plan_text',
  PLAN_COMMISSION_SUGGESTION_PAGE_PLANS_DIFFERENCE_ANSWER_SHOP_PLAN_NAME:
    'plan_commission_suggestion_page_plans_difference_answer_shop_plan_name',
  PLAN_COMMISSION_SUGGESTION_PAGE_PLANS_DIFFERENCE_ANSWER_SHOP_PLAN_TEXT:
    'plan_commission_suggestion_page_plans_difference_answer_shop_plan_text',
  PLAN_COMMISSION_SUGGESTION_PAGE_PLANS_DIFFERENCE_ANSWER_TARGETED_PLAN_NAME:
    'plan_commission_suggestion_page_plans_difference_answer_targeted_plan_name',
  PLAN_COMMISSION_SUGGESTION_PAGE_PLANS_DIFFERENCE_ANSWER_TARGETED_PLAN_TEXT:
    'plan_commission_suggestion_page_plans_difference_answer_targeted_plan_text',
  PLAN_COMMISSION_SUGGESTION_PAGE_PLANS_DIFFERENCE_QUESTION:
    'plan_commission_suggestion_page_plans_difference_question',
  PLAN_COMMISSION_SUGGESTION_PAGE_TITLE:
    'plan_commission_suggestion_page_title',
  PLAN_COMMISSION_SUGGESTION_PAGE_WHY_SET_UP_COMMISSION_ANSWER:
    'plan_commission_suggestion_page_why_set_up_commission_answer',
  PLAN_COMMISSION_SUGGESTION_PAGE_WHY_SET_UP_COMMISSION_QUESTION:
    'plan_commission_suggestion_page_why_set_up_commission_question',
  PLAN_COMMISSION_SUGGESTION_POPUP_LIST_SUGGESTED_COMMISSION:
    'plan_commission_suggestion_popup_list_suggested_commission',
  PLAN_COMMISSION_SUGGESTION_POPUP_TEXT_CATEGORY_COMMISSION_SUGGESTION:
    'plan_commission_suggestion_popup_text_category_commission_suggestion',
  PLAN_COMMISSION_SUGGESTION_POPUP_TITLE:
    'plan_commission_suggestion_popup_title',
  PLAN_COMMON_PAYMENT_AGREEMENT_DARKTIP:
    'plan_common_payment_agreement_darktip',
  PLAN_COMMON_PRODUCT_RECOMMEND_ERROR: 'plan_common_product_recommend_error',
  PLAN_COMMON_PRODUCT_RECOMMEND_TIPS: 'plan_common_product_recommend_tips',
  PLAN_COMMON_PRODUCT_RECOMMEND_TITLE: 'plan_common_product_recommend_title',
  PLAN_COMMON_PRODUCT_RECOMMEND_TOOLTIP:
    'plan_common_product_recommend_tooltip',
  PLAN_COMMON_SELLER_CENTER: 'plan_common_seller_center',
  PLAN_COMMON_SELLER_OFFLINE_NOTIFY_TEXT:
    'plan_common_seller_offline_notify_text',
  PLAN_COMMON_SELLER_OFFLINE_NOTIFY_TITLE:
    'plan_common_seller_offline_notify_title',
  PLAN_CREATE_02_TABLE_BAR_COMMISSION_RATE_0:
    'plan_create_02_table_bar_commission_rate_0',
  PLAN_CREATE_NEW_COMMISSION_TIP_TOTAL_RATE:
    'plan_create_new_commission_tip_total_rate',
  PLAN_CREATE_NEW_COMMISSION_TIP_TT_BOOST:
    'plan_create_new_commission_tip_tt_boost',
  PLAN_CREATE_NEW_COMMISSION_TOOLTIP_RANGE:
    'plan_create_new_commission_tooltip_range',
  PLAN_CREATE_NEW_COMMISSION_TOTAL_MAX_LIMIT_ERROR:
    'plan_create_new_commission_total_max_limit_error',
  PLAN_EDIT_CHANGES_EFFECT_IMMEDIATELY: 'plan_edit_changes_effect_immediately',
  PLAN_EDIT_CHANGES_EFFECT_MIX_TOAST: 'plan_edit_changes_effect_mix_toast',
  PLAN_EDIT_COMMISSION_PERCENTAGE_PLACEHOLDER_RANGE:
    'plan_edit_commission_percentage_placeholder_range',
  PLAN_EDIT_EFFECT_TIME_HOUR: 'plan_edit_effect_time_hour',
  PLAN_EDIT_EFFECT_TIME_MIN: 'plan_edit_effect_time_min',
  PLAN_LIST_COMMISSION_TOOLTIP_TT_BOOST:
    'plan_list_commission_tooltip_tt_boost',
  PLAN_LIST_PAGE_NUM: 'plan_list_page_num',
  PLAN_LIST_TITLE: 'plan_list_title',
  PLAN_MANAGE_CREATORS_ACTION: 'plan_manage_creators_action',
  PLAN_MANAGE_CREATORS_BTN: 'plan_manage_creators_btn',
  PLAN_MANAGE_CREATORS_FILTER_CREATOR_NAME:
    'plan_manage_creators_filter_creator_name',
  PLAN_MANAGE_CREATORS_FILTER_PLACEHOLDER:
    'plan_manage_creators_filter_placeholder',
  PLAN_MANAGE_CREATORS_FILTER_PLAN_ID: 'plan_manage_creators_filter_plan_id',
  PLAN_MANAGE_CREATORS_FILTER_PRODUCT_ID:
    'plan_manage_creators_filter_product_id',
  PLAN_MANAGE_CREATORS_FILTER_PRODUCT_NAME:
    'plan_manage_creators_filter_product_name',
  PLAN_MANAGE_CREATORS_LIST_ACTION: 'plan_manage_creators_list_action',
  PLAN_MANAGE_CREATORS_LIST_ACTION_REJECT:
    'plan_manage_creators_list_action_reject',
  PLAN_MANAGE_CREATORS_LIST_ADDED_TIME: 'plan_manage_creators_list_added_time',
  PLAN_MANAGE_CREATORS_LIST_CREATOR: 'plan_manage_creators_list_creator',
  PLAN_MANAGE_CREATORS_LIST_PAGE_NUM: 'plan_manage_creators_list_page_num',
  PLAN_MANAGE_CREATORS_LIST_PLAN_ID: 'plan_manage_creators_list_plan_id',
  PLAN_MANAGE_CREATORS_LIST_PRODUCT: 'plan_manage_creators_list_product',
  PLAN_MANAGE_CREATORS_LIST_REJECTED_TIME:
    'plan_manage_creators_list_rejected_time',
  PLAN_MANAGE_CREATORS_LIST_SHOWCASE_PRODUCT_STATUS:
    'plan_manage_creators_list_showcase_product_status',
  PLAN_MANAGE_CREATORS_REJECT_CONFIRM_REJECT_BTN:
    'plan_manage_creators_reject_confirm_reject_btn',
  PLAN_MANAGE_CREATORS_REJECT_CONFIRM_TEXT_PLURAL:
    'plan_manage_creators_reject_confirm_text_plural',
  PLAN_MANAGE_CREATORS_REJECT_CONFIRM_TEXT_PLURAL_V2:
    'plan_manage_creators_reject_confirm_text_plural_v2',
  PLAN_MANAGE_CREATORS_REJECT_CONFIRM_TEXT_SINGULAR:
    'plan_manage_creators_reject_confirm_text_singular',
  PLAN_MANAGE_CREATORS_REJECT_CONFIRM_TEXT_SINGULAR_V2:
    'plan_manage_creators_reject_confirm_text_singular_v2',
  PLAN_MANAGE_CREATORS_REJECT_CONFIRM_TITLE_PLURAL:
    'plan_manage_creators_reject_confirm_title_plural',
  PLAN_MANAGE_CREATORS_REJECT_CONFIRM_TITLE_SINGULAR:
    'plan_manage_creators_reject_confirm_title_singular',
  PLAN_MANAGE_CREATORS_SHOWCASE_PRODUCT_STATUS_ADDED:
    'plan_manage_creators_showcase_product_status_added',
  PLAN_MANAGE_CREATORS_SHOWCASE_PRODUCT_STATUS_PENDING:
    'plan_manage_creators_showcase_product_status_pending',
  PLAN_MANAGE_CREATORS_SHOWCASE_PRODUCT_STATUS_REJECTED:
    'plan_manage_creators_showcase_product_status_rejected',
  PLAN_MANAGE_CREATORS_STATUS_EXPLAIN_TIPS_SHOP_PLAN:
    'plan_manage_creators_status_explain_tips_shop_plan',
  PLAN_MANAGE_CREATORS_STATUS_EXPLAIN_TIPS_TARGET_PLAN:
    'plan_manage_creators_status_explain_tips_target_plan',
  PLAN_MANAGE_CREATORS_TITLE: 'plan_manage_creators_title',
  PLAN_MANAGE_CREATOR_REJECT_DIALOG_DESCRIPTION_V2:
    'plan_manage_creator_reject_dialog_description_v2',
  PLAN_NETWORK_ERROR_COMMON: 'plan_network_error_common',
  PLAN_OPEN_EDIT_SUBMIT_TOAST_TAKE_EFFECT_TIME:
    'plan_open_edit_submit_toast_take_effect_time',
  PLAN_PRODUCT_GENERAL_STATUS: 'plan_product_general_status',
  PLAN_PRODUCT_STATUS: 'plan_product_status',
  PLAN_PRODUCT_WITHDRAWN_TOOLTIP_NOT_MEET_STANDARD_CHECK_PRODUCT_MANAGEMENT:
    'plan_product_withdrawn_tooltip_not_meet_standard_check_product_management',
  PLAN_PUBLIC_COMMISSION_BOX_EMPTY_TOAST:
    'plan_public_commission_box_empty_toast',
  PLAN_PUBLIC_COMMISSION_BOX_EXCEED_TOAST:
    'plan_public_commission_box_exceed_toast',
  PLAN_PUBLIC_COMMISSION_BOX_LESS_TOAST:
    'plan_public_commission_box_less_toast',
  PLAN_PUBLIC_COMMISSION_EMPTY_PAGE_TOAST:
    'plan_public_commission_empty_page_toast',
  PLAN_PUBLIC_COMMISSION_EMPTY_PAGE_TOAST_SINGULAR:
    'plan_public_commission_empty_page_toast_singular',
  PLAN_PUBLIC_CREATE_01_PAGE_BACK: 'plan_public_create_01_page_back',
  PLAN_PUBLIC_CREATE_01_PAGE_BTN_CANCEL:
    'plan_public_create_01_page_btn_cancel',
  PLAN_PUBLIC_CREATE_01_PAGE_BTN_CANCEL_CLICK_CONFIRM:
    'plan_public_create_01_page_btn_cancel_click_confirm',
  PLAN_PUBLIC_CREATE_01_PAGE_BTN_CANCEL_CLICK_CONFIRM_CANCEL:
    'plan_public_create_01_page_btn_cancel_click_confirm_cancel',
  PLAN_PUBLIC_CREATE_01_PAGE_BTN_CANCEL_CLICK_CONFIRM_DISCARD:
    'plan_public_create_01_page_btn_cancel_click_confirm_discard',
  PLAN_PUBLIC_CREATE_01_PAGE_BTN_NEXT: 'plan_public_create_01_page_btn_next',
  PLAN_PUBLIC_CREATE_01_PAGE_ITEM_NUMBER:
    'plan_public_create_01_page_item_number',
  PLAN_PUBLIC_CREATE_01_PAGE_PRODUCT_NUMBER:
    'plan_public_create_01_page_product_number',
  PLAN_PUBLIC_CREATE_01_PAGE_PRODUCT_NUMBER_EXCEED_DIALOG_ACTION:
    'plan_public_create_01_page_product_number_exceed_dialog_action',
  PLAN_PUBLIC_CREATE_01_PAGE_PRODUCT_NUMBER_EXCEED_DIALOG_CONTENT:
    'plan_public_create_01_page_product_number_exceed_dialog_content',
  PLAN_PUBLIC_CREATE_01_PAGE_PRODUCT_NUMBER_EXCEED_DIALOG_CONTENT_NEW:
    'plan_public_create_01_page_product_number_exceed_dialog_content_new',
  PLAN_PUBLIC_CREATE_01_PAGE_PRODUCT_NUMBER_EXCEED_DIALOG_TITLE:
    'plan_public_create_01_page_product_number_exceed_dialog_title',
  PLAN_PUBLIC_CREATE_01_PAGE_PRODUCT_NUMBER_NEW:
    'plan_public_create_01_page_product_number_new',
  PLAN_PUBLIC_CREATE_01_PAGE_TITLE: 'plan_public_create_01_page_title',
  PLAN_PUBLIC_CREATE_01_STATUS_DEACTIVE:
    'plan_public_create_01_status_deactive',
  PLAN_PUBLIC_CREATE_01_STATUS_DEACTIVE_BUBBLE:
    'plan_public_create_01_status_deactive_bubble',
  PLAN_PUBLIC_CREATE_01_STATUS_FAILED: 'plan_public_create_01_status_failed',
  PLAN_PUBLIC_CREATE_01_STATUS_FAILED_BUBBLE:
    'plan_public_create_01_status_failed_bubble',
  PLAN_PUBLIC_CREATE_01_STATUS_INPLAN: 'plan_public_create_01_status_inplan',
  PLAN_PUBLIC_CREATE_01_STATUS_INPLAN_BUBBLE:
    'plan_public_create_01_status_inplan_bubble',
  PLAN_PUBLIC_CREATE_01_STATUS_LIVE: 'plan_public_create_01_status_live',
  PLAN_PUBLIC_CREATE_01_STATUS_PENDING: 'plan_public_create_01_status_pending',
  PLAN_PUBLIC_CREATE_01_STATUS_PENDING_BUBBLE:
    'plan_public_create_01_status_pending_bubble',
  PLAN_PUBLIC_CREATE_01_STATUS_SOLD_OUT:
    'plan_public_create_01_status_sold_out',
  PLAN_PUBLIC_CREATE_01_TITLE_EDIT: 'plan_public_create_01_title_edit',
  PLAN_PUBLIC_CREATE_01_TITLE_SELECT: 'plan_public_create_01_title_select',
  PLAN_PUBLIC_CREATE_02_BTN_UPDATE_ALL: 'plan_public_create_02_btn_update_all',
  PLAN_PUBLIC_CREATE_02_BULK_ACTION_APPROVE:
    'plan_public_create_02_bulk_action_approve',
  PLAN_PUBLIC_CREATE_02_BULK_ACTION_COMMISSION:
    'plan_public_create_02_bulk_action_commission',
  PLAN_PUBLIC_CREATE_02_BULK_ACTION_COMMISSION_UPDATE:
    'plan_public_create_02_bulk_action_commission_update',
  PLAN_PUBLIC_CREATE_02_BULK_ACTION_DISAPPROVE:
    'plan_public_create_02_bulk_action_disapprove',
  PLAN_PUBLIC_CREATE_02_PAGE_BACK: 'plan_public_create_02_page_back',
  PLAN_PUBLIC_CREATE_02_PAGE_BTN_PREVIOUS:
    'plan_public_create_02_page_btn_previous',
  PLAN_PUBLIC_CREATE_02_PAGE_BTN_SUBMIT:
    'plan_public_create_02_page_btn_submit',
  PLAN_PUBLIC_CREATE_02_PAGE_EMPTY: 'plan_public_create_02_page_empty',
  PLAN_PUBLIC_CREATE_02_PAGE_SUBMIT_FAILED:
    'plan_public_create_02_page_submit_failed',
  PLAN_PUBLIC_CREATE_02_PAGE_SUBMIT_FAILED_INVALID_PRODUCTS:
    'plan_public_create_02_page_submit_failed_invalid_products',
  PLAN_PUBLIC_CREATE_02_TABLE_BAR_AUDIT_CREATOR:
    'plan_public_create_02_table_bar_audit_creator',
  PLAN_PUBLIC_CREATE_02_TABLE_BAR_COMMISSION_RATE:
    'plan_public_create_02_table_bar_commission_rate',
  PLAN_PUBLIC_CREATE_02_TIP_ENTER_DARK: 'plan_public_create_02_tip_enter_dark',
  PLAN_PUBLIC_CREATE_02_TITLE_ACTION: 'plan_public_create_02_title_action',
  PLAN_PUBLIC_CREATE_02_TITLE_ACTION_REMOVE:
    'plan_public_create_02_title_action_remove',
  PLAN_PUBLIC_CREATE_02_TITLE_AUDIT_CREATOR:
    'plan_public_create_02_title_audit_creator',
  PLAN_PUBLIC_CREATE_02_TITLE_DESCRIPTION:
    'plan_public_create_02_title_description',
  PLAN_PUBLIC_CREATE_02_TITLE_ESTIMATED_COMMISSION:
    'plan_public_create_02_title_estimated_commission',
  PLAN_PUBLIC_CREATE_02_TOAST_BTN_ADD: 'plan_public_create_02_toast_btn_add',
  PLAN_PUBLIC_CREATE_02_TOAST_BTN_BACK: 'plan_public_create_02_toast_btn_back',
  PLAN_PUBLIC_CREATE_02_TOAST_BTN_CONTINUE:
    'plan_public_create_02_toast_btn_continue',
  PLAN_PUBLIC_CREATE_02_TOAST_CONGRATULATIONS:
    'plan_public_create_02_toast_congratulations',
  PLAN_PUBLIC_CREATE_02_TOAST_CREATE_FAIL01:
    'plan_public_create_02_toast_create_fail01',
  PLAN_PUBLIC_CREATE_02_TOAST_CREATE_FAIL02:
    'plan_public_create_02_toast_create_fail02',
  PLAN_PUBLIC_CREATE_02_TOAST_CREATE_FAIL02_PLURAL:
    'plan_public_create_02_toast_create_fail02_plural',
  PLAN_PUBLIC_CREATE_02_TOAST_CREATE_SUCCESS:
    'plan_public_create_02_toast_create_success',
  PLAN_PUBLIC_CREATE_NEW_FAILED_ALL_PRODUCTS_01:
    'plan_public_create_new_failed_all_products_01',
  PLAN_PUBLIC_CREATE_NEW_FAILED_ALL_PRODUCTS_02:
    'plan_public_create_new_failed_all_products_02',
  PLAN_PUBLIC_CREATE_NEW_FAILED_NO_PRODUCTS_01:
    'plan_public_create_new_failed_no_products_01',
  PLAN_PUBLIC_CREATE_NEW_FAILED_NO_PRODUCTS_02:
    'plan_public_create_new_failed_no_products_02',
  PLAN_PUBLIC_CREATE_STATUS_SOLD_OUT_EXPLAIN:
    'plan_public_create_status_sold_out_explain',
  PLAN_PUBLIC_DELETE_COMFIRM_DIALOG_CANCEL:
    'plan_public_delete_comfirm_dialog_cancel',
  PLAN_PUBLIC_DELETE_COMFIRM_DIALOG_CONTINUE:
    'plan_public_delete_comfirm_dialog_continue',
  PLAN_PUBLIC_DELETE_COMFIRM_DIALOG_PLURAL:
    'plan_public_delete_comfirm_dialog_plural',
  PLAN_PUBLIC_DELETE_COMFIRM_DIALOG_SINGULAR:
    'plan_public_delete_comfirm_dialog_singular',
  PLAN_PUBLIC_DELETE_CONFIRM_DIALOG_DESCRIPTION_PLURAL:
    'plan_public_delete_confirm_dialog_description_plural',
  PLAN_PUBLIC_DELETE_CONFIRM_DIALOG_DESCRIPTION_PLURAL_V2:
    'plan_public_delete_confirm_dialog_description_plural_v2',
  PLAN_PUBLIC_DELETE_CONFIRM_DIALOG_DESCRIPTION_SINGULAR:
    'plan_public_delete_confirm_dialog_description_singular',
  PLAN_PUBLIC_DELETE_CONFIRM_DIALOG_TITLE_PLURAL:
    'plan_public_delete_confirm_dialog_title_plural',
  PLAN_PUBLIC_DELETE_CONFIRM_DIALOG_TITLE_SINGULAR:
    'plan_public_delete_confirm_dialog_title_singular',
  PLAN_PUBLIC_DELETE_DIALOG_DESCRIPTION_NO_PROMOTING_SINGULAR:
    'plan_public_delete_dialog_description_no_promoting_singular',
  PLAN_PUBLIC_DELETE_DIALOG_DESCRIPTION_NO_PROMOTING_SINGULAR_HAVE_SAMPLE_REQUESTS:
    'plan_public_delete_dialog_description_no_promoting_singular_have_sample_requests',
  PLAN_PUBLIC_DELETE_DIALOG_DESCRIPTION_PROMOTING_PLURAL:
    'plan_public_delete_dialog_description_promoting_plural',
  PLAN_PUBLIC_DELETE_DIALOG_DESCRIPTION_PROMOTING_PLURAL_SAMPLE_REQUESTS:
    'plan_public_delete_dialog_description_promoting_plural_sample_requests',
  PLAN_PUBLIC_DELETE_DIALOG_DESCRIPTION_PROMOTING_PLURAL_SAMPLE_REQUESTS_V2:
    'plan_public_delete_dialog_description_promoting_plural_sample_requests_v2',
  PLAN_PUBLIC_DELETE_DIALOG_DESCRIPTION_PROMOTING_SINGULAR:
    'plan_public_delete_dialog_description_promoting_singular',
  PLAN_PUBLIC_DELETE_DIALOG_DESCRIPTION_PROMOTING_SINGULAR_HAVE_SAMPLE_REQUESTS:
    'plan_public_delete_dialog_description_promoting_singular_have_sample_requests',
  PLAN_PUBLIC_DELETE_DIALOG_DESCRIPTION_PROMOTING_SINGULAR_HAVE_SAMPLE_REQUESTS_V2:
    'plan_public_delete_dialog_description_promoting_singular_have_sample_requests_v2',
  PLAN_PUBLIC_DELETE_DIALOG_DESCRIPTION_PROMOTING_SINGULAR_V2:
    'plan_public_delete_dialog_description_promoting_singular_v2',
  PLAN_PUBLIC_DELETE_RUNNING_CONFIRM_DIALOG_DESCRIPTION_SINGULAR:
    'plan_public_delete_running_confirm_dialog_description_singular',
  PLAN_PUBLIC_DELETE_RUNNING_CONFIRM_DIALOG_TITLE_SINGULAR:
    'plan_public_delete_running_confirm_dialog_title_singular',
  PLAN_PUBLIC_DELETE_RUNNING_PENDING_CONFIRM_DIALOG_DESCRIPTION_SINGULAR:
    'plan_public_delete_running_pending_confirm_dialog_description_singular',
  PLAN_PUBLIC_DELETE_RUNNING_PENDING_CONFIRM_DIALOG_TITLE_SINGULAR:
    'plan_public_delete_running_pending_confirm_dialog_title_singular',
  PLAN_PUBLIC_DELETE_UNAVAILABLE_CONFIRM_DIALOG_DESCRIPTION_SINGULAR:
    'plan_public_delete_unavailable_confirm_dialog_description_singular',
  PLAN_PUBLIC_DELETE_UNAVAILABLE_CONFIRM_DIALOG_TITLE_SINGULAR:
    'plan_public_delete_unavailable_confirm_dialog_title_singular',
  PLAN_PUBLIC_EDIT_NO_PROMOTING_SUCCESS_TOAST_SINGLUAR:
    'plan_public_edit_no_promoting_success_toast_singluar',
  PLAN_PUBLIC_EDIT_PROMOTING_SUCCESS_TOAST_PLURAL:
    'plan_public_edit_promoting_success_toast_plural',
  PLAN_PUBLIC_EDIT_PROMOTING_SUCCESS_TOAST_PLURAL_V2:
    'plan_public_edit_promoting_success_toast_plural_v2',
  PLAN_PUBLIC_EDIT_PROMOTING_SUCCESS_TOAST_SINGULAR:
    'plan_public_edit_promoting_success_toast_singular',
  PLAN_PUBLIC_EDIT_PROMOTING_SUCCESS_TOAST_SINGULAR_V2:
    'plan_public_edit_promoting_success_toast_singular_v2',
  PLAN_PUBLIC_INSTANT_CHANGE_FAIL: 'plan_public_instant_change_fail',
  PLAN_PUBLIC_INSTANT_CHANGE_POPUP_ACTION_CANCEL:
    'plan_public_instant_change_popup_action_cancel',
  PLAN_PUBLIC_INSTANT_CHANGE_POPUP_ACTION_DONE:
    'plan_public_instant_change_popup_action_done',
  PLAN_PUBLIC_INSTANT_CHANGE_POPUP_ENTER_ERROR:
    'plan_public_instant_change_popup_enter_error',
  PLAN_PUBLIC_INSTANT_CHANGE_POPUP_ENTER_HINT:
    'plan_public_instant_change_popup_enter_hint',
  PLAN_PUBLIC_INSTANT_CHANGE_POPUP_TITLE:
    'plan_public_instant_change_popup_title',
  PLAN_PUBLIC_INSTANT_CHANGE_SUCCEED: 'plan_public_instant_change_succeed',
  PLAN_PUBLIC_LIST_PAGE_ADD_BUTTON: 'plan_public_list_page_add_button',
  PLAN_PUBLIC_LIST_PAGE_ITEMS_NUM: 'plan_public_list_page_items_num',
  PLAN_PUBLIC_LIST_PAGE_MANAGE_BTN_BUBBLE:
    'plan_public_list_page_manage_btn_bubble',
  PLAN_PUBLIC_LIST_PAGE_MANAGE_BUTTON: 'plan_public_list_page_manage_button',
  PLAN_PUBLIC_LIST_PAGE_MULTI_BUTTON_DELETE:
    'plan_public_list_page_multi_button_delete',
  PLAN_PUBLIC_LIST_PAGE_MULTI_BUTTON_EDIT:
    'plan_public_list_page_multi_button_edit',
  PLAN_PUBLIC_LIST_PAGE_MULTI_BUTTON_RECOVER:
    'plan_public_list_page_multi_button_recover',
  PLAN_PUBLIC_LIST_PAGE_MULTI_BUTTON_STOP:
    'plan_public_list_page_multi_button_stop',
  PLAN_PUBLIC_LIST_PAGE_PLAN_STATUS_DISABLED:
    'plan_public_list_page_plan_status_disabled',
  PLAN_PUBLIC_LIST_PAGE_PLAN_STATUS_HELD:
    'plan_public_list_page_plan_status_held',
  PLAN_PUBLIC_LIST_PAGE_PLAN_STATUS_INHIBITED:
    'plan_public_list_page_plan_status_inhibited',
  PLAN_PUBLIC_LIST_PAGE_PLAN_STATUS_STOPPED:
    'plan_public_list_page_plan_status_stopped',
  PLAN_PUBLIC_LIST_PAGE_PLAN_STATUS_WORKING:
    'plan_public_list_page_plan_status_working',
  PLAN_PUBLIC_LIST_PAGE_PRODUCT_ID: 'plan_public_list_page_product_id',
  PLAN_PUBLIC_LIST_PAGE_SEARCH_01: 'plan_public_list_page_search_01',
  PLAN_PUBLIC_LIST_PAGE_SEARCH_02: 'plan_public_list_page_search_02',
  PLAN_PUBLIC_LIST_PAGE_SEARCH_03: 'plan_public_list_page_search_03',
  PLAN_PUBLIC_LIST_PAGE_SEARCH_DARK_HINT:
    'plan_public_list_page_search_dark_hint',
  PLAN_PUBLIC_LIST_PAGE_SEARCH_PRODUCT: 'plan_public_list_page_search_product',
  PLAN_PUBLIC_LIST_PAGE_SEARCH_PRODUCT_ID:
    'plan_public_list_page_search_product_id',
  PLAN_PUBLIC_LIST_PAGE_SINGLE_BUTTON_CHECK:
    'plan_public_list_page_single_button_check',
  PLAN_PUBLIC_LIST_PAGE_SINGLE_BUTTON_DELETE:
    'plan_public_list_page_single_button_delete',
  PLAN_PUBLIC_LIST_PAGE_SINGLE_BUTTON_EDIT:
    'plan_public_list_page_single_button_edit',
  PLAN_PUBLIC_LIST_PAGE_SINGLE_BUTTON_RECOVER:
    'plan_public_list_page_single_button_recover',
  PLAN_PUBLIC_LIST_PAGE_SINGLE_BUTTON_STOP:
    'plan_public_list_page_single_button_stop',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_ACTION:
    'plan_public_list_page_table_bar_action',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_ACTION_BUBBLE:
    'plan_public_list_page_table_bar_action_bubble',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_COMMISSION:
    'plan_public_list_page_table_bar_commission',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_COMMISSION_BUBBLE:
    'plan_public_list_page_table_bar_commission_bubble',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_PLANID:
    'plan_public_list_page_table_bar_planid',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_PRICE:
    'plan_public_list_page_table_bar_price',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_PRODUCT:
    'plan_public_list_page_table_bar_product',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_SALES:
    'plan_public_list_page_table_bar_sales',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_SALES_BUBBLE:
    'plan_public_list_page_table_bar_sales_bubble',
  PLAN_PUBLIC_LIST_PAGE_TABLE_BAR_STATUS:
    'plan_public_list_page_table_bar_status',
  PLAN_PUBLIC_LIST_PAGE_TITLE: 'plan_public_list_page_title',
  PLAN_PUBLIC_MANAGE_ACTION_CANCEL: 'plan_public_manage_action_cancel',
  PLAN_PUBLIC_MANAGE_ACTION_DELETE: 'plan_public_manage_action_delete',
  PLAN_PUBLIC_MANAGE_ACTION_RECOVER: 'plan_public_manage_action_recover',
  PLAN_PUBLIC_MANAGE_ACTION_STOP: 'plan_public_manage_action_stop',
  PLAN_PUBLIC_MANAGE_ACTION_SUBMIT: 'plan_public_manage_action_submit',
  PLAN_PUBLIC_MANAGE_ACTION_SUBMIT_FAILED:
    'plan_public_manage_action_submit_failed',
  PLAN_PUBLIC_MANAGE_ACTION_SUBMIT_FAILED_PLAN:
    'plan_public_manage_action_submit_failed_plan',
  PLAN_PUBLIC_MANAGE_APPLICATION_BTN_CANCEL:
    'plan_public_manage_application_btn_cancel',
  PLAN_PUBLIC_MANAGE_APPLICATION_BTN_CONTINUE:
    'plan_public_manage_application_btn_continue',
  PLAN_PUBLIC_MANAGE_APPLICATION_DO_NOT_SHOW:
    'plan_public_manage_application_do_not_show',
  PLAN_PUBLIC_MANAGE_BACK: 'plan_public_manage_back',
  PLAN_PUBLIC_MANAGE_CANCEL: 'plan_public_manage_cancel',
  PLAN_PUBLIC_MANAGE_CONFIRM: 'plan_public_manage_confirm',
  PLAN_PUBLIC_MANAGE_CREATORS: 'plan_public_manage_creators',
  PLAN_PUBLIC_MANAGE_CREATORS_SEARCH_DARK:
    'plan_public_manage_creators_search_dark',
  PLAN_PUBLIC_MANAGE_CREATOR_ACTION_APPROVE:
    'plan_public_manage_creator_action_approve',
  PLAN_PUBLIC_MANAGE_CREATOR_ACTION_DELETE:
    'plan_public_manage_creator_action_delete',
  PLAN_PUBLIC_MANAGE_CREATOR_ACTION_REJECT:
    'plan_public_manage_creator_action_reject',
  PLAN_PUBLIC_MANAGE_CREATOR_APPROVE_FAILED_PLURAL:
    'plan_public_manage_creator_approve_failed_plural',
  PLAN_PUBLIC_MANAGE_CREATOR_APPROVE_FAILED_SINGULAR:
    'plan_public_manage_creator_approve_failed_singular',
  PLAN_PUBLIC_MANAGE_CREATOR_BULK_REJECT_DIALOG:
    'plan_public_manage_creator_bulk_reject_dialog',
  PLAN_PUBLIC_MANAGE_CREATOR_BULK_REJECT_DIALOG_V2:
    'plan_public_manage_creator_bulk_reject_dialog_v2',
  PLAN_PUBLIC_MANAGE_CREATOR_DIALOG_CANCEL_BTN:
    'plan_public_manage_creator_dialog_cancel_btn',
  PLAN_PUBLIC_MANAGE_CREATOR_DIALOG_CONFIRM_BTN:
    'plan_public_manage_creator_dialog_confirm_btn',
  PLAN_PUBLIC_MANAGE_CREATOR_ERROR_HIGHLIGHT_APPROVE:
    'plan_public_manage_creator_error_highlight_approve',
  PLAN_PUBLIC_MANAGE_CREATOR_ERROR_HIGHLIGHT_REJECT:
    'plan_public_manage_creator_error_highlight_reject',
  PLAN_PUBLIC_MANAGE_CREATOR_REJECT_DIALOG2:
    'plan_public_manage_creator_reject_dialog2',
  PLAN_PUBLIC_MANAGE_CREATOR_REJECT_DIALOG2_DESCRIPTION:
    'plan_public_manage_creator_reject_dialog2_description',
  PLAN_PUBLIC_MANAGE_CREATOR_REJECT_DIALOG2_DESCRIPTION_PLURAL:
    'plan_public_manage_creator_reject_dialog2_description_plural',
  PLAN_PUBLIC_MANAGE_CREATOR_REJECT_DIALOG:
    'plan_public_manage_creator_reject_dialog',
  PLAN_PUBLIC_MANAGE_CREATOR_REJECT_DIALOG_DESCRIPTION:
    'plan_public_manage_creator_reject_dialog_description',
  PLAN_PUBLIC_MANAGE_CREATOR_REJECT_DIALOG_DESCRIPTION_V2:
    'plan_public_manage_creator_reject_dialog_description_v2',
  PLAN_PUBLIC_MANAGE_CREATOR_REJECT_FAILED_PLURAL:
    'plan_public_manage_creator_reject_failed_plural',
  PLAN_PUBLIC_MANAGE_CREATOR_REJECT_FAILED_SINGULAR:
    'plan_public_manage_creator_reject_failed_singular',
  PLAN_PUBLIC_MANAGE_CREATOR_REMOVE_DIALOG:
    'plan_public_manage_creator_remove_dialog',
  PLAN_PUBLIC_MANAGE_CREATOR_REMOVE_DIALOG_DESCRIPTION:
    'plan_public_manage_creator_remove_dialog_description',
  PLAN_PUBLIC_MANAGE_CREATOR_SEARCH_BY_PLAN_ID:
    'plan_public_manage_creator_search_by_plan_id',
  PLAN_PUBLIC_MANAGE_CREATOR_SEARCH_BY_PRODUCT_ID:
    'plan_public_manage_creator_search_by_product_id',
  PLAN_PUBLIC_MANAGE_CREATOR_SEARCH_BY_PRODUCT_NAME:
    'plan_public_manage_creator_search_by_product_name',
  PLAN_PUBLIC_MANAGE_CREATOR_SEARCH_BY_USERNAME:
    'plan_public_manage_creator_search_by_username',
  PLAN_PUBLIC_MANAGE_CREATOR_SEARCH_HINT:
    'plan_public_manage_creator_search_hint',
  PLAN_PUBLIC_MANAGE_CREATOR_STATUS_APPROVED:
    'plan_public_manage_creator_status_approved',
  PLAN_PUBLIC_MANAGE_CREATOR_STATUS_EXPIRED:
    'plan_public_manage_creator_status_expired',
  PLAN_PUBLIC_MANAGE_CREATOR_STATUS_PENDING:
    'plan_public_manage_creator_status_pending',
  PLAN_PUBLIC_MANAGE_CREATOR_STATUS_REJECTED:
    'plan_public_manage_creator_status_rejected',
  PLAN_PUBLIC_MANAGE_CREATOR_TABLE_CREATOR:
    'plan_public_manage_creator_table_creator',
  PLAN_PUBLIC_MANAGE_CREATOR_TABLE_ID_APPLICATION:
    'plan_public_manage_creator_table_id_application',
  PLAN_PUBLIC_MANAGE_CREATOR_TABLE_ID_PLAN:
    'plan_public_manage_creator_table_id_plan',
  PLAN_PUBLIC_MANAGE_CREATOR_TABLE_PRODUCT:
    'plan_public_manage_creator_table_product',
  PLAN_PUBLIC_MANAGE_CREATOR_TABLE_STATUS:
    'plan_public_manage_creator_table_status',
  PLAN_PUBLIC_MANAGE_CREATOR_TABLE_TIME:
    'plan_public_manage_creator_table_time',
  PLAN_PUBLIC_MANAGE_CREATOR_TAB_ALL: 'plan_public_manage_creator_tab_all',
  PLAN_PUBLIC_MANAGE_CREATOR_TAB_APPROVED:
    'plan_public_manage_creator_tab_approved',
  PLAN_PUBLIC_MANAGE_CREATOR_TAB_NEW: 'plan_public_manage_creator_tab_new',
  PLAN_PUBLIC_MANAGE_CREATOR_TITLE: 'plan_public_manage_creator_title',
  PLAN_PUBLIC_MANAGE_MULTI_AUDIT: 'plan_public_manage_multi_audit',
  PLAN_PUBLIC_MANAGE_MULTI_COMMISSION: 'plan_public_manage_multi_commission',
  PLAN_PUBLIC_MANAGE_MULTI_DELETE: 'plan_public_manage_multi_delete',
  PLAN_PUBLIC_MANAGE_MULTI_DISAPPROVE: 'plan_public_manage_multi_disapprove',
  PLAN_PUBLIC_MANAGE_MULTI_UPDATE: 'plan_public_manage_multi_update',
  PLAN_PUBLIC_MANAGE_REMOVE_FROM_LIST: 'plan_public_manage_remove_from_list',
  PLAN_PUBLIC_MANAGE_TIME_TOAST: 'plan_public_manage_time_toast',
  PLAN_PUBLIC_MANAGE_TIME_TOAST_V2: 'plan_public_manage_time_toast_v2',
  PLAN_PUBLIC_MANAGE_TITLE: 'plan_public_manage_title',
  PLAN_PUBLIC_MANAGE_TITLE_ACTION: 'plan_public_manage_title_action',
  PLAN_PUBLIC_MANAGE_TURNOFF_AUDIT: 'plan_public_manage_turnoff_audit',
  PLAN_PUBLIC_MANAGE_TURNON_AUDIT: 'plan_public_manage_turnon_audit',
  PLAN_PUBLIC_PAGE_BULK_BTN_APPROVE: 'plan_public_page_bulk_btn_approve',
  PLAN_PUBLIC_PAGE_BULK_BTN_DISAPPROVE: 'plan_public_page_bulk_btn_disapprove',
  PLAN_PUBLIC_PAGE_LIST_EMPTY: 'plan_public_page_list_empty',
  PLAN_PUBLIC_PAGE_NEXT_BTN_TOAST: 'plan_public_page_next_btn_toast',
  PLAN_PUBLIC_PAGE_SEARCH_EMPTY: 'plan_public_page_search_empty',
  PLAN_PUBLIC_PLAN_DELETE_CONFIRM_DIALOG_BTN_CANCEL:
    'plan_public_plan_delete_confirm_dialog_btn_cancel',
  PLAN_PUBLIC_PLAN_DELETE_CONFIRM_DIALOG_BTN_SURE:
    'plan_public_plan_delete_confirm_dialog_btn_sure',
  PLAN_PUBLIC_RECOVER_CONFIRM_DIALOG_DESCRIPTION_PLURAL:
    'plan_public_recover_confirm_dialog_description_plural',
  PLAN_PUBLIC_RECOVER_CONFIRM_DIALOG_DESCRIPTION_SINGULAR:
    'plan_public_recover_confirm_dialog_description_singular',
  PLAN_PUBLIC_RECOVER_CONFIRM_DIALOG_TITLE_PLURAL:
    'plan_public_recover_confirm_dialog_title_plural',
  PLAN_PUBLIC_RECOVER_CONFIRM_DIALOG_TITLE_SINGULAR:
    'plan_public_recover_confirm_dialog_title_singular',
  PLAN_PUBLIC_REJECT_CREATOR_DIALOG_POPUP_BTN_CANCEL:
    'plan_public_reject_creator_dialog_popup_btn_cancel',
  PLAN_PUBLIC_REJECT_CREATOR_DIALOG_POPUP_BTN_REJECT:
    'plan_public_reject_creator_dialog_popup_btn_reject',
  PLAN_PUBLIC_REJECT_CREATOR_DIALOG_POPUP_CONTENT:
    'plan_public_reject_creator_dialog_popup_content',
  PLAN_PUBLIC_STOP_CONFIRM_DIALOG_DESCRIPTION_PLURAL:
    'plan_public_stop_confirm_dialog_description_plural',
  PLAN_PUBLIC_STOP_CONFIRM_DIALOG_DESCRIPTION_SINGULAR:
    'plan_public_stop_confirm_dialog_description_singular',
  PLAN_PUBLIC_STOP_CONFIRM_DIALOG_TITLE_PLURAL:
    'plan_public_stop_confirm_dialog_title_plural',
  PLAN_PUBLIC_STOP_CONFIRM_DIALOG_TITLE_SINGULAR:
    'plan_public_stop_confirm_dialog_title_singular',
  PLAN_PUBLIC_UNAVAILABLE_CHECK_POPUP_APPROVE_OFF:
    'plan_public_unavailable_check_popup_approve_off',
  PLAN_PUBLIC_UNAVAILABLE_CHECK_POPUP_APPROVE_ON:
    'plan_public_unavailable_check_popup_approve_on',
  PLAN_PUBLIC_UNAVAILABLE_CHECK_POPUP_BTN_OK:
    'plan_public_unavailable_check_popup_btn_ok',
  PLAN_PUBLIC_UNAVAILABLE_CHECK_POPUP_METRIC_COMMISSION:
    'plan_public_unavailable_check_popup_metric_commission',
  PLAN_PUBLIC_UNAVAILABLE_CHECK_POPUP_METRIC_EST_COMMISSION:
    'plan_public_unavailable_check_popup_metric_est_commission',
  PLAN_PUBLIC_UNAVAILABLE_CHECK_POPUP_METRIC_PRICE:
    'plan_public_unavailable_check_popup_metric_price',
  PLAN_PUBLIC_UNAVAILABLE_CHECK_POPUP_TITLE:
    'plan_public_unavailable_check_popup_title',
  PLAN_SHOP_CREATE_POPUP_COMMISSION_PERCENTAGE_PLACEHOLDER_RANGE:
    'plan_shop_create_popup_commission_percentage_placeholder_range',
  PLAN_SHOP_CREATE_POPUP_COMMISSION_PERCENTAGE_TIP_CALCULATION_FORMULA:
    'plan_shop_create_popup_commission_percentage_tip_calculation_formula',
  PLAN_SHOP_CREATE_POPUP_COMMISSION_PERCENTAGE_TIP_RANGE_ERROR:
    'plan_shop_create_popup_commission_percentage_tip_range_error',
  PLAN_SHOP_CREATE_POPUP_COMMISSION_PERCENTAGE_TIP_TOTAL_RATE:
    'plan_shop_create_popup_commission_percentage_tip_total_rate',
  PLAN_SHOP_CREATE_POPUP_COMMISSION_PERCENTAGE_TOOLTIP_RANGE:
    'plan_shop_create_popup_commission_percentage_tooltip_range',
  PLAN_SHOP_CREATE_POPUP_SUGGESTED_COMMISSION:
    'plan_shop_create_popup_suggested_commission',
  PLAN_SHOP_CREATE_POPUP_SUGGESTED_COMMISSION_TOOLTIP:
    'plan_shop_create_popup_suggested_commission_tooltip',
  PLAN_SHOP_CREATE_POPUP_TIP_TT_BOOST_COMMISION:
    'plan_shop_create_popup_tip_tt_boost_commision',
  PLAN_SHOP_CREATE_POPUP_TIP_TT_BOOST_COMMISION_NUM:
    'plan_shop_create_popup_tip_tt_boost_commision_num',
  PLAN_TARGETED_SHOP_MANAGE_CREATORS_BTN_TOOLTIPS:
    'plan_targeted_shop_manage_creators_btn_tooltips',
  PLAN_TARGET_ADD_PRODUCT_CONFLICT_TOAST:
    'plan_target_add_product_conflict_toast',
  PLAN_TARGET_CHANGE_COMMISSION_DIALOG_DESCRIPTION_V2:
    'plan_target_change_commission_dialog_description_v2',
  PLAN_TARGET_CHECK_PAGE_ACTION: 'plan_target_check_page_action',
  PLAN_TARGET_CHECK_PAGE_DESCRIPTION_EMPTY:
    'plan_target_check_page_description_empty',
  PLAN_TARGET_CHECK_PAGE_TITLE: 'plan_target_check_page_title',
  PLAN_TARGET_CONFLICT_CREATOR_TOAST_CONTENT:
    'plan_target_conflict_creator_toast_content',
  PLAN_TARGET_CONFLICT_CREATOR_TOAST_TITLE:
    'plan_target_conflict_creator_toast_title',
  PLAN_TARGET_CREATE_01_PAGE_BTN_NEXT: 'plan_target_create_01_page_btn_next',
  PLAN_TARGET_CREATE_01_STATUS_DEACTIVE:
    'plan_target_create_01_status_deactive',
  PLAN_TARGET_CREATE_01_STATUS_FAILED: 'plan_target_create_01_status_failed',
  PLAN_TARGET_CREATE_01_STATUS_INPLAN: 'plan_target_create_01_status_inplan',
  PLAN_TARGET_CREATE_01_STATUS_INPLAN_BUBBLE:
    'plan_target_create_01_status_inplan_bubble',
  PLAN_TARGET_CREATE_01_STATUS_PENDING: 'plan_target_create_01_status_pending',
  PLAN_TARGET_CREATE_ADD_FAIL_NO_PRODUCT_TOAST_TEXT:
    'plan_target_create_add_fail_no_product_toast_text',
  PLAN_TARGET_CREATE_ADD_FAIL_NO_PRODUCT_TOAST_TITLE:
    'plan_target_create_add_fail_no_product_toast_title',
  PLAN_TARGET_CREATE_ADD_FAIL_TOAST_BTN:
    'plan_target_create_add_fail_toast_btn',
  PLAN_TARGET_CREATE_ADD_FAIL_TOAST_TEXT:
    'plan_target_create_add_fail_toast_text',
  PLAN_TARGET_CREATE_ADD_FAIL_TOAST_TITLE:
    'plan_target_create_add_fail_toast_title',
  PLAN_TARGET_CREATE_STEP_ADD: 'plan_target_create_step_add',
  PLAN_TARGET_CREATE_STEP_SELECT: 'plan_target_create_step_select',
  PLAN_TARGET_CREATOR_IN_OTHER_PLAN_TIP_PLURAL:
    'plan_target_creator_in_other_plan_tip_plural',
  PLAN_TARGET_CREATOR_IN_OTHER_PLAN_TIP_SINGULAR:
    'plan_target_creator_in_other_plan_tip_singular',
  PLAN_TARGET_CREATOR_REMOVE_DIALOG: 'plan_target_creator_remove_dialog',
  PLAN_TARGET_CREATOR_REMOVE_DIALOG_DESCRIPTION:
    'plan_target_creator_remove_dialog_description',
  PLAN_TARGET_CREATOR_REMOVE_DIALOG_DESCRIPTION_NEW:
    'plan_target_creator_remove_dialog_description_new',
  PLAN_TARGET_CREATOR_REMOVE_DIALOG_DESCRIPTION_V2:
    'plan_target_creator_remove_dialog_description_v2',
  PLAN_TARGET_CREATOR_SEARCH_NO_RESULT: 'plan_target_creator_search_no_result',
  PLAN_TARGET_DELETE_DIALOG_TAKE_EFFECT_MIDNIGHT_OR_IMMEDIATELY:
    'plan_target_delete_dialog_take_effect_midnight_or_immediately',
  PLAN_TARGET_DIALOG_BTN_CANCEL: 'plan_target_dialog_btn_cancel',
  PLAN_TARGET_DIALOG_BTN_CONFIRM: 'plan_target_dialog_btn_confirm',
  PLAN_TARGET_DISCARD_CHANGES_DIALOG_BTN_CANCEL:
    'plan_target_discard_changes_dialog_btn_cancel',
  PLAN_TARGET_DISCARD_CHANGES_DIALOG_BTN_DISCARD:
    'plan_target_discard_changes_dialog_btn_discard',
  PLAN_TARGET_DISCARD_CHANGES_DIALOG_CONTENT:
    'plan_target_discard_changes_dialog_content',
  PLAN_TARGET_EDIT_ACTION_REMOVE: 'plan_target_edit_action_remove',
  PLAN_TARGET_EDIT_TITLE: 'plan_target_edit_title',
  PLAN_TARGET_LIST_BTN_ADD_NEW: 'plan_target_list_btn_add_new',
  PLAN_TARGET_LIST_BTN_CANCEL: 'plan_target_list_btn_cancel',
  PLAN_TARGET_LIST_BTN_CONFIRM: 'plan_target_list_btn_confirm',
  PLAN_TARGET_LIST_DETAIL_ID: 'plan_target_list_detail_id',
  PLAN_TARGET_LIST_PAGE_SEARCH_NAME: 'plan_target_list_page_search_name',
  PLAN_TARGET_LIST_POP_UP_GMV: 'plan_target_list_pop_up_gmv',
  PLAN_TARGET_LIST_POP_UP_PRICE: 'plan_target_list_pop_up_price',
  PLAN_TARGET_LIST_POP_UP_PRODUCT: 'plan_target_list_pop_up_product',
  PLAN_TARGET_LIST_POP_UP_RATE: 'plan_target_list_pop_up_rate',
  PLAN_TARGET_LIST_POP_UP_SALES: 'plan_target_list_pop_up_sales',
  PLAN_TARGET_LIST_POP_UP_TITLE: 'plan_target_list_pop_up_title',
  PLAN_TARGET_LIST_TABLE_BAR_GMV: 'plan_target_list_table_bar_gmv',
  PLAN_TARGET_LIST_TABLE_BAR_STATUS: 'plan_target_list_table_bar_status',
  PLAN_TARGET_LIST_TITLE: 'plan_target_list_title',
  PLAN_TARGET_LIST_TITLE_COMISSION_EXPENSE:
    'plan_target_list_title_comission_expense',
  PLAN_TARGET_LIST_TITLE_GMV: 'plan_target_list_title_gmv',
  PLAN_TARGET_LIST_TITLE_PLAN_NAME: 'plan_target_list_title_plan_name',
  PLAN_TARGET_LIST_VIEW_DETAILS: 'plan_target_list_view_details',
  PLAN_TARGET_MANAGE_ADD_MORE_PRODUCT_BTN:
    'plan_target_manage_add_more_product_btn',
  PLAN_TARGET_MANAGE_CREATOR_REMOVE_DIALOG_DESCRIPTION_V2:
    'plan_target_manage_creator_remove_dialog_description_v2',
  PLAN_TARGET_MANAGE_CREATOR_STATUS_APPROVED:
    'plan_target_manage_creator_status_approved',
  PLAN_TARGET_MANAGE_CREATOR_STATUS_PENDING:
    'plan_target_manage_creator_status_pending',
  PLAN_TARGET_MANAGE_CREATOR_STATUS_REJECTED:
    'plan_target_manage_creator_status_rejected',
  PLAN_TARGET_MANAGE_DESCRIPTION: 'plan_target_manage_description',
  PLAN_TARGET_MANAGE_NOTE: 'plan_target_manage_note',
  PLAN_TARGET_MANAGE_OPTIONAL: 'plan_target_manage_optional',
  PLAN_TARGET_MANAGE_SEARCH_ACTION: 'plan_target_manage_search_action',
  PLAN_TARGET_MANAGE_SEARCH_ACTION_BLOCK:
    'plan_target_manage_search_action_block',
  PLAN_TARGET_MANAGE_SEARCH_ACTION_CONTACT:
    'plan_target_manage_search_action_contact',
  PLAN_TARGET_MANAGE_SEARCH_ACTION_SAVE:
    'plan_target_manage_search_action_save',
  PLAN_TARGET_MANAGE_SEARCH_CREATOR: 'plan_target_manage_search_creator',
  PLAN_TARGET_MANAGE_SEARCH_STATUS: 'plan_target_manage_search_status',
  PLAN_TARGET_MANAGE_SEARCH_TABLE_BAR_USERNAME:
    'plan_target_manage_search_table_bar_username',
  PLAN_TARGET_MANAGE_SEARCH_TOAST_ERROR:
    'plan_target_manage_search_toast_error',
  PLAN_TARGET_MANAGE_TIP_ADD_DARK: 'plan_target_manage_tip_add_dark',
  PLAN_TARGET_MANAGE_TIP_ENTER_DARK: 'plan_target_manage_tip_enter_dark',
  PLAN_TARGET_MANAGE_TITLE_SET_COOPERATE:
    'plan_target_manage_title_set_cooperate',
  PLAN_TARGET_MANAGE_USER_NAME: 'plan_target_manage_user_name',
  PLAN_TARGET_PAGE_ERROR_TOAST: 'plan_target_page_error_toast',
  PLAN_TARGET_PAGE_SUBMIT_BTN: 'plan_target_page_submit_btn',
  PLAN_TARGET_PLAN_EDIT_CHANGES_MIX_SUBMIT:
    'plan_target_plan_edit_changes_mix_submit',
  PLAN_TARGET_PRODUCT_REMOVE_DIALOG: 'plan_target_product_remove_dialog',
  PLAN_TARGET_PRODUCT_REMOVE_DIALOG_DESCRIPTION:
    'plan_target_product_remove_dialog_description',
  PLAN_TARGET_SET_ZERO_PERMISSION_EXPIRED:
    'plan_target_set_zero_permission_expired',
  PLAN_TARGET_SET_ZERO_PERMISSION_EXPIRED_TITLE:
    'plan_target_set_zero_permission_expired_title',
  PLAN_TARGET_SET_ZERO_TIPS: 'plan_target_set_zero_tips',
  PLAN_TARGET_SET_ZERO_TIPS_TILE: 'plan_target_set_zero_tips_tile',
  PLAN_TT_BOOST_COMMISSION_MESSAGE_TEXT:
    'plan_tt_boost_commission_message_text',
  PLAN_TT_BOOST_COMMISSION_MESSAGE_TITLE:
    'plan_tt_boost_commission_message_title',
  PLAN_TYPE_SHOP: 'plan_type_shop',
  PRODUCT_STATUS_WITHDRAWN_BY_SELLER_HOVER:
    'product_status_withdrawn_by_seller_hover',
  PROMOTION_COUPON_ACTIONS: 'promotion_coupon_actions',
  PROMOTION_COUPON_ACTIONS_CLOSED: 'promotion_coupon_actions_closed',
  PROMOTION_COUPON_ALERT: 'promotion_coupon_alert',
  PROMOTION_COUPON_CLOSED_HOVER: 'promotion_coupon_closed_hover',
  PROMOTION_COUPON_CREATE_CLAIM_PERIOD: 'promotion_coupon_create_claim_period',
  PROMOTION_COUPON_EDIT: 'promotion_coupon_edit',
  PROMOTION_COUPON_FAILED_1: 'promotion_coupon_failed_1',
  PROMOTION_COUPON_FAILED_2: 'promotion_coupon_failed_2',
  PROMOTION_COUPON_FAILED_3: 'promotion_coupon_failed_3',
  PROMOTION_COUPON_FAILED_4: 'promotion_coupon_failed_4',
  PROMOTION_COUPON_FAILED_5: 'promotion_coupon_failed_5',
  PROMOTION_COUPON_FAILED_6: 'promotion_coupon_failed_6',
  PROMOTION_COUPON_FAILED_7: 'promotion_coupon_failed_7',
  PROMOTION_COUPON_FAILED_8: 'promotion_coupon_failed_8',
  PROMOTION_COUPON_LIST_CLAIMED_TOTAL: 'promotion_coupon_list_claimed_total',
  PROMOTION_COUPON_LIST_DISCOUNT_DETAILS:
    'promotion_coupon_list_discount_details',
  PROMOTION_COUPON_LIST_SCOPE: 'promotion_coupon_list_scope',
  PROMOTION_COUPON_LIST_USED_CLAIMED_TOTAL:
    'promotion_coupon_list_used_claimed_total',
  PROMOTION_COUPON_LIST_VOUCHER_NAME: 'promotion_coupon_list_voucher_name',
  PROMOTION_COUPON_LIVESTREAM_COUPONS: 'promotion_coupon_livestream_coupons',
  PROMOTION_COUPON_MANAGEMENT_BUTTON_DISTRIBUTE:
    'promotion_coupon_management_button_distribute',
  PROMOTION_COUPON_MANAGEMENT_MODAL_BUTTON_INACTIVATE:
    'promotion_coupon_management_modal_button_inactivate',
  PROMOTION_COUPON_MANAGEMENT_MODAL_DESCRIPTION_CONFIRM_INACTIVATE:
    'promotion_coupon_management_modal_description_confirm_inactivate',
  PROMOTION_COUPON_MANAGEMENT_MODAL_DESCRIPTION_CONFIRM_WITH_NO_TASK:
    'promotion_coupon_management_modal_description_confirm_with_no_task',
  PROMOTION_COUPON_MANAGEMENT_MODAL_DESCRIPTION_CONFIRM_WITH_TASK:
    'promotion_coupon_management_modal_description_confirm_with_task',
  PROMOTION_COUPON_MANAGEMENT_MODAL_DESCRIPTION_NO_TASK:
    'promotion_coupon_management_modal_description_no_task',
  PROMOTION_COUPON_MANAGEMENT_MODAL_DESCRIPTION__VIEWING_TIME_TASK:
    'promotion_coupon_management_modal_description__viewing_time_task',
  PROMOTION_COUPON_MANAGEMENT_MODAL_NEW_COUPON:
    'promotion_coupon_management_modal_new_coupon',
  PROMOTION_COUPON_MANAGEMENT_MODAL_TITLE_CONFIRM:
    'promotion_coupon_management_modal_title_confirm',
  PROMOTION_COUPON_MANAGEMENT_MODAL_TITLE_CONFIRM_INACTIVATE:
    'promotion_coupon_management_modal_title_confirm_inactivate',
  PROMOTION_COUPON_MANAGEMENT_MODAL_TITLE_NO_TASK:
    'promotion_coupon_management_modal_title_no_task',
  PROMOTION_COUPON_MANAGEMENT_MODAL_TITLE_VIEWING_TIME_TASK:
    'promotion_coupon_management_modal_title_viewing_time_task',
  PROMOTION_COUPON_MANAGEMENT_STATUS_ACTIVE:
    'promotion_coupon_management_status_active',
  PROMOTION_COUPON_MANAGEMENT_TOAST_DISTRIBUTED:
    'promotion_coupon_management_toast_distributed',
  PROMOTION_COUPON_MANAGEMENT_TOAST_INACTIVATED:
    'promotion_coupon_management_toast_inactivated',
  PROMOTION_COUPON_MANAGEMENT_VIEWING_TIME_TASK_OPTIONS:
    'promotion_coupon_management_viewing_time_task_options',
  PROMOTION_COUPON_NO_TARGETED: 'promotion_coupon_no_targeted',
  PROMOTION_COUPON_NO_TARGETED_GO_TO_SELLERCENTER:
    'promotion_coupon_no_targeted_go_to_sellercenter',
  PROMOTION_COUPON_NO_TARGETED_INTRODUCTION:
    'promotion_coupon_no_targeted_introduction',
  PROMOTION_COUPON_NO_TASK_YET: 'promotion_coupon_no_task_yet',
  PROMOTION_COUPON_SAVE: 'promotion_coupon_save',
  PROMOTION_COUPON_SET: 'promotion_coupon_set',
  PROMOTION_COUPON_TARGETED_COUPON: 'promotion_coupon_targeted_coupon',
  PROMOTION_COUPON_TASK_DETAILS: 'promotion_coupon_task_details',
  PROMOTION_COUPON_TASK_TIMES: 'promotion_coupon_task_times',
  PUBLIC_PLAN_MANAGE_APPLICATION_LIST_BATCH_DELETE:
    'public_plan_manage_application_list_batch_delete',
  SHOP_PLAN_COMMISSION: 'shop_plan_commission',
  SHOP_PLAN_COMMISSION_TYPE_PERCENT: 'shop_plan_commission_type_percent',
  SHOP_PLAN_EDIT_DIALOG_DESCRIPTION_NO_PROMOTING:
    'shop_plan_edit_dialog_description_no_promoting',
  SHOP_PLAN_EDIT_DIALOG_DESCRIPTION_PROMOTING:
    'shop_plan_edit_dialog_description_promoting',
  SHOP_PLAN_EDIT_DIALOG_DESCRIPTION_PROMOTING_V2:
    'shop_plan_edit_dialog_description_promoting_v2',
  SHOP_PLAN_EXISTING_PAGE_CHART_TITLE_TYPE:
    'shop_plan_existing_page_chart_title_type',
  SHOP_PLAN_EXISTING_PAGE_CHART_TITLE_TYPE_BUBBLE:
    'shop_plan_existing_page_chart_title_type_bubble',
  SHOP_PLAN_EXISTING_PAGE_EDITING_ACTION_CANCEL:
    'shop_plan_existing_page_editing_action_cancel',
  SHOP_PLAN_EXISTING_PAGE_EDITING_ACTION_DONE:
    'shop_plan_existing_page_editing_action_done',
  SHOP_PLAN_EXISTING_PAGE_EDITING_CONFIRM_DIALOGUE_BODY:
    'shop_plan_existing_page_editing_confirm_dialogue_body',
  SHOP_PLAN_EXISTING_PAGE_EDITING_CONFIRM_DIALOGUE_TITLE:
    'shop_plan_existing_page_editing_confirm_dialogue_title',
  SHOP_PLAN_EXISTING_PAGE_EDITING_ENTER_ERROR:
    'shop_plan_existing_page_editing_enter_error',
  SHOP_PLAN_EXISTING_PAGE_EDITING_TOAST_FAIL:
    'shop_plan_existing_page_editing_toast_fail',
  SHOP_PLAN_EXISTING_PAGE_EDITING_TOAST_SUCCESS:
    'shop_plan_existing_page_editing_toast_success',
  SHOP_PLAN_EXISTING_PAGE_FAQ_A1: 'shop_plan_existing_page_faq_a1',
  SHOP_PLAN_EXISTING_PAGE_FAQ_A2: 'shop_plan_existing_page_faq_a2',
  SHOP_PLAN_EXISTING_PAGE_FAQ_A3: 'shop_plan_existing_page_faq_a3',
  SHOP_PLAN_EXISTING_PAGE_FAQ_Q1: 'shop_plan_existing_page_faq_q1',
  SHOP_PLAN_EXISTING_PAGE_FAQ_Q2: 'shop_plan_existing_page_faq_q2',
  SHOP_PLAN_EXISTING_PAGE_FAQ_Q3: 'shop_plan_existing_page_faq_q3',
  SHOP_PLAN_EXISTING_PAGE_FAQ_TITLE: 'shop_plan_existing_page_faq_title',
  SHOP_PLAN_EXISTING_PAGE_QUIT: 'shop_plan_existing_page_quit',
  SHOP_PLAN_EXISTING_PAGE_QUIT_BUTTON: 'shop_plan_existing_page_quit_button',
  SHOP_PLAN_EXISTING_PAGE_QUIT_DIALOGUE_BODY:
    'shop_plan_existing_page_quit_dialogue_body',
  SHOP_PLAN_EXISTING_PAGE_QUIT_DIALOGUE_BUTTON_CANCEL:
    'shop_plan_existing_page_quit_dialogue_button_cancel',
  SHOP_PLAN_EXISTING_PAGE_QUIT_DIALOGUE_BUTTON_CONFIRM:
    'shop_plan_existing_page_quit_dialogue_button_confirm',
  SHOP_PLAN_EXISTING_PAGE_QUIT_DIALOGUE_TITLE:
    'shop_plan_existing_page_quit_dialogue_title',
  SHOP_PLAN_EXISTING_PAGE_STATUS_EXPLANATION:
    'shop_plan_existing_page_status_explanation',
  SHOP_PLAN_EXISTING_PAGE_TITLE: 'shop_plan_existing_page_title',
  SHOP_PLAN_INVALID_SELLER_ACCOUNT_POPUP_CONTENT:
    'shop_plan_invalid_seller_account_popup_content',
  SHOP_PLAN_INVALID_SELLER_ACCOUNT_POPUP_TITLE:
    'shop_plan_invalid_seller_account_popup_title',
  SHOP_PLAN_MANAGE_APPLICATION_LIST_PRODUCT_STATUS:
    'shop_plan_manage_application_list_product_status',
  SHOP_PLAN_MANAGE_CREATORS_LIST_DELETED_TIME:
    'shop_plan_manage_creators_list_deleted_time',
  SHOP_PLAN_MANAGE_CREATORS_LIST_PRODUCT_STATUS:
    'shop_plan_manage_creators_list_product_status',
  SHOP_PLAN_MANAGE_CREATORS_LIST_PRODUCT_STATUS_LIVE:
    'shop_plan_manage_creators_list_product_status_live',
  SHOP_PLAN_MANAGE_CREATORS_LIST_PRODUCT_STATUS_SOLD_OUT:
    'shop_plan_manage_creators_list_product_status_sold_out',
  SHOP_PLAN_MANAGE_CREATORS_LIST_PRODUCT_STATUS_UNAVAILABLE:
    'shop_plan_manage_creators_list_product_status_unavailable',
  SHOP_PLAN_MANAGE_CREATORS_LIST_PRODUCT_STATUS_WITHDRAWN:
    'shop_plan_manage_creators_list_product_status_withdrawn',
  SHOP_PLAN_MANAGE_CREATORS_LIST_SHOWCASE_STATUS_DELETED:
    'shop_plan_manage_creators_list_showcase_status_deleted',
  SHOP_PLAN_MANAGE_CREATORS_LIST_SHOWCASE_STATUS_DELETING:
    'shop_plan_manage_creators_list_showcase_status_deleting',
  SHOP_PLAN_MANAGE_CREATORS_LIST_SHOWCASE_STATUS_DELETING_HOVER:
    'shop_plan_manage_creators_list_showcase_status_deleting_hover',
  SHOP_PLAN_NO_PAGE_CREATE_POPUP_BTN_CANCEL:
    'shop_plan_no_page_create_popup_btn_cancel',
  SHOP_PLAN_NO_PAGE_CREATE_POPUP_BTN_SUBMIT:
    'shop_plan_no_page_create_popup_btn_submit',
  SHOP_PLAN_NO_PAGE_CREATE_POPUP_ERROR_EXCEED:
    'shop_plan_no_page_create_popup_error_exceed',
  SHOP_PLAN_NO_PAGE_CREATE_POPUP_INTRO: 'shop_plan_no_page_create_popup_intro',
  SHOP_PLAN_NO_PAGE_CREATE_POPUP_INTRO_LESS:
    'shop_plan_no_page_create_popup_intro_less',
  SHOP_PLAN_NO_PAGE_CREATE_POPUP_TITLE: 'shop_plan_no_page_create_popup_title',
  SHOP_PLAN_NO_PAGE_EXPLAIN_BODY: 'shop_plan_no_page_explain_body',
  SHOP_PLAN_NO_PAGE_EXPLAIN_TITLE: 'shop_plan_no_page_explain_title',
  SHOP_PLAN_NO_PAGE_START_BTN: 'shop_plan_no_page_start_btn',
  SHOP_PLAN_OK_COMMON: 'shop_plan_ok_common',
  SHOP_PLAN_PENDING_PAGE_EXPLAIN_BODY: 'shop_plan_pending_page_explain_body',
  SHOP_PLAN_PENDING_PAGE_EXPLAIN_BODY_V2:
    'shop_plan_pending_page_explain_body_v2',
  SHOP_PLAN_PENDING_PAGE_EXPLAIN_TITLE: 'shop_plan_pending_page_explain_title',
  SHOP_PLAN_QUIT_DIALOG_DESCRIPTION_NO_PROMOTING:
    'shop_plan_quit_dialog_description_no_promoting',
  SHOP_PLAN_QUIT_DIALOG_DESCRIPTION_PROMOTING:
    'shop_plan_quit_dialog_description_promoting',
  SHOP_PLAN_QUIT_DIALOG_DESCRIPTION_PROMOTING_V2:
    'shop_plan_quit_dialog_description_promoting_v2',
  SHOP_PLAN_REFRESH_COMMON: 'shop_plan_refresh_common',
  SHOP_PLAN_SHOP_PLAN_REPEAT_POPUP_CONTENT:
    'shop_plan_shop_plan_repeat_popup_content',
  SHOP_PLAN_SHOP_PLAN_REPEAT_POPUP_TITLE:
    'shop_plan_shop_plan_repeat_popup_title',
  STATEMENT_DETAIL_BTN_EXPORT: 'statement_detail_btn_export',
  STATEMENT_DETAIL_BTN_EXPORT_NO_DATA: 'statement_detail_btn_export_no_data',
  STATEMENT_DETAIL_LIST_SETTLEMENT_AMOUNT:
    'statement_detail_list_settlement_amount',
  STEAMER_NUMBER_HOVER: 'steamer_number_hover',
  STEAMER_NUMBER_TOO_LARGE: 'steamer_number_too_large',
  STEAMER_NUMBER_TOO_SMALL: 'steamer_number_too_small',
  STREAMER_ADD_NEW_PRODUCTS_LEFT_BOTTOM_TEXT_1:
    'streamer_add_new_products_left_bottom_text_1',
  STREAMER_ADD_NEW_PRODUCTS_LEFT_BOTTOM_TEXT_1_BOTTOM:
    'streamer_add_new_products_left_bottom_text_1_bottom',
  STREAMER_ADD_NEW_PRODUCTS_LEFT_BOTTOM_TEXT_2:
    'streamer_add_new_products_left_bottom_text_2',
  STREAMER_ADD_NEW_PRODUCTS_LEFT_BOTTOM_TEXT_HOPOVER:
    'streamer_add_new_products_left_bottom_text_hopover',
  STREAMER_ADD_NEW_PRODUCTS_MODIFY_PREFERENCE:
    'streamer_add_new_products_modify_preference',
  STREAMER_ADD_NEW_PRODUCTS_MODIFY_PREFERENCE_BOTTOM:
    'streamer_add_new_products_modify_preference_bottom',
  STREAMER_ADD_NEW_PRODUCTS_MODIFY_PREFERENCE_CONFIRM:
    'streamer_add_new_products_modify_preference_confirm',
  STREAMER_ADD_NEW_PRODUCTS_MODIFY_PREFERENCE_TOP:
    'streamer_add_new_products_modify_preference_top',
  STREAMER_DESKTOP_ADD_PRODUCT_LIST_SHOP:
    'streamer_desktop_add_product_list_shop',
  STREAMER_DESKTOP_ADD_PRODUCT_PARTIAL_RISK_CONTROL_ERROR_TOAST:
    'streamer_desktop_add_product_partial_risk_control_error_toast',
  STREAMER_DESKTOP_ADD_PRODUCT_SOURCE_TARGETED_PLAN:
    'streamer_desktop_add_product_source_targeted_plan',
  STREAMER_DESKTOP_ADD_PRODUCT_URL_HOVER_CREATOR:
    'streamer_desktop_add_product_url_hover_creator',
  STREAMER_DESKTOP_ADD_PRODUCT_URL_HOVER_MERCHANT:
    'streamer_desktop_add_product_url_hover_merchant',
  STREAMER_DESKTOP_CAMPAIGN_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CRITERIA_COMPETITION:
    'streamer_desktop_campaign_livestream_campaigns_detail_page_criteria_competition',
  STREAMER_DESKTOP_CAMPAIGN_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CRITERIA_COMPETITION_TOOLTIPS:
    'streamer_desktop_campaign_livestream_campaigns_detail_page_criteria_competition_tooltips',
  STREAMER_DESKTOP_CAMPAIGN_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CRITERIA_MAX_SESSION_PLURAL:
    'streamer_desktop_campaign_livestream_campaigns_detail_page_criteria_max_session_plural',
  STREAMER_DESKTOP_CAMPAIGN_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CRITERIA_MAX_SESSION_SINGULAR:
    'streamer_desktop_campaign_livestream_campaigns_detail_page_criteria_max_session_singular',
  STREAMER_DESKTOP_CAMPAIGN_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CRITERIA_MAX_SESSION_TOOLTIPS:
    'streamer_desktop_campaign_livestream_campaigns_detail_page_criteria_max_session_tooltips',
  STREAMER_DESKTOP_CAMPAIGN_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CRITERIA_REGISTRATION_LIMIT:
    'streamer_desktop_campaign_livestream_campaigns_detail_page_criteria_registration_limit',
  STREAMER_DESKTOP_CAMPAIGN_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOAST_SIGN_UP_FAILED_PLURAL:
    'streamer_desktop_campaign_livestream_campaigns_detail_page_toast_sign_up_failed_plural',
  STREAMER_DESKTOP_CAMPAIGN_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOAST_SIGN_UP_FAILED_SINGULAR:
    'streamer_desktop_campaign_livestream_campaigns_detail_page_toast_sign_up_failed_singular',
  STREAMER_DESKTOP_DIRECT_INVITES: 'streamer_desktop_direct_invites',
  STREAMER_DESKTOP_GUIDANCE_ACADEMY: 'streamer_desktop_guidance_academy',
  STREAMER_DESKTOP_GUIDANCE_ACADEMY_INTRO_TEXT:
    'streamer_desktop_guidance_academy_intro_text',
  STREAMER_DESKTOP_GUIDANCE_ACADEMY_INTRO_TITLE:
    'streamer_desktop_guidance_academy_intro_title',
  STREAMER_DESKTOP_GUIDE_MAIN_POPUP_1ST_TEXT:
    'streamer_desktop_guide_main_popup_1st_text',
  STREAMER_DESKTOP_GUIDE_MAIN_POPUP_2ND_TEXT:
    'streamer_desktop_guide_main_popup_2nd_text',
  STREAMER_DESKTOP_GUIDE_MAIN_POPUP_3RD_TEXT:
    'streamer_desktop_guide_main_popup_3rd_text',
  STREAMER_DESKTOP_GUIDE_MAIN_POPUP_BTN:
    'streamer_desktop_guide_main_popup_btn',
  STREAMER_DESKTOP_GUIDE_MAIN_POPUP_SUBTITLE:
    'streamer_desktop_guide_main_popup_subtitle',
  STREAMER_DESKTOP_GUIDE_MAIN_POPUP_TEXT:
    'streamer_desktop_guide_main_popup_text',
  STREAMER_DESKTOP_GUIDE_MAIN_POPUP_TITLE:
    'streamer_desktop_guide_main_popup_title',
  STREAMER_DESKTOP_GUIDE_STEP_DATA_COMPASS_POPUP_TEXT:
    'streamer_desktop_guide_step_data_compass_popup_text',
  STREAMER_DESKTOP_GUIDE_STEP_DATA_COMPASS_POPUP_TITLE:
    'streamer_desktop_guide_step_data_compass_popup_title',
  STREAMER_DESKTOP_GUIDE_STEP_LIVESTREAM_PRODUCTS_POPUP_TEXT:
    'streamer_desktop_guide_step_livestream_products_popup_text',
  STREAMER_DESKTOP_GUIDE_STEP_LIVESTREAM_PRODUCTS_POPUP_TITLE:
    'streamer_desktop_guide_step_livestream_products_popup_title',
  STREAMER_DESKTOP_GUIDE_STEP_POPUP_BTN_BACK:
    'streamer_desktop_guide_step_popup_btn_back',
  STREAMER_DESKTOP_GUIDE_STEP_POPUP_BTN_NEXT:
    'streamer_desktop_guide_step_popup_btn_next',
  STREAMER_DESKTOP_GUIDE_STEP_POPUP_BTN_SKIP:
    'streamer_desktop_guide_step_popup_btn_skip',
  STREAMER_DESKTOP_GUIDE_STEP_SHOWCASE_PRODUCTS_POPUP_TEXT:
    'streamer_desktop_guide_step_showcase_products_popup_text',
  STREAMER_DESKTOP_GUIDE_STEP_SHOWCASE_PRODUCTS_POPUP_TITLE:
    'streamer_desktop_guide_step_showcase_products_popup_title',
  STREAMER_DESKTOP_HELP_CENTER: 'streamer_desktop_help_center',
  STREAMER_DESKTOP_HOVER_PRODUCT_DETAILS:
    'streamer_desktop_hover_product_details',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_ADDING_TEXT1:
    'streamer_desktop_livestream_add_products_product_set_adding_text1',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_ADDING_TEXT2:
    'streamer_desktop_livestream_add_products_product_set_adding_text2',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_ALL_FAIL_TITLE:
    'streamer_desktop_livestream_add_products_product_set_all_fail_title',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_BTN_RETRY:
    'streamer_desktop_livestream_add_products_product_set_btn_retry',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_DETAILS_BUTTON_ADD:
    'streamer_desktop_livestream_add_products_product_set_details_button_add',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_DETAILS_HINT_PRODUCT_TO_BE_ADD:
    'streamer_desktop_livestream_add_products_product_set_details_hint_product_to_be_add',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_EMPTY_BTN_CREATE:
    'streamer_desktop_livestream_add_products_product_set_empty_btn_create',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_EMPTY_TEXT:
    'streamer_desktop_livestream_add_products_product_set_empty_text',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_EMPTY_TITLE:
    'streamer_desktop_livestream_add_products_product_set_empty_title',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_MODAL_RETRY_BTN_CANCEL:
    'streamer_desktop_livestream_add_products_product_set_fail_modal_retry_btn_cancel',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_MODAL_RETRY_BTN_STAY:
    'streamer_desktop_livestream_add_products_product_set_fail_modal_retry_btn_stay',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_MODAL_RETRY_TEXT:
    'streamer_desktop_livestream_add_products_product_set_fail_modal_retry_text',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_MODAL_RETRY_TITLE:
    'streamer_desktop_livestream_add_products_product_set_fail_modal_retry_title',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_TEXT_SERVER_ERROR:
    'streamer_desktop_livestream_add_products_product_set_fail_text_server_error',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_TEXT_UNAVAILABLE_ADDED:
    'streamer_desktop_livestream_add_products_product_set_fail_text_unavailable_added',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_TEXT_UNAVAILABLE_ADDED_ALL:
    'streamer_desktop_livestream_add_products_product_set_fail_text_unavailable_added_all',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_TITLE_ADDED:
    'streamer_desktop_livestream_add_products_product_set_fail_title_added',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_TITLE_SERVER_ERROR:
    'streamer_desktop_livestream_add_products_product_set_fail_title_server_error',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_FAIL_TITLE_UNAVAILABLE:
    'streamer_desktop_livestream_add_products_product_set_fail_title_unavailable',
  STREAMER_DESKTOP_LIVESTREAM_ADD_PRODUCTS_PRODUCT_SET_SUCCESS_TITLE:
    'streamer_desktop_livestream_add_products_product_set_success_title',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_BTN_CANCEL:
    'streamer_desktop_livestream_campaigns_detail_page_btn_cancel',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_BTN_DUPLICATE:
    'streamer_desktop_livestream_campaigns_detail_page_btn_duplicate',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CAMPAIGN_DATES:
    'streamer_desktop_livestream_campaigns_detail_page_campaign_dates',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CANCEL_POPUP_BUTTON_CONFIRM:
    'streamer_desktop_livestream_campaigns_detail_page_cancel_popup_button_confirm',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CANCEL_POPUP_TEXT:
    'streamer_desktop_livestream_campaigns_detail_page_cancel_popup_text',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_CANCEL_POPUP_TITLE:
    'streamer_desktop_livestream_campaigns_detail_page_cancel_popup_title',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_GENDER:
    'streamer_desktop_livestream_campaigns_detail_page_header_gender',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_LEVEL:
    'streamer_desktop_livestream_campaigns_detail_page_header_level',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_LIVESTREAM_REGISTERED_TIME:
    'streamer_desktop_livestream_campaigns_detail_page_header_livestream_registered_time',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_MERCHANDISE_SUPPORT:
    'streamer_desktop_livestream_campaigns_detail_page_header_merchandise_support',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_MIN_SESSION_PERIOD:
    'streamer_desktop_livestream_campaigns_detail_page_header_min_session_period',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_NOTES:
    'streamer_desktop_livestream_campaigns_detail_page_header_notes',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_PERSONAL_IMAGE:
    'streamer_desktop_livestream_campaigns_detail_page_header_personal_image',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_SESSION_CATEGORY:
    'streamer_desktop_livestream_campaigns_detail_page_header_session_category',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_SESSION_ID:
    'streamer_desktop_livestream_campaigns_detail_page_header_session_id',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_SESSION_LEVEL:
    'streamer_desktop_livestream_campaigns_detail_page_header_session_level',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HEADER_SESSION_PERIOD:
    'streamer_desktop_livestream_campaigns_detail_page_header_session_period',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HOUR_PLURAL:
    'streamer_desktop_livestream_campaigns_detail_page_hour_plural',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_HOUR_SINGULAR:
    'streamer_desktop_livestream_campaigns_detail_page_hour_singular',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_MIN_PLURAL:
    'streamer_desktop_livestream_campaigns_detail_page_min_plural',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_MIN_SINGULAR:
    'streamer_desktop_livestream_campaigns_detail_page_min_singular',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_NAME_SESSION_CRITERIA:
    'streamer_desktop_livestream_campaigns_detail_page_name_session_criteria',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_NO_CRITERIA:
    'streamer_desktop_livestream_campaigns_detail_page_no_criteria',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_POPUP_CANCEL_TEXT:
    'streamer_desktop_livestream_campaigns_detail_page_popup_cancel_text',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_POPUP_CANCEL_TITLE:
    'streamer_desktop_livestream_campaigns_detail_page_popup_cancel_title',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_REGISTERED_BY_PLATFORM_MANAGER:
    'streamer_desktop_livestream_campaigns_detail_page_registered_by_platform_manager',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_REGISTERED_BY_SELLER:
    'streamer_desktop_livestream_campaigns_detail_page_registered_by_seller',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAB_NAME_CRITERIA:
    'streamer_desktop_livestream_campaigns_detail_page_tab_name_criteria',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAB_NAME_DETAILS:
    'streamer_desktop_livestream_campaigns_detail_page_tab_name_details',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAB_NAME_REGISTERED:
    'streamer_desktop_livestream_campaigns_detail_page_tab_name_registered',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAG_APPROVED:
    'streamer_desktop_livestream_campaigns_detail_page_tag_approved',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAG_EXPIRED:
    'streamer_desktop_livestream_campaigns_detail_page_tag_expired',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAG_FEMALE:
    'streamer_desktop_livestream_campaigns_detail_page_tag_female',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAG_MALE:
    'streamer_desktop_livestream_campaigns_detail_page_tag_male',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAG_NEED_SUPPORT:
    'streamer_desktop_livestream_campaigns_detail_page_tag_need_support',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAG_NOT_NEED_SUPPORT:
    'streamer_desktop_livestream_campaigns_detail_page_tag_not_need_support',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAG_REJECTED:
    'streamer_desktop_livestream_campaigns_detail_page_tag_rejected',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TAG_UNDER_REVIEW:
    'streamer_desktop_livestream_campaigns_detail_page_tag_under_review',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TITLE:
    'streamer_desktop_livestream_campaigns_detail_page_title',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOAST_COPY:
    'streamer_desktop_livestream_campaigns_detail_page_toast_copy',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOAST_SIGN_UP_FAILED_100:
    'streamer_desktop_livestream_campaigns_detail_page_toast_sign_up_failed_100',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOAST_SUBMIT_SUCCESS:
    'streamer_desktop_livestream_campaigns_detail_page_toast_submit_success',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOOLTIO_EXPIRED_CANCEL:
    'streamer_desktop_livestream_campaigns_detail_page_tooltio_expired_cancel',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOOLTIO_EXPIRED_END:
    'streamer_desktop_livestream_campaigns_detail_page_tooltio_expired_end',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOOLTIP_CANCEL:
    'streamer_desktop_livestream_campaigns_detail_page_tooltip_cancel',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOOLTIP_DUPLICATE:
    'streamer_desktop_livestream_campaigns_detail_page_tooltip_duplicate',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOOLTIP_LEVEL_CHANGE:
    'streamer_desktop_livestream_campaigns_detail_page_tooltip_level_change',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOOLTIP_LEVEL_INFORM:
    'streamer_desktop_livestream_campaigns_detail_page_tooltip_level_inform',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOOLTIP_PERIOD_CHANGE:
    'streamer_desktop_livestream_campaigns_detail_page_tooltip_period_change',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_DETAIL_PAGE_TOOLTIP_PERIOD_INFORM:
    'streamer_desktop_livestream_campaigns_detail_page_tooltip_period_inform',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_BTN_HIDE_EXPIRED:
    'streamer_desktop_livestream_campaigns_list_page_btn_hide_expired',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_BTN_MANAGE:
    'streamer_desktop_livestream_campaigns_list_page_btn_manage',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_BTN_SHOW_EXPIRED:
    'streamer_desktop_livestream_campaigns_list_page_btn_show_expired',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_BTN_SIGN_UP:
    'streamer_desktop_livestream_campaigns_list_page_btn_sign_up',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_BTN_VIEW:
    'streamer_desktop_livestream_campaigns_list_page_btn_view',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_HEADER_ACTION:
    'streamer_desktop_livestream_campaigns_list_page_header_action',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_HEADER_CAMPAIGN:
    'streamer_desktop_livestream_campaigns_list_page_header_campaign',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_HEADER_REGISTRATION_DATES:
    'streamer_desktop_livestream_campaigns_list_page_header_registration_dates',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_HEADER_STATUS:
    'streamer_desktop_livestream_campaigns_list_page_header_status',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_SEARCH_PLACEHOLDER:
    'streamer_desktop_livestream_campaigns_list_page_search_placeholder',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_TAB_NAME_ALL:
    'streamer_desktop_livestream_campaigns_list_page_tab_name_all',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_TAB_NAME_OPEN_FOR_REGISTRATION:
    'streamer_desktop_livestream_campaigns_list_page_tab_name_open_for_registration',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_TAB_NAME_REGISTERED:
    'streamer_desktop_livestream_campaigns_list_page_tab_name_registered',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_TAG_EXPIRED:
    'streamer_desktop_livestream_campaigns_list_page_tag_expired',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_TAG_MAJOR_CAMPAIGN:
    'streamer_desktop_livestream_campaigns_list_page_tag_major_campaign',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_TAG_ONGOING:
    'streamer_desktop_livestream_campaigns_list_page_tag_ongoing',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_TAG_OPEN_FOR_REGISTRATION:
    'streamer_desktop_livestream_campaigns_list_page_tag_open_for_registration',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_LIST_PAGE_TAG_UPCOMING:
    'streamer_desktop_livestream_campaigns_list_page_tag_upcoming',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_BRIEF_PERSONAL_IMAGE:
    'streamer_desktop_livestream_campaigns_sign_up_page_brief_personal_image',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_BRIEF_PERSONAL_IMAGE_PLURAL:
    'streamer_desktop_livestream_campaigns_sign_up_page_brief_personal_image_plural',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_BTN_CANCEL:
    'streamer_desktop_livestream_campaigns_sign_up_page_btn_cancel',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_BTN_SUBMIT:
    'streamer_desktop_livestream_campaigns_sign_up_page_btn_submit',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_CATEGORY_ELECTRONICS:
    'streamer_desktop_livestream_campaigns_sign_up_page_category_electronics',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_CATEGORY_FASHION:
    'streamer_desktop_livestream_campaigns_sign_up_page_category_fashion',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_CATEGORY_FOOD:
    'streamer_desktop_livestream_campaigns_sign_up_page_category_food',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_CATEGORY_HEALTH_BEAUTY:
    'streamer_desktop_livestream_campaigns_sign_up_page_category_health_beauty',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_CATEGORY_HOME_LIVING:
    'streamer_desktop_livestream_campaigns_sign_up_page_category_home_living',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_CATEGORY_MOMS_BABIES:
    'streamer_desktop_livestream_campaigns_sign_up_page_category_moms_babies',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_CATEGORY_OTHER:
    'streamer_desktop_livestream_campaigns_sign_up_page_category_other',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_ERROR_CATEGORY:
    'streamer_desktop_livestream_campaigns_sign_up_page_error_category',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_ERROR_GENDER:
    'streamer_desktop_livestream_campaigns_sign_up_page_error_gender',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_ERROR_IMAGE:
    'streamer_desktop_livestream_campaigns_sign_up_page_error_image',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_ERROR_NO_TIME:
    'streamer_desktop_livestream_campaigns_sign_up_page_error_no_time',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_ERROR_PERIOD_NOT_REACH_REQUIREMENT:
    'streamer_desktop_livestream_campaigns_sign_up_page_error_period_not_reach_requirement',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_ERROR_PERIOD_OUT:
    'streamer_desktop_livestream_campaigns_sign_up_page_error_period_out',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_ERROR_SUPPORT:
    'streamer_desktop_livestream_campaigns_sign_up_page_error_support',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_ERROR_WILLINGNESS_LEVEL:
    'streamer_desktop_livestream_campaigns_sign_up_page_error_willingness_level',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_HINT_MODIFIED:
    'streamer_desktop_livestream_campaigns_sign_up_page_hint_modified',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_HINT_OPTIONAL:
    'streamer_desktop_livestream_campaigns_sign_up_page_hint_optional',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_MAIN_CATEGORY:
    'streamer_desktop_livestream_campaigns_sign_up_page_main_category',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_MERCHANDISE_SUPPORT:
    'streamer_desktop_livestream_campaigns_sign_up_page_merchandise_support',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_MERCHANDISE_SUPPORT_TOOLTIPS:
    'streamer_desktop_livestream_campaigns_sign_up_page_merchandise_support_tooltips',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_MODULE_TITLE:
    'streamer_desktop_livestream_campaigns_sign_up_page_module_title',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_PERSONAL_IMAGE:
    'streamer_desktop_livestream_campaigns_sign_up_page_personal_image',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_PLACEHOLDER_CATEGORY:
    'streamer_desktop_livestream_campaigns_sign_up_page_placeholder_category',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_PLACEHOLDER_END_DATE:
    'streamer_desktop_livestream_campaigns_sign_up_page_placeholder_end_date',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_PLACEHOLDER_OPTIONAL:
    'streamer_desktop_livestream_campaigns_sign_up_page_placeholder_optional',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_PLACEHOLDER_PERSONAL_IMAGE:
    'streamer_desktop_livestream_campaigns_sign_up_page_placeholder_personal_image',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_PLACEHOLDER_START_DATE:
    'streamer_desktop_livestream_campaigns_sign_up_page_placeholder_start_date',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_SESSION_PERIOD:
    'streamer_desktop_livestream_campaigns_sign_up_page_session_period',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_TITLE:
    'streamer_desktop_livestream_campaigns_sign_up_page_title',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_TOAST_ERROR_IMAGE_DIMENSION:
    'streamer_desktop_livestream_campaigns_sign_up_page_toast_error_image_dimension',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_TOAST_ERROR_IMAGE_SIZE:
    'streamer_desktop_livestream_campaigns_sign_up_page_toast_error_image_size',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_TOAST_SIGN_UP_FAILED_MISS:
    'streamer_desktop_livestream_campaigns_sign_up_page_toast_sign_up_failed_miss',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_SIGN_UP_PAGE_WILLINGNESS_LEVEL:
    'streamer_desktop_livestream_campaigns_sign_up_page_willingness_level',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGNS_TITLE:
    'streamer_desktop_livestream_campaigns_title',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGN_CLICK_LEARN_MORE:
    'streamer_desktop_livestream_campaign_click_learn_more',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGN_ERROR_DEFAULT_TEXT:
    'streamer_desktop_livestream_campaign_error_default_text',
  STREAMER_DESKTOP_LIVESTREAM_CAMPAIGN_TAG_INELIGIBLE:
    'streamer_desktop_livestream_campaign_tag_ineligible',
  STREAMER_DESKTOP_LIVESTREAM_PRODUCTS_BTN_REFRESH:
    'streamer_desktop_livestream_products_btn_refresh',
  STREAMER_DESKTOP_LIVESTREAM_PRODUCTS_LIST_PLACEHOLDER:
    'streamer_desktop_livestream_products_list_placeholder',
  STREAMER_DESKTOP_LIVESTREAM_PRODUCTS_STATUS_TAG_TOOLTIP:
    'streamer_desktop_livestream_products_status_tag_tooltip',
  STREAMER_DESKTOP_LIVESTREAM_PRODUCT_NUM:
    'streamer_desktop_livestream_product_num',
  STREAMER_DESKTOP_LIVE_ABNORMAL_PRODUCTS_LIST_PAGE_TIP_MANUALLY_ADD:
    'streamer_desktop_live_abnormal_products_list_page_tip_manually_add',
  STREAMER_DESKTOP_LIVE_ABNORMAL_PRODUCTS_LIST_PAGE_TITLE:
    'streamer_desktop_live_abnormal_products_list_page_title',
  STREAMER_DESKTOP_LIVE_ABNORMAL_PRODUCTS_REASON_DELETED:
    'streamer_desktop_live_abnormal_products_reason_deleted',
  STREAMER_DESKTOP_LIVE_ABNORMAL_PRODUCTS_REASON_INFO_CHANGED_AND_REVIEWING:
    'streamer_desktop_live_abnormal_products_reason_info_changed_and_reviewing',
  STREAMER_DESKTOP_LIVE_ABNORMAL_PRODUCTS_REASON_PLAN_CHANGED_OR_DELETED:
    'streamer_desktop_live_abnormal_products_reason_plan_changed_or_deleted',
  STREAMER_DESKTOP_LIVE_ABNORMAL_PRODUCTS_REASON_SOMETHING_WRONG:
    'streamer_desktop_live_abnormal_products_reason_something_wrong',
  STREAMER_DESKTOP_LIVE_ABNORMAL_PRODUCTS_REASON_VIOLATE_PLATFORM_GUIDELINES:
    'streamer_desktop_live_abnormal_products_reason_violate_platform_guidelines',
  STREAMER_DESKTOP_LIVE_ADD_NEW_PRODUCT_BTN:
    'streamer_desktop_live_add_new_product_btn',
  STREAMER_DESKTOP_LIVE_ADD_NEW_PRODUCT_PAGE_TITLE:
    'streamer_desktop_live_add_new_product_page_title',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_BTN:
    'streamer_desktop_live_add_product_btn',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_EXCEED_SKU_MAX_LIMIT_ERROR_TOAST:
    'streamer_desktop_live_add_product_exceed_sku_max_limit_error_toast',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_EXCEED_SPU_MAX_LIMIT_BEFORE_CLICKING_ADD_PRODUCT_ERROR_TOAST:
    'streamer_desktop_live_add_product_exceed_spu_max_limit_before_clicking_add_product_error_toast',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_EXCEED_SPU_MAX_LIMIT_ERROR_TOAST:
    'streamer_desktop_live_add_product_exceed_spu_max_limit_error_toast',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_FAIL_REASON:
    'streamer_desktop_live_add_product_fail_reason',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_FAIL_REASON_LIVE:
    'streamer_desktop_live_add_product_fail_reason_live',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_FAIL_REASON_NOT_APPROVED:
    'streamer_desktop_live_add_product_fail_reason_not_approved',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_FAIL_REASON_UNAVAILABLE:
    'streamer_desktop_live_add_product_fail_reason_unavailable',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_FAIL_REASON_UNDER_REVIEW:
    'streamer_desktop_live_add_product_fail_reason_under_review',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_NOT_LINK_SHOPEE_TIP:
    'streamer_desktop_live_add_product_not_link_shopee_tip',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_NOT_LINK_SHOPEE_TIP_TOOLTIP:
    'streamer_desktop_live_add_product_not_link_shopee_tip_tooltip',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_RESULT:
    'streamer_desktop_live_add_product_result',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_RESULT_CHECK_FAIL_PRODUCT_TEXT:
    'streamer_desktop_live_add_product_result_check_fail_product_text',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_RESULT_FAIL_PRODUCT_TIP:
    'streamer_desktop_live_add_product_result_fail_product_tip',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_RESULT_TITLE_SUCCESS_NUM_PLURAL:
    'streamer_desktop_live_add_product_result_title_success_num_plural',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_RESULT_TITLE_SUCCESS_NUM_SINGULAR:
    'streamer_desktop_live_add_product_result_title_success_num_singular',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SHOWCASE_MAX_LIMIT_ERROR_TOAST:
    'streamer_desktop_live_add_product_showcase_max_limit_error_toast',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SHOWCASE_SOME_PRODUCTS_ADDED_ERROR_TOAST:
    'streamer_desktop_live_add_product_showcase_some_products_added_error_toast',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_SHOWCASE:
    'streamer_desktop_live_add_product_source_showcase',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL:
    'streamer_desktop_live_add_product_source_url',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_ENTER_URL_PLACEHOLDER_EXAMPLE:
    'streamer_desktop_live_add_product_source_url_enter_url_placeholder_example',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_ENTER_URL_PLACEHOLDER_NUM_LIMIT:
    'streamer_desktop_live_add_product_source_url_enter_url_placeholder_num_limit',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_ENTER_URL_PLACEHOLDER_SEPARATE:
    'streamer_desktop_live_add_product_source_url_enter_url_placeholder_separate',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_FAIL_DIALOG_REASON_NOT_APPROVED:
    'streamer_desktop_live_add_product_source_url_fail_dialog_reason_not_approved',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_FAIL_DIALOG_REASON_UNAVAILABLE:
    'streamer_desktop_live_add_product_source_url_fail_dialog_reason_unavailable',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_FAIL_DIALOG_REASON_UNDER_REVIEW:
    'streamer_desktop_live_add_product_source_url_fail_dialog_reason_under_review',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_FAIL_DIALOG_TITLE:
    'streamer_desktop_live_add_product_source_url_fail_dialog_title',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_IDENTIFY_URL_BTN:
    'streamer_desktop_live_add_product_source_url_identify_url_btn',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_NOT_IN_PLAN_ERROR_TIP_REMOVE:
    'streamer_desktop_live_add_product_source_url_not_in_plan_error_tip_remove',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_UNIDENTIFIABLE_ERROR_TIP_REMOVE:
    'streamer_desktop_live_add_product_source_url_unidentifiable_error_tip_remove',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SOURCE_URL_UNSUPPORTED_ERROR_TIP_REMOVE:
    'streamer_desktop_live_add_product_source_url_unsupported_error_tip_remove',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_STATUS_ADDED:
    'streamer_desktop_live_add_product_status_added',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_STATUS_ADDED_TOOLTIP:
    'streamer_desktop_live_add_product_status_added_tooltip',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SUCCESS_NUM_TOAST_PLURAL:
    'streamer_desktop_live_add_product_success_num_toast_plural',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_SUCCESS_NUM_TOAST_SINGULAR:
    'streamer_desktop_live_add_product_success_num_toast_singular',
  STREAMER_DESKTOP_LIVE_ADD_PRODUCT_UNAVAILABLE_PRODUCT_TIP:
    'streamer_desktop_live_add_product_unavailable_product_tip',
  STREAMER_DESKTOP_LIVE_EMPTY: 'streamer_desktop_live_empty',
  STREAMER_DESKTOP_LIVE_END_TOAST: 'streamer_desktop_live_end_toast',
  STREAMER_DESKTOP_LIVE_PRODUCTS_ABNORMAL_DIALOG_BTN_CHECK_OUT:
    'streamer_desktop_live_products_abnormal_dialog_btn_check_out',
  STREAMER_DESKTOP_LIVE_PRODUCTS_ABNORMAL_DIALOG_TEXT_CHECK_REASON:
    'streamer_desktop_live_products_abnormal_dialog_text_check_reason',
  STREAMER_DESKTOP_LIVE_PRODUCTS_ABNORMAL_DIALOG_TITLE_REMOVE_FROM_LIVE:
    'streamer_desktop_live_products_abnormal_dialog_title_remove_from_live',
  STREAMER_DESKTOP_LIVE_PRODUCTS_ABNORMAL_TIP_BTN_CHECK_REASONS:
    'streamer_desktop_live_products_abnormal_tip_btn_check_reasons',
  STREAMER_DESKTOP_LIVE_PRODUCTS_ABNORMAL_TIP_WITH_NUM:
    'streamer_desktop_live_products_abnormal_tip_with_num',
  STREAMER_DESKTOP_LIVE_PRODUCTS_MAX_LIMIT_TIP:
    'streamer_desktop_live_products_max_limit_tip',
  STREAMER_DESKTOP_LIVE_PRODUCTS_PAGE_TITLE:
    'streamer_desktop_live_products_page_title',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_DELETE_SUCCESS_TOAST_PLURAL:
    'streamer_desktop_live_product_action_delete_success_toast_plural',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_DELETE_SUCCESS_TOAST_SINGULAR:
    'streamer_desktop_live_product_action_delete_success_toast_singular',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_EDIT_SUCCESS_TOAST_SINGULAR:
    'streamer_desktop_live_product_action_edit_success_toast_singular',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_PIN:
    'streamer_desktop_live_product_action_pin',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_PIN_SUCCESS_TOAST:
    'streamer_desktop_live_product_action_pin_success_toast',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_SORT_DRAG_TIP:
    'streamer_desktop_live_product_action_sort_drag_tip',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_SORT_SUCCESS_TOAST_PLURAL:
    'streamer_desktop_live_product_action_sort_success_toast_plural',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_TOP_SUCCESS_TOAST_PLURAL:
    'streamer_desktop_live_product_action_top_success_toast_plural',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_TOP_SUCCESS_TOAST_SINGULAR:
    'streamer_desktop_live_product_action_top_success_toast_singular',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_UNPIN:
    'streamer_desktop_live_product_action_unpin',
  STREAMER_DESKTOP_LIVE_PRODUCT_ACTION_UNPIN_SUCCESS_TOAST:
    'streamer_desktop_live_product_action_unpin_success_toast',
  STREAMER_DESKTOP_LIVE_PRODUCT_COMMISSION:
    'streamer_desktop_live_product_commission',
  STREAMER_DESKTOP_LIVE_PRODUCT_DELETE_DIALOG_TEXT_PLURAL:
    'streamer_desktop_live_product_delete_dialog_text_plural',
  STREAMER_DESKTOP_LIVE_PRODUCT_DELETE_DIALOG_TEXT_SINGULAR:
    'streamer_desktop_live_product_delete_dialog_text_singular',
  STREAMER_DESKTOP_LIVE_PRODUCT_EDIT_POPUP_PRODUCT_IMAGE:
    'streamer_desktop_live_product_edit_popup_product_image',
  STREAMER_DESKTOP_LIVE_PRODUCT_EDIT_POPUP_TITLE:
    'streamer_desktop_live_product_edit_popup_title',
  STREAMER_DESKTOP_LIVE_PRODUCT_ESTIMATED_COMMISSION:
    'streamer_desktop_live_product_estimated_commission',
  STREAMER_DESKTOP_LIVE_PRODUCT_FLASH_SALE_END_IN_DAY:
    'streamer_desktop_live_product_flash_sale_end_in_day',
  STREAMER_DESKTOP_LIVE_PRODUCT_FLASH_SALE_END_IN_DAYS:
    'streamer_desktop_live_product_flash_sale_end_in_days',
  STREAMER_DESKTOP_LIVE_PRODUCT_FLASH_SALE_END_IN_TIME:
    'streamer_desktop_live_product_flash_sale_end_in_time',
  STREAMER_DESKTOP_LIVE_PRODUCT_FLASH_SALE_START_DATE:
    'streamer_desktop_live_product_flash_sale_start_date',
  STREAMER_DESKTOP_LIVE_PRODUCT_FLASH_SALE_START_TIME:
    'streamer_desktop_live_product_flash_sale_start_time',
  STREAMER_DESKTOP_LIVE_PRODUCT_IMAGE_SET_COVER:
    'streamer_desktop_live_product_image_set_cover',
  STREAMER_DESKTOP_LIVE_PRODUCT_IMAGE_STATUS_COVER:
    'streamer_desktop_live_product_image_status_cover',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_EMPTY_TIP:
    'streamer_desktop_live_product_list_empty_tip',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_NEW_SELECT_TIP:
    'streamer_desktop_live_product_list_new_select_tip',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_PRODUCT_STATUS_LIVE:
    'streamer_desktop_live_product_list_product_status_live',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_PRODUCT_STATUS_NOT_APPROVED:
    'streamer_desktop_live_product_list_product_status_not_approved',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_PRODUCT_STATUS_NOT_APPROVED_TOOLTIP:
    'streamer_desktop_live_product_list_product_status_not_approved_tooltip',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_PRODUCT_STATUS_UNAVAILABLE:
    'streamer_desktop_live_product_list_product_status_unavailable',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_PRODUCT_STATUS_UNAVAILABLE_TOOLTIP:
    'streamer_desktop_live_product_list_product_status_unavailable_tooltip',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_PRODUCT_STATUS_UNDER_REVIEW:
    'streamer_desktop_live_product_list_product_status_under_review',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_PRODUCT_STATUS_UNDER_REVIEW_TOOLTIP:
    'streamer_desktop_live_product_list_product_status_under_review_tooltip',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_SELECTED_NUM:
    'streamer_desktop_live_product_list_selected_num',
  STREAMER_DESKTOP_LIVE_PRODUCT_LIST_SELECTED_NUM_LEFT:
    'streamer_desktop_live_product_list_selected_num_left',
  STREAMER_DESKTOP_LIVE_PRODUCT_PRICE: 'streamer_desktop_live_product_price',
  STREAMER_DESKTOP_LIVE_PRODUCT_STATUS: 'streamer_desktop_live_product_status',
  STREAMER_DESKTOP_LIVE_PRODUCT_STOCK: 'streamer_desktop_live_product_stock',
  STREAMER_DESKTOP_LIVE_PRODUCT_STOCK_STATUS_IN_STOCK:
    'streamer_desktop_live_product_stock_status_in_stock',
  STREAMER_DESKTOP_LIVE_PRODUCT_STOCK_STATUS_SOLD_OUT:
    'streamer_desktop_live_product_stock_status_sold_out',
  STREAMER_DESKTOP_LIVE_SOME_PRODUCT_ADD_FAIL_NOT_LIVE:
    'streamer_desktop_live_some_product_add_fail_not_live',
  STREAMER_DESKTOP_LIVE_START_TOAST: 'streamer_desktop_live_start_toast',
  STREAMER_DESKTOP_LIVE_STATUS: 'streamer_desktop_live_status',
  STREAMER_DESKTOP_LOG_IN_CONTRACT: 'streamer_desktop_log_in_contract',
  STREAMER_DESKTOP_LOG_IN_METHOD_TT_BTN:
    'streamer_desktop_log_in_method_tt_btn',
  STREAMER_DESKTOP_LOG_IN_TEXT: 'streamer_desktop_log_in_text',
  STREAMER_DESKTOP_LOG_IN_TITLE: 'streamer_desktop_log_in_title',
  STREAMER_DESKTOP_NEW_FEATURE_SIDE_TAB_TAG:
    'streamer_desktop_new_feature_side_tab_tag',
  STREAMER_DESKTOP_NO_ACCESS_BTN_SWITCH:
    'streamer_desktop_no_access_btn_switch',
  STREAMER_DESKTOP_NO_ACCESS_TEXT_MERCHANT:
    'streamer_desktop_no_access_text_merchant',
  STREAMER_DESKTOP_NO_EC_PERMISSION_TEXT:
    'streamer_desktop_no_ec_permission_text',
  STREAMER_DESKTOP_NO_EC_PERMISSION_TITLE:
    'streamer_desktop_no_ec_permission_title',
  STREAMER_DESKTOP_NO_LIVESTREAM_PERMISSION_TEXT:
    'streamer_desktop_no_livestream_permission_text',
  STREAMER_DESKTOP_NO_LIVESTREAM_PERMISSION_TITLE:
    'streamer_desktop_no_livestream_permission_title',
  STREAMER_DESKTOP_PRODUCTS_MAX_HINT: 'streamer_desktop_products_max_hint',
  STREAMER_DESKTOP_PRODUCT_LIST_STATUS_TOOLTIP:
    'streamer_desktop_product_list_status_tooltip',
  STREAMER_DESKTOP_PRODUCT_PLURAL: 'streamer_desktop_product_plural',
  STREAMER_DESKTOP_PRODUCT_PLURAL_LIVESTREAM:
    'streamer_desktop_product_plural_livestream',
  STREAMER_DESKTOP_PRODUCT_REMOVE_SOME_FAIL_TOAST:
    'streamer_desktop_product_remove_some_fail_toast',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_FAILED_TOAST_EXCEED:
    'streamer_desktop_product_set_create_page_add_products_failed_toast_exceed',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_FAILED_TOAST_EXCEED_SERVER:
    'streamer_desktop_product_set_create_page_add_products_failed_toast_exceed_server',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_HINT_AVAILABLE_SPACE:
    'streamer_desktop_product_set_create_page_add_products_hint_available_space',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_HINT_SELECTED_PRODUCTS_BOTTOM:
    'streamer_desktop_product_set_create_page_add_products_hint_selected_products_bottom',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_HINT_SELECTED_PRODUCTS_BOTTOM_SINGULAR:
    'streamer_desktop_product_set_create_page_add_products_hint_selected_products_bottom_singular',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_HINT_SELECTED_PRODUCTS_TOP:
    'streamer_desktop_product_set_create_page_add_products_hint_selected_products_top',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_HINT_SELECTED_PRODUCTS_TOP_SINGULAR:
    'streamer_desktop_product_set_create_page_add_products_hint_selected_products_top_singular',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_MODAL_BTN_REMOVE:
    'streamer_desktop_product_set_create_page_add_products_modal_btn_remove',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_MODAL_TEXT:
    'streamer_desktop_product_set_create_page_add_products_modal_text',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_ADD_PRODUCTS_TOAST_REMOVE_SUCCESFFULY:
    'streamer_desktop_product_set_create_page_add_products_toast_remove_succesffuly',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_BASIC_INFO_DES_PLACEHOLDER:
    'streamer_desktop_product_set_create_page_basic_info_des_placeholder',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_BASIC_INFO_DES_TITLE:
    'streamer_desktop_product_set_create_page_basic_info_des_title',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_BASIC_INFO_NAME_PLACEHOLDER:
    'streamer_desktop_product_set_create_page_basic_info_name_placeholder',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_BASIC_INFO_NAME_TIPS:
    'streamer_desktop_product_set_create_page_basic_info_name_tips',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_BASIC_INFO_NAME_TITLE:
    'streamer_desktop_product_set_create_page_basic_info_name_title',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_BASIC_INFO_TITLE:
    'streamer_desktop_product_set_create_page_basic_info_title',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_DISCARD_MODAL_DISCRAD_BTN:
    'streamer_desktop_product_set_create_page_discard_modal_discrad_btn',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_DISCARD_MODAL_TEXT:
    'streamer_desktop_product_set_create_page_discard_modal_text',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_PRODUCT_ADDED_PRODUCTS:
    'streamer_desktop_product_set_create_page_product_added_products',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_PRODUCT_ADD_BTN:
    'streamer_desktop_product_set_create_page_product_add_btn',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_PRODUCT_SELECTED_PRODUCTS:
    'streamer_desktop_product_set_create_page_product_selected_products',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_PRODUCT_SUB_TITLE:
    'streamer_desktop_product_set_create_page_product_sub_title',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_PRODUCT_TITLE:
    'streamer_desktop_product_set_create_page_product_title',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_SUBMIT_ERROR_MSG_NAME_EMOJI:
    'streamer_desktop_product_set_create_page_submit_error_msg_name_emoji',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_SUBMIT_ERROR_MSG_NAME_EMPTY:
    'streamer_desktop_product_set_create_page_submit_error_msg_name_empty',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_SUBMIT_ERROR_MSG_NAME_EXCEED:
    'streamer_desktop_product_set_create_page_submit_error_msg_name_exceed',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_PAGE_TITLE:
    'streamer_desktop_product_set_create_page_title',
  STREAMER_DESKTOP_PRODUCT_SET_CREATE_SUCCESS_TOAST:
    'streamer_desktop_product_set_create_success_toast',
  STREAMER_DESKTOP_PRODUCT_SET_DETAIL_LIST_TITLE:
    'streamer_desktop_product_set_detail_list_title',
  STREAMER_DESKTOP_PRODUCT_SET_EDIT_PAGE_TITLE:
    'streamer_desktop_product_set_edit_page_title',
  STREAMER_DESKTOP_PRODUCT_SET_EDIT_SUCCESS_TOAST:
    'streamer_desktop_product_set_edit_success_toast',
  STREAMER_DESKTOP_PRODUCT_SET_EMPTY_PAGE_SUB_TITLE_1:
    'streamer_desktop_product_set_empty_page_sub_title_1',
  STREAMER_DESKTOP_PRODUCT_SET_EMPTY_PAGE_SUB_TITLE_2:
    'streamer_desktop_product_set_empty_page_sub_title_2',
  STREAMER_DESKTOP_PRODUCT_SET_EMPTY_PAGE_TEXT_1:
    'streamer_desktop_product_set_empty_page_text_1',
  STREAMER_DESKTOP_PRODUCT_SET_EMPTY_PAGE_TEXT_2:
    'streamer_desktop_product_set_empty_page_text_2',
  STREAMER_DESKTOP_PRODUCT_SET_EMPTY_PAGE_TITLE:
    'streamer_desktop_product_set_empty_page_title',
  STREAMER_DESKTOP_PRODUCT_SET_FEATURE_NAME_SENTENCE:
    'streamer_desktop_product_set_feature_name_sentence',
  STREAMER_DESKTOP_PRODUCT_SET_FINISH_CREATE_PAGE_1ST_TIME_BTN_BACK_TO_LIST:
    'streamer_desktop_product_set_finish_create_page_1st_time_btn_back_to_list',
  STREAMER_DESKTOP_PRODUCT_SET_FINISH_CREATE_PAGE_1ST_TIME_BTN_TRY_NOW:
    'streamer_desktop_product_set_finish_create_page_1st_time_btn_try_now',
  STREAMER_DESKTOP_PRODUCT_SET_FINISH_CREATE_PAGE_1ST_TIME_TEXT:
    'streamer_desktop_product_set_finish_create_page_1st_time_text',
  STREAMER_DESKTOP_PRODUCT_SET_FINISH_CREATE_PAGE_1ST_TIME_TITLE:
    'streamer_desktop_product_set_finish_create_page_1st_time_title',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_BUTTON_HOVER_COPY:
    'streamer_desktop_product_set_list_button_hover_copy',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_BUTTON_HOVER_DELETE:
    'streamer_desktop_product_set_list_button_hover_delete',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_BUTTON_HOVER_EDIT:
    'streamer_desktop_product_set_list_button_hover_edit',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_BUTTON_HOVER_VIEW:
    'streamer_desktop_product_set_list_button_hover_view',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_COPY_FAILED_TOAST:
    'streamer_desktop_product_set_list_copy_failed_toast',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_COPY_SUCCESS_TOAST:
    'streamer_desktop_product_set_list_copy_success_toast',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_DELETE_MODAL_TEXT:
    'streamer_desktop_product_set_list_delete_modal_text',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_DELETE_SUCCESS_TOAST:
    'streamer_desktop_product_set_list_delete_success_toast',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_HINT_MORE_PRODUCTS:
    'streamer_desktop_product_set_list_hint_more_products',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_PRODUCT_NUMBER_PLURAL:
    'streamer_desktop_product_set_list_product_number_plural',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_PRODUCT_NUMBER_SIGULAR:
    'streamer_desktop_product_set_list_product_number_sigular',
  STREAMER_DESKTOP_PRODUCT_SET_LIST_SEARCH_PLACEHOLDER:
    'streamer_desktop_product_set_list_search_placeholder',
  STREAMER_DESKTOP_PRODUCT_SET_NEW_FEATURE_POPUP_BTN:
    'streamer_desktop_product_set_new_feature_popup_btn',
  STREAMER_DESKTOP_PRODUCT_SET_NEW_FEATURE_POPUP_TEXT:
    'streamer_desktop_product_set_new_feature_popup_text',
  STREAMER_DESKTOP_PRODUCT_SET_NEW_FEATURE_POPUP_TITLE:
    'streamer_desktop_product_set_new_feature_popup_title',
  STREAMER_DESKTOP_PRODUCT_SET_PRODUCT_SET_LIST_BUTTON_CREATE_SET:
    'streamer_desktop_product_set_product_set_list_button_create_set',
  STREAMER_DESKTOP_PRODUCT_SET_PRODUCT_SET_PRODUCT_SET_PRODUCT_SET_PRODUCT_SET_FEATURE_NAME_TITLE:
    'streamer_desktop_product_set_product_set_product_set_product_set_product_set_feature_name_title',
  STREAMER_DESKTOP_PRODUCT_SINGLE: 'streamer_desktop_product_single',
  STREAMER_DESKTOP_PRODUCT_SINGLE_LIVESTREAM:
    'streamer_desktop_product_single_livestream',
  STREAMER_DESKTOP_PRODUCT_SOURCE_MY_SHOP:
    'streamer_desktop_product_source_my_shop',
  STREAMER_DESKTOP_PRODUCT_STATUS_ADDED_TO_SHOWCASE_TOOLTIP:
    'streamer_desktop_product_status_added_to_showcase_tooltip',
  STREAMER_DESKTOP_PRODUCT_TOP_SOME_FAIL_TOAST:
    'streamer_desktop_product_top_some_fail_toast',
  STREAMER_DESKTOP_SEARCH: 'streamer_desktop_search',
  STREAMER_DESKTOP_SEARCH_CLEAR_SELECT_HINT:
    'streamer_desktop_search_clear_select_hint',
  STREAMER_DESKTOP_SEARCH_NO_RESULT: 'streamer_desktop_search_no_result',
  STREAMER_DESKTOP_SHOWCASE_ADD_PRODUCT_SOURCE_URL_FAIL_DIALOG_TITLE:
    'streamer_desktop_showcase_add_product_source_url_fail_dialog_title',
  STREAMER_DESKTOP_SHOWCASE_ADD_PRODUCT_SUCCESS_TOAST_PLURAL:
    'streamer_desktop_showcase_add_product_success_toast_plural',
  STREAMER_DESKTOP_SHOWCASE_ADD_PRODUCT_SUCCESS_TOAST_SINGULAR:
    'streamer_desktop_showcase_add_product_success_toast_singular',
  STREAMER_DESKTOP_SHOWCASE_ADD_PRODUCT_UNAVAILABLE_PRODUCT_TIP:
    'streamer_desktop_showcase_add_product_unavailable_product_tip',
  STREAMER_DESKTOP_SHOWCASE_EMPTY: 'streamer_desktop_showcase_empty',
  STREAMER_DESKTOP_SHOWCASE_PRODUCTS_LIST_PLACEHOLDER:
    'streamer_desktop_showcase_products_list_placeholder',
  STREAMER_DESKTOP_SHOWCASE_PRODUCT_ACTION_HIDE:
    'streamer_desktop_showcase_product_action_hide',
  STREAMER_DESKTOP_SHOWCASE_PRODUCT_ACTION_UNHIDE:
    'streamer_desktop_showcase_product_action_unhide',
  STREAMER_DESKTOP_SHOWCASE_PRODUCT_HIDE_SOME_FAIL_TOAST:
    'streamer_desktop_showcase_product_hide_some_fail_toast',
  STREAMER_DESKTOP_SHOWCASE_PRODUCT_HIDE_SUCCESS_TOAST_PLURAL:
    'streamer_desktop_showcase_product_hide_success_toast_plural',
  STREAMER_DESKTOP_SHOWCASE_PRODUCT_HIDE_SUCCESS_TOAST_SINGULAR:
    'streamer_desktop_showcase_product_hide_success_toast_singular',
  STREAMER_DESKTOP_SHOWCASE_PRODUCT_NUM:
    'streamer_desktop_showcase_product_num',
  STREAMER_DESKTOP_SHOWCASE_PRODUCT_UNHIDE_SOME_FAIL_TOAST:
    'streamer_desktop_showcase_product_unhide_some_fail_toast',
  STREAMER_DESKTOP_SHOWCASE_PRODUCT_UNHIDE_SUCCESS_TOAST_PLURAL:
    'streamer_desktop_showcase_product_unhide_success_toast_plural',
  STREAMER_DESKTOP_SHOWCASE_PRODUCT_UNHIDE_SUCCESS_TOAST_SINGULAR:
    'streamer_desktop_showcase_product_unhide_success_toast_singular',
  STREAMER_DESKTOP_SIDEBAR_LIVE: 'streamer_desktop_sidebar_live',
  STREAMER_DESKTOP_SIDEBAR_LIVE_PRODUCTS:
    'streamer_desktop_sidebar_live_products',
  TARGETED_PLAN_COPY_SUCCESS_TOAST: 'targeted_plan_copy_success_toast',
  TARGETED_PLAN_CREATE_RESULT_PAGE_EXCLUSIVE_STOCK_LEARN_MORE:
    'targeted_plan_create_result_page_exclusive_stock_learn_more',
  TARGETED_PLAN_CREATE_RESULT_PAGE_EXCLUSIVE_STOCK_TEXT:
    'targeted_plan_create_result_page_exclusive_stock_text',
  TARGETED_PLAN_CREATE_RESULT_PAGE_EXCLUSIVE_STOCK_TITLE:
    'targeted_plan_create_result_page_exclusive_stock_title',
  TARGETED_PLAN_CREATE_RESULT_PAGE_RECOMMEND:
    'targeted_plan_create_result_page_recommend',
  TARGETED_PLAN_CREATE_SUCCESS_PAGE_BTN_ADD:
    'targeted_plan_create_success_page_btn_add',
  TARGETED_PLAN_CREATE_SUCCESS_PAGE_BTN_BACK:
    'targeted_plan_create_success_page_btn_back',
  TARGETED_PLAN_CREATE_SUCCESS_PAGE_BTN_BACK_FIND_CREATOR:
    'targeted_plan_create_success_page_btn_back_find_creator',
  TARGETED_PLAN_CREATE_SUCCESS_PAGE_BTN_SHARE:
    'targeted_plan_create_success_page_btn_share',
  TARGETED_PLAN_CREATE_SUCCESS_PAGE_TEXT1:
    'targeted_plan_create_success_page_text1',
  TARGETED_PLAN_CREATE_SUCCESS_PAGE_TEXT2:
    'targeted_plan_create_success_page_text2',
  TARGETED_PLAN_EDIT_ADD_PRODUCTS: 'targeted_plan_edit_add_products',
  TARGETED_PLAN_EDIT_COMMISSION_TIPS_SET_RATE_FIRST_POINT:
    'targeted_plan_edit_commission_tips_set_rate_first_point',
  TARGETED_PLAN_EDIT_COMMISSION_TIPS_SET_RATE_SECOND_POINT:
    'targeted_plan_edit_commission_tips_set_rate_second_point',
  TARGETED_PLAN_EDIT_COMMISSION_TIPS_SET_RATE_SUGGESTION:
    'targeted_plan_edit_commission_tips_set_rate_suggestion',
  TARGETED_PLAN_EDIT_COMMISSION_TIPS_SET_RATE_TITLE:
    'targeted_plan_edit_commission_tips_set_rate_title',
  TARGETED_PLAN_EDIT_COMMISSION_TIPS_TITLE:
    'targeted_plan_edit_commission_tips_title',
  TARGETED_PLAN_EDIT_INVITE_CREATOR_TIPS_CREATOR_MARKETPLACE:
    'targeted_plan_edit_invite_creator_tips_creator_marketplace',
  TARGETED_PLAN_EDIT_INVITE_CREATOR_TIPS_FIND_CREA:
    'targeted_plan_edit_invite_creator_tips_find_crea',
  TARGETED_PLAN_EDIT_INVITE_CREATOR_TIPS_FIND_CREATOR:
    'targeted_plan_edit_invite_creator_tips_find_creator',
  TARGETED_PLAN_EDIT_INVITE_CREATOR_TIPS_FIND_TITLE:
    'targeted_plan_edit_invite_creator_tips_find_title',
  TARGETED_PLAN_EDIT_INVITE_CREATOR_TIPS_SEARCH_ISSUE_TEXT:
    'targeted_plan_edit_invite_creator_tips_search_issue_text',
  TARGETED_PLAN_EDIT_INVITE_CREATOR_TIPS_SEARCH_ISSUE_TITLE:
    'targeted_plan_edit_invite_creator_tips_search_issue_title',
  TARGETED_PLAN_EDIT_INVITE_CREATOR_TIPS_TITLE:
    'targeted_plan_edit_invite_creator_tips_title',
  TARGETED_PLAN_SEARCH_CREATOR_NO_RESULT_TOAST1:
    'targeted_plan_search_creator_no_result_toast1',
  TARGETED_PLAN_SEARCH_CREATOR_NO_RESULT_TOAST2:
    'targeted_plan_search_creator_no_result_toast2',
  TARGETED_PLAN_SEARCH_CREATOR_NO_RESULT_TOAST:
    'targeted_plan_search_creator_no_result_toast',
  TARGETED_PLAN_SEARCH_CREATOR_TIPS: 'targeted_plan_search_creator_tips',
  TARGETED_PLAN_SEARCH_CREATOR_TITLE: 'targeted_plan_search_creator_title',
  TARGETED_PLAN_SELECT_PRODUCT_TIPS_HOW_TO_USE:
    'targeted_plan_select_product_tips_how_to_use',
  TARGETED_PLAN_SELECT_PRODUCT_TIPS_HOW_TO_USE_TEXT:
    'targeted_plan_select_product_tips_how_to_use_text',
  TARGETED_PLAN_SELECT_PRODUCT_TIPS_PRODUCT_LIMIT_TEXT:
    'targeted_plan_select_product_tips_product_limit_text',
  TARGETED_PLAN_SELECT_PRODUCT_TIPS_PRODUCT_LIMIT_TITLE:
    'targeted_plan_select_product_tips_product_limit_title',
  TARGETED_PLAN_SELECT_PRODUCT_TIPS_READ_DOC:
    'targeted_plan_select_product_tips_read_doc',
  TARGETED_PLAN_SELECT_PRODUCT_TIPS_SELECT_ISSUE_TEXT:
    'targeted_plan_select_product_tips_select_issue_text',
  TARGETED_PLAN_SELECT_PRODUCT_TIPS_SELECT_ISSUE_TITLE:
    'targeted_plan_select_product_tips_select_issue_title',
  TARGETED_PLAN_SELECT_PRODUCT_TIPS_TOPIC:
    'targeted_plan_select_product_tips_topic',
  TARGETED_PLAN_SHARE_BTN: 'targeted_plan_share_btn',
  TARGETED_PLAN_SHARE_COPY_LINK: 'targeted_plan_share_copy_link',
  TARGETED_PLAN_SHARE_WHATSAPP_BTN: 'targeted_plan_share_whatsapp_btn',
  TARGET_PLAN_BANNER_BTN_HOW_TO_USE: 'target_plan_banner_btn_how_to_use',
  TARGET_PLAN_BANNER_EXCLUSIVE_STOCK_LEARN_MORE:
    'target_plan_banner_exclusive_stock_learn_more',
  TARGET_PLAN_BANNER_EXCLUSIVE_STOCK_TEXT:
    'target_plan_banner_exclusive_stock_text',
  TARGET_PLAN_BANNER_EXCLUSIVE_STOCK_TITLE:
    'target_plan_banner_exclusive_stock_title',
  TARGET_PLAN_BANNER_TITLE: 'target_plan_banner_title',
  TARGET_PLAN_CONFLICT_POPUP_EXPLAIN_TEXT:
    'target_plan_conflict_popup_explain_text',
  TARGET_PLAN_CONFLICT_POPUP_EXPLAIN_TITLE:
    'target_plan_conflict_popup_explain_title',
  TARGET_PLAN_CONFLICT_POPUP_TABLE_HEADER_ACTION:
    'target_plan_conflict_popup_table_header_action',
  TARGET_PLAN_CONFLICT_POPUP_TABLE_HEADER_ACTION_VIEW_DETAILS:
    'target_plan_conflict_popup_table_header_action_view_details',
  TARGET_PLAN_CONFLICT_POPUP_TABLE_HEADER_CREATOR:
    'target_plan_conflict_popup_table_header_creator',
  TARGET_PLAN_CONFLICT_POPUP_TABLE_HEADER_PLANID:
    'target_plan_conflict_popup_table_header_planid',
  TARGET_PLAN_CONFLICT_POPUP_TABLE_HEADER_PRODUCT:
    'target_plan_conflict_popup_table_header_product',
  TARGET_PLAN_CONFLICT_POPUP_TABLE_HEADER_SHOWCASE_ADD_TIME:
    'target_plan_conflict_popup_table_header_showcase_add_time',
  TARGET_PLAN_CONFLICT_POPUP_TABLE_HEADER_SHOWCASE_STATUS:
    'target_plan_conflict_popup_table_header_showcase_status',
  TARGET_PLAN_CONFLICT_POPUP_TITLE: 'target_plan_conflict_popup_title',
  TARGET_PLAN_EDIT_DELETE_CREATOR_DELETING:
    'target_plan_edit_delete_creator_deleting',
  TARGET_PLAN_EDIT_DELETE_CREATOR_DELETING_HOVER:
    'target_plan_edit_delete_creator_deleting_hover',
  TARGET_PLAN_EDIT_DELETE_PRODUCT_ACTION_DELETING:
    'target_plan_edit_delete_product_action_deleting',
  TARGET_PLAN_EDIT_DELETE_PRODUCT_ACTION_DELETING_HOVER:
    'target_plan_edit_delete_product_action_deleting_hover',
  TARGET_PLAN_EDIT_PLAN_NAME_NULL_TIP: 'target_plan_edit_plan_name_null_tip',
  TARGET_PLAN_LIST_CREATOR: 'target_plan_list_creator',
  TARGET_PLAN_LIST_CREATOR_AVATARS_HOVER:
    'target_plan_list_creator_avatars_hover',
  TARGET_PLAN_LIST_CREATOR_AVATARS_PENDING_LABEL:
    'target_plan_list_creator_avatars_pending_label',
  TARGET_PLAN_LIST_CREATOR_TOOLTIP: 'target_plan_list_creator_tooltip',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BTN_RESET:
    'target_plan_list_filter_search_plan_btn_reset',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BTN_SEARCH:
    'target_plan_list_filter_search_plan_btn_search',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BY_CREATOR:
    'target_plan_list_filter_search_plan_by_creator',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BY_CREATOR_ERROR:
    'target_plan_list_filter_search_plan_by_creator_error',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BY_CREATOR_NO_RESULT:
    'target_plan_list_filter_search_plan_by_creator_no_result',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BY_CREATOR_TRY_AGAIN:
    'target_plan_list_filter_search_plan_by_creator_try_again',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BY_PLAN_ID:
    'target_plan_list_filter_search_plan_by_plan_id',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BY_PLAN_NAME:
    'target_plan_list_filter_search_plan_by_plan_name',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BY_PRODUCT_NAME:
    'target_plan_list_filter_search_plan_by_product_name',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BY_PRODUCT_NAME_TIPS:
    'target_plan_list_filter_search_plan_by_product_name_tips',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_BY_PRODUCUT_ID:
    'target_plan_list_filter_search_plan_by_producut_id',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_PLACEHOLDER_SEARCH_PLAN:
    'target_plan_list_filter_search_plan_placeholder_search_plan',
  TARGET_PLAN_LIST_FILTER_SEARCH_PLAN_SELECTED:
    'target_plan_list_filter_search_plan_selected',
  TARGET_PLAN_LIST_NONE_TIPS_BTN: 'target_plan_list_none_tips_btn',
  TARGET_PLAN_LIST_NONE_TIPS_SUBTITLE: 'target_plan_list_none_tips_subtitle',
  TARGET_PLAN_LIST_NONE_TIPS_TITLE: 'target_plan_list_none_tips_title',
  TARGET_PLAN_LIST_PLAN_STATUS: 'target_plan_list_plan_status',
  TARGET_PLAN_LIST_PLAN_STATUS_DELETED: 'target_plan_list_plan_status_deleted',
  TARGET_PLAN_LIST_PLAN_STATUS_DELETED_HOVER:
    'target_plan_list_plan_status_deleted_hover',
  TARGET_PLAN_LIST_PLAN_STATUS_DELETING:
    'target_plan_list_plan_status_deleting',
  TARGET_PLAN_LIST_PLAN_STATUS_DELETING_HOVER:
    'target_plan_list_plan_status_deleting_hover',
  TARGET_PLAN_LIST_PLAN_STATUS_FILTER_OK_BTN:
    'target_plan_list_plan_status_filter_ok_btn',
  TARGET_PLAN_LIST_PLAN_STATUS_FILTER_RESET_BTN:
    'target_plan_list_plan_status_filter_reset_btn',
  TARGET_PLAN_LIST_PLAN_STATUS_RUNNING: 'target_plan_list_plan_status_running',
  TARGET_PLAN_LIST_PLAN_STATUS_UNAVAILABLE:
    'target_plan_list_plan_status_unavailable',
  TARGET_PLAN_LIST_PRODUCT: 'target_plan_list_product',
  TARGET_PLAN_LIST_PRODUCT_ABNORMAL_HOVER:
    'target_plan_list_product_abnormal_hover',
  TARGET_PLAN_LIST_PRODUCT_ABNORMAL_SOLD_OUT_HOVER:
    'target_plan_list_product_abnormal_sold_out_hover',
  TARGET_PLAN_LIST_PRODUCT_SOLD_OUT_HOVER:
    'target_plan_list_product_sold_out_hover',
  TARGET_PLAN_MANAGE_CREATOR_ACTION_DELETE:
    'target_plan_manage_creator_action_delete',
  TARGET_PLAN_MANAGE_CREATOR_DELETING: 'target_plan_manage_creator_deleting',
  TARGET_PLAN_MANAGE_CREATOR_DELETING_: 'target_plan_manage_creator_deleting_',
  TARGET_PLAN_MANAGE_CREATOR_DELETING_HOVER:
    'target_plan_manage_creator_deleting_hover',
  TARGET_PLAN_MANAGE_CREATOR_LIST_PRODUCT_STATUS:
    'target_plan_manage_creator_list_product_status',
  TARGET_PLAN_MANAGE_CREATOR_LIST_PRODUCT_STATUS_LIVE:
    'target_plan_manage_creator_list_product_status_live',
  TARGET_PLAN_MANAGE_CREATOR_LIST_PRODUCT_STATUS_SOLD_OUT:
    'target_plan_manage_creator_list_product_status_sold_out',
  TARGET_PLAN_MANAGE_CREATOR_LIST_PRODUCT_STATUS_UNAVAILABLE:
    'target_plan_manage_creator_list_product_status_unavailable',
  TARGET_PLAN_MANAGE_CREATOR_LIST_PRODUCT_STATUS_WITHDRAWN:
    'target_plan_manage_creator_list_product_status_withdrawn',
  TARGET_PLAN_MANAGE_CREATOR_RESET_BTN: 'target_plan_manage_creator_reset_btn',
  TARGET_PLAN_MANAGE_CREATOR_SERACH_BTN:
    'target_plan_manage_creator_serach_btn',
  TARGET_PLAN_MANAGE_CREATOR_SERACH_BY_CREATOR:
    'target_plan_manage_creator_serach_by_creator',
  TARGET_PLAN_MANAGE_CREATOR_SERACH_BY_PLAN_ID:
    'target_plan_manage_creator_serach_by_plan_id',
  TARGET_PLAN_MANAGE_CREATOR_SERACH_BY_PRODUCT_ID:
    'target_plan_manage_creator_serach_by_product_id',
  TARGET_PLAN_MANAGE_CREATOR_SERACH_BY_PRODUCT_NAME:
    'target_plan_manage_creator_serach_by_product_name',
  TARGET_PLAN_PRODUCT_DETAIL_FILTER_ERROR:
    'target_plan_product_detail_filter_error',
  TARGET_PLAN_PRODUCT_DETAIL_FILTER_NETWORK_ERROR:
    'target_plan_product_detail_filter_network_error',
  TARGET_PLAN_PRODUCT_DETAIL_FILTER_NONE:
    'target_plan_product_detail_filter_none',
  TARGET_PLAN_PRODUCT_DETAIL_FILTER_SEARCH_BY_PRODCUT_NAME:
    'target_plan_product_detail_filter_search_by_prodcut_name',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_COMMISSION:
    'target_plan_product_detail_list_commission',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_COMMISSION_HOVER:
    'target_plan_product_detail_list_commission_hover',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_GMV: 'target_plan_product_detail_list_gmv',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_PRICE:
    'target_plan_product_detail_list_price',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_PRODUCT:
    'target_plan_product_detail_list_product',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_PRODUCT_STATUS_DELETING_HOVER:
    'target_plan_product_detail_list_product_status_deleting_hover',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_PRODUCT_STATUS_UNAVAILABLE_HOVER:
    'target_plan_product_detail_list_product_status_unavailable_hover',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_PRODUCT_STATUS_UNAVAILABLE_MANAGE_PRODUCTS_TEXT:
    'target_plan_product_detail_list_product_status_unavailable_manage_products_text',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_SALES:
    'target_plan_product_detail_list_sales',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_STATUS:
    'target_plan_product_detail_list_status',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_STATUS_DELETING:
    'target_plan_product_detail_list_status_deleting',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_STATUS_LIVE:
    'target_plan_product_detail_list_status_live',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_STATUS_SOLD_OUT:
    'target_plan_product_detail_list_status_sold_out',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_STATUS_UNAVAILABLE:
    'target_plan_product_detail_list_status_unavailable',
  TARGET_PLAN_PRODUCT_DETAIL_LIST_STATUS_WITHDRAWN:
    'target_plan_product_detail_list_status_withdrawn',
  TARGET_PLAN_PRODUCT_DETAIL_TITLE: 'target_plan_product_detail_title',
  TARGET_PLAN_PRODUCT_NUM_REACH_TO_MAX_TITLE:
    'target_plan_product_num_reach_to_max_title',
  TARGET_PLAN_PRODUCT_NUM_REACH_TO_MAX_TOAST:
    'target_plan_product_num_reach_to_max_toast',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_BRANDING_ABILITY:
    'tts_creator_video_analysis_video_analysis_branding_ability',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_BRANDING_ABILITY_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_branding_ability_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_BUTTON_EXPORT:
    'tts_creator_video_analysis_video_analysis_button_export',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_CALENDAR_DAY:
    'tts_creator_video_analysis_video_analysis_calendar_day',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_DRAINAGE_ABILITY:
    'tts_creator_video_analysis_video_analysis_drainage_ability',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_DRAINAGE_ABILITY_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_drainage_ability_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_INPUT_PLACEHOLDER:
    'tts_creator_video_analysis_video_analysis_input_placeholder',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_ITEM_DRAINAGE_PV_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_item_drainage_pv_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_ITEM_DRAINAGE_SUCCESS_RATE_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_item_drainage_success_rate_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_ITEM_DURATION:
    'tts_creator_video_analysis_video_analysis_item_duration',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_ITEM_NEW_FOLLOWERS_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_item_new_followers_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_ITEM_RELEASE_TIME:
    'tts_creator_video_analysis_video_analysis_item_release_time',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_ITEM_REVENUE_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_item_revenue_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_ITEM_RPM_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_item_rpm_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_ITEM_VIEW_DETAILS:
    'tts_creator_video_analysis_video_analysis_item_view_details',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_ITEM_VV_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_item_vv_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_LAST_28D:
    'tts_creator_video_analysis_video_analysis_last_28d',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_LAST_7D:
    'tts_creator_video_analysis_video_analysis_last_7d',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_DRAINAGE_PV:
    'tts_creator_video_analysis_video_analysis_metric_drainage_pv',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_DRAINAGE_PV_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_metric_drainage_pv_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_DRAINAGE_SUCCESS_RATE:
    'tts_creator_video_analysis_video_analysis_metric_drainage_success_rate',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_DRAINAGE_SUCCESS_RATE_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_metric_drainage_success_rate_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_NEW_FOLLOWERS:
    'tts_creator_video_analysis_video_analysis_metric_new_followers',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_NEW_FOLLOWERS_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_metric_new_followers_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_REVENUE:
    'tts_creator_video_analysis_video_analysis_metric_revenue',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_REVENUE_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_metric_revenue_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_RPM:
    'tts_creator_video_analysis_video_analysis_metric_rpm',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_RPM_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_metric_rpm_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_VV:
    'tts_creator_video_analysis_video_analysis_metric_vv',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_METRIC_VV_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_metric_vv_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_MOUDLE_TITLE_CORE_DATA:
    'tts_creator_video_analysis_video_analysis_moudle_title_core_data',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_PAGE_TITLE:
    'tts_creator_video_analysis_video_analysis_page_title',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_SORT_BY:
    'tts_creator_video_analysis_video_analysis_sort_by',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_SORT_METRIC_TIME:
    'tts_creator_video_analysis_video_analysis_sort_metric_time',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_TRANSACTION_TRANSACTION_ABILITY:
    'tts_creator_video_analysis_video_analysis_transaction_transaction_ability',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_TRANSACTION_TRANSACTION_ABILITY_TOOLTIP:
    'tts_creator_video_analysis_video_analysis_transaction_transaction_ability_tooltip',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_VIDEO_LIST:
    'tts_creator_video_analysis_video_analysis_video_list',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_VIDEO_NAME:
    'tts_creator_video_analysis_video_analysis_video_name',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_VS_LAST_28D:
    'tts_creator_video_analysis_video_analysis_vs_last_28d',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_VS_LAST_7D:
    'tts_creator_video_analysis_video_analysis_vs_last_7d',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_ANALYSIS_VS_PREVIOUS_D:
    'tts_creator_video_analysis_video_analysis_vs_previous_d',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_METRIC_COMMENT_RATE:
    'tts_creator_video_analysis_video_details_metric_comment_rate',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_METRIC_DRAINAGE_RATE:
    'tts_creator_video_analysis_video_details_metric_drainage_rate',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_METRIC_FOLLOW_RATE:
    'tts_creator_video_analysis_video_details_metric_follow_rate',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_METRIC_JUMP_RATE:
    'tts_creator_video_analysis_video_details_metric_jump_rate',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_METRIC_LIKE_RATE:
    'tts_creator_video_analysis_video_details_metric_like_rate',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_MOUDLE_TITLE_CONTENT_ANALYSIS:
    'tts_creator_video_analysis_video_details_moudle_title_content_analysis',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_MOUDLE_TITLE_INTERACTION_ANALYSIS:
    'tts_creator_video_analysis_video_details_moudle_title_interaction_analysis',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_MOUDLE_TITLE_TOP_VIDEOS:
    'tts_creator_video_analysis_video_details_moudle_title_top_videos',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_MOUDLE_TITLE_TRANSACTION_ANALYSIS:
    'tts_creator_video_analysis_video_details_moudle_title_transaction_analysis',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_MOUDLE_TITLE_VIDEO_PROFILE:
    'tts_creator_video_analysis_video_details_moudle_title_video_profile',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_MUSIC_INFO:
    'tts_creator_video_analysis_video_details_music_info',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PAGE_TITLE:
    'tts_creator_video_analysis_video_details_page_title',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_13_17:
    'tts_creator_video_analysis_video_details_pie_chart_13_17',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_18_24:
    'tts_creator_video_analysis_video_details_pie_chart_18_24',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_25_34:
    'tts_creator_video_analysis_video_details_pie_chart_25_34',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_35_44:
    'tts_creator_video_analysis_video_details_pie_chart_35_44',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_45_54:
    'tts_creator_video_analysis_video_details_pie_chart_45_54',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_55:
    'tts_creator_video_analysis_video_details_pie_chart_55',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_AGE:
    'tts_creator_video_analysis_video_details_pie_chart_age',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_FEMALE:
    'tts_creator_video_analysis_video_details_pie_chart_female',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_GENDER:
    'tts_creator_video_analysis_video_details_pie_chart_gender',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_PIE_CHART_MALE:
    'tts_creator_video_analysis_video_details_pie_chart_male',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_SELECT_ALL:
    'tts_creator_video_analysis_video_details_select_all',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_TAB_CONTENT_ANALYSIS:
    'tts_creator_video_analysis_video_details_tab_content_analysis',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_TAB_INTERACTION_ANALYSIS:
    'tts_creator_video_analysis_video_details_tab_interaction_analysis',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_TAB_TRANSACTION_ANALYSIS:
    'tts_creator_video_analysis_video_details_tab_transaction_analysis',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_TAB_VIDEO_PROFILE:
    'tts_creator_video_analysis_video_details_tab_video_profile',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_VIDEO_PROFILE_TAB_NEW_FOLLOWERS:
    'tts_creator_video_analysis_video_details_video_profile_tab_new_followers',
  TTS_CREATOR_VIDEO_ANALYSIS_VIDEO_DETAILS_VIDEO_PROFILE_TAB_WATCH_USERS:
    'tts_creator_video_analysis_video_details_video_profile_tab_watch_users',
  UPDATE_HINT_HOPUP: 'Update_Hint_Hopup',
  // for compass creator analysis from seller
  DATACOMPASS_DATE_RANGE_LAST_7_D:
    'datacompass_dataoverview_data_period_filter1',
  DATACOMPASS_DATA_RANGE_LAST_28_D:
    'datacompass_dataoverview_data_period_filter2',
  DATACOMPASS_DATA_RANGE_CALENDAR_D:
    'datacompass_dataoverview_data_period_filter3',
  DATACOMPASS_DATA_RANGE_LAST_30_D: 'data_compass_range_select_last_30_d',
  DATACOMPASS_DATA_RANGE_DAY: 'data_compass_range_select_day',
  DATACOMPASS_DATA_RANGE_WEEK: 'data_compass_range_select_week',
  DATACOMPASS_DATA_RANGE_MONTH: 'data_compass_range_select_month',
  DATACOMPASS_DATAOVERVIEW_DATA_PERIOD_FILTER_TODAY:
    'datacompass_dataoverview_data_period_filter_today',
  DATACOMPASS_DATAOVERVIEW_DATA_PERIOD_FILTER_DAY:
    'datacompass_dataoverview_data_period_filter_day',
  DATACOMPASS_DATAOVERVIEW_DATA_PERIOD_FILTER_WEEK:
    'datacompass_dataoverview_data_period_filter_week',
  DATACOMPASS_DATAOVERVIEW_DATA_PERIOD_FILTER_MONTH:
    'datacompass_dataoverview_data_period_filter_month',
  DATACOMPASS_DATAOVERVIEW_DATA_PERIOD_FILTER_CUSTOM:
    'datacompass_dataoverview_data_period_filter_custom',
  DATACOMPASS_LIVE_ANALYSIS_OVERALL_YESTERDAY:
    'datacompass_live_analysis_overall_yesterday',
  DATACOMPASS_CREATOR_ANALYSIS_TITLE: 'datacompass_creator_analysis_title',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_HIGHEST_SALES_CREATOR:
    'datacompass_creator_analysis_core_data_highest_sales_creator',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_HIGHEST_GMV_CREATOR:
    'datacompass_creator_analysis_core_data_highest_gmv_creator',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_HIGHEST_SALES_PRODUCT:
    'datacompass_creator_analysis_core_data_highest_sales_product',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_HIGHEST_GMV_PRODUCT:
    'datacompass_creator_analysis_core_data_highest_gmv_product',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_REVENUE:
    'datacompass_creator_analysis_core_data_revenue',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_EST_COMMISSION:
    'datacompass_creator_analysis_core_data_est_commission',
  DATACOMPASS_METRIC_CARD_LAST_7_D: 'datacompass_dataoverview_data_compare1',
  DATACOMPASS_METRIC_CARD_LAST_28_D: 'datacompass_dataoverview_data_compare2',
  DATACOMPASS_METRIC_CARD_LAST_30_D: 'data_compass_range_compare_last_30_d',
  DATACOMPASS_METRIC_CARD_PREV_D: 'datacompass_dataoverview_data_compare3',
  DATACOMPASS_METRIC_CARD_LAST_MONTH: 'data_compass_range_compare_last_month',
  DATACOMPASS_METRIC_CARD_LAST_WEEK: 'data_compass_range_compare_last_week',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_EST_COMMISSION_DESCRIPTION:
    'datacompass_creator_analysis_core_data_est_commission_description',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_CREATORS:
    'datacompass_creator_analysis_core_data_creators',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_CREATORS_DESCRIPTION:
    'datacompass_creator_analysis_core_data_creators_description',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_PRODUCTS:
    'datacompass_creator_analysis_core_data_products',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_PRODUCTS_DESCRIPTION:
    'datacompass_creator_analysis_core_data_products_description',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_REVENUE_DESCRIPTION:
    'datacompass_creator_analysis_core_data_revenue_description',
  DATACOMPASS_CREATOR_ANALYSIS_CORE_DATA_TITLE:
    'datacompass_creator_analysis_core_data_title',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_PRODUCT:
    'datacompass_creator_analysis_top_creators_product',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_REVENUE:
    'datacompass_creator_analysis_top_creators_revenue',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_REVENUE_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_revenue_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_LIVE_REVENUE:
    'datacompass_creator_analysis_top_creators_live_revenue',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_LIVE_REVENUE_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_live_revenue_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_VIDEO_REVENUE:
    'datacompass_creator_analysis_top_creators_video_revenue',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_VIDEO_REVENUE_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_video_revenue_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_EST_COMMISSION:
    'datacompass_creator_analysis_top_creators_est_commission',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_EST_COMMISSION_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_est_commission_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_FACT_COMMISSION:
    'datacompass_creator_analysis_top_creators_fact_commission',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_FACT_COMMISSION_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_fact_commission_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_ORDERS:
    'datacompass_creator_analysis_top_creators_orders',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_ORDERS_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_orders_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_LIVE_ORDERS:
    'datacompass_creator_analysis_top_creators_live_orders',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_LIVE_ORDERS_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_live_orders_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_VIDEO_ORDERS:
    'datacompass_creator_analysis_top_creators_video_orders',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_VIDEO_ORDERS_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_video_orders_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_BUYERS:
    'datacompass_creator_analysis_top_creators_buyers',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_BUYERS_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_buyers_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_SALES:
    'datacompass_creator_analysis_top_creators_sales',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_SALES_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_sales_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_FOLLOWERS:
    'datacompass_creator_analysis_top_creators_followers',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_FOLLOWERS_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_followers_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_EC_LIVE_STREAM:
    'datacompass_creator_analysis_top_creators_ec_live_stream',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_EC_LIVE_STREAM_DESCRIPTION:
    'datacompass_creator_analysis_top_creators_ec_live_stream_description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_EC_VIDEOS:
    'datacompass_creator_analysis_top_creators_ec_videos',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_EC_VIDEOS__DESCRIPTION:
    'datacompass_creator_analysis_top_creators_ec_videos__description',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_CREATOR_INFO:
    'datacompass_creator_analysis_top_creators_creator_info',
  DATACOMPASS_CREATOR_ANALYSIS_CUSTOM_METRICS_CREATOR_INDICATOR:
    'datacompass_creator_analysis_custom_metrics_creator_indicator',
  DATACOMPASS_CREATOR_ANALYSIS_CUSTOM_METRICS_DEAL_INDICATOR:
    'datacompass_creator_analysis_custom_metrics_deal_indicator',
  DATACOMPASS_ACCOUNT_ALLIANCE_ONLY:
    'datacompass_product_analysis_detail_prop_account_type_3',
  DATACOMPASS_CREATOR_ANALYSIS_TOP_CREATORS_TITLE:
    'datacompass_creator_analysis_top_creators_title',
  DATACOMPASS_TABLE_EXPORT_ERROR: 'datacompass_creator_export_error',
  DATACOMPASS_TABLE_EXPORT_BTN:
    'datacompass_creator_analysis_top_creators_export_excel_btn',
  DATACOMPASS_TABLE_CUSTOM_METRICS_SELECTED_INDICATORS:
    'datacompass_creator_analysis_custom_metrics_selected_indicators',
  DATACOMPASS_TABLE_CUSTOM_METRICS_SELECTED_INDICATOR:
    'datacompass_creator_analysis_custom_metrics_selected_indicator',
  DATACOMPASS_TABLE_CUSTOM_METRICS_BTN:
    'datacompass_creator_analysis_top_creators_custom_metrics_btn',
  DATACOMPASS_TABLE_CUSTOM_METRICS_TITLE:
    'datacompass_creator_analysis_custom_metrics_title',
  DATACOMPASS_TABLE_CUSTOM_METRICS_SAVE_BTN:
    'datacompass_creator_analysis_custom_metrics_save_btn',
  DATACOMPASS_TABLE_CUSTOM_METRICS_CANCEL_BTN:
    'datacompass_creator_analysis_custom_metrics_cancel_btn',
  DATACOMPASS_TABLE_CUSTOM_METRICS_SELECT_INDICATORS:
    'datacompass_creator_analysis_custom_metrics_select_indicators',
  DATACOMPASS_TABLE_CUSTOM_METRICS_DEFAULT:
    'datacompass_creator_analysis_custom_metrics_default',
  DATACOMPASS_TABLE_CUSTOM_METRIC_MIN_ONE:
    'datacompass_live_analysis_metric_selector_min_one',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_RANK:
    'datacompass_creator_analysis_overlay_rank',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_VIDEO_INFO:
    'datacompass_creator_analysis_overlay_video_info',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_VIEW_VIDEO:
    'datacompass_creator_analysis_overlay_view_video',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_LIVE_STREAM_INFO:
    'datacompass_creator_analysis_overlay_live_stream_info',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_PRODUCT_INFO:
    'datacompass_creator_analysis_overlay_product_info',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_PRODUCT:
    'datacompass_creator_analysis_overlay_product',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_SALES:
    'datacompass_creator_analysis_overlay_sales',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_REVENUE:
    'datacompass_creator_analysis_overlay_revenue',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_START_TIME:
    'datacompass_creator_analysis_overlay_start_time',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_PRODUCT_TITLE:
    'datacompass_creator_analysis_overlay_product_title',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_LIVE_STREAM_TITLE:
    'datacompass_creator_analysis_overlay_live_stream_title',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_VIDEO_TITLE:
    'datacompass_creator_analysis_overlay_video_title',
  DATACOMPASS_CREATOR_ANALYSIS_OVERLAY_OK_BTN:
    'datacompass_creator_analysis_overlay_ok_btn',
  DATACOMPASS_TABLE_NO_DATA: 'datacompass_table_no_data',

  DATACOMPASS_VIDEO_ANALYTICS_SEARCH_CREATOR_PLACEHOLDER:
    'datacompass_video_analytics_search_creator_placeholder',
  // for compass creator analysis from seller
  // ...common,
  AFFILIATE_PLAN_TITLE: 'affiliate_plan_title',
  AFFILIATE_PLAN_CORE_DATA_TITLE: 'affiliate_plan_core_data_title',
  AFFILIATE_PLAN_CORE_DATA_TARGET_TITLE:
    'affiliate_plan_core_data_target_title',
  AFFILIATE_PLAN_CORE_DATA_TARGET_AFFILIATE_REVENUE_TIPS:
    'affiliate_plan_core_data_target_affiliate_revenue_tips',
  AFFILIATE_PLAN_CORE_DATA_TARGET_AFFILIATE_ITEMS_SOLD_TIPS:
    'affiliate_plan_core_data_target_affiliate_items_sold_tips',
  AFFILIATE_PLAN_CORE_DATA_TARGET_COMMISSION_TIPS:
    'affiliate_plan_core_data_target_commission_tips',
  AFFILIATE_PLAN_CORE_DATA_TARGET_CREATORS_TIPS:
    'affiliate_plan_core_data_target_creators_tips',

  AFFILIATE_PLAN_CORE_DATA_OPEN_TITLE: 'affiliate_plan_core_data_open_title',
  AFFILIATE_PLAN_CORE_DATA_OPEN_AFFILIATE_REVENUE_TIPS:
    'affiliate_plan_core_data_open_affiliate_revenue_tips',
  AFFILIATE_PLAN_CORE_DATA_OPEN_AFFILIATE_ITEMS_SOLD_TIPS:
    'affiliate_plan_core_data_open_affiliate_items_sold_tips',
  AFFILIATE_PLAN_CORE_DATA_OPEN_COMMISSION_TIPS:
    'affiliate_plan_core_data_open_commission_tips',
  AFFILIATE_PLAN_CORE_DATA_OPEN_CREATORS_TIPS:
    'affiliate_plan_core_data_open_creators_tips',

  AFFILIATE_PLAN_CORE_DATA_SHOP_TITLE: 'affiliate_plan_core_data_shop_title',
  AFFILIATE_PLAN_CORE_DATA_SHOP_REVENUE_TIPS:
    'affiliate_plan_core_data_shop_revenue_tips',
  AFFILIATE_PLAN_CORE_DATA_SHOP_ITEMS_SOLD_TIPS:
    'affiliate_plan_core_data_shop_items_sold_tips',
  AFFILIATE_PLAN_CORE_DATA_SHOP_COMMISSION_TIPS:
    'affiliate_plan_core_data_shop_commission_tips',
  AFFILIATE_PLAN_CORE_DATA_SHOP_CREATORS_TIPS:
    'affiliate_plan_core_data_shop_creators_tips',

  AFFILIATE_PLAN_LIST_TITLE: 'affiliate_plan_list_title',
  AFFILIATE_PLAN_LIST_PLAN_INFO: 'affiliate_plan_list_plan_info',
  AFFILIATE_PLAN_LIST_CREATE_TIME: 'affiliate_plan_list_create_time',
  AFFILIATE_PLAN_LIST_PRODUCTS: 'affiliate_plan_list_products',
  AFFILIATE_PLAN_LIST_EXPORT: 'affiliate_plan_list_export',
  AFFILIATE_PLAN_CUSTOM_CANOT_CAL_TIPS: 'affiliate_plan_custom_canot_cal_tips',

  AFFILIATE_PLAN_LIST_FILTER_PLAN_NAME: 'affiliate_plan_list_filter_plan_name',
  AFFILIATE_PLAN_LIST_FILTER_PLAN_ID: 'affiliate_plan_list_filter_plan_id',
  AFFILIATE_PLAN_LIST_FILTER_PRODUCT_NAME:
    'affiliate_plan_list_filter_product_name',
  AFFILIATE_PLAN_LIST_FILTER_PRODUCT_ID:
    'affiliate_plan_list_filter_product_id',
  AFFILIATE_PLAN_LIST_FILTER_CREATOR_NAME:
    'affiliate_plan_list_filter_creator_name',

  AFFILIATE_PLAN_LIST_SEARCH_TEXT: 'affiliate_plan_list_search_text',
  AFFILIATE_PLAN_LIST_EMPTY_TIPS: 'affiliate_plan_list_empty_tips',
  AFFILIATE_PLAN_NO_DATA_TIPS: 'affiliate_plan_no_data_tips',

  AFFILIATE_OVERVIEW_DASHBOARD_TITLE: 'affiliate_overview_dashboard_title',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_TITLE:
    'affiliate_overview_dashboard_core_title',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_AFFILIATE_REVENUE:
    'affiliate_overview_dashboard_core_card_affiliate_revenue',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_LIVE_REVENUE:
    'affiliate_overview_dashboard_core_card_live_revenue',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_VIDEO_REVENUE:
    'affiliate_overview_dashboard_core_card_video_revenue',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_REVENUE_US:
    'affiliate_overview_dashboard_core_card_revenue_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_LIVE_REVENUE_US:
    'affiliate_overview_dashboard_core_card_live_revenue_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_VIDEO_REVENUE_US:
    'affiliate_overview_dashboard_core_card_video_revenue_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_ORDERS:
    'affiliate_overview_dashboard_core_card_orders',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_ORDERS_US:
    'affiliate_overview_dashboard_core_card_orders_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_EST_COMMISSION:
    'affiliate_overview_dashboard_core_card_est_commission',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_AFFILIATE_ITEMSOLD:
    'affiliate_overview_dashboard_core_card_affiliate_itemsold',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_ITEMSOLD_US:
    'affiliate_overview_dashboard_core_card_itemsold_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_REVENUE_HOVER:
    'affiliate_overview_dashboard_core_card_revenue_hover',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_REVENUE_HOVER_US:
    'affiliate_overview_dashboard_core_card_revenue_hover_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_LIVE_REVENUE_HOVER:
    'affiliate_overview_dashboard_core_card_live_revenue_hover',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_VIDEO_REVENUE_HOVER:
    'affiliate_overview_dashboard_core_card_video_revenue_hover',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_LIVE_REVENUE_HOVER_US:
    'affiliate_overview_dashboard_core_card_live_revenue_hover_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_VIDEO_REVENUE_HOVER_US:
    'affiliate_overview_dashboard_core_card_video_revenue_hover_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_ORDERS_HOVER:
    'affiliate_overview_dashboard_core_card_orders_hover',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_ORDERS_HOVER_US:
    'affiliate_overview_dashboard_core_card_orders_hover_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_EST_COMMISSION_HOVER:
    'affiliate_overview_dashboard_core_card_est_commission_hover',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_ITEMSOLD_HOVER:
    'affiliate_overview_dashboard_core_card_itemsold_hover',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_ITEMSOLD_HOVER_US:
    'affiliate_overview_dashboard_core_card_itemsold_hover_us',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_COMPARE:
    'affiliate_overview_dashboard_core_card_compare',
  AFFILIATE_OVERVIEW_DASHBOARD_CORE_CARD_COMPARE_NO_CHANGE:
    'affiliate_overview_dashboard_core_card_compare_no_change',
  AFFILIATE_OVERVIEW_DASHBOARD_FILTER_LAST_7_DAYS:
    'affiliate_overview_dashboard_filter_last_7_days',
  AFFILIATE_OVERVIEW_DASHBOARD_FILTER_LAST_28_DAYS:
    'affiliate_overview_dashboard_filter_last_28_days',
  AFFILIATE_OVERVIEW_DASHBOARD_FILTER_DAY:
    'affiliate_overview_dashboard_filter_day',
  AFFILIATE_OVERVIEW_DASHBOARD_FILTER_WEEK:
    'affiliate_overview_dashboard_filter_week',
  AFFILIATE_OVERVIEW_DASHBOARD_FILTER_MONTH:
    'affiliate_overview_dashboard_filter_month',
  AFFILIATE_OVERVIEW_DASHBOARD_FILTER_CUSTOM:
    'affiliate_overview_dashboard_filter_custom',
  AFFILIATE_OVERVIEW_CREATOR_LIST_TITLE:
    'affiliate_overview_creator_list_title',
  AFFILIATE_OVERVIEW_PRODUCT_LIST_TITLE:
    'affiliate_overview_product_list_title',
  AFFILIATE_OVERVIEW_CREATOR_LIST_MORE: 'affiliate_overview_creator_list_more',
  AFFILIATE_OVERVIEW_CREATOR_LIST_DESC: 'affiliate_overview_creator_list_desc',
  AFFILIATE_OVERVIEW_PRODUCT_LIST_DESC: 'affiliate_overview_product_list_desc',
  AFFILIATE_OVERVIEW_PRODUCT_TABLE_PRODUCT_INFO:
    'affiliate_overview_product_table_product_info',
  AFFILIATE_OVERVIEW_PRODUCT_TABLE_VIDEOS:
    'affiliate_overview_product_table_videos',
  AFFILIATE_OVERVIEW_PRODUCT_TABLE_CREATORS:
    'affiliate_overview_product_table_creators',
  AFFILIATE_OVERVIEW_PRODUCT_TABLE_NUM_OF_ITEMS:
    'affiliate_overview_product_table_num_of_items',
  AFFILIATE_OVERVIEW_VIDEO_LIST_LINK: 'affiliate_overview_video_list_link',
  AFFILIATE_OVERVIEW_VIDEO_LIST_COPY: 'affiliate_overview_video_list_copy',
  AFFILIATE_OVERVIEW_PRODUCT_LIST_TABLE_TITLE:
    'affiliate_overview_product_list_table_title',
  ECOM_AFFILIATE_DASHBOARD_EU_CONNECTION_BANNER_CONTENT:
    'ecom_affiliate_dashboard_eu_connection_banner_content',
};
