import { BaseError } from '@i18n-ecom/logcatcher';
import { ReportOptions } from '@i18n-ecom/logcatcher/lib/logger/types';

export function getNameToMakeItCompatibleWithAffiliateHostLibrary(
  name: string,
) {
  // Changing this format may cause slardar log to be ignored. Check properly before changing
  return `[error][${name}]`;
}

export class AffiliateBaseError extends BaseError {
  constructor(message: string, type: string, options?: ReportOptions) {
    super(message, type, options);
    this.name = getNameToMakeItCompatibleWithAffiliateHostLibrary(this.name);
  }
}
