export enum PartnerStatus {
  PartnerStatus_UNSPECIFIED = 0,
  NEW_CREATE = 1,
  PENDING_REVIEW = 2,
  ACTIVE = 3,
  FREEZE = 4,
  DEACTIVE = 5,
}

export enum PartnerType {
  PartnerType_UNSPECIFIED = 0,
  MCN = 1,
  TSP = 2,
}
